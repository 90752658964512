<br>
<div class="text-white glb_Font">
  <p class="glb_TitleMD glb_TitleMDMobile">{{AppName}} Password Reset</p>

  <div class="glb_paddingBottomLG">
    <p class="glb_paddingBottomXS">Please note that your password:</p>
    <li>cannot be same as your previous 10 passwords,</li>
    <li>cannot contain your LoginID, Email, First, Middle or Last Names,</li>
    <li>must be 12 to 50 characters long,</li>
    <li>contain at least one special character,</li>
    <li>contain at least one upper case character,</li>
    <li>at least one numeric digit,</li>
    <li>and contain NO spaces.</li>
  </div>

  <div class="text-white glb_customFlexRow row-cols-1 d-flex col-lg-6 justify-content-left glb_paddingBottomMD">
    <div class="glb_paddingBottomSM">
      <input tabindex="21" #InputLoginID class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" type="text"
        placeholder="Enter your Login ID" name="loginID" [(ngModel)]="UpdatePasswordData.LoginID">
    </div>

    <div class="glb_paddingBottomSM glb_floatParent">
      <input tabindex="22" #InputPassword1 class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" [(type)]="InputTypePassword"
        placeholder="Enter a password" name="password1" [(ngModel)]="UpdatePasswordData.Password1">
      <div class="glb_floatTopRight">
        <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)" (mouseup)="PasswordReveal_Toggle(true)"
          class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
          <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
          <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
        </button>
      </div>
    </div>

    <div class="glb_paddingBottomSM glb_floatParent">
      <input tabindex="23" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" [(type)]="InputTypePassword"
        placeholder="Please repeat the password" name="password2" [(ngModel)]="UpdatePasswordData.Password2" (keydown.enter)="Password_Update()">
      <div class="glb_floatTopRight">
        <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)" (mouseup)="PasswordReveal_Toggle(true)"
          class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
          <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
          <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- buttons -->
  <div class="glb_customFlexRow row-cols-1 justify-content-left">
    <div *ngIf="!IsUpdatingPassword" class="col-sm-8 col-lg-6 glb_paddingBottomSM d-grid">
      <button tabindex="24" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (keydown.enter)="Password_Update()"
        (click)="Password_Update()">
        <span class="glb_Font glb_smallerText">Update Password</span></button>
    </div>
    <div *ngIf="IsUpdatingPassword" class="col-sm-8 col-lg-6 glb_paddingBottomSM d-grid">
      <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
        <span class="glb_Font glb_hiddenText glb_smallerText">Update Password</span>
        <div class="spinner-border glb_floatingSpinner"></div>
      </button>
    </div>
  </div>

  <div class="glb_customFlexRow row-cols-1 justify-content-left">
    <div class="col-sm-8 col-lg-6 glb_paddingBottomSM d-grid">
      <button tabindex="25" class="btn glb_skinLightOutline" (click)="Home_Go()">
        <span class="glb_Font glb_smallerText glb_alternateColor">Home</span>
      </button>
    </div>
  </div>

</div>