import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import xmlFormat from 'xml-formatter';
import { MatDialogRef } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { NotifyService } from '@app/Services/NotifyService';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ClientDataStore } from '@app/Global/ClientDataStore';

@Component({
	selector: 'DocViewer',
	templateUrl: './DocViewer.html',
	styleUrls: ['./DocViewer.scss'],
})

//This class can be used to render pdfs, images and text into a component
export class DocViewer implements OnInit, OnDestroy {

	constructor(
		private sanitizer: DomSanitizer
		, private dialogRef: MatDialogRef<DocViewer>
		, public globalFunctions: GlobalFunctions
		, private notifyService: NotifyService
		, public store: ClientDataStore) {
	}

	//The Top Header section, so we can read its height at runtime and adjust the height
	@ViewChild('TopHeader') TopHeader: ElementRef;

	//Items coming from loanIndex
	@Input() LoanIndex: LoanIndex;
	@Input() FileBlob: Blob;
	@Input() FileName: string;
	@Input() FileType: string;
	@Input() OriginalFileType: string;
	@Input() DocumentGUID: string;
	@Input() EntityType: string;

	//Variables used to decide which doc viewer to use
	public ShowPDFViewer = false;
	public ShowImageViewer = false;
	public ShowTextViewer = false;
	public ShowEmailWarning = false;
	public ShowExcelWarning = false;

	//Render text mode when loading a pdf
	public PdfRenderTextMode = 1;

	//Unique Modal Identifer
	public ModalIdentifier;

	//Contains source data for pdf
	public PdfSrc: string;
	//Used to store images
	public ImageSrc: SafeUrl;
	//Used to store text
	public TxtContent: string;

	//Properties used for handling zoom levels
	readonly PdfZoomDefault: number = 0.6;
	public PdfZoom = 0.6;

	readonly ImageZoomDefault: number = 60;
	public ImageZoom = 60;

	//The height of the entire window
	public WindowHeight: number;

	//Height of the Top header section that contains the zoom/close modal buttons
	private TopHeaderHeight: number;

	//What percentage of the window height should we be rendered into. Let's try 90%
	readonly WindowRatioDefault: number = 0.90;

	ngOnInit(): void {
		//Read blob based on the file type
		this.Blob_Read(this.OriginalFileType);

		//After a short delay, update the height of the Top Header, and then the window. Delay is needed as the element may not be rendered immediately.
		this.globalFunctions.delay(30).then(() => { this.TopHeaderHeight_Sync(); });
	}

	//Sync the Top Header height from the DOM element
	private TopHeaderHeight_Sync(): void {
		//Make sure that the Top header has been assigned
		if (!this.globalFunctions.isEmpty(this.TopHeader)) {
			//Make sure that the Top header native element has been assigned
			if (!this.globalFunctions.isEmpty(this.TopHeader.nativeElement)) {
				//console.log('this.TopHeader.nativeElement.clientHeight', this.TopHeader.nativeElement.clientHeight);
				//Set the value of the top header now
				this.TopHeaderHeight = this.TopHeader.nativeElement.clientHeight;
			}
		}

		//Resize the window height too
		this.WindowHeight_Resize();
	}

	//Resizes the window height property, with the inner height of the browser window, minus an offset.
	public WindowHeight_Resize(): void {
		//console.log('this.TopHeaderHeight', this.TopHeaderHeight);
		//Resize the window height for this to be (WindowRatioDefault) percent of the window, less the (offset) height of the top header section. This helps us avoid the double scroll bar.
		//The offset is needed, because this element doesn't know how much screen space other components are taking up
		this.WindowHeight = window.innerHeight * this.WindowRatioDefault - this.TopHeaderHeight;
	}

	//Use this to catch any window resize changes, and then update any styles or behaviour
	@HostListener('window:resize', ['$event'])
	Window_OnResize(): void {
		//Sync and update the window size
		this.WindowHeight_Resize();
	}

	//Zoom pdf based on an increment
	public PdfZoom_Modify(modifyAmount: number): void {
		//Are we resetting? 0 means reset to the default zoom level
		if (modifyAmount === 0) {
			this.PdfZoom = this.PdfZoomDefault;
			return;
		}

		//Check if we have reached min or max zoom levels
		if (this.PdfZoom + modifyAmount < 0.1 || this.PdfZoom + modifyAmount > 1.5) {
			//Do nothing
		}
		else {
			this.PdfZoom = this.PdfZoom + modifyAmount;
		}
	}

	//Zoom image based on an increment
	public ImageZoom_Modify(modifyAmount: number): void {
		//Are we resetting? 0 means reset to the default zoom level
		if (modifyAmount === 0) {
			this.ImageZoom = this.ImageZoomDefault;
			return;
		}

		//Check if we have reached min or max zoom levels
		if (this.ImageZoom + modifyAmount < 10 || this.ImageZoom + modifyAmount > 100) {
			//Do nothing
		}
		else {
			this.ImageZoom = this.ImageZoom + modifyAmount;
		}
	}

	ngOnDestroy(): void {

		//Display the minimized menu dialog if applicable
		this.globalFunctions.MinimizedDialogsVisibility_Toggle(true);
	}

	//Download document
	public Document_Download(): void {

		//For these NON Gembox rendered documents, we can just donwload it here using the blob
		if (this.globalFunctions.AllowedFileTypesToDownload_Get().includes(this.OriginalFileType)) {
			//Construct a URL for file blob passed to it
			const docURL = URL.createObjectURL(this.FileBlob);

			//Lets create a html element so that we can decorate it with a custom file name.
			const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
			a.href = docURL;

			//Give it the file name that the server data gave us
			a.download = this.FileName;

			//Add and click it. this should launch a download in the browser.
			document.body.appendChild(a);
			a.click();

			//Turn the loading spinner off
			this.store.SetShowFullscreenLoading(false);

			//A notification might be a nice touch here
			this.notifyService.Info_Show("Please check your Downloads folder", "Download Completed");

			//Add a small delay before removing the injected link element.
			this.globalFunctions.delay(500).then(() => {
				document.body.removeChild(a);
				URL.revokeObjectURL(docURL);
			});
		}
		else {
			if (!this.globalFunctions.isEmpty(this.DocumentGUID)) {
				//Download document from the server
				this.LoanIndex.Document_RequestData(this.DocumentGUID, this.EntityType);
			}
			else {
				//Show warning if we don't find the document guid
				this.notifyService.Warning_Show("Document could not be downloaded.", "Warning")
			}
		}
	}

	//Reads the blob into the correct source for the relevant viewer to use
	private Blob_Read(originalFileType = ''): void {

		if (!this.globalFunctions.isEmpty(originalFileType)) {

			//Convert the filetype to upper case for comparison later
			originalFileType = originalFileType.toUpperCase();
		}

		//Check if we need to display the email preview warning about missing attachments
		if (originalFileType === 'EML' || originalFileType === 'MSG') {
			this.ShowEmailWarning = true;
		}

		//Check if we need to display the email preview warning about missing attachments
		if (originalFileType === 'XLS' || originalFileType === 'XLSX') {
			this.ShowExcelWarning = true;
		}
		//Check if FileType is null
		if (this.globalFunctions.isEmpty(this.FileType)) {
			return;
		}

		//Convert the filetype to upper case for comparison later
		const fileTypeUpper = this.FileType.toUpperCase();

		//Pdf viewer here
		if (fileTypeUpper.includes("PDF")) {
			this.ShowPDFViewer = true;

			//Check if the file is larger than 2MB
			if (this.FileBlob.size > 2097152) {

				//We noticed that the pdf viewer struggles to render larger file with render-text-mode enabled, and the app crashed. We didn't find the full documentation with this issue but when we turn off the render-text-mode, the file is loaded without any issue.
				//For now, lets disable render-text-mode
				this.PdfRenderTextMode = 0;
			}

			//This is used to read the blob into the PDF viewer
			const fileReader = new FileReader();
			fileReader.onload = (event) => {
				const eventTarget = event.target as FileReader;
				this.PdfSrc = eventTarget.result as string;
			};
			fileReader.readAsArrayBuffer(this.FileBlob);
		}
		//Image viewer here
		else if (fileTypeUpper.includes("JPG") || fileTypeUpper.includes("JPEG") || fileTypeUpper.includes("PNG")) {
			this.ShowImageViewer = true;
			const objectURL = URL.createObjectURL(this.FileBlob);
			//Trust this URL as only we supply it from a doc blob
			const safeURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
			this.ImageSrc = safeURL;
		}
		//Xml and txt file viewer here
		else if (fileTypeUpper.includes("TXT") || fileTypeUpper.includes("XML") || fileTypeUpper.includes("CSV")) {
			this.ShowTextViewer = true;
			//This is used to read the blob into the PDF viewer
			const fileReader = new FileReader();
			fileReader.onload = (event) => {
				const eventTarget = event.target as FileReader;
				this.TxtContent = eventTarget.result as string;

				if (fileTypeUpper.includes("XML")) {
					this.TxtContent = xmlFormat(this.TxtContent);
				}
			};
			fileReader.readAsText(this.FileBlob);
		}
	}
}