<!-- Spinner 
<div *ngIf="ShowSpinner" class="chart floatGrid">
	Sticking the spinner here so that it can float on top of the chart 
	<div *ngIf="ShowSpinner" class="glb_boxedPaddingLG overlay d-flex align-self-center justify-content-center onTop">
		<div class="spinner-border glb_spinnerLight glb_spinnerMD"></div>
	</div>
</div> -->

<!-- Here is the actual chart -->
<div>
	<div class="title titleMobile" [innerHTML]="name | sanitizeHtml">
	</div>
	<div [ngClass]="ShowChart()">
		<div *ngIf="ShowSpinner" class="glb_boxedPaddingLG overlay glb_customFlexRow align-self-center justify-content-center onTop"
			style="position: relative;">
			<div class="spinner-border glb_spinnerLight glb_spinnerMD"></div>
		</div>
		<canvas #ChartID id="ChartID" (click)="ShowDrillThrough($event)"></canvas>
	</div>

</div>