<div id="PG_PendingSettlements" class="glb_formBGColor glb_boxedPadding glb_Font text-white glb_Font">
  <div class="glb_Font header-padding glb_customFlexRow">
    <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">
      <div class="col-12 glb_customFlexRow glb_paddingBottomMD align-items-center glb_paddingZeroOffset">
        <div class="glb_TitleLG glb_TitleLGMobile text-start glb_fontColorSkin glb_breakLongWords glb_paddingZeroOffset col-6">
          Pending Settlements
        </div>
        <div class="col-6 glb_customFlexRow justify-content-end">
          <div
            class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">
            <div *ngIf="!IsValidationInProgress" class="glb_rightAlignedContentPadding">
              <button id="BTN_ValidateAllPendingSettlements" tabindex="2"
                class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="PendingSettlement_ValidateAll()">
                <fa-icon [icon]="['fal', 'list-check']" class="fa-fw glb_fixedSizeIcons glb_clickableIconButtonColorDark"
                  matTooltip="Click to Validate all filtered accounts">
                </fa-icon>
              </button>
            </div>
            <div *ngIf="IsValidationInProgress" class="glb_rightAlignedContentPadding">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <fa-icon [icon]="['fal', 'compact-disc']" class="fa-fw glb_fastSpin glb_fixedSizeIcons glb_clickableIconButtonColorDark"
                  matTooltip="Loading...Please wait"></fa-icon>
              </button>
            </div>
          </div>
          <div
            class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">
            <div *ngIf="!IsGenerateInProgress" class="glb_rightAlignedContentPadding">
              <button tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="PendingSettlements_Refresh()">
                <fa-icon [icon]="['fal', 'sync-alt']" class="fa-fw glb_fixedSizeIcons glb_clickableIconButtonColorDark" matTooltip="Click to Refresh">
                </fa-icon>
              </button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="glb_rightAlignedContentPadding">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <fa-icon [icon]="['fal', 'compact-disc']" class="fa-fw glb_fastSpin glb_fixedSizeIcons glb_clickableIconButtonColorDark"
                  matTooltip="Loading...Please wait"></fa-icon>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <br>
    <br>
    <div class="col-md-12 col-lg-12">
      <div
        class="glb_forceMarginAuto glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 justify-content-center align-items-center glb_paddingBottomMD">
        <div>
          <!-- This tries to mimic how a mat-form-field-label looks on the autocomplete or control types -->
          <input class="glb_formInputBoxDark glb_skinLightOutline glb_Font text-white" (keyup)='FilterSearchValueKeyUp.next($event)'
            [(ngModel)]='FilterSearchValue' cdkFocusInitial tabindex="1" type="name" placeholder="Filter">
        </div>
        <div class="glb_customFlexRow justify-content-start align-items-end">
          <span style="padding-left:0.375em;">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorDark glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="SettlementsFilter_Clear()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eraser']" matTooltip="Clear search filter">
              </fa-icon>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">
          <div
            class="glb_thinBottomWhiteBorder glb_customFlexRow col-12 glb_paddingZeroOffset row-cols-7 headerStyle glb_paddingTopSM glb_paddingBottomSM">
            <div class="glb_customFlexRow align-items-center">
              <div>Account ID</div>
            </div>
            <!-- <div class="glb_customFlexRow align-items-center">
              <div>Lender</div>
            </div> -->
            <!-- <div class="glb_customFlexRow align-items-center">
              <div>Offset Available</div>
            </div> -->
            <div class="glb_customFlexRow align-items-center">
              <div>Lender Reference</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>Product</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>Principal Borrower</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>Approved Limit</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>Approval Date</div>
            </div>
            <!-- <div class="glb_customFlexRow align-items-center">
              <div>Output</div>
            </div> -->
            <div class="glb_customFlexRow align-items-center justify-content-end">
              <div>Validation</div>
            </div>
          </div>
        </div>
        <div id="LBL_PendingSettlementsRows" *ngIf="Item_IsEmpty()" class="col-12">
          <div *ngFor="let item of PendingSettlements | searchfilter | paginate: {id: 'PendingSettlements', itemsPerPage: 15, currentPage:
        CurrentPage}" class="col-12 glb_customFlexRow glb_paddingZeroOffset">
            <div class="glb_customFlexRow glb_paddingZeroOffset glb_paddingTopSM glb_paddingBottomSM col-12 row-cols-7 borderBottom">

              <div class="glb_customFlexRow align-items-center">
                <div id="SettlementAccountID">{{item.AccountID}}</div>
              </div>
              <!-- <div class="glb_customFlexRow align-items-center">
              <div>{{TextSize_Limit(item.LenderName,20)}}</div>
            </div> -->
              <!-- <div class="glb_customFlexRow align-items-center">
              <div>{{item.OffsetDecision}}</div>
            </div> -->
              <div class="glb_customFlexRow align-items-center" matTooltip="{{item.LenderReference}}" matTooltipPosition="right"
                [matTooltipDisabled]="item.DisableLenderRefToolTip">
                <div>{{TextSize_Limit(item.LenderReference,TextDisplayLimitLenderRef)}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center" matTooltip="{{item.Product}}" matTooltipPosition="right"
                [matTooltipDisabled]="item.DisableProductToolTip">
                <div>{{TextSize_Limit(item.Product,TextDisplayLimit)}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center" matTooltip="{{item.PrincipalBorrower}}" matTooltipPosition="right"
                [matTooltipDisabled]="item.DisablePBToolTip">
                <div>{{TextSize_Limit(item.PrincipalBorrower,TextDisplayLimit)}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.ApprovedLimit_FMT}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.ApprovedDate_FMT}}</div>
              </div>
              <!-- <div class="glb_customFlexRow align-items-center">
              <div></div>
            </div> -->
              <div class="glb_customFlexRow align-items-center justify-content-end col-12">
                <div class="col-4">
                  <div class="glb_paddingZeroOffset justify-content-start glb_customFlexRow">
                    <div class="glb_customFlexRow justify-content-center align-items-center">
                      <div *ngIf="!item.IsValidating" class="glb_customFlexRow">
                        <button id="BTN_ValidatePendingSettlement" tabindex="2"
                          class="glb_standardButton glb_skinLightOutline glb_roundedBorderSM glb_BGColorSkin text-white"
                          (click)="PendingSettlement_ValidateAccount(item)">
                          <fa-icon [icon]="['fal', 'list-check']" class="fa-fw glb_fixedSizeIcons glb_clickableIconButtonColorDark"
                            matTooltip="Click to Validate" matTooltipPosition="left">
                          </fa-icon>
                        </button>
                      </div>
                      <div *ngIf="item.IsValidating" class="glb_customFlexRow">
                        <button class="glb_standardButton glb_roundedBorderSM glb_BGColorSkin text-white glb_positionRelative">
                          <fa-icon [icon]="['fal', 'compact-disc']" class="fa-fw glb_fastSpin glb_fixedSizeIcons glb_clickableIconButtonColorDark"
                            matTooltip="Loading...Please wait"></fa-icon>
                        </button>
                      </div>
                    </div>
                    <div *ngIf="item.IsValidationPassed && AllowSettlementProcessing"
                      class="glb_customFlexRow justify-content-center align-items-center">
                      <div *ngIf="!item.IsValidating" class="glb_customFlexRow">
                        <button id="LaunchSettlementConfirmation" tabindex="2"
                          class="glb_standardButton glb_skinLightOutline glb_roundedBorderSM glb_BGColorSkin text-white"
                          (click)="SettlementConfirmationModal_Launch(item)">
                          <fa-icon [icon]="['fal', 'money-check-dollar-pen']" class="fa-fw glb_fixedSizeIcons glb_clickableIconButtonColorDark"
                            matTooltip="Click to Launch Settlement Form" matTooltipPosition="left">
                          </fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="glb_customFlexRow justify-content-end align-items-center">
                    <div id="LBL_PendingSettlementValidationResult" class="text-end glb_boxedPaddingNarrow">{{item.Validation}}</div>
                    <div *ngIf="item.IsValidationPassed" class="glb_customFlexRow">
                      <span class="glb_standardButton text-white glb_positionRelative">
                        <fa-icon [icon]="['fal', 'badge-check']" class="successValidationCheck fa-fw glb_fixedSizeIcons"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="glb_customFlexRow glb_paddingTopSM col-12">
          <div class="col">
            <div class="glb_Font glb_customFlexRow justify-content-center">
              <pagination-controls class="glb_paginatorDark" id='PendingSettlements' (pageChange)="Page_OnChange($event)" [maxSize]="Paging.maxSize"
                [responsive]="true" [previousLabel]="Paging.previousLabel" [nextLabel]="Paging.nextLabel"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="glb_boxedPaddingNarrow col-12">
      <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM glb_paddingTopSM row-cols-2">
          <div class="glb_customFlexRow align-items-center col-10 glb_paddingZeroOffset justify-content-end glb_TitleMD">
            <div>Total Awaiting Approvals</div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>