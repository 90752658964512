import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { Subscription } from 'rxjs';
import { Documentation } from '@app/Components/Documentation/Documentation';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'Home',
  templateUrl: './Home.html',
  styleUrls: ['./Home.scss']
})

export class HomeComponent implements OnInit {

  //Used for navigating to other pages
  public NavigationUrls;

  //Subscription needed to sync login status with observable, so that page elements can be shown/hidden as required, e.g. if not logged in, show the Login Button
  public MySubscription: Subscription;
  public LoginData = {
    LoginID: "", UserPassword: "", UserDisplayName: "", UserEmail: "", UserLoggedInStatus: false, UserMobile: "", AccessToken: ""
  }

  public AppName = this.globalFunctions.GetApplicationName();

  //Store an array of release notes
  public ReleaseNotes: { Date: string; IsPublic: boolean; Text: string; }[];

  //Flags to hide/show features on the home page
  public ShowPrivateNotes = false;
  public ShowPendingSettlements = false;
  public ShowSLAReporting = false;

  //Unordered list needs a specific inline style to prevent bullet points from rendering outside the grid and into the margin.
  //Once release note data is migrated server side, we can use map properties and use template html on the client, and remove the in line styling here.
  private readonly UnorderedListHTML = '<ul class="glb_customFlexRow align-items-center" style="margin-left: 1em;text-align: left;">';

  constructor(private globalFunctions: GlobalFunctions, private clientDataStore: ClientDataStore, private router: Router, private dialog: MatDialog) {
    this.NavigationUrls = this.globalFunctions.navigationUrls;
  }

  ngOnInit(): void {

    this.globalFunctions.CheckBuildVersion();

    //Reset the login modal state on entry, so that it can be relaunched, if needed
    this.clientDataStore.LoginModalLaunched = false;

    //Reset error state when we hit the home page, so that we can query the server again
    this.clientDataStore.HitErrorPage = false;

    //Subscribe to LoginData so that we can hide the login button, and other elements, if needed
    this.MySubscription = this.clientDataStore.LoginData
      .subscribe(x => {
        if (!this.globalFunctions.isEmpty(x)) {
          this.LoginData = x;
        }
      })

    //Check if the user is logged in
    if (this.LoginData.UserLoggedInStatus) {

      //If they are, unlock the search bar input box.
      this.clientDataStore.Update_HideSearchBar(false);
    }

    //Testing an error
    //throw new RangeError();    

    this.ReleaseNotes_Add();

    //Check claims and decide what elements should be hidden or displayed
    //AMALEmployee claim - used to show the private/internal release notes
    if (this.globalFunctions.Claim_VerifyAMALEmployee(this.clientDataStore) === true) {
      this.ShowPrivateNotes = true;
    }

    //Pending Settlements
    if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "PendingSettlements", "Read") === true) {
      this.ShowPendingSettlements = true;
    }

    //SLA Reporting
    if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "SLAReporting", "Read") === true) {
      this.ShowSLAReporting = true;
    }
  }

  //Launch Login modal
  public LoginModal_Launch(): void {
    this.globalFunctions.LaunchLoginModal(true, "Please log in", true);
  }

  //Launch the user guide
  public UserGuide_Launch(): void {
    this.globalFunctions.FeatureModal_Launch(Documentation, this.globalFunctions.GetFeatureModalConfig("95%"), this.dialog, "User Guide", 0, false, true);
  }

  //Navigate to a url, retaining state. Used for returning to pages
  public URLWithState_Navigate(link: string, incomingState = ''): void {
    this.router.navigate([link], { state: { Link: incomingState } });
  }

  //Add release notes here
  private ReleaseNotes_Add(): void {

    //TODO Move to server side
    this.ReleaseNotes = [
      {
        Date: "23/11/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Portfolio Client editing on non-settled accounts is now available for lenders based on role permission</li>
          </ul>`
      },
      {
        Date: "16/11/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Task ID column has been added to Condition Subsequent Dashboard Report</li>
          <li class="col-12">Task ID has been added on User Tasks screen </li>
           </ul>`
      },
      {
        Date: "16/11/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
            <li class="col-12">Account Summary feature is now available to all internal users and some lenders</li>
            <li class="col-12">User Guide has been updated to include new sections for Account Status and Portfolio Client editing </li>
           </ul>`
      },
      {
        Date: "26/10/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
           <li class="col-12">Documents of size larger than 2.3 MB are now supported in document viewer</li>
          </ul>`
      },
      {
        Date: "26/10/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Anticipated Settlement Date is now editable on non-settled accounts for lenders</li>
              <li class="col-12">All linked offset accounts are frozen when a payout letter is generated with settlement booked on a loan account </li>
         </ul>`
      },
      {
        Date: "19/10/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Workflow Delegator Dashboard will no longer report tasks assigned to lender users</li>
          <li class="col-12">Account Status editing on non-settled accounts is now available for lenders based on role permission</li>
          </ul>`
      },
      {
        Date: "05/10/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Password Reset Link is now valid for 24 hours</li>
          <li class="col-12">User Link Type Filters now show values relevant to the role</li>
          </ul>`
      },
      {
        Date: "05/10/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Doc Template Generator minimizable dialog added</li>
          <li class="col-12">Dashboard minimizable dialog bug was resolved</li>
          <li class="col-12">Fixed null reference errors occurring due to empty client and document</li>
          <li class="col-12">Task Notifier Email Queuing has been changed to run synchronously</li>
          </ul>`
      },
      {
        Date: "21/09/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Feature dialogs are now minimizable, draggable and retrievable using the top header menu bar</li>
          </ul>`
      },
      {
        Date: "21/09/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Resi Repayment Calculator - with Amortization Chart, Journal Notes</li>
          <li class="col-12">Access to Discharge Type - Settlement Booked is now feature claim based</li>
          <li class="col-12">SLA Reporting - Lender List is now a user friendly multi-select</li>
          </ul>`
      },
      {
        Date: "24/08/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Task Types on Account Enquiry and User Task screens are now populated based on Lender Config</li>
          <li class="col-12">Fixed a bug where Payout Letter generation was failing due to missing address details</li>
          <li class="col-12">Reviewed Payout Letter display to support special characters</li>
          <li class="col-12">Enhanced validation for un-mapped/missing templates when generating the statement</li>
          <li class="col-12">Reviewed the display of rate class without rate details on Rates tab</li>
          </ul>`
      },
      {
        Date: "24/08/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Task Types on Account Enquiry and User Task screens have been changed from dropdown to a searchable text field</li>
          </ul>`
      },
      {
        Date: "10/08/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Fixed a bug where Loan status not updating to Pending Discharge (Resi Payout for Settlement Booked)</li>
          </ul>`
      },
      {
        Date: "10/08/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Account Header Enhancements - Highlight Arrears/Discharge Status with Red/Grey colors</li>
          <li class="col-12">Display Loan Collection Status in Orange</li>
          <li class="col-12">Show Anticipated Discharge Date when applicable</li>
          <li class="col-12">Redraw Frozen label color changed from Red to Blue</li>
          <li class="col-12">Statement Generator - Added borrower access checkbox to allow statements to be made visible to borrowers</li>
          </ul>`
      },
      {
        Date: "27/07/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Allow editing for specific custom fields, dates, identifiers and balances</li>
          <li class="col-12">Replaced Created Date with Start Date on Dashboard Reports</li>
          </ul>`
      },
      {
        Date: "13/07/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Resi Payout Calculator has been released to AMAL roles</li>
          <li class="col-12">Bank Details - Remove new line characters from Bank Account Number and Name on save</li>
          </ul>`
      },
      {
        Date: "06/07/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Bank Details - BSB No. is now validated on save</li>
          </ul>`
      },
      {
        Date: "29/06/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Bank Details - Allow only one bank detail to be added per account</li>
          <li class="col-12">Set the account enabled for DD and DC by default when a new Bank Account is added</li>
          </ul>`
      },
      {
        Date: "22/06/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Lease Payout Calculator - Performance Enhancements</li>
          <li class="col-12">Added user guide for updating Security Insurance fields</li>
          </ul>`
      },
      {
        Date: "15/06/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Show Residential Payout Calculator button on matching product class = Residential</li>
          <li class="col-12">Hide the Residential Payout Calculator button for Midkey and First Federal Prime Capital products</li>
          </ul>`
      },
      {
        Date: "08/06/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Residential Payout Calculator - Client selection filter applied on allowed link types</li>
          <li class="col-12">Missing Break Cost Fee alert has been added on accounts linked to fixed product</li>
          </ul>`
      },
      {
        Date: "01/06/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">.NET has been upgraded to 8.0</li>
          <li class="col-12">Angular upgraded to 17.0</li>
          <li class="col-12">Quill upgraded to 2.0</li>
          </ul>`
      },
      {
        Date: "01/06/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Start Date can be added when creating an Account Enquiry for Condition Subsequent task</li>
          <li class="col-12">User Guide - A new section for Account Enquiry</li>
          </ul>`
      },
      {
        Date: "18/05/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Principal Balance has been renamed to Statement Balance in the Header of the Account Page.</li>
          </ul>`
      },
      {
        Date: "27/04/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Condition subsequent and Task Notifier features are now available. Please contact AMAL Servicing for more information and to configure/enable for your roles</li>
          <li class="col-12">Fixed a bug while loading accounts when the product type is missing.</li>
          </ul>`
      },
      {
        Date: "20/04/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Reversed, dishonored and ghosted transactions are now paired with their originating transaction for ordering on the transactions screen, to allow running balances to be calculated and presented accurately.</li>
          <li class="col-12">Remove 0 value labels from charts.</li>
          <li class="col-12">Discharged accounts will now show 0 for remaining instalments.</li>
          </ul>`
      },
      {
        Date: "13/04/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">2FA grace period has been extended to 8 hours to reduce the frequency of 2FA checks on user logins within a day.</li>
          </ul>`
      },
      {
        Date: "06/04/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Task Notifier is now available. It can be configured per task type via Lender Configuration screen.</li>
          </ul>`
      },
      {
        Date: "10/02/2024"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Enhanced the display of Workflow Delegator details on the dashboard.</li>
          <li class="col-12">Lender config is now ordered alphabetically.</li>
          <li class="col-12">Resi payout calculator bug fixes.</li>
          <li class="col-12">Account Manager dashboard no longer includes Condition Subsequent tasks.</li>
          </ul>`
      },
      {
        Date: "10/02/2024"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Clone feature has a new colour and confirm flow added to differentiate from edit requests.</li>
          <li class="col-12">Added Created Date to Lender tasks.</li>
          <li class="col-12">Enhanced the display of Lender task details on the dashboard.</li>
          <li class="col-12">Status charts no longer include Offset, Retention and Prepaid accounts.</li>
          <li class="col-12">Insurance policy type is now an autocomplete field.</li>
          </ul>`
      },
      {
        Date: "9/12/2023"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Added Task Type filter on the Workflow Delegator dashboard.</li>
          </ul>`
      },
      {
        Date: "2/12/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Enhanced the display of checkboxes on the dashboard.</li>
          </ul>`
      },
      {
        Date: "4/11/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Account Index page now contains a collapsible sidebar with buttons that contain shortcuts to frequently used actions, e.g. making an account enquiry, adding a journal note, uploading a document or generating a statement etc. The old Account Enquiry button has been removed.</li>
          </ul>`
      },
      {
        Date: "07/10/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">XCHANGE emails will now be delivered from XCHANGE Service (xchange.service@amal.com.au).</li>
          </ul>`
      },
      {
        Date: "09/09/2023"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Quality feedback form is now resizable and draggable.</li>
          </ul>`
      },
      {
        Date: "02/09/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Account search response time has been improved.</li>
          <li class="col-12">User guide now appears in your page as a pop up that is dismissable, so that you can continue where you left off after viewing it.</li>
          </ul>`
      },
      {
        Date: "26/08/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Lease Payout Calculator is now supported. Look for the <img src="/LenderClient/assets/icons/LeasePayoutCalculation.png" style="width:40px"> icon on the top right of the Transactions tab. Click to Launch the Lease Payout Calculator.</li>
          </ul>`
      },
      {
        Date: "19/08/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Users can now download documents directly from the Document Viewer using a new button: <img src="/LenderClient/assets/icons/DocumentDownload.jpg" style="width:30px"></li>
          </ul>`
      },
      {
        Date: "12/08/2023"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Quality feedback review is now available.</li>
          </ul>`
      },
      {
        Date: "05/08/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Lender admins can now clone users from User Management. Role and User Link Type Filters will be copied from the user being cloned.</li>
          </ul>`
      },
      {
        Date: "15/07/2023"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Checkboxes have been added to show/hide status columns in the Workflow Delegator Dashboard.</li>
          </ul>`
      },
      {
        Date: "15/07/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Moved Valuations and Insurance Policies as nested child navigation tabs under its security property.</li>
          <li class="col-12">Checkboxes have been added to show/hide status columns in the Lender Tasks Dashboard.</li>
          <li class="col-12">A preview icon on image files is now visible on document uploads.</li>
          </ul>`
      },
      {
        Date: "8/07/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Self Employed data is now available on the Association tab, under individual type clients.</li>
          </ul>`
      },
      {
        Date: "1/07/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Number of Lender Administrators is now capped to a maximum limit.</li>
          </ul>`
      },
      {
        Date: "28/06/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Account Enquiry will now fill the task due date and time based on SLA. Users can request the task to be completed prior to the SLA by ticking the override due date checkbox and supplying a customized due date/time.</li>
          </ul>`
      },
      {
        Date: "24/06/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Transactions export functionality now filters the ghosted transactions if "Hide Ghosted" checkbox is ticked.</li>
          <li class="col-12">User assigned role is now displayed on User Management. It can be used for filtering from the user search.</li>
          <li class="col-12">Checkbox has been added to filter out the disabled users on User Management.</li>
          <li class="col-12">Download button to export the users into CSV is now available on User Management.</li>
          </ul>`
      },
      {
        Date: "03/06/2023"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">SLA Reporting Data (Grid View and Pie Chart) is now accessible by clicking "SLA Reporting" button on Home Page.</li>
          </ul>`
      },
      {
        Date: "13/05/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Passwords can no longer contain your LoginID, Email, First, Middle or Last Names.</li>
          </ul>`
      },
      {
        Date: "06/05/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">"Task Note" label has been renamed to "Instructions" on "Account Enquiry" and "User Tasks" screens.</li>
          </ul>`
      },
      {
        Date: "22/04/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">XCHANGE passwords will now expire every 42 days.</li>
          <li class="col-12">Password expiry email reminders will be sent out close to the expiry dates.</li>
          <li class="col-12">On Login, you will be reminded to change the password if close to expiry.</li>
          <li class="col-12">Once it is expired, you will need to contact your administrator to reset it.</li>
          </ul>`
      },
      {
        Date: "15/04/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Two Factor (2FA) Code is now required for a password change.</li>
          <li class="col-12">Password is required to set up/reset a 2FA Code.</li>
          <li class="col-12">Login ID is required to reset a password. Invalid password reset attempts is now limited to 5. After this, you will be required to contact your Administrator.</li>
          <li class="col-12">When updating your password, it cannot be same as your previous 10 passwords.</li>
          <li class="col-12">A button with the icon <img src="/LenderClient/assets/icons/eye-slash.jpg" style="width:40px"> has been added to reveal/hide passwords in all password type fields.</li>
          </ul>`
      },
      {
        Date: "01/04/2023"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Account settlement is now supported in XCHANGE via Pending Settlements screen.</li>
          </ul>`
      },
      {
        Date: "18/03/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Two Factor setup QR code will no longer be delivered via email. Instead, users will receive a link to view their new 2FA QR setup code on XCHANGE.</li>
          </ul>`
      },
      {
        Date: "18/02/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Ability to add user tasks or account enquiries during read only mode is now supported.</li>
          </ul>`
      },
      {
        Date: "18/02/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Password reset feature for XCHANGE borrower logins via XV8 associations page (under Client Custom Fields tab) is now available.</li>
          <li class="col-12"><img src="/LenderClient/assets/screenshots/ClientPasswordReset.jpg" style="width:300px"></li>
          </ul>`
      },
      {
        Date: "11/02/2023"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">IP Whitelisting feature is now available. Please contact AMAL for more details.
          </li>
          <li class="col-12">Control click to select multiple tasks on Workflow delegator is now available. Regular clicks will now select individual tasks only.</li>
          <li class="col-12">Support to add new Scheduled Transfers is now available.</li>
          </ul>`
      },
      {
        Date: "14/01/2023"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">User option to hide the discharge accounts in the search result has been added. This can be accessed via the user profile by clicking on your name at the top of the page. It can be toggled on or off at any time and will be applied on your next search results. This option will be retained between sessions.</li>
          <li class="col-12"><img src="/LenderClient/assets/icons/UserOptions.jpg" style="width:225px">
          </li>
          </ul>`
      }, {
        Date: "24/12/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Borrower XCHANGE login info has been added. Data includes Account Locked Status, Registration, Security Questions and Terms and Conditions. These are listed under each relevant client on the Assocations Entity, in the Client Custom Fields tab.
          </li>
          </ul>`
      },
      {
        Date: "17/12/2022"
        , IsPublic: false
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Residential Payout Calculator is now supported. Look for the <img src="/LenderClient/assets/icons/PayoutCalculation.png" style="width:40px"> icon on the top right of the Transactions tab. Click to Launch the Residential Payout Calculator.
          </li>          
          </ul>`
      },
      {
        Date: "17/12/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Optimized search result algorithm - Linked accounts will no longer appear as individual results, and now only appear inside its parent facility, and will have it's identifiers highlighted when there is a text match on it.
          </li>   
          <li class="col-12">Account search using borrower's XCHANGE V6 login ID is now supported.
          </li>          
          </ul>`
      },
      {
        Date: "10/12/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Login flow updated - you will now land on the Home Page after logging in, and be able to view release notes from there, instead of on the public landing page.
          </li>
          <li class="col-12">The site will now display <span class="btn btn-warning glb_paddingBoxZeroOffset">Read Only</span> at the top left during the End of Day Processing (EOD), giving a visual indicator when XCHANGE is in Read only mode.</li>
          <li class="col-12">A new permission added to allow Bank detail masking - Account numbers will be masked with asterisks, only displaying the last 3 digits, e.g. ******342. This can be applied to existing or new roles as desired. Please contact AMAL Servicing if you want this feature added to any roles.
          </li>
          </ul>`
      },
      {
        Date: "03/12/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Statement Generation support has now been added! <br/>Look for the 
                <img src="/LenderClient/assets/icons/statementgenerator.jpg" style="width:40px">
                icon on the top right of the Transaction tab. Choose a From and To Date, and then click Generate. Note that all statements that are generated will also be saved inside the Documents tab.<br/>
                Please contact AMALServicing Team for access to this feature.</li>
          <li class="col-12">Document Generation from a template is now supported.<br/>
                Look for the <img src="/LenderClient/assets/icons/DocumentGenerator.JPG" style="width:40px"> icon on the top right of the Documents tab. Select the document template, and then click Generate.</br>
                Please contact AMALServicing Team to this feature.</li>
          </ul>`
      },
      {
        Date: "03/12/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Call Note Type is now available on Notes.</li>
          </ul>`
      },
      {
        Date: "12/11/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Loan search based on the EFT Account Number is now supported.</li>
          </ul>`
      },
      {
        Date: "06/11/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Document viewer now supports Excel (xls, xlsx - first tab only) preview in pdf format.</li>
          <li class="col-12">Document viewer also supports csv preview.</li>
          </ul>`
      },
      {
        Date: "26/10/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + `
          <li class="col-12">Bugfix - Viewing certain pdf documents was failing due to textmode rendering. This affected pdf documents that contain input text fields. They should now be viewable.</li>
          </ul>`
      },

      {
        Date: "24/09/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + ` 
          <li class="col-12">Allow the search bar to be clicked and used, even while currently looking at an account. Users should no longer need to click on the Search Icon or return to the Home or Dashboard screens to start a new search.
          </ul>`
      },

      {
        Date: "17/09/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + ` 
          <li class="col-12">Add a Redraw Frozen label in red, like this:             
          <span class=""> 
          <button class="glb_standardButtonNarrow btn glb_BGColorDanger glb_removeCursor text-white">
          <span class="glb_Font glb_smallerText">Redraw Frozen</span>
          </button>
          </span>
          in the account index page header, which will display when the Account Redraw is frozen.
          </li>
          <li class="col-12">Add a "Update Client Contact Details" section to the User Guide. This gives a specific example on how to update the email address for a client.          
          </li>
          </ul>`
      },

      {
        Date: "09/09/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + ` 
          <li class="col-12">Add document viewer support for emails (eml and msg files) and for MS Word doc/docx files. Look for the 
          <img src="/LenderClient/assets/icons/eye.jpg" style="width:40px"> icon in the Action Bar and click on it to view the document within the page.
          </li>
          <li class="col-12">In the Associations tab, show the "XCHANGEv6 Login Active" status as a new field under each client, in the "Client Custom Fields" section. A true value indicates that the client has registered and has an active LoginID to the Borrower website. A false value indicates that the borrower has not registered, or has been disabled (due to the account being discharged, for example).
          </li>
          </ul>`
      },

      {
        Date: "03/09/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + ` 
          <li class="col-12">On the Associations tab, Contacts, Addresses and Employments will now start in an uncollapsed state, making it faster and easier to view these data points, with less clicks required.
          </li>
          <li class="col-12">On the Account Details tab, under Sub Details, there will now be a new field called "Remaining Total Instalments (months)". This will calculate and display the number of months left until the maturity date of the contract.
          </li>
          </ul>`
      },

      {
        Date: "30/08/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + ` 
          <li class="col-12">Account Enquires section on the Dashboard page temporarily removed. Will be reintroduced at a later date.
          </li>
          <li class="col-12">On the Facility tab, the Linked Accounts section now shows a new column for the Lender Reference Number next to the Account ID, to make it easier to identify accounts using either one.
          </li>
          </ul>`
      },

      {
        Date: "28/08/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + ` 
          <li class="col-12">Document viewer support added, look for the 
          <img src="/LenderClient/assets/icons/eye.jpg" style="width:40px">
          icon in the Action Bar and click on it to view the document within the page. Currently supported file types are pdf, docx (NOT doc), images (png, jpg), txt and xml.</li>
          <li class="col-12">The notes column in the notes tab has been moved to be the first, leftmost column, and text inside it now left aligned, to improve readability.</li> 
          </ul>`
      },

      {
        Date: "09/08/2022"
        , IsPublic: true
        , Text:
          this.UnorderedListHTML + ` 
          <li class="col-12">Fix a minor typo on the 2-factor registration setup email</li>
          </ul>`
      },
    ];

  }
}