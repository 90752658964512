<br>
<div class="text-white glb_Font col-lg-8">
  <p class="glb_TitleLG glb_TitleLGMobile">{{appName}} User Registration</p>
  <p class="">Please enter your Login ID (received via email) and create a new password.</p>

  <div class="col-lg-12" style="padding-bottom: 2.2em;">
    <p style="padding-bottom: 0.2em;">Please note that your password must be:</p>
    <li>12 to 50 characters long,</li>
    <li>cannot contain your LoginID, Email, First, Middle or Last Names,</li>
    <li>contain at least one special character,</li>
    <li>contain at least one upper case character,</li>
    <li>at least one numeric digit,</li>
    <li>and contain NO spaces.</li>
  </div>

  <div class="text-white glb_customFlexRow row-cols-1 d-flex col-lg-12 justify-content-left" style="padding-bottom: 1.2em;">
    <div style="padding-bottom: 8px;">
      <input #focusField1 cdkFocusInitial tabindex="21" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font"
        placeholder="Enter your Login ID" name="userNumber" [(ngModel)]="userRegisterData.UserNumber">
    </div>

    <div style="padding-bottom: 8px;" class="glb_floatParent">
      <input #focusField2 tabindex="22" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" [(type)]="InputTypePassword"
        placeholder="Enter a password" name="password1" [(ngModel)]="userRegisterData.Password1">
      <div class="glb_floatTopRight">
        <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)" (mouseup)="PasswordReveal_Toggle(true)"
          class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
          <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
          <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
        </button>
      </div>
    </div>

    <div style="padding-bottom: 8px;" class="glb_floatParent">
      <input #focusField3 tabindex="23" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" [(type)]="InputTypePassword"
        placeholder="Please repeat the password" name="password2" [(ngModel)]="userRegisterData.Password2"
        (keydown.enter)="RegisterUser(UserRegistrationSuccess)">
      <div class="glb_floatTopRight">
        <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)" (mouseup)="PasswordReveal_Toggle(true)"
          class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
          <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
          <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <br>
  <!-- terms and conditions -->
  <div class="" style="padding-bottom: 1.2em;">
    <p class="glb_TitleLG glb_TitleLGMobile">{{appName}} Terms and Conditions</p>
    Registering for access to XCHANGE requires you to read and accept the following Terms and Conditions.<br />
    To accept these, you must tick the &quot;I Accept the Terms and Conditions&quot; box at the bottom of the page.
    <br />
    <br />
    <div>
      <p class="glb_TitleMD glb_TitleMDMobile">Definitions</p>
      <!-- <p class="glb_paddingBottomLG"> -->

      <ul class="glb_paddingBottomSM">
        <p class="col-lg-12 glb_PaddingHorizontalMD">
          <li><b>ACCESS CODES</b> means any user ID and/or passwords used to access AMAL XCHANGE.</li>
          <li><b>AMAL</b> means AMAL Asset Management Limited (ABN 31 065 914 918) and any other related entity.</li>
          <li><b>AMAL XCHANGE</b> means web services, application programming interfaces (APIs) and other associated software, provided by AMAL to
            allow
            you access to the Data.</li>
          <li><b>DATA</b> means all information able to be viewed and/or edited on AMAL XCHANGE.</li>
          <li><b>INTELLECTUAL PROPERTY RIGHTS</b> means AMAL&apos;s rights in AMAL XCHANGE, including rights to patents, trademarks, service marks,
            inventions, copyright, know how whether or not registered, and any related enhancements or modifications.</li>
        </p>
      </ul>

      <p class="glb_TitleMD glb_TitleMDMobile">No representation or warranty</p>
      <p class="glb_paddingBottomSM">
        All Data contained on AMAL XCHANGE is given in good faith and has been derived from sources believed to be
        accurate. However, the Data is selective, has not been verified by AMAL and it may not be complete or accurate for your purposes. AMAL makes
        no representation or warranty of any kind as to the accuracy or completeness of the Data. It is general information only and should not be
        considered as a comprehensive statement on any matter and should not be relied upon as such.</p>

      <p class="glb_TitleMD glb_TitleMDMobile">No responsibility</p>
      <p class="glb_paddingBottomSM">
        Neither AMAL nor any of its directors, employees and associates guarantees the security of AMAL XCHANGE, gives
        any warranty of reliability or accuracy nor accepts any responsibility arising in any other way, including by reason of negligence for, errors
        in, or omissions from, the Data on AMAL XCHANGE and does not accept any liability for any loss or damage, however caused, as a result of any
        person relying on any Data on AMAL XCHANGE or being unable to access AMAL XCHANGE. This disclaimer is subject to any applicable contrary
        provisions of the Australian Securities and Investments Commission Act and Trade Practices Act.</p>

      <p class="glb_TitleMD glb_TitleMDMobile">System access</p>
      <p class="glb_paddingBottomSM">
        You agree to take reasonable steps to maintain the security of your hardware and software and to sign/log off
        XCHANGE before leaving a computer unattended. In order to access AMAL XCHANGE you will need to enter your Access Codes. You agree to take
        reasonable steps to keep your Access Codes confidential and agree not to share your Access Codes with any other person(s). AMAL may suspend or
        terminate your use of AMAL XCHANGE at any time.</p>

      <p class="glb_TitleMD glb_TitleMDMobile">Ownership and Restrictions</p>
      <p class="glb_paddingBottomSM">The Intellectual Property Rights in AMAL XCHANGE belong to AMAL.</p>

      <p class="glb_TitleMD glb_TitleMDMobile">No Damage</p>
      <p class="glb_paddingBottomSM">
        You agree not to transfer, or attempt to transfer, any material, viruses or code to AMAL XCHANGE that could cause damage or harm or in
        any way impede the security of AMAL XCHANGE. You may not attempt to alter AMAL XCHANGE in any way.</p>

      <p class="glb_TitleMD glb_TitleMDMobile">No liability</p>
      <p class="glb_paddingBottomSM">
        To the extent permitted by law, neither AMAL, nor any other company in the AMAL Group of companies (including all
        related bodies corporate), will be liable for any loss or damage from any cause (including negligence) to your site and system, or to people
        linking to AMAL xChange from your site(s), caused by or in connection with your link to AMAL XCHANGE. If you create a link to AMAL XCHANGE,
        you remain fully responsible for any consequences of that link, whether direct or indirect and you will protect AMAL against all loss, damage,
        liability, cost or expense arising from or in connection with the link.</p>

      <p class="glb_TitleMD glb_TitleMDMobile">Indemnity</p>
      <p class="glb_paddingBottomSM">
        You agree to indemnify AMAL and keep it indemnified against all actions, claims, costs, demands, damages or
        liability arising in any manner from a breach by you of the terms of this agreement.</p>

      <p class="glb_TitleMD glb_TitleMDMobile">Change of terms</p>
      <p class="glb_paddingBottomSM">
        AMAL may change the terms of this agreement solely at its discretion. Where possible, AMAL will try to notify you
        of any such changes prior to their implementation.</p>
      <br />
    </div>

    <!-- empty input so that we can snap to this as needed -->
    <div class="glb_paddingBottomLG userInvite glb_TitleMD glb_TitleMDMobile col-12">
      <mat-checkbox [(ngModel)]="acceptTermsAndConditions" class="glb_checkBoxMargin mat-checkbox-dark-custom mat-checkbox-customLargeText ">
        I Accept the Terms and Conditions
      </mat-checkbox>
    </div>
  </div>

  <!-- buttons -->
  <div class="glb_customFlexRow row-cols-1 justify-content-left">
    <div *ngIf="!isUpdatingPassword" class="col-sm-8 col-lg-12 d-grid" style="padding-bottom: 8px;">
      <button tabindex="24" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
        (keydown.enter)="RegisterUser(UserRegistrationSuccess)" (click)="RegisterUser(UserRegistrationSuccess)">
        <span class="glb_Font glb_smallerText">Register</span></button>
    </div>
    <div *ngIf="isUpdatingPassword" class="col-sm-8 col-lg-12 d-grid" style="padding-bottom: 8px;">
      <button class="glb_standardButton btn glb_BGColorSkin text-white" style="position: relative;">
        <span class="glb_Font glb_hiddenText glb_smallerText">Register</span>
        <div class="spinner-border glb_floatingSpinner"></div>
      </button>
    </div>
  </div>

  <div class="glb_customFlexRow row-cols-1 justify-content-left">
    <div style="padding-bottom: 8px;" class="col-sm-8 col-lg-12 d-grid">
      <button tabindex="25" class="btn glb_skinLightOutline" (click)="GoHome()">
        <span class="glb_Font glb_smallerText" style="color:grey">Home</span></button>
    </div>
  </div>

</div>

<ng-template #UserRegistrationSuccess>
  <div class="glb_boxedPadding glb_formBGColor glb_Font">
    <div class="text-white glb_Font" style="padding-bottom: 1.2em;">
      <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
      <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
          <button [disabled]="isUpdatingPassword" tabindex="29"
            class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
            (click)="LauchLoginModal()">
            <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
          </button>
        </div>
      </div>

      <div class="glb_customFlexRow" style="padding-bottom: 1.2em;">
        <p class="glb_TitleMD glb_TitleMDMobile">User Registration Successful</p>
      </div>
      <div class="glb_customFlexRow" style="padding-bottom: 1.2em;">
        <div cdkFocusInitial [innerHTML]="responseMessageHTML | sanitizeHtml"></div>
      </div>
      <!-- buttons -->
      <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div *ngIf="!isUpdatingPassword" style="padding-bottom: 8px;" class="d-grid">
          <button tabindex="28" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (keydown.enter)="LauchLoginModal()"
            (click)="LauchLoginModal()">
            <span class="glb_Font glb_smallerText">Login</span></button>
        </div>
        <div *ngIf="isUpdatingPassword" style="padding-bottom: 8px;" class="d-grid">
          <button class="glb_standardButton btn glb_BGColorSkin text-white" style="position: relative;">
            <span class="glb_Font glb_hiddenText glb_smallerText">Login</span>
            <div class="spinner-border glb_floatingSpinner"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>