<div *ngIf="ShowSpinner">
	<div class="row">
		<div class="col">
			<div class="glb_Font d-flex justify-content-center text-white">
				Loading...
			</div>
		</div>
	</div>
	<!-- this is the loading spinner -->
	<div class="row">
		<div class="col">
			<div class="d-flex justify-content-center">
				<div class="loadingio-spinner-spinner-z47l50pe76s">
					<div class="ldio-2q8e2dt3kyi">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!ShowSpinner">

	<div *ngIf="EnableWorkflowDelegator" class="topAndBottomPadding">
		<WorkflowDelegator #WorkflowDelegator [dashboard]="this">
			<!-- <WorkflowDelegator #workflowDelegator> -->
		</WorkflowDelegator>
	</div>

	<div *ngIf="EnableAccountManager" class="topAndBottomPadding">
		<AccountManager #AccountManager [dashboard]="this">
		</AccountManager>
	</div>

	<div *ngIf="EnableQualityCheckingQueue" class="topAndBottomPadding">
		<QualityCheckingQueue #QualityCheckingQueue [dashboard]="this">
		</QualityCheckingQueue>
	</div>

	<div *ngIf="EnableLenderTasks" class="topAndBottomPadding">
		<LenderTasks #LenderTasks [Dashboard]="this">
		</LenderTasks>
	</div>

	<div *ngIf="EnableConditionSubsequent" class="topAndBottomPadding">
		<ConditionSubsequent #ConditionSubsequent [Dashboard]="this">
		</ConditionSubsequent>
	</div>

	<!-- Disabled as requested by Leanne -->
	<!-- <div *ngIf="EnableAccountEnquiries" class="topAndBottomPadding">
		<AccountEnquiries #AccountEnquiries [dashboard]="this">
		</AccountEnquiries>
	</div> -->

	<div *ngIf="EnableChartViewer" class="topAndBottomPadding">
		<!-- some header buttons for refreshing the chart data. let's move these to the chart section -->
		<div *ngIf="!NoCharts" class="glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
			<div class="glb_customFlexRow justify-content-end glb_PaddingHorizontalSM someTopPadding">
				<span class="chartLeftPadding">
					<button (click)="RefreshData(true)"
						class="glb_clickableIconButtonStyle glb_clickableIconButtonColorDark glb_clickableIconButtonMD align-self-right">
						<fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'sync']" matTooltip="Refresh Chart Data">
						</fa-icon>
					</button>
				</span>
			</div>
		</div>

		<!-- Create a row per lender using an ng for loop. Custom Paginator is linked here using the pipe paginate -->
		<div *ngFor="let lenderItem of LenderArray | paginate: PaginatorConfig">
			<div class="glb_customFlexRow lenderTitle lenderTitleMobile">{{lenderItem}}</div>
			<div class="glb_customFlexRow justify-content-center row-cols-1 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2">
				<!-- A row per chart definition -->
				<ng-container *ngFor="let chartDef of ChartDefs">
					<div *ngIf="GetChildProperty(lenderItem + chartDef.chartName)" class="chartPaddingVert">
						<div class="lenderChartContainer">
							<div class="chartPadding chartPaddingMobile">
								<!-- Bind these to a variable #chart so that the parent component can access them via ViewChildren -->
								<CustomChart #chart [LenderName]="lenderItem" [ChartName]="chartDef.chartName" [ColumnName]="chartDef.chartColumns"
									[FormatCurrency]="chartDef.formatCurrency" [ChildChartProps]="ChildChartProps" [ChartType]='chartDef.chartType'
									[LabelColor]="LabelColor" [ChartDataStyle]="chartDef.chartDataStyle" [IsStacked]="chartDef.isStacked"
									[GroupByPortfolio]="chartDef.groupByPortfolio" [DashboardArrayFuture]="DashboardArrayFuture"
									[DashboardArrayHistorical]="DashboardArrayHistorical" [ChartDrawDelay]="ChartDrawDelay"
									class="lenderChartContainer" [ShowAllDataLabels]="ShowAllDataLabels">
								</CustomChart>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<div class="glb_customFlexRow">
			<div class="col-12">
				<div class="glb_Font glb_customFlexRow justify-content-center col-12 row-cols-1">
					<!-- Custoim paginator using our own tempate -->
					<pagination-template #p="paginationApi" [id]="PaginatorConfig.id" (pageChange)="OnPageChange($event)"
						(pageBoundsCorrection)="PaginatorConfig.pageBoundsCorrection.emit($event)">
						<nav role="navigation" [attr.aria-label]="PaginatorConfig.screenReaderPaginationLabel"
							class="glb_customFlexRow justify-content-center">
							<ul class="glb_customFlexRow justify-content-center col-12 row-cols-10 ngx-paginationCustom"
								[class.responsive]="PaginatorConfig.responsive" *ngIf="!(PaginatorConfig.autoHide && p.pages.length <= 1)">
								<li class="minHeight pagination-previous" [class.disabled]="p.isFirstPage()" *ngIf="PaginatorConfig.directionLinks">
									<a class="minHeight glb_customFlexRow align-items-center justify-content-center" tabindex="0"
										*ngIf="1 < p.getCurrent()" (keyup.enter)="p.previous()" (click)="p.previous()">
										{{ PaginatorConfig.previousLabel }}
										<!-- <span class="centerText show-for-sr">{{ PaginatorConfig.screenReaderPageLabel }}</span> -->
									</a>
									<a class="minHeight glb_customFlexRow align-items-center justify-content-center" *ngIf="p.isFirstPage()"
										aria-disabled="true">
										{{ PaginatorConfig.previousLabel }}
										<!-- <span class="centerText show-for-sr">{{ PaginatorConfig.screenReaderPageLabel }}</span> -->
									</a>
								</li>
								<li class="small-screen minHeight glb_customFlexRow align-items-center justify-content-center">
									<span vlass="centerText">
										{{ p.getCurrent() }} / {{ p.getLastPage() }}
									</span>
								</li>
								<li class="minHeight " [class.current]="p.getCurrent() === page.value" [class.ellipsis]="page.label === '...'"
									*ngFor="let page of p.pages; trackBy: PaginatorConfig.trackByIndex">
									<a class="minHeight glb_customFlexRow align-items-center justify-content-center" tabindex="0"
										(keyup.enter)="p.setCurrent(page.value)" (click)="p.setCurrent(page.value)"
										*ngIf="p.getCurrent() !== page.value">
										<span class="centerText">{{ (page.label === '...') ? page.label : GetLenderNameFromPage(page.value) }}</span>
										<!-- These screenreader helpers cause more trouble than they are worth. Leave it out for now -->
										<!-- <span class="show-for-sr">{{ PaginatorConfig.screenReaderPageLabel }} </span> -->
									</a>
									<ng-container *ngIf="p.getCurrent() === page.value">
										<a class="minHeight glb_customFlexRow align-items-center justify-content-center">
											<span class="centerText">{{ (page.label === '...') ? page.label : GetLenderNameFromPage(page.value)
												}}</span>
											<!-- These screenreader helpers cause more trouble than they are worth. Leave it out for now -->
											<!-- 	<span aria-live="polite">
												<!~~ <span class="show-for-sr">{{ PaginatorConfig.screenReaderCurrentLabel }} </span> ~~>
											</span> -->
										</a>
									</ng-container>
								</li>
								<li class="minHeight glb_customFlexRow align-items-center pagination-next" [class.disabled]="p.isLastPage()"
									*ngIf="PaginatorConfig.directionLinks">
									<a class="minHeight glb_customFlexRow align-items-center justify-content-center" tabindex="0"
										*ngIf="!p.isLastPage()" (keyup.enter)="p.next()" (click)="p.next()">
										{{ PaginatorConfig.nextLabel }}
										<!-- These screenreader helpers cause more trouble than they are worth. Leave it out for now -->
										<!-- <span class="show-for-sr">{{ PaginatorConfig.screenReaderPageLabel }}</span> -->
									</a>
									<a class="minHeight glb_customFlexRow align-items-center justify-content-center" *ngIf="p.isLastPage()"
										aria-disabled="true">
										{{ PaginatorConfig.nextLabel }}
										<!-- These screenreader helpers cause more trouble than they are worth. Leave it out for now -->
										<!-- <span class="centerText show-for-sr">{{ PaginatorConfig.screenReaderPageLabel }}</span> -->
									</a>
								</li>
							</ul>
						</nav>
					</pagination-template>
				</div>
			</div>
		</div>
	</div>

	<!-- No charts provided, must have no lender claim. Show a standard message -->
	<div *ngIf="NoCharts">
		<br>
		<br>
		<div class="glb_customFlexRow">
			<div class="col">
				<div class="glb_Font glb_Title glb_TitleMobile glb_customFlexRow d-flex justify-content-center text-white">
					<p>Welcome to your XCHANGE dashboard</p>
				</div>
				<div class="glb_Font glb_TitleSmall glb_TitleSmallMobile glb_customFlexRow d-flex justify-content-center text-white">
					Search for an account using the search bar above.
				</div>
			</div>
		</div>
	</div>
</div>