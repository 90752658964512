<div id="PG_UserOptions" class="glb_formBGColor text-white glb_Font glb_paddingBottomLG" [ngClass]="GetClass()">
  <div class="col-md-12 col-lg-8 glb_paddingZeroOffset">
    <p class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomSM glb_fontColorSkin">User Options</p>
    <div *ngIf="!ShowSpinner">
      <div class="glb_customFlexRow">
        <div class="glb_customFlexRow">
          <!-- <div>
            <p class="text-start glb_breakLongWords glb_TitleMD glb_TitleMDMobile">User Defined Options</p>
          </div> -->
        </div>
        <div class="glb_customFlexRow col-12">
          <div class="col-12">
            <div class="glb_paddingBottomSM userOptionsCheckbox">
              <mat-checkbox id="CB_HideDischargedAccounts" [(ngModel)]="this.HideDischargedLoansInSearch"
                class="glb_checkBoxMargin mat-checkbox-dark-custom glb_Font" (change)="UserOption_Toggle('HideDischargedLoansInSearch')">Hide
                Discharged Accounts In Search Results
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="ShowSpinner" class="col-12 glb_paddingZeroOffset">
    <!-- This is the loading spinner, lets show it while the entity is loading -->
    <div class="glb_customFlexRow">
      <div class="col">
        <div class="glb_customFlexRow justify-content-center glb_paddingBottomSM">
          <div class="spinner-border" style="width: 3rem; height: 3rem;"></div>
        </div>
        <div class="glb_customFlexRow justify-content-center">Loading</div>
      </div>
    </div>
  </div>
</div>