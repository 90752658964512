import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientDataStore, StoreItemTypes } from '@app/Global/ClientDataStore';
import { Subscription } from 'rxjs';
import { NavigationUrls, RouteActions } from '@app/Global/EnumManager';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { NotifyService } from '@app/Services/NotifyService';

@Component({
	selector: 'LoanSearch',
	templateUrl: './LoanSearch.html',
	styleUrls: ['./LoanSearch.scss'],
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: '0' }),
				animate('0.1s ease-out', style({ opacity: '1' })),
			]),
		]),
	]
})
export class LoanSearch implements OnInit, OnDestroy {
	//using local variables now. observables that have an aync pipe doesn't appear to  update when the page is routed back to it. i think it may be related to these bugs: https://github.com/angular/angular/issues/13865 or https://github.com/angular/angular/issues/18469
	//local variables to store search results. is subscribed so should get any updates
	public searchResults: any[];
	public resultsCounter = 0;
	public IsSearchingLoans: boolean;
	public emptyResultSet = true;
	public keywords: any;
	//storing the list of last searched loans
	public lastClickedAccounts: any[];
	public showLastSearchedLoans = false;
	public lastClickedListName: string;

	//for storing the subscriptions
	private searchSubscription: Subscription;
	private isSearchingSubscription: Subscription;

	//used by paginator
	public paging = {
		maxSize: 10,
		previousLabel: "",
		nextLabel: ""
	}
	public currentPage: number;

	private navState: string;

	constructor(private clientDataStore: ClientDataStore, private router: Router, private globalFunctions: GlobalFunctions, private notifyService: NotifyService) {

		this.setRouterState();
		//console.log("hitting search page");
		if (this.navState === RouteActions[RouteActions.ReturnToSearch]) {
			//console.log("returning to search page");
			//use session storage for this one, since we don't want to pop them from another session
			const prev = JSON.parse(sessionStorage.getItem(StoreItemTypes[StoreItemTypes.PreviousSearchResults]));
			if (!this.globalFunctions.isEmpty(prev)) {
				//console.log("restoring prior results to search page");
				this.clientDataStore.UpdateSearchResults(prev);
			}
			//we are back to the search page. show the search bar in the header
			this.clientDataStore.Update_HideSearchBar(false);
		}
		else {
			//console.log("search page regular hit");
			this.clientDataStore.UpdateSearchResults(null);
			this.clientDataStore.UpdateIsSearchingAccounts(false);
			//show the search bar when we start
			this.clientDataStore.Update_HideSearchBar(false);
		}

		//grab the list of last searched loans from storage
		this.lastClickedListName = this.clientDataStore.loginDataDirect.LoginID + StoreItemTypes[StoreItemTypes.LastClickedAccounts];
		//if its non empty
		if (!globalFunctions.isEmpty(localStorage.getItem(this.lastClickedListName))) {
			//then set it on the class variable here
			this.lastClickedAccounts = JSON.parse(localStorage.getItem(this.lastClickedListName));
		}

		//now check if there are any entries, and we can flip the bool to show them on initialize
		if (!globalFunctions.isEmpty(this.lastClickedAccounts) && this.lastClickedAccounts.length > 0) {
			this.showLastSearchedLoans = true;
		}
		else {
			//empty, so lets init the variable ourself as empty. this is needed so that we don't get null errors
			this.lastClickedAccounts = [];
		}
	}

	private setRouterState() {
		const navigation = this.router.getCurrentNavigation();
		const state = navigation.extras?.state as {
			Link: string;
		};

		if (state != null) {
			this.navState = state.Link;
		}
	}

	escapedKeywords: any = "";

	ngOnInit(): void {
		this.searchSubscription = this.clientDataStore.SearchResults
			.subscribe(searchResults => {
				if (searchResults != null) {

					//Update our class variable based on the search result in the store
					this.searchResults = searchResults

					//Let's use a local counter to force the div to appear/hide properly. the async pipe doesn't work with observables after routing has been done.
					this.resultsCounter = searchResults.length;
					this.clientDataStore.UpdateIsSearchingAccounts(false);

					//Turn off the loading screen
					this.IsSearchingLoans = false;

					//and update keywords, so that the empty result screen can show properly too!
					this.keywords = this.clientDataStore.GetItemByType(StoreItemTypes.SearchKeywords)
					//console.log('this.keywords', this.keywords);

					//Add html escaping to the search keyword, or the matching wont work.
					if (!this.globalFunctions.isEmpty(this.keywords)) {

						//Deep clone it
						this.escapedKeywords = JSON.parse(JSON.stringify(this.globalFunctions.HTMLEscape(this.keywords)));
					}
					else {
						this.escapedKeywords = "";
					}
					//console.log('this.escapedKeywords', this.escapedKeywords);

					//Cache this set of results for repopulating if the user comes back to this page
					sessionStorage.setItem(StoreItemTypes[StoreItemTypes.PreviousSearchResults], JSON.stringify(searchResults));

					//Did we have some results?
					if (searchResults.length > 0) {

						//Yes, some results. Hide the empty result set
						this.emptyResultSet = false;

						//Also, go back to the first page. (since we may be sitting on another, untraversable page from a prior paginated result)
						this.onPageChange(0, false);

						//Lets parse the search result, and highlight any matching bits of text against the search query.
						for (const key in searchResults) {
							const value = searchResults[key];

							//Apply any search text highlights
							this.ApplySearchTextMatchHighlights(value);
						}
					}
					else {
						//No results. show the empty result set
						this.emptyResultSet = true;
					}
				}
			});

		//we need another subscription to get changes to the isloading value
		this.isSearchingSubscription = this.clientDataStore.IsSearchingAccounts
			.subscribe(IsSearchingLoans => {
				this.IsSearchingLoans = IsSearchingLoans;
				//if its true, then don't show results. easy way to hide it is to set the local result counter to zero
				if (this.IsSearchingLoans) {
					this.resultsCounter = 0
					//we also dont need to show the empty result set either
					this.emptyResultSet = false;
				}
			})
	}

	public onPageChange(page: number, scrollToBottom = true) {
		this.currentPage = page;
		//scroll to the bottom
		if (scrollToBottom) {
			this.globalFunctions.delay(50).then(any => {
				window.scrollTo(0, document.body.scrollHeight);
			});
		}
	}

	//Applies text based highlight tags when there is a search match on an accounts properties
	public ApplySearchTextMatchHighlights(item: any) {

		//Loop through each property
		Object.entries(item).forEach(
			([rowKey, rowValue]) => {
				//Only apply this to the three properties that we are interested in highlighting (the others are not searched, display only). also ensure that the value doesn't already contain mark tags, or we would continue to stack too many of them in here

				if (rowKey === 'AccountID' || rowKey === 'ForeignIdentifier' || rowKey === 'PrincipalBorrower' || rowKey === "LoanCustomFields") {
					if (!this.globalFunctions.isEmpty(rowValue) && rowValue.toString().toUpperCase().includes(this.escapedKeywords.toUpperCase()) && !rowValue.toString().includes(this.globalFunctions.customHighlightTagEnd)) {

						//Lets try putting <mark> tags around the hit! use regex to find all instances of it, and case insensitive too
						//Need to deal with html escaping! use the locally cached escaped copy
						const typedVal = this.escapedKeywords;

						//console.log('typedVal', typedVal);
						//console.log('rowValue inside loop', rowValue);

						const re = new RegExp("(" + typedVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + ")", "ig");
						rowValue = rowValue.toString().replace(re, this.globalFunctions.getCustomHighlightTagStart(this.globalFunctions.GetSearchHighlightColor()) + '$1' + this.globalFunctions.customHighlightTagEnd);

						//Update the value into the parent object so that the html is updated
						item[rowKey] = rowValue;
					}
				}
			});

		//This may also have linked accounts. Let's check for them, and also apply highlights there!
		if (!this.globalFunctions.isEmpty(item.LinkedAccounts)) {

			//Loop through each linked account
			item.LinkedAccounts.forEach(linkedAccount => {

				//Recursive call to this same method, to apply text based highlights
				this.ApplySearchTextMatchHighlights(linkedAccount);
			});
		}
	}

	//After we have selected a loan to view, let's push it into a client side 'last viewed' account list, that is cached.
	public SelectAccount(account: any): void {

		//We can only do this is there was a search! (search result needs some data). This could happen if they straight away click on a prior account result, without searching.
		let clickedSearchAccount = null;

		//Get a copy of the AccountID that we are matching on, without any highlight tags
		const accountIDNoHighlights = this.globalFunctions.StripAllHighlightTags(account.AccountID.toString(), this.globalFunctions.GetSearchHighlightColor());

		//First ensure we have some search results to look through
		if (!this.globalFunctions.isEmpty(this.searchResults)) {

			//Now look for this clicked loan in the search result
			clickedSearchAccount = this.searchResults.filter(x => this.globalFunctions.StripAllHighlightTags(x.AccountID.toString(), this.globalFunctions.GetSearchHighlightColor()) === accountIDNoHighlights)[0];

			//If we didn't find it, it might be inside a search result as a linked account
			if (this.globalFunctions.isEmpty(clickedSearchAccount)) {

				//Loop through each search result
				for (let i = 0; i < this.searchResults.length; i++) {
					//console.log('element', this.searchResults[i]);

					//See if we can find a matching account inside its linked accounts
					const matchingAccount = this.searchResults[i].LinkedAccounts.filter(x => this.globalFunctions.StripAllHighlightTags(x.AccountID.toString(), this.globalFunctions.GetSearchHighlightColor()) === accountIDNoHighlights)[0];

					//Check if we found one
					if (!this.globalFunctions.isEmpty(matchingAccount)) {
						//console.log('We found a matching linked account', matchingAccount);

						//Set its principal borrower to match that of the parent. This is so that the display in the header is there
						if (this.globalFunctions.isEmpty(matchingAccount.PrincipalBorrower)) {
							matchingAccount.PrincipalBorrower = this.searchResults[i].PrincipalBorrower;
						}

						//Set it to clickedSearchAccount
						clickedSearchAccount = matchingAccount;

						//Break the loop, no need to keep searching, as we found a linked account already
						break;
					}
				}
			}
		}
		else {

			//No search result. we still want to remove and pop it back into the list though. We need to get the value from the last click list itself. since search result is empty. This assumes that since it wasn't in the Search result or a linked account, a recently clicked account was clicked.
			clickedSearchAccount = this.lastClickedAccounts.filter(x => x.AccountID === accountIDNoHighlights)[0];
		}

		//Check the loan that was clicked on
		if (!this.globalFunctions.isEmpty(clickedSearchAccount)) {

			//What was the last clicked loan? need to strip mark tags before checking the AccountID, or it won't match.
			clickedSearchAccount.AccountID = this.globalFunctions.StripAllHighlightTags(clickedSearchAccount.AccountID.toString(), this.globalFunctions.GetSearchHighlightColor());

			//Now we can get all copies of this loan that already exist in the lastClickedAccounts array
			const allMatching = this.lastClickedAccounts.filter(x => x.AccountID === clickedSearchAccount.AccountID);

			//If its non empty
			if (!this.globalFunctions.isEmpty(allMatching)) {

				//Check for all existing instances of this loan, and remove it. we don't want it in this list multiple times
				this.lastClickedAccounts.forEach((item, index) => {

					//Check if we found a matching item
					if (item.AccountID === clickedSearchAccount.AccountID) {

						//Remove it from the last clicked Accounts array
						this.lastClickedAccounts.splice(index, 1);
					}
				});
			}

			//Before we stick it in, we need to remove all the higlights. (mark tags)
			Object.entries(clickedSearchAccount).forEach(
				([rowKey, rowValue]) => {
					//console.log('rowKey', rowKey);
					//console.log('rowValue', rowValue);

					if (!this.globalFunctions.isEmpty(rowValue)) {
						//Remove all mark tags
						rowValue = this.globalFunctions.StripAllHighlightTags(rowValue.toString(), this.globalFunctions.GetSearchHighlightColor());

						//Update the value into the parent object so that the html is updated
						//console.log('post rowValue', rowValue);
						clickedSearchAccount[rowKey] = rowValue;
					}
				})

			//This inserts to the updated list to the local class variable
			this.lastClickedAccounts.unshift(clickedSearchAccount);

			//Limit this array to 20 or so items. loop through and remove any over the limit
			const listMax = 20
			if (this.lastClickedAccounts.length > listMax) {

				//Count how many we are over the limit by
				const deletionCount = this.lastClickedAccounts.length - listMax;

				//Now we can splice this many items out of the array.
				if (deletionCount > 0) {
					this.lastClickedAccounts.splice(listMax, deletionCount);
				}
			}

			//Set the selected loan, but we want to use the parsed copy where the highlights are removed
			this.clientDataStore.SetSelectedAccount(clickedSearchAccount);

			//Disable the search bar input and button on the page header, after a loan is selected
			//this.clientDataStore.Update_HideSearchBar(true);

			//Now we can save this updated list of clicked loans into the cache
			localStorage.setItem(this.lastClickedListName, JSON.stringify(this.lastClickedAccounts));

			//Now we are ready to route to the account (LoanIndex) page
			const targetURL = [NavigationUrls.Account.replace(':AccountID', clickedSearchAccount.AccountID)];

			//Tell router to reload the component, even if the URL is the same
			this.router.navigate(targetURL, { onSameUrlNavigation: 'reload' });
		}
		else {

			//Here the account we clicked on was not found in the parent search results.
			this.notifyService.Warning_Show("Please search for this account using its identifier", "Search again");
			return;
		}
	}

	ngOnDestroy() {
		this.searchSubscription.unsubscribe();
		this.isSearchingSubscription.unsubscribe();
	}
}