import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ClientDataStore {
	private Cache = {};
	constructor() {
		//Init class variables
		this.loginDataDirect = { LoginID: "", UserPassword: "", UserDisplayName: "", UserEmail: "", UserLoggedInStatus: false };
	}

	//For storing the user login/session data
	public loginDataDirect: any;
	//Entity types to display in the nav tabs
	public EntityTypesDisplay: any;
	//All entity types
	public EntityTypes: any;
	//The enum of claim names
	public ClaimNames: any;
	//Client claims
	public ClientClaims: any;
	//Control data for dropdown selections
	public ControlData: any = [];
	//Client view model
	public ClientViewModelData: any = [];
	//Email Variables
	public EmailVariables: any;
	//Pivoted Entities
	public PivotedEntities: any;
	//GUID of control data and view model on the server
	public ControlDataCacheGUID = "";
	public LoginModalLaunched = false;
	public HitErrorPage = false;

	//Is EOD Running
	//Use this to display the read only mode message
	private IsReadOnlyMode = new BehaviorSubject<boolean>(false);
	public get CheckReadOnlyMode(): Observable<boolean> {
		return this.IsReadOnlyMode.asObservable();
	}

	public Update_IsReadOnlyMode(data = true) {
		this.IsReadOnlyMode.next(data);
	}

	//This array contains the GUIDs of the account enquiry task types. for the account enquiry button
	public AccountEnquiryControlGUIDArray: any[] = [];

	//For controlling the display of the fullscreen loading spinner (blocking version)
	private ShowFullscreenLoading = new BehaviorSubject<any>(false);
	//Use this to allow anyone to subscribe to its value
	public get GetShowFullscreenLoading(): Observable<any> {
		return this.ShowFullscreenLoading.asObservable();
	}
	//And other components can use this to manipulate its value
	public SetShowFullscreenLoading(value: any) {
		//console.log('SetShowFullscreenLoading value', value);
		this.ShowFullscreenLoading.next(value);
	}

	//This is a store of the selected (clicked) loan that is being served into the loanIndex page.
	private selectedAccount = new BehaviorSubject<any>(null);
	//Any script that wants access to this selected loan (the latest value) can subscribe to this observable in its ngOnInit(), and then set it to a local class variable (to keep watching for updates and render them in html pages), or do something with it straight away.
	public get SelectedAccount(): Observable<any> {
		return this.selectedAccount.asObservable();
	}
	//If a script wants to update the selected loan, they can use this method to do it. this will insert a new value, and notify all subscribers
	public SetSelectedAccount(account: any) {
		this.selectedAccount.next(account);
	}

	//All the others work in the same way. here is the search results
	private searchResults = new BehaviorSubject<Array<any>>(null);
	public get SearchResults(): Observable<any[]> {
		return this.searchResults.asObservable();
	}
	//Use this to update the search results
	public UpdateSearchResults(data: Array<any>) {
		this.searchResults.next(data);
	}

	//Bool to control search states
	private isSearchingAccounts = new BehaviorSubject<boolean>(false);
	public get IsSearchingAccounts(): Observable<boolean> {
		return this.isSearchingAccounts.asObservable();
	}
	public UpdateIsSearchingAccounts(data: boolean) {
		this.isSearchingAccounts.next(data);
	}

	//Am i looking at a loan? use this to hide and show the search form in the header
	private hideSearchBar = new BehaviorSubject<boolean>(false);
	public get HideSearchBar(): Observable<boolean> {
		return this.hideSearchBar.asObservable();
	}
	public Update_HideSearchBar(data = true) {
		this.hideSearchBar.next(data);
	}

	//Make the search bar invisible
	private invisibleSearchBar = new BehaviorSubject<boolean>(false);
	public get InvisibleSearchBar(): Observable<boolean> {
		return this.invisibleSearchBar.asObservable();
	}
	public Update_InvisibleSearchBar(data = true) {
		this.invisibleSearchBar.next(data);
	}

	//Make the home button invisible when we hit an error. use this subscription to help the Search Bar to sync to it.
	private hitErrorPageSub = new BehaviorSubject<boolean>(false);
	public get HitErrorPageSub(): Observable<boolean> {
		return this.hitErrorPageSub.asObservable();
	}
	public Update_HitErrorPageSub(data = true) {
		this.hitErrorPageSub.next(data);
	}

	//LoginData
	private loginData = new BehaviorSubject<any>(null);
	public get LoginData(): Observable<any> {
		return this.loginData.asObservable();
	}
	//Use this to update the search results
	public UpdateLoginData(data: any) {
		this.loginData.next(data);
	}

	//User Option Data
	private userOptionData = new BehaviorSubject<any>(null);
	public get UserOptionData(): Observable<any> {
		return this.userOptionData.asObservable();
	}
	//Use this to update the search results
	public UpdateUserOptionData(data: any) {
		this.userOptionData.next(data);
	}

	//The remaining methods are for manipulating the plain Javascript Object Stores
	public AddTypedItem(itemType: StoreItemTypes, item: any) {
		this.Cache[itemType] = item;
	}

	public AddAnyItem(key: any, item: any) {
		this.Cache[key] = item;
	}

	public GetItemByType(itemType: StoreItemTypes) {
		return this.Cache[itemType];
	}

	public GetItemByKey(key: string) {
		return this.Cache[key];
	}

	public ClearItemByType(key: string) {
		this.Cache[key] = null;
	}

	public ClearItemByKey(itemType: StoreItemTypes) {
		this.Cache[itemType] = null;
	}
}

export enum StoreItemTypes {
	Any,
	SearchKeywords,
	SearchResults,
	PreviousSearchResults,
	DashboardData,
	DashboardDataCacheDate,
	LastClickedAccounts,
	LastLoggedInUser,
	BuildVersion,
	GetControlData,
	GetClientViewModel,
	GetControlDataCacheGUID,
	RefreshToken
}