import { Component, Input, OnDestroy } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'UserOptions',
  templateUrl: './UserOptions.html',
  styleUrls: ['./UserOptions.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})
export class UserOptions implements OnDestroy {
  //User Options
  public HideDischargedLoansInSearch: boolean;

  //Should we hide the top padding. This is passed from the parent html
  @Input() HideTopHeaderPadding = false;

  //Spinner on page load
  public ShowSpinner = false;

  //Subscription to get changes to the User Options
  private UserOptionsSubscription: Subscription;

  //User Option Data for subscription
  public userOptionData: any;

  //Track state of any server requests, so that we can show the spinner, if necessary.
  public IsPerformingServerRequest = false;

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private clientDataStore: ClientDataStore) {
  }

  //This is our Angular Constructor
  public ngOnInit() {

    //Subscribe to the User Options: HideDischargedLoansInSearch & userOptionData
    this.UserOptionsSubscription = this.clientDataStore.UserOptionData
      .subscribe(x => {
        this.HideDischargedLoansInSearch = x.HideDischargedLoansInSearch;
        this.userOptionData = x;
      })
  }

  //Toggle the UserOption value
  public UserOption_Toggle(userOptionName: any) {
    this.IsPerformingServerRequest = true;
    const apiRequest = { UserOptionName: userOptionName, UserOptionValue: this.HideDischargedLoansInSearch };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UserOptions_Update], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsPerformingServerRequest = false;
          return;
        }
        else {
          //Update the userOptionData to push the changes after successful response
          this.userOptionData.HideDischargedLoansInSearch = this.HideDischargedLoansInSearch;
          this.clientDataStore.UpdateUserOptionData(this.userOptionData);

          //Not much to do here. just a message to let the user know that they can login now. a notification might be a nice touch here
          this.notifyService.Success_Show("Hide Discharged Accounts has been updated", "Successful");
          this.IsPerformingServerRequest = false;
        }
      });
  }

  //Gets the class based on the input property: HideTopHeaderPadding
  public GetClass() {
    if (!this.HideTopHeaderPadding) {
      return 'glb_paddingTopLG';
    }
  }

  //Unsubscribe the subscription
  ngOnDestroy() {
    this.UserOptionsSubscription.unsubscribe();
  }
}