<div id="PG_ReassignForm">

  <div class="glb_boxedPadding glb_formBGColor text-white glb_Font">

    <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button id="BTN_CloseReassignForm" [disabled]="ReassignTask_ShowSpinner()" tabindex="30"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="ReassignTask_CloseModal()">
          <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
        </button>
      </div>
    </div>

    <div class="glb_customFlexRow row-cols-1 justify-content-left align-items-left">

      <!-- header -->
      <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
        Reassign Task Request
      </div>

      <!-- the autocomplete input field for the client name -->
      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomLG">
        <div class="">
          <div class="text-white glb_inputFieldLabel glb_customFlexRow">Please choose a Employee</div>
          <mat-form-field appearance="outline" class="glb_Font mat-autocomplete-dark-custom mat-autocomplete-custom glb_paddingZeroOffset">
            <input #reassignTask_Client cdkFocusInitial tabindex="11" class="glb_formInputBox glb_skinLightOutline text-dark glb_Font" type="text"
              placeholder="Assignee" [(ngModel)]="ReassignTaskClientBind" (click)="AutoComplete_Client_ApplyFilter(ReassignTask, 'Search',
                reassignTask_Client.value, 'Employee', true)" (keydown)="AutoComplete_Client_ApplyFilter(ReassignTask, 'Search',
                reassignTask_Client.value, 'Employee', true)" #insertLenderName type="text" matInput
              [matAutocomplete]="reassignTask_autoCompleteClient">

            <mat-autocomplete class="" #reassignTask_autoCompleteClient="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
              (optionSelected)="AutoComplete_SaveSelectedClient_ForCreate($event.option)" [panelWidth]="500">
              <div *ngFor="let control of AutoComplete_Client_ApplyFilter(ReassignTask, 'Search',
                reassignTask_Client.value, 'Employee')">
                <mat-option class="glb_Font" [value]=control>
                  {{control.ControlValue}}
                </mat-option>
              </div>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left">
        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
          <div *ngIf="!ReassignTask_ShowSpinner()" class="glb_paddingBottomSM d-grid">
            <button tabindex="12" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="Task_Reassign()">
              <span class="glb_Font glb_smallerText">Reassign Task</span></button>
          </div>
          <div *ngIf="ReassignTask_ShowSpinner()" class="glb_paddingBottomSM d-grid">
            <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
              <span class="glb_Font glb_hiddenText glb_smallerText">Reassign Task</span>
              <div class="spinner-border glb_floatingSpinner"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>