<div id="PG_Login" class="glb_formBGColor glb_boxedPadding">
    <div *ngIf="!ShowForgotPassword && !ShowGoogleAuthenticationVerification glb_removeOverflow">
        <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
        <div *ngIf="AllowClose" class="glb_customFlexRow justify-content-end">
            <div class="justify-content-start align-self-bottom">
                <button id="BTN_CloseLogin" [disabled]="IsLoggingIn" tabindex="15"
                    class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                    (click)="Dialog_Close()">
                    <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
                </button>
            </div>
        </div>

        <div *ngIf="!AllowClose" class="glb_customFlexRow justify-content-end">
            <div class="justify-content-start align-self-bottom">
                <button [disabled]="true" tabindex="15"
                    class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button">
                    <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
                </button>
            </div>
        </div>

        <!-- the overflow: hidden stops the scrollbar for this section appearing (its not needed) -->
        <div class="glb_customFlexRow glb_Font glb_TitleMD glb_TitleMDMobile text-white glb_removeOverflow">
            <div [innerHtml]="HtmlContent"></div>
        </div>
        <br>

        <!-- Input fields -->
        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div class="glb_paddingBottomSM">
                <input id="INP_LoginID" #LoginInput autocomplete="username login loginid" type="text" cdkFocusInitial tabindex="11"
                    placeholder="Login ID" name="username" [(ngModel)]="LoginData.LoginID" #databox (keydown.enter)="Login_Perform()"
                    class="glb_loginInputBox glb_skinLightOutline text-white glb_Font">
            </div>
            <div class="glb_paddingBottomSM glb_floatParent">
                <input id="INP_Password" #PasswordInput tabindex="12" [(type)]="InputTypePassword" autocomplete="emailaddress email"
                    placeholder="Password" name="password" [(ngModel)]="LoginData.UserPassword" #databox (keydown.enter)="Login_Perform()"
                    class="glb_loginInputBox glb_skinLightOutline text-white glb_Font">
                <div class="glb_floatTopRight">
                    <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)"
                        (mouseup)="PasswordReveal_Toggle(true)" class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
                        <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
                        <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!IsLoggingIn" class="glb_paddingBottomSM d-grid">
                <button id="BTN_Login" tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                    (keydown.enter)="Login_Perform()" (click)="Login_Perform()">
                    <span class="glb_Font glb_smallerText">Login</span></button>
            </div>
            <div *ngIf="IsLoggingIn" class="glb_paddingBottomSM d-grid">
                <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                    <span class="glb_Font glb_hiddenText glb_smallerText">Login</span>
                    <div class="spinner-border glb_floatingSpinner"></div>
                </button>
            </div>
        </div>

        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div class="glb_paddingBottomSM d-grid">
                <button [disabled]="IsLoggingIn" tabindex="14" class="btn glb_skinLightOutline" (click)="ForgotPasswordForm_Launch()">
                    <span class="glb_Font glb_smallerText glb_alternateColor">Forgot Password</span></button>
            </div>
        </div>

    </div>

    <div *ngIf="ShowForgotPassword">
        <ForgotPassword #ForgotPassword [loginModal]="this"></ForgotPassword>
    </div>
    <div *ngIf="ShowGoogleAuthenticationVerification">
        <TwoFactorAuthentication #GoogleVerification [loginModal]="this">
        </TwoFactorAuthentication>
    </div>
</div>