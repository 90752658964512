<div id="PG_DocViewer" class="glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
    <div>

        <div class="glb_customFlexRow justify-content-start" #TopHeader>

            <div class="glb_customFlexRow justify-content-start col-4 topHeader">
                <div class="glb_customFlexRow justify-content-start col-12">
                    <ng-container *ngIf="!ShowTextViewer">
                        <div class="justify-content-start align-self-bottom">
                            <button tabindex="10" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline"
                                type="button" (click)="PdfZoom_Modify(-.1)">
                                <fa-icon [icon]="['fal', 'search-minus']" class="fa-md" style="color:grey"></fa-icon>
                            </button>
                        </div>

                        <div class="justify-content-start align-self-bottom">
                            <button tabindex="11" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline"
                                type="button" (click)="PdfZoom_Modify(.1)">
                                <fa-icon [icon]="['fal', 'search-plus']" class="fa-md" style="color:grey"></fa-icon>
                            </button>
                        </div>

                        <div class="justify-content-start align-self-bottom">
                            <button tabindex="12" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline"
                                type="button" (click)="PdfZoom_Modify(0)">Reset Zoom
                            </button>
                        </div>
                    </ng-container>

                    <div class="justify-content-start align-self-bottom">
                        <button id="BTN_Download" tabindex="13"
                            class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                            (click)="Document_Download()">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
                            </fa-icon>
                        </button>
                    </div>
                </div>

                <div *ngIf="ShowEmailWarning" class="glb_customFlexRow justify-content-start col-12">
                    <div class="justify-content-start align-self-bottom glb_paddingBottomSM">
                        <span>
                            <button
                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorDelete glb_clickableIconButtonLG align-self-right">
                                <div class="justify-content-start text-start">Quick preview only, will be missing any attachments that are not inline
                                    images. Please download the email and use a local viewer to extract any attachments.</div>
                            </button>
                        </span>
                    </div>
                </div>
                <div *ngIf="ShowExcelWarning" class="glb_customFlexRow justify-content-start col-12">
                    <div class="justify-content-start align-self-bottom glb_paddingBottomSM">
                        <span>
                            <button
                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorDelete glb_clickableIconButtonLG align-self-right">
                                <div class="justify-content-start text-start">Quick preview of first tab only (pdf format), please download and launch
                                    in MS Excel for full functionality.</div>
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
            <div class="glb_customFlexRow justify-content-end col-8 topHeader">
                <div class="justify-content-start align-self-bottom">
                    <button id="BTN_CloseDocViewer" tabindex="1"
                        class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                        (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
                        <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
                    </button>
                </div>

            </div>
        </div>

        <!-- This is used by the ng2 pdf viewer library -->
        <div *ngIf="ShowPDFViewer" id="outerContainer" class="outerContainer">
            <div [style.height.px]="WindowHeight">
                <pdf-viewer id="PDFViewer" [src]="PdfSrc" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
                    [(zoom)]="PdfZoom" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true" [render-text-mode]="PdfRenderTextMode"
                    [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true" style="width: 100%;"></pdf-viewer>
            </div>
        </div>

        <!-- This is used to display images -->
        <div *ngIf="ShowImageViewer" class="outerContainer">
            <div class="assetContainer glb_boxedPaddingLG" [style.height.px]="WindowHeight">
                <div class="glb_customFlexRow justify-content-center">
                    <img id="ImageViewer" [(style.width.%)]="ImageZoom" [src]="ImageSrc" />
                </div>
            </div>
        </div>

        <!-- This is used to display txt or xml files -->
        <div *ngIf="ShowTextViewer" class="outerContainer">
            <div class="assetContainer glb_boxedPaddingNarrow">
                <div class="glb_customFlexRow justify-content-center" [style.height.px]="WindowHeight">
                    <textarea id="TextViewer" disabled class="assetContainer">{{TxtContent}}</textarea>
                </div>
            </div>
        </div>

    </div>