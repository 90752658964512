<div id="PG_FinaliseTask" class="glb_formBGColor glb_boxedPadding">

    <!-- Title + close modal together with flex grow -->
    <div class="col-12 glb_customFlexNoWrapRow glb_paddingBottomMD">
        <div class="glb_customFlexRow glb_paddingRightXS glb_flexGrow">
            <div [ngClass]="globalFunctions.FlexCSS" class="align-items-end" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
                <div [ngClass]="globalFunctions.FlexCSS">
                    <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords">
                        Finalise Task
                    </div>
                </div>
            </div>
        </div>
        <button id="FinaliseTask_Close" [disabled]="IsFinalisingTask" tabindex="15"
            class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" (click)="FinaliseTask_Close()">
            <fa-icon [icon]="['fal', 'times']" class="fa-fw" style="color:grey"></fa-icon>
        </button>
    </div>

    <!-- Dropdown to select the task outcome-->
    <div @fadeInSection [ngClass]="globalFunctions.L1SectionCSS">

        <!-- Outcome -->
        <div [ngClass]="globalFunctions.Col2CSS">

            <FormInputDataUnit cdkFocusInitial [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_Outcome'"
                [DropdownOptions]="TaskOutcomeOptions">
            </FormInputDataUnit>

        </div>
    </div>

    <!-- Approved By and Date Declared-->
    <div *ngIf="ShowApprovedBy" @fadeInSection [ngClass]="globalFunctions.L1SectionCSS">

        <!-- Approved By -->
        <div [ngClass]="globalFunctions.Col2CSS">

            <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ApprovedBy'"></FormInputDataUnit>
        </div>

        <!-- Date Declared -->
        <div [ngClass]="globalFunctions.Col2CSS">

            <!-- Primeng Calendar Date Picker -->
            <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_DateDeclared'" [DTPMaxDate]="DTPMaxDate">
            </FormInputDataUnit>
        </div>
    </div>

    <!-- Results and comments -->
    <div *ngIf="ShowResultandComments" @fadeInSection [ngClass]="globalFunctions.L1SectionCSS">

        <!-- Result and Comments -->
        <div [ngClass]="globalFunctions.FlexCSS">

            <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_Result'"></FormInputDataUnit>
        </div>

    </div>

    <!--  <!~~ Condition Satisfied Question ~~>
    <div [ngClass]="CSS_Lookup(ShowLoanCovenantQuestion,'L1SectionCSS')">

        <!~~ Covenant Question ~~>
        <div @fadeInSection>

            <!~~ Covenant Question ~~>
            <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ShowLoanCovenantOption'">
            </FormInputDataUnit>
        </div>

        <!~~ Convenant section ~~>
        <div [ngClass]="CSS_Lookup(ShowLoanCovenantSection,'FlexCSS')">

            <div [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                    <!~~ Approved LVR ~~>
                    <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ApprovedLVR'"></FormInputDataUnit>

                </div>
            </div>

            <div [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                    <!~~ Debt Service Coverage Ratio (DSCR) ~~>
                    <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_DSCR'"></FormInputDataUnit>
                </div>
            </div>

            <div [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                    <!~~ Interest Coverage Ratio (ICR) ~~>
                    <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ICR'"></FormInputDataUnit>
                </div>
            </div>

            <div [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                    <!~~ EBITDA ~~>
                    <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_EBITDA'"></FormInputDataUnit>
                </div>
            </div>
        </div>
    </div>-->

    <!-- Loan Security Valuations -->
    <div [ngClass]="CSS_Lookup(ShowSecurityValuationQuestion,'FlexCSS')">

        <!-- Security Valuation Question -->
        <div @fadeInSection>

            <!-- Security Valuation Question -->
            <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ShowSecurityValuationOption'">
            </FormInputDataUnit>
        </div>

        <div [ngClass]="CSS_Lookup(ShowSecurityValuationSection,'FlexCSS')">

            <!-- Loop and show security valuation questions -->
            <div [ngClass]="globalFunctions.FlexCSS" *ngFor="let security of Securities">
                <ng-container *ngTemplateOutlet="SecurityTemplate;context:ParentFieldContext_Get(security)"></ng-container>
            </div>

            <!-- Add another security valuation button -->
            <div *ngIf="ShowAddNewValuationButton" @fadeInSection class="glb_PaddingVerticalSM">
                <button id="BTN_AddNewSecurityValuation" [ngClass]="globalFunctions.FlexCSS"
                    class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="AddNewValuation_Click()">
                    <span class="glb_flexGrow glb_Font glb_smallerText align-items-center justify-content-center">Update Another Valuation
                        (optional)</span>
                </button>
            </div>
        </div>
    </div>

    <!-- Default Event Question -->
    <div [ngClass]="CSS_Lookup(ShowDefaultEventQuestion,'L1SectionCSS')">

        <div @fadeInSection>

            <!-- Default Event Question -->
            <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ShowDefaultEventOption'">
            </FormInputDataUnit>
        </div>

        <!-- Default Event Section -->
        <div [ngClass]="CSS_Lookup(ShowDefaultEventSection,'FlexCSS')">

            <!-- Default Event Yes Label -->
            <div @fadeInSection *ngIf="ShowDefaultEventYesText" [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.FlexCSS" class="glb_inputBackgroundColor glb_boxedPaddingNarrow">
                    <div class="glb_Font text-white">Please use the Account Enquiry feature on the loan to instruct Servicing on default rate etc.
                    </div>
                </div>
            </div>

            <!-- Default Event No Label -->
            <div @fadeInSection *ngIf="ShowDefaultEventNoText" [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.FlexCSS" class="glb_inputBackgroundColor glb_boxedPaddingNarrow">
                    <div class="glb_Font text-white">Please add notes on this outcome in Result and Comments section.</div>
                </div>
            </div>
        </div>
    </div>

    <!-- File Upload -->
    <div [ngClass]="CSS_Lookup(ShowFileUploadQuestion,'L1SectionCSS')">

        <!-- Show File Upload Question -->
        <div @fadeInSection>

            <!-- Show File Upload Question -->
            <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ShowFileUploadOption'">
            </FormInputDataUnit>
        </div>

        <div [ngClass]="CSS_Lookup(ShowFileUploadSection,'FlexCSS')">

            <!-- File Upload -->
            <div [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                    <!-- File Upload -->
                    <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_FileUpload'">
                    </FormInputDataUnit>
                </div>
            </div>

            <!-- Document Category -->
            <div [ngClass]="globalFunctions.Col2CSS">
                <div [ngClass]="globalFunctions.ChildNoWrapCSS">
                    <!-- Document Category -->
                    <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_DocumentCategory'"
                        [DropdownOptions]="ControlData_Filter('Document Category')">
                    </FormInputDataUnit>
                </div>
            </div>
        </div>
    </div>

    <!-- Submit section -->
    <div @fadeInSection *ngIf="ShowSubmitButton" class="glb_PaddingVerticalSM">
        <button id="BTN_FinaliseTask" tabindex="13" [ngClass]="globalFunctions.FlexCSS"
            class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="FinaliseTask_Click()">
            <span class="glb_flexGrow glb_Font glb_smallerText align-items-center justify-content-center">Submit</span>
        </button>
    </div>
</div>

<!-- Template for Security Valuation section -->
<ng-template #SecurityTemplate let-parentGUID="GUID" let-parentComponent="ParentComponent" let-index="Index">

    <!-- Security -->
    <div [ngClass]="globalFunctions.FlexCSS">
        <div [ngClass]="globalFunctions.Col2CSS">
            <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                <!-- Security Autocomplete -->
                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="parentComponent"
                    [ID]="parentComponent.ParentFieldName_Get(parentGUID)" [AutoCompleteControlTypeName]="'Lookup_AccountSecurity'"
                    [AccountID]="LoanIndex.AccountID">
                </FormInputDataUnit>
            </div>
        </div>

        <div *ngIf="DeleteValuation_Show()" [ngClass]="globalFunctions.Col2CSS">
            <div [ngClass]="globalFunctions.ChildNoWrapCSS" class="justify-content-end">
                <div class="justify-content-end align-self-end glb_customFlexRow">
                    <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white text-start glb_maxWidth"></div>
                    <div class="glb_PaddingHorizontalXXS">
                        <button class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_PaddingHorizontalXXS glb_BGColorDelete"
                            matTooltipClass="glb_tooltipSM" matTooltip="Click to Remove" (click)="SecurityValuation_Remove(parentGUID)">
                            <fa-icon [icon]="['fal', 'trash']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Show valuation fields only after the security has been selected -->
    <div *ngIf="parentComponent.SecurityValuation_Show(parentGUID)" @fadeInSection [ngClass]="globalFunctions.FlexCSS">
        <div [ngClass]="globalFunctions.Col2CSS">
            <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                <!-- Valuation Amount -->
                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="parentComponent"
                    [ID]="parentComponent.LinkedFieldName_Get(parentGUID,'INP_ValuationAmount_'+index)">
                </FormInputDataUnit>
            </div>
        </div>

        <div [ngClass]="globalFunctions.Col2CSS">
            <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                <!-- Valuation Date -->
                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="parentComponent"
                    [ID]="parentComponent.LinkedFieldName_Get(parentGUID,'INP_ValuationDate_'+index)" [DTPMaxDate]="parentComponent.DTPMaxDate">
                </FormInputDataUnit>
            </div>
        </div>

        <!-- Valuer Company -->
        <div [ngClass]="globalFunctions.Col2CSS">
            <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                <!-- Valuer Company Autocomplete-->
                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="parentComponent"
                    [ID]="parentComponent.LinkedFieldName_Get(parentGUID,'INP_ValuerCompany_'+index)"
                    [AutoCompleteControlTypeName]="'Valuation Company'" [AccountID]="LoanIndex.AccountID">
                </FormInputDataUnit>
            </div>
        </div>

        <!-- Valuer -->
        <div [ngClass]="globalFunctions.Col2CSS">
            <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                <!-- Valuer Contact -->
                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="parentComponent"
                    [ID]="parentComponent.LinkedFieldName_Get(parentGUID,'INP_ValuerContact_'+index)"
                    [AutoCompleteControlTypeName]="'Valuation Contact'" [AccountID]="LoanIndex.AccountID">
                </FormInputDataUnit>
            </div>
        </div>

        <!-- Minimum Insurable Amount -->
        <div [ngClass]="globalFunctions.Col2CSS">
            <div [ngClass]="globalFunctions.ChildNoWrapCSS">

                <!-- Minimum Insurable Amount -->
                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="parentComponent"
                    [ID]="parentComponent.LinkedFieldName_Get(parentGUID,'INP_MinimumInsurableAmount_'+index)">
                </FormInputDataUnit>
            </div>
        </div>
    </div>

</ng-template>