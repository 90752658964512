<div id="PG_AccountFee" class="glb_formBGColor glb_boxedPadding glb_Font glb_Font">

  <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start glb_boxedPaddingNarrow align-self-bottom glb_PaddingVerticalZeroOffset">
      <button id="BTN_CloseResiPayout" tabindex="3" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline"
        type="button" (click)="AccountFee_CloseModal()">
        <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
      </button>
    </div>
  </div>
  <div class="glb_Font header-padding glb_customFlexRow text-white">
    <div class="glb_customFlexRow col-12">
      <div class="glb_customFlexRow col-8 glb_paddingZeroOffset">
        <div class="col-8 glb_paddingZeroOffset">
          <div class="glb_TitleLG glb_TitleLGMobile text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomSM">
            Account Fees
          </div>
        </div>
      </div>
    </div>
    <!-- Adding new fees - a chevron with sliding row. -->
    <div class="col-12 glb_customFlexRow justify-content-end glb_paddingBottomSM">
      <button class="justify-content-end glb_customFlexRow glb_hoverHand justify-content-end BGColorGreyCircular glb_clickableIconButtonMD"
        (click)="TemplateID_Toggle('insertFee')">
        <fa-icon [icon]="['fal', 'plus']" class="fa-fw glb_fixedSizeIcons IconColorDark" [ngClass]="TemplateID_GetCSS('insertFee',true)"
          matTooltip="Add Fee">
        </fa-icon>
        <fa-icon [icon]="['fal', 'chevron-up']" class="fa-fw glb_fixedSizeIcons IconColorDark" [ngClass]="TemplateID_GetCSS('insertFee',false)"
          matTooltip="Hide">
        </fa-icon>
      </button>
    </div>

    <div id="insertFee" #insertFeeForm [ngClass]="TemplateID_GetCSS('insertFee')" class="col-12 glb_paddingBottomMD">
      <div class="glb_Font col-12 header-padding glb_customFlexRow glb_paddingBottomSM">

        <!-- Payout Date and Discharge Type (Mandatory Fields)-->
        <div [ngClass]="globalFunctions.L1SectionCSS">
          <div class="glb_flexGrow">
            <div [ngClass]="globalFunctions.L1SectionCSS">

              <!-- Transaction Type -->
              <div [ngClass]="globalFunctions.Col3CSS">

                <FormInputDataUnit cdkFocusInitial #INP_TransactionType [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this"
                  [ID]="'INP_TransactionType'" [AutoCompleteControlTypeName]="'Transaction Type'" [AccountID]="AccountPayoutCalculation.AccountID"
                  AutoCompleteLinkType="Payout Fee">
                </FormInputDataUnit>

              </div>

              <!-- Fee Amount -->
              <div [ngClass]="globalFunctions.Col3CSS">

                <!-- Valuation Amount -->
                <FormInputDataUnit #INP_FeeAmount [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_FeeAmount'">
                </FormInputDataUnit>
              </div>

              <!-- Notes -->
              <div [ngClass]="globalFunctions.Col3CSS">

                <FormInputDataUnit #INP_FeeNote [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_FeeNote'"></FormInputDataUnit>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="glb_customFlexRow row-cols-1 justify-content-end align-items-center">
        <div class="d-grid col-lg-2 col-md-4 col-12">
          <button id="BTN_AddFee" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
            matTooltip="Click to add this fee to the account" (click)="Fee_Add()" [disabled]="!this.EnableAddButton">
            <span class="glb_Font glb_smallerText">Add Fee</span></button>
        </div>
      </div>
    </div>

    <div class="glb_boxedPaddingNarrow col-12">
      <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM">
          <div class="glb_thinBottomWhiteBorder glb_customFlexRow col-12 glb_paddingZeroOffset row-cols-5">
            <div class="glb_customFlexRow align-items-center">
              <div>Account ID</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>Transaction Type</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>Amount</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>Notes</div>
            </div>
          </div>
        </div>
        <div *ngFor="let item of AccountPayoutCalculation.DischargeFeesResults" class="col-12 glb_customFlexRow glb_paddingZeroOffset">
          <div class="glb_customFlexRow glb_paddingZeroOffset glb_paddingBottomSM col-12 row-cols-5" [ngClass]="AccountRowCSS_Get(item)">

            <div class="glb_customFlexRow align-items-center">
              <div class="col-1 glb_customFlexRow justify-content-left align-items-center" [matTooltip]="item.ToolTipText" matTooltipPosition="below"
                [matTooltipShowDelay]="ToolTipDelay">
                <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="item.IsIncluded" (change)="IncludeCheckbox_Click(item)">
                </mat-checkbox>
              </div>
              <div class="col-11">{{item.AccountID}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center">
              <div>{{item.TransactionType}}</div>
            </div>
            <div *ngIf="!item.ModifyFeeClicked" class="glb_customFlexRow align-items-center">
              <div>{{item.Fees_FMT}}</div>
            </div>
            <div *ngIf="item.ModifyFeeClicked" class="glb_customFlexRow align-items-center justify-content-start glb_boxedPaddingXS">
              <textarea id="DU_FeeAmount" class="glb_textArea_SM glb_Font glb_maxWidth glb_resizeNone" placeholder="Fee Amount"
                [(ngModel)]="item.Fees" (input)="TextArea_Validate(item, $event,'currency','Fees')"></textarea>
            </div>
            <div *ngIf="!item.ModifyFeeClicked" class="glb_customFlexRow align-items-center">
              <div>{{item.Notes}}</div>
            </div>
            <div *ngIf="item.ModifyFeeClicked" class="glb_customFlexRow align-items-center justify-content-start glb_boxedPaddingXS">
              <textarea id="DU_TextAreaNotes" class="glb_textArea_SM glb_Font glb_maxWidth" placeholder="Notes" [(ngModel)]="item.Notes"></textarea>
            </div>
            <div *ngIf="!FeeBeingEdited || item.ModifyFeeClicked" class="glb_customFlexRow justify-content-end align-items-center">
              <div class="glb_boxedPaddingXS">
                <button *ngIf="!item.ModifyFeeClicked"
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD justify-content-end"
                  (click)="Fee_Modify(item)">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit this fee" matTooltipPosition="right">
                  </fa-icon>
                </button>
              </div>
              <div class="glb_boxedPaddingXS">
                <button *ngIf="item.ModifyFeeClicked"
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD justify-content-end"
                  (click)="FeeModify_Save(item)">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to save" matTooltipPosition="left">
                  </fa-icon>
                </button>
              </div>
              <div class="glb_boxedPaddingXS">
                <button *ngIf="item.ModifyFeeClicked"
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD justify-content-end"
                  (click)="FeeModify_Cancel(item)">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'ban']" matTooltip="Click to cancel" matTooltipPosition="right">
                  </fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="glb_customFlexRow glb_thinTopWhiteBorder col-12 glb_PaddingVerticalSM row-cols-5 glb_TitleMD">
          <div class="glb_customFlexRow align-items-center">
            <div>Total</div>
          </div>
          <div class="glb_customFlexRow align-items-center">
            <div></div>
          </div>
          <div class="glb_customFlexRow align-items-center">
            <div>{{TotalFeesDisplay}}</div>
          </div>
          <div class="glb_customFlexRow align-items-center">
            <div></div>
          </div>
          <div class="glb_customFlexRow align-items-center">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>