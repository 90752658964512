<div id="PG_TwoFactorAuthentication" class="text-white glb_Font">
  <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom">
      <button [disabled]="loginModal.isLoggingIn" tabindex="29"
        class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
        (click)="loginModal.TwoFactorVerification_Cancel()">
        <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
      </button>
    </div>
  </div>

  <div class="glb_customFlexRow glb_paddingBottomMD">
    <p class="glb_TitleMD glb_TitleMDMobile">Two Factor Authentication Verification</p>
  </div>

  <div class="glb_customFlexRow glb_paddingBottomMD">
    <div>Please launch your Two Factor Authentication App and enter the code for {{appName}}</div>
  </div>

  <!-- input fields -->
  <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_paddingBottomMD">
    <div>
      <input #focusField id="INP_TwoFactorCode" autocomplete="token" cdkFocusInitial tabindex="27"
        class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" placeholder="Enter your Two Factor Auth code"
        [(ngModel)]="loginModal.TwoFactorCode" (keydown.enter)="loginModal.GoogleAuthenticationCode_ValidateAndLogin()">
    </div>
  </div>

  <!-- buttons -->
  <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
    <div *ngIf="!loginModal.IsLoggingIn" class="glb_paddingBottomSM d-grid">
      <button id="BTN_SubmitTwoFactorCode" tabindex="28" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
        (keydown.enter)="loginModal.GoogleAuthenticationCode_ValidateAndLogin()" (click)="loginModal.GoogleAuthenticationCode_ValidateAndLogin()">
        <span class="glb_Font glb_smallerText">Verify Two Factor Code</span></button>
    </div>
    <div *ngIf="loginModal.IsLoggingIn" class="glb_paddingBottomSM d-grid">
      <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
        <span class="glb_Font glb_hiddenText glb_smallerText">Verify Two Factor Code</span>
        <div class="spinner-border glb_floatingSpinner"></div>
      </button>
    </div>
  </div>
</div>