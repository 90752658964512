import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { SLAReportingSummary } from '@app/Components/SLAReporting/SLAReportingSummary/SLAReportingSummary';
import { ControlData } from '@app/Global/Models/EntityModels';

@Component({
  selector: 'SLAReporting',
  templateUrl: './SLAReporting.html',
  styleUrls: ['./SLAReporting.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class SLAReporting implements OnInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog) {


    //Define static colors for each sla category
    this.SLAColors = new Map<string, string>();
    this.SLAColors.set("Within SLA", "#32ba57");
    this.SLAColors.set("Past SLA + 1 hours", "#69b87e");
    this.SLAColors.set("Past SLA + 2 hours", "#adac63");
    this.SLAColors.set("Past SLA + 3 hours", "#aba950");
    this.SLAColors.set("Past SLA + 4 hours", "#adab3b");
    this.SLAColors.set("Past SLA + 5 hours", "#adaa28");
    this.SLAColors.set("Past SLA + 6 hours", "#b0ab13");
    this.SLAColors.set("Past SLA + 7 hours", "#b3ad05");
    this.SLAColors.set("Past SLA + 24 hours", "#ba6868");
    this.SLAColors.set("Past SLA + 48 Hours", "#bf5252");
    this.SLAColors.set("Past SLA + >48 hours", "#ba0606");
    this.SLAColors.set("Past SLA + 72 Hours", "#bd3535");
    this.SLAColors.set("Past SLA + >72 hours", "#ba0606");
  }

  //Child nav tabs
  @ViewChild('ActiveTab') ActiveTab: SLAReportingSummary;
  @ViewChild('ActiveOverrideTab') ActiveOverrideTab: SLAReportingSummary;
  @ViewChild('CompletedTab') CompletedTab: SLAReportingSummary;
  @ViewChild('CompletedOverrideTab') CompletedOverrideTab: SLAReportingSummary;

  //Lenders Multiselect Input
  @ViewChild('INP_Lenders') INP_Lenders;

  //Declare the variables
  public ShowSpinner = false;
  public SLAReportingActive;
  public SLAReportingCompleted;
  public SLAReportingCompletedOverride;
  public SLACalcDateDisplay;
  public SLAReportingActiveOverride;
  public AggregateDataColumnCount;
  public ShowSLACacheRefreshButton = false;
  public AreInputsActive = true;
  public NavTabsSLATypes = [];
  public LenderList = [];

  //Flag to track the status of selectAll checkbox on the task type primeng multiselect
  public LenderSelectAll = { Value: false };

  //Selected lenders options as per the primeng multiselect
  public SelectedLenders: any[] = [];

  //Comma-separated list of selected lender GUIDs
  public SelectedLenderGUIDs = "";
  public SLAColors;

  //This is used to track the last AutoComplete Request that was sent to the server, and checked when processing the response, to ensure that we are processing only the latest one sent
  public LastAutoCompleteRequestGUID: string;

  //Name of the currently displayed nav tab
  public CurrentNavItem: string;

  //DateTime picker properties. Use boxing (using date as an object) to allow passing it by reference to convert it into ISO Format
  public DTPFromDate = { JSDate: null, ISODate: null };
  public DTPToDate = { JSDate: null, ISODate: null };
  public DTPMaxDate = new Date(2050, 1, 1);
  public DTPDateFormat = "dd/MM/yy";

  //For storing the selected lenders from the autocomplete lender input
  public AllLendersChecked = false;
  public Source = "{ALL}";

  //For storing the selected assignees from the autocomplete lender input
  public Assignees = [];
  public AssigneeClientGUIDs = "";
  public AllAssigneesChecked = false;

  //The Lender input class variable this needs to default like the control data, or we get null errors on the first run of autocomplete
  public SLAReportingLender = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
  //public SLAReportingLender = { ControlDisplay: "Brighten Test", ControlGUID: "{8daf2b8f-2a26-420a-a9fc-aab165a4090a}", ControlValue: "Brighten Test", ControlType: "autocomplete" };

  //The Assignee input class variable this needs to default like the control data, or we get null errors on the first run of autocomplete
  public SLAAssignee = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

  //Store the autocomplete data for the client to be passed into the API Request. This is needed to bind a default to the element on the form, otherwise you get null errors. This is Client lookup specific, hence the Client prefix in the property names
  public LenderAutoComplete = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };
  public AssigneeAutoComplete = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //Angular startup method
  ngOnInit() {

    //Child NavTabs list
    this.NavTabsSLATypes.push({ Key: "LBL_Active", DisplayName: "Active" });
    this.NavTabsSLATypes.push({ Key: "LBL_ActiveOverride", DisplayName: "Active - Override" });
    this.NavTabsSLATypes.push({ Key: "LBL_Completed", DisplayName: "Completed" });
    this.NavTabsSLATypes.push({ Key: "LBL_CompletedOverride", DisplayName: "Completed - Override" });

    //Default to Active nav tab
    this.CurrentNavItem = 'LBL_Active';

    //Set defaults to month start to current date
    const dateNow = new Date();
    this.DTPToDate.JSDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 23, 59, 59);
    this.DTPFromDate.JSDate = new Date(this.DTPToDate.JSDate.getFullYear(), this.DTPToDate.JSDate.getMonth(), 1);

    //Convert into ISO format for processing on server side
    this.globalFunctions.Date_ToISO(this.DTPFromDate);
    this.globalFunctions.Date_ToISO(this.DTPToDate);

    //Get the last SLA refreshed date 
    this.SLAData_GetRefreshedDate();

    //Check the claim if the user has access to the refresh button
    if (!this.globalFunctions.isEmpty(this.clientDataStore.ClientClaims)) {
      const SLAReportingClaim = this.clientDataStore.ClientClaims.filter(x => x.Name == "SLAReporting");
      if (SLAReportingClaim.length > 0) {
        if (SLAReportingClaim[0].Edit === true) {
          this.ShowSLACacheRefreshButton = true;
        }
      }
    }

    //Default to all assignee
    this.AllAssigneesChecked = true;

    //Push Assignee for testing
    //this.Assignees.push({ ControlDisplay: "Maria Wood", ControlGUID: "{de7c2e47-aa0f-4da0-861e-9e641c846de0}", ControlValue: "Maria Wood", ControlType: "autocomplete", IsChecked: true });

    //Push lenders for testing
    //this.ChosenLenders.push({ ControlDisplay: "Brighten Home Loans", ControlGUID: "{8daf2b8f-2a26-420a-a9fc-aab165a4090a}", ControlValue: "", ControlType: "", IsChecked: true });
    //this.ChosenLenders.push({ ControlDisplay: "Midkey", ControlGUID: "{78b03b37-1c26-4ac9-a65f-6ceabc38d749}", ControlValue: "", ControlType: "", IsChecked: true });

    //Populate PrimeNG multi select Lender List
    this.MultiSelectLender_Get();
  }

  //Get the Nav Tab Display Name
  public SLANavTabDisplay_Get(key: string, arrayItems): string {
    return this.globalFunctions.ArrayItem_Get(key, "DisplayName", arrayItems);
  }

  //Hide the datetime picker panel
  public DTP_Close(calendar, date): void {
    this.globalFunctions.Date_ToISO(date);
    calendar.hideOverlay();
  }

  //Clears the From Date
  public DTPFromDate_ClearData(): void {

    //Do not clear the date if inputs are on disabled state
    if (!this.AreInputsActive) {
      return;
    }
    this.DTPFromDate = null;
  }

  //Clears the To Date
  public DTPToDate_ClearData(): void {

    //Do not clear the date if inputs are on disabled state
    if (!this.AreInputsActive) {
      return;
    }
    this.DTPToDate = null;
  }

  //Checks the name of the supplied entity, and supplies the css to fade IN the content (make it visible with a short fade in animation using css keyframes)
  public CurrentNavItem_Show(navTabSLAName: string): string {
    if (navTabSLAName === this.CurrentNavItem) {

      //If we are showing the content, supply the css keyframes that fade it in
      return 'glb_keyFrameFadeIn';
    }
    return '';
  }

  //Get the control data for Task Source
  public ControlData_Filter(controlType): string[] {
    return this.globalFunctions.ControlData_Filter(controlType);
  }

  //When a nav bar item is clicked, show the relevant nav tab
  public NavBarItem_Clicked(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    const eventTargetID = eventTarget.id;

    //Update the currently visible nav tab
    this.CurrentNavItem = eventTargetID;

    //Navigate to the clicked NavBarItem
    this.ActiveTab.CurrentNavItem_Check();
    this.ActiveOverrideTab.CurrentNavItem_Check();
    this.CompletedTab.CurrentNavItem_Check();
    this.CompletedOverrideTab.CurrentNavItem_Check();
  }

  //Checks the name of the supplied entity, and supplies the css to just REMOVE the content for unmatching items. Used on parent items to completely remove it from rendering (display: none)
  public CurrentNavItem_ForceRemove(navTabSLAName: string): string {
    if (navTabSLAName !== this.CurrentNavItem)
      return 'glb_hiddenObjectImmediate';
    else return '';
  }

  //Get and highlight the currently active nav bar item
  public CurrentNavBarItem_Get(navTab: string): string {
    if (this.CurrentNavItem === navTab)
      return 'active'
    else return ''
  }

  //Reset the inputs and results
  public SLAResults_Reset(): void {

    //Activate the first nav tab by default: SLAReportingActive
    this.CurrentNavItem = 'LBL_Active';

    //Initialize empty array on each click
    this.SLAReportingActive = new Array<string>();
    this.SLAReportingActive.length = 0;

    this.SLAReportingActiveOverride = new Array<string>();
    this.SLAReportingActiveOverride.length = 0;

    this.SLAReportingCompleted = new Array<string>();
    this.SLAReportingCompleted.length = 0;

    this.SLAReportingCompletedOverride = new Array<string>();
    this.SLAReportingCompletedOverride.length = 0;

    //Refresh display for Nav Tabs
    this.globalFunctions.delay(50).then(() => {
      this.ChildNavTabs_Refresh();
    });
  }

  //Toggle the input state
  public SLADataInputs_Toggle(disableInputs = false): void {
    if (disableInputs == true) {
      this.AreInputsActive = false;
    }
    else {
      this.AreInputsActive = !this.AreInputsActive;
    }

    //Re-initialise the child variables on Generate/Modify button toggle
    this.ChildNavTabs_Refresh();
  }

  //Refresh child nav tabs
  public ChildNavTabs_Refresh(): void {
    this.ActiveTab.Display_Refresh();
    this.ActiveOverrideTab.Display_Refresh();
    this.CompletedTab.Display_Refresh();
    this.CompletedOverrideTab.Display_Refresh();
  }

  //Refreshes the SLA Cache data on the server
  public SLACacheData_Refresh(): void {

    //Reset the inputs/results
    this.SLAResults_Reset();

    //Reset the inputs/results
    this.AreInputsActive = true;

    //Full screen spinner
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct API Request
    const apiRequest = {};

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.SLAReporting_RefreshCache], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //This is the last SLA refreshed date
          this.SLACalcDateDisplay = this.globalFunctions.getCustomDateFormat(response, "longdate", "custom", "YYYY-MM-DD HH:mm:ss");
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.notifyService.Success_Show("SLA calculations have been reapplied", "Success");
          return;
        }
      });
  }

  //We want to tick All Lenders checkbox on multi select panel hide event if Select All is ticked
  public LendersMultiSelect_PanelHide() {

    //Check if PrimeNG Select All is true
    if (this.LenderSelectAll.Value === true) {
      this.AllLendersChecked = true;
    }
  }

  //Gets the SLA Reporting data from the server
  public SLAData_Get(): void {

    //Reset & Refill the chosen lender guids and assignees before generating the report
    this.SelectedLenderGUIDs = "";
    this.AssigneeClientGUIDs = "";

    if (this.AllLendersChecked === true) {
      this.SelectedLenderGUIDs = "{ALL}"
    }
    else {
      this.SelectedLenders.forEach(selectedLender => {
        this.SelectedLenderGUIDs = this.SelectedLenderGUIDs + selectedLender.ControlGUID + ",";
      });

      //Let's check for empty string
      if (!this.globalFunctions.isEmpty(this.SelectedLenderGUIDs) && this.SelectedLenderGUIDs.length > 0) {

        //Strip out the last comma delimiter
        this.SelectedLenderGUIDs = this.SelectedLenderGUIDs.substring(0, this.SelectedLenderGUIDs.length - 1);
      }
    }

    if (this.AllAssigneesChecked === true) {
      this.AssigneeClientGUIDs = "{ALL}"
    }
    else {
      this.Assignees.forEach(assignee => {
        if (assignee.IsChecked === true) {
          this.AssigneeClientGUIDs = this.AssigneeClientGUIDs + assignee.ControlGUID + ",";
        }
      });

      //Strip out the last comma delimiter
      this.AssigneeClientGUIDs = this.AssigneeClientGUIDs.substring(0, this.AssigneeClientGUIDs.length - 1);
    }

    //Input validations
    if (this.globalFunctions.isEmpty(this.SelectedLenderGUIDs)) {
      this.notifyService.Error_Show("Please choose a Lender", "Error");
      return;
    }

    if (this.globalFunctions.isEmpty(this.AssigneeClientGUIDs)) {
      this.notifyService.Error_Show("Please choose an Assignee", "Error");
      return;
    }

    if (this.globalFunctions.isEmpty(this.DTPFromDate.JSDate)) {
      this.notifyService.Error_Show("Please choose a From Date", "Error");
      return;
    }

    if (this.globalFunctions.isEmpty(this.DTPToDate.JSDate)) {
      this.notifyService.Error_Show("Please choose a To Date", "Error");
      return;
    }

    if (this.globalFunctions.isEmpty(this.Source)) {
      this.notifyService.Error_Show("Please choose a Source", "Error");
      return;
    }

    this.ShowSpinner = true;

    //Sync the JS date to ISODate. If the user doesn't click the OK button on the Calendar picker, we are forcing the dates to resync here
    this.globalFunctions.Date_ToISO(this.DTPFromDate);
    this.globalFunctions.Date_ToISO(this.DTPToDate);

    //Disable the inputs
    this.SLADataInputs_Toggle(true);

    //Reset/Initialize the inputs and results
    this.SLAResults_Reset();

    //Construct API Request
    const apiRequest = { LenderGUIDs: this.SelectedLenderGUIDs, AssigneeGUIDs: this.AssigneeClientGUIDs, Source: this.Source, FromDate: this.DTPFromDate.ISODate, ToDate: this.DTPToDate.ISODate };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetSLAReporting], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.ShowSpinner = false;
          this.SLADataInputs_Toggle();
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //This is the Active SLA Reporting Data
          this.SLAReportingActive = response.SLAReportingActive;

          //This is the Active SLA Reporting Data with Override Due Date
          this.SLAReportingActiveOverride = response.SLAReportingActiveOverride;

          //This is the Completed SLA Reporting Data
          this.SLAReportingCompleted = response.SLAReportingCompleted;

          //This is the Completed SLA Reporting Data with Override Due Date
          this.SLAReportingCompletedOverride = response.SLAReportingCompletedOverride;

          //Refresh display for Active Tab
          this.globalFunctions.delay(50).then(() => {
            this.ChildNavTabs_Refresh();
          });

          //Turn the spinners off
          this.ShowSpinner = false;
          return;
        }
      });
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue). This is currently specific to Client type Autocomplete. Will look to make it more generic, where possible (TODO).
  public AutoComplete_Client_ApplyFilter(autocompleteRecord, requestType: string, controlValue: string, fieldType: string, seed = false): ControlData[] {
    //console.log('AutoComplete_Client_ApplyFilter.controlValue', controlValue);

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Client_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (this.globalFunctions.isEmpty(controlValue) && seed && autocompleteRecord.Client_AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      autocompleteRecord.Client_AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteLastSearchedValue)) {
      if ((autocompleteRecord.Client_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {

        //Request new data and update the current value so we don't request it again.
        autocompleteRecord.Client_AutoCompleteLastSearchedValue = controlValue;

        //We want this to have a slight delay on key press. so that we don't trigger a server request too quickly as keys are being pressed.
        this.globalFunctions.delay(350).then(() => {

          //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
          this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
          //Make a copy of it for sending to the update request
          const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

          //Request a new set of values from the server
          this.AutoComplete_RequestData(requestType, autocompleteRecord.Client_AutoCompleteControlTypeName, controlValue, autocompleteRecord.Client_AutoCompleteControlData, fieldType, copyOfGUID);
        });
      }
    }
    //Don't place an else here, or seeding will always ask server for new data.
    //The only minor issue is that you need to press or click twice when backspacing to a blank string from the last character. not sure why it behaves this way. If you don't, it won't trigger the seed

    //Check if the control data is non null
    if (!this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteControlData)) {

      //Just return the array, even though its not synchronous. the update will come later and update it.
      return autocompleteRecord.Client_AutoCompleteControlData;
    }
  }

  //Get all lenders from server using autocomplete
  public MultiSelectLender_Get(): void {

    //Construct an AutoComplete request body that we can post to the server
    const apiRequest = this.globalFunctions.AutoComplete_PrepareAPIRequest("Search", "Lender", "{seed}", "Lender");

    //Call the server, if we have some value to request
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, apiRequest.AutoCompleteEndpoint, apiRequest)
      .subscribe(apiResponse => {
        if (apiResponse === null) { return; }
        else {

          //Store the autocomplete data for the client to be passed into the API Request
          const LenderMultiSelect = { Client_AutoCompleteControlData: [] };

          //Processing response from server
          this.globalFunctions.AutoComplete_ProcessResponse(apiResponse, LenderMultiSelect.Client_AutoCompleteControlData);

          //Let's iterate through array Client_AutoCompleteControlData and add each item to PrimeNG multiselect array
          LenderMultiSelect.Client_AutoCompleteControlData.forEach(item => {
            this.LenderList.push({ ControlGUID: item.ControlGUID, ControlDisplay: item.ControlValue });
          });
        }
      });
  }

  //Group selected items at the top
  public LenderMultiselect_Group(options, selectedItems): void {

    //Check for empty 
    if (!this.globalFunctions.isEmpty(options) && options.length > 0) {
      //Sort in ascending order
      options.sort((a, b) => a.ControlDisplay.localeCompare(b.ControlDisplay));
    }

    //Check for empty 
    if (!this.globalFunctions.isEmpty(selectedItems) && selectedItems.length > 0) {

      //Sort in descending order, since when grouping selected items, we want to add them in reverse order Z --> A, so that resultant array becomes sorted alphabetically (All selected items - in alphabetical order at the top, followed by un-selected items ordered alphabetically)
      selectedItems.sort((a, b) => b.ControlDisplay.localeCompare(a.ControlDisplay));

      //Loop through all the selected items and add those on the top of options
      selectedItems.forEach(item => {

        const selectedOption = options.filter(x => x.ControlGUID === item.ControlGUID)[0];

        if (!this.globalFunctions.isEmpty(selectedOption)) {

          //Remove the selected item from the list
          options.splice(options.indexOf(selectedOption), 1);

          //Now add the same selected item on the top 
          options.unshift(selectedOption);
        }
      })
    }
  }

  //Saves the autocomplete value locally on a click, for later use. provide a local class property to bind to, via html
  public AutoComplete_SaveSelectedControlRecord(value, localBind): void {

    //We are mutating the passed in object by reference. Disabling the eslint warning for unused variables on this one
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    localBind = this.globalFunctions.AutoComplete_SaveSelectedControlRecord(value, localBind);

    //Add the selected lender into the array if it doesn't exist already
    if (!this.globalFunctions.isEmpty(localBind)) {

      const assignee = this.Assignees.filter(x => x.ControlGUID == localBind.ControlGUID);
      if (this.globalFunctions.isEmpty(assignee)) {
        localBind.IsChecked = true;
        this.Assignees.push(localBind);
      }
    }
  }


  //For Material to access global functions, we need to use a get and arrow syntax to bring context along.
  public get AutoComplete_GetPrettyName() {

    //We no longer want to return a value as the selected lender will be displayed under this input box
    return () => "";

  }

  //Limit text size on any input
  public TextSize_Limit(input, length: number): number {
    return this.globalFunctions.LimitTextSize(input, length);
  }

  //Store the state of the All Lender Checkbox state
  public AllLendersCheckBox_Click(event) {
    this.AllLendersChecked = event.checked;

    //Reset the chosen lenders list in PrimeNG Multi Select
    this.SelectedLenders = [];
    this.LenderSelectAll.Value = this.AllLendersChecked;
  }

  //Store the state of the All Assignees Checkbox
  public AllAssigneesCheckBox_Click(event) {
    this.AllAssigneesChecked = event.checked;
    if (this.AllAssigneesChecked === true) {

      //Clear the Assignees list 
      this.Assignees = [];
    }
  }

  //Store the state of the selected assignee
  public AssigneeCheckBox_Click(assignee, event) {
    assignee.IsChecked = event.checked;
  }

  //Get the CSS based on whether the selected client is active or not
  public SelectedClientBG_GetCSS(chosenClient) {
    if (chosenClient.IsChecked === true) {
      return "color-bg-active";
    }
    return "color-bg-disabled";
  }

  //Gets the SLA Reporting data refreshed date from the server
  private SLAData_GetRefreshedDate(): void {

    //Construct API Request
    const apiRequest = {};

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.SLAReporting_GetRefreshedDate], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //This is the last SLA refreshed date
          this.SLACalcDateDisplay = this.globalFunctions.getCustomDateFormat(response, "longdate", "custom", "YYYY-MM-DD HH:mm:ss");
          return;
        }
      });
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, fieldType: string, autoCompleteRequestGUID: string): void {

    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = this.globalFunctions.AutoComplete_PrepareAPIRequest(requestType, controlType, requestValue, fieldType);

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, apiRequest.AutoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {

            //This helps with flow, so we only resolve the last request.
            if (autoCompleteRequestGUID === this.LastAutoCompleteRequestGUID) {

              //console.log('processing response from server');
              this.globalFunctions.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }
}