import { Injectable, Injector, NgZone } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router, Event, NavigationError } from '@angular/router';
import StackTraceParser from 'error-stack-parser';
import StackTraceGPS from 'stacktrace-gps';
import { ContextInfo } from './context-info';
import { Observable, of } from 'rxjs';
import { GlobalFunctions } from '../GlobalFunctions';
import { ClientDataStore } from '../ClientDataStore';
import moment from 'moment';
import { serializeError } from 'serialize-error';
import { NotifyService } from '@app/Services/NotifyService';
import { environment } from '@env/environment';

const errorStorageKey = 'errorStore';
@Injectable()
export class ErrorService {

	public getLastError(): any {
		return JSON.parse(localStorage.getItem(errorStorageKey))
	}

	/**
	 * Creates an instance of error service.
	 * @param injector 
	 * @param router 
	 */
	constructor(private injector: Injector, private notifyService: NotifyService, private router: Router, private globalFunctions: GlobalFunctions, private clientDataStore: ClientDataStore) {
		//subscribe to the NavigationError
		this.router
			.events
			.subscribe((event: Event) => {
				if (event instanceof NavigationError) {

					//Log a custom error
					this.LogCustomError(event.error, "Navigation Error", "Navigation Error")
				}
			});
	}

	/**
	 * Logs http response error
	 * @param error 
	 * @returns  
	 */
	public logHttpResponseError(error, friendlyErrorMessage = ""): Observable<ContextInfo> {
		//console.log('this.clientDataStore.HitErrorPage', this.clientDataStore.HitErrorPage);
		if (!this.clientDataStore.HitErrorPage) {
			//turn it on, so we don't keep hitting the error. first one is enough
			this.clientDataStore.HitErrorPage = true;
			this.clientDataStore.Update_HitErrorPageSub(true);

			//show error
			const errorToSend = this.addContextInfo(error, true, friendlyErrorMessage);
			//console.log("errorToSend: ", errorToSend);
			localStorage.setItem(errorStorageKey, JSON.stringify(errorToSend));

			//close all mat dialogs (e.g. login), so that the user can see the error page.
			this.globalFunctions.CloseAllMatDialogs();

			return fakeHttpService.post(errorToSend);
		}
	}

	public LogCustomError(error, friendlyErrorMessage, errorTitle) {

		//Run a sync method that grabs error data, parses and fills stack trace
		const errorToSend = this.addContextInfo(error, false, friendlyErrorMessage);
		//console.log('errorToSend', errorToSend);

		const ngZone = this.injector.get(NgZone);

		//Check if we have a stack trace
		if (!this.globalFunctions.isEmpty(errorToSend.stack)) {
			//There is a stack trace. Let's try to use stacktraceGPS to try and identify the original typescript code lines. Then we can continue with the remaining steps

			//Create an array of promises. We need this to store a reference to and await execution of all the stacetracegps promises
			const promiseArray = [] as any[];

			//Create a copy of the stack trace gps library
			const gps = new StackTraceGPS();
			//A new array to store the stacktrace gps results
			errorToSend.gpsStack = [] as any[];

			//Loop through each stack trace line, and project a gps pinpointed stack trace into the promise array
			errorToSend.stack.forEach(element => {

				if (!environment.environmentName.toUpperCase().includes("PROD03")) {
					promiseArray.push(gps.pinpoint(element).then(value => {
						//console.log('GPS Stack', value);
						errorToSend.gpsStack.push(value);
					}));
				}
			});

			//Wait for the stacktrace gps to finish on each promise in the array
			Promise.all(promiseArray).then(results => {
				//Now that each gps stack promise has finished, we can continue with error processing
				this.CompleteErrorProcessing(errorToSend);
				this.notifyService.Error_Show(friendlyErrorMessage, errorTitle);
				ngZone.run(() => this.router.navigate(['/Error']));
			});
		}
		else {
			this.CompleteErrorProcessing(errorToSend);
			this.notifyService.Error_Show(friendlyErrorMessage, errorTitle);
			ngZone.run(() => this.router.navigate(['/Error']));
		}
	}

	//Completes error processing
	public CompleteErrorProcessing(errorToSend: ContextInfo) {
		//Save error to local storage
		localStorage.setItem(errorStorageKey, JSON.stringify(errorToSend));

		//Indicate that we hit the error page
		this.clientDataStore.HitErrorPage = true;
		this.clientDataStore.Update_HitErrorPageSub(true);

		//Close all mat dialogs (e.g. login), so that the user can see the error page.
		this.globalFunctions.CloseAllMatDialogs();
	}

	/**
	 * Adds context info
	 * @param error 
	 * @returns  
	 */
	private addContextInfo(error, isHttpError: boolean, i_friendlyErrorMessage = ""): ContextInfo {

		//You can include context details here
		let name = "TBD";
		let status = "TBD";
		let message = "TBD";

		if (!this.globalFunctions.isEmpty(error)) {
			name = error.name || null;
			status = error.status || null;
			message = error.message || JSON.stringify(serializeError(error));
			//console.log('error.message', error.message);
			//console.log('JSON.stringify(serializeError(error)', JSON.stringify(serializeError(error)));
		}

		if (message === "TBD") {
			message = error;
		}

		const appId = 'xChangev8';
		const user = "LoginID: " + this.clientDataStore.loginDataDirect.LoginID;

		//Format this nicely.
		const timeNow = moment(new Date().getTime())
		const time = timeNow.format("DD/MM/YYYY hh:mm:ss a");

		//Id is not really needed
		const id = '';

		const location = this.injector.get(LocationStrategy);
		const url = location instanceof PathLocationStrategy ? window.location.protocol + "//" + window.location.host + location.path() : '';
		//console.log('url', url);

		//Stack trace
		let stack: any = "";

		//Only parse the error if it is NON http and non empty.
		//const stack = isHttpError ? error.status === 0 ? error.message : error.error : StackTraceParser.parse(error);
		if (!isHttpError && !this.globalFunctions.isEmpty(error) && !this.globalFunctions.isEmpty(error.message)) {
			stack = StackTraceParser.parse(error);
		}

		const friendlyErrorMessage = i_friendlyErrorMessage;

		//Empty gps stack for now. Will get filled later
		const gpsStack: any = "";

		//Add the stack trace, which should contain line numbers
		const errorWithContext = { name, appId, user, time, id, url, status, message, friendlyErrorMessage, gpsStack, stack };
		return errorWithContext;
	}
}

/**
 * Fake http service
 */
class fakeHttpService {
	/**
	 * Posts fake http service
	 * @param error 
	 * @returns post 
	 */
	static post(error: ContextInfo): Observable<ContextInfo> {
		//console.log('Error sent to the server: ', error);
		return of(error);
	}
}