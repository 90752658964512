<div id="PG_LoanHeader" class="glb_Font top_section glb_customFlexRow align-items-center row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
	<div class="labelClassStandard labelClass labelClassMobile glb_customFlexRow align-items-center glb_highlightLabelPadding">{{ AccountID | async }}
		<div id="LBL_RedrawFrozen" *ngIf="CheckRedrawFrozen()" class="glb_paddingLeft">
			<button class="glb_highlightLabelPadding btn glb_highlightLabelRedrawFrozen glb_removeCursor text-white"><span
					class="glb_highlightLabelSM">Redraw
					Frozen</span>
			</button>
		</div>
		<div id="LBL_CollectionStatus" *ngIf="loanIndex.ShowCollectionStatusLabel" class="glb_paddingLeft">
			<button class="glb_highlightLabelPadding btn glb_BGColorAlert glb_removeCursor text-white"><span
					class="glb_highlightLabelSM">{{loanIndex.SelectedAccount.CollectionStatus}}</span>
			</button>
		</div>
	</div>

	<div class="labelClassStandard labelClass labelClassMobile text-lg-end text-md-end text-sm-end text-xs-start">
		<div class="glb_customFlexRow justify-content-end">
			<div id="EL_HeaderStatus" [ngClass]="LoanStatusCSS_Get()">{{LoanStatus}}</div>
		</div>
	</div>
	<div class="labelClassStandard labelClassLarger labelClassLargerMobile" [innerHTML]="PrincipalBorrower | sanitizeHtml"></div>
	<div class="labelClassStandard labelClass labelClassMobile text-lg-end text-md-end text-sm-end text-xs-start">Statement Balance: {{
		PrincipalBalance | async | currency }}</div>
	<div class="labelClassStandard labelClass labelClassMobile" [innerHTML]="ProductName | sanitizeHtml"></div>
	<div *ngIf="loanIndex.ShowAnticipatedDischargeDate"
		class="labelClassStandard labelClass labelClassMobile text-lg-end text-md-end text-sm-end text-xs-start">
		<div class="glb_customFlexRow justify-content-end">
			<div class="glb_highlightLabelPadding">Anticipated Discharge Date:</div>
			<div class="glb_highlightLabelSM glb_highlightLabelPendingDischarge glb_highlightLabelPadding">
				{{loanIndex.SelectedAccount.AnticipatedDischargeDate}}</div>
		</div>
	</div>
</div>