import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { InputDataUnit } from '@app/Global/Models/ClientModels';
import { AccountFee } from '@app/Components/Loan/AccountFee/AccountFee';
import { DocViewer } from '@app/Components/Loan/DocViewer/DocViewer';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { environment } from '@env/environment';
import { ConfirmModal } from '@app/Components/Loan//ConfirmModal/ConfirmModal';

@Component({
  selector: 'PayoutCalculator',
  templateUrl: './PayoutCalculator.html',
  styleUrls: ['./PayoutCalculator.scss'],
  animations: [
    trigger('fadeInSection', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1.0s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class PayoutCalculator implements OnInit, AfterViewInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<PayoutCalculator>,
    private csvDataService: CsvDataService = null) {
  }

  //It is requried for downloading a document
  @Input() LoanIndex: LoanIndex;

  //Unique identifier for the launched modal
  @Input() ModalIdentifier;

  public PayoutCalculations;
  public PayoutCalculationsPrior;
  public PayoutCalculationsExport;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //Flag for tracking the payout figure results
  public NoPayouts = false;

  //Add delay for showing mat tool tip (in ms)
  public ToolTipDelay = 300;

  public IncludeToolTip = "Click to include";
  public ExcludeToolTip = "Click to exclude";

  //Tip to enable the buttons
  public EnableButtonsMessage = "Please choose a discharge date, type and client to generate letter or save payout details.";

  //Payout Date
  public PayoutDate;

  //Flag to track whether the payout letter has been generated or not
  public PayoutLetterGenerated = false;
  public DischargeReason = "";
  public DischargeNote = "";

  //Flags to show revelant UI sections as they get revealed
  public ShowOptionalFields = false;
  public MandatoryFieldsCompleted = false;
  public EnableButtons = false;

  //New ModelData that captures it inside an array, which lets us use a generic function to retrieve and update
  public ModelData: InputDataUnit[] = [];

  private LastRequestedPayoutDate: Date;
  private LastSavedPayoutRequest;
  private LastPayoutLetterRequest;

  //For totals
  private TotalFacilityPayout: number;
  private TotalPrincipal: number;
  private TotalInterestAccrued: number;
  private TotalDefaultInterestAccrued: number;
  private TotalUnclearedFunds: number;
  private TotalOffsetBalance: number;
  private TotalFees: number;

  //For totals display
  public TotalFacilityPayoutDisplay;
  public TotalPrincipalDisplay: number;
  public TotalInterestAccruedDisplay: number;
  public TotalDefaultInterestAccruedDisplay: number;
  public TotalUnclearedFundsDisplay: number;
  public TotalOffsetBalanceDisplay: number;
  public TotalFeesDisplay: number;

  //To display info for fixed product
  public FixedProductMessage = "There is a fixed rate product in the list that may have break costs payable.";
  public ShowFixedProductMessage = false;

  //To display alert for missing break cost fee on accounts linked to fixed product
  public BreakCostMissingMessage = "";
  public ShowMissingBreakCostMessage = false;

  //To check if it is the initial load
  private InitialPageLoad = true;

  //DateTime max date
  public DTPMaxDate = new Date(2050, 1, 1);

  //Today's date
  public DTPDate = new Date();

  //DateTime min date, ignoring the timestamp
  public DTPMinDate = new Date(this.DTPDate.getFullYear(), this.DTPDate.getMonth(), this.DTPDate.getDate());

  //Get the environment name
  public EnvironmentName = environment.environmentName;

  //Discharge Types
  public DischargeTypes = [{ ControlGUID: "Indicative", ControlValue: "Indicative" }];

  //Multiselect options for clients
  public ClientMultiselectOptions = [
    // { name: "Primary Borrower - OptIn Yes - Rama Sutton sivaratham narayanan surendra maharrananana - Rama Sutton sivaratham narayanan surendra maharrananana" },
    // { name: "Secondary Borrower - OptIn No - Maree Sutton" }
  ];

  //Discharge Reason
  public DischargeReasons = [{ ControlGUID: "Arrears", ControlValue: "Arrears" }, { ControlGUID: "Cash Payout", ControlValue: "Cash Payout" }, { ControlGUID: "Refinance - Other", ControlValue: "Refinance - Other" }];

  //Angular startup method
  ngOnInit() {

    //Set the payout date to current date on initial load within this method: InitPage
    this.Page_Init();

    //Fill static data for the input data model here, base it on the input names. Demonstrating the use of the partial constructor here, only mapping the properties we want to use
    this.ModelData.push(
      new InputDataUnit({ Name: "INP_PayoutDate", DisplayName: "Payout Date", Placeholder: "Payout Date", HTMLInputType: "date", Type: "shortdate", ValueDate: this.PayoutDate, Value: this.PayoutDate, MinDate: this.DTPMinDate })
      , new InputDataUnit({ Name: "INP_DischargeNote", DisplayName: "Discharge Note", Placeholder: "Discharge Note", HTMLInputType: "textarea", Type: "string", MinLength: this.globalFunctions.TextAreaValidLengthOptional, MaxLength: this.globalFunctions.TextAreaValidMaxLengthMD, IsResizable: true, CSSHeight: "glb_textAreaHeightMedium" })
      , new InputDataUnit({ Name: "INP_DischargeType", DisplayName: "Discharge Type", Placeholder: "Choose discharge type", HTMLInputType: "dropdown", Type: "string" })
      , new InputDataUnit({ Name: "INP_DischargeReason", DisplayName: "Discharge Reason", Placeholder: "Choose discharge reason", HTMLInputType: "autocomplete", Type: "string" })
      , new InputDataUnit({ Name: "INP_ShowOptionalFields", LabelUnchecked: "Show optional fields", LabelChecked: "Show optional fields", LabelDisplay: "Show optional fields", HTMLInputType: "checkbox", Type: "string", Value: "false", IsChecked: false })
      //Custom html type for single selection using primeng multiselect using p-multiselect-single 
      , new InputDataUnit({ Name: "INP_MultiselectSingle", DisplayName: "Client", Placeholder: "Choose a client", HTMLInputType: "p-multiselect-single", Type: "string" })
    );

    //Let's check if user has access to Discharge Type = SettlementBooked 
    if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "ResiPayoutSettlementBooked", "Read") === true) {

      //Add an option to the array
      this.DischargeTypes.push({ ControlGUID: "Settlement Booked", ControlValue: "Settlement Booked" });
    }
  }

  ngAfterViewInit() {

    //Run it on initial load only
    if (this.InitialPageLoad) {
      //We still need a short delay even this method is added on ngAfterViewInit
      this.globalFunctions.delay(50).then(() => {
        this.PayoutCalculation_Generate();

        //Set class variable initialPageLoad to false so that it won't force the payout date to current date
        this.InitialPageLoad = false;
      });
    }
  }

  //Set all UI input visibilty to a specified value (all visible or not)
  public InputVisibility_Set(flag: boolean): void {
    this.ShowOptionalFields = flag;
    this.MandatoryFieldsCompleted = flag;

    this.EnableButtons_Sync();
  }

  //Get the matching model data input, used in InputView_Sync to determine when new content needs to be rendered
  public ModelDataUnit_Get(input) {
    const modelDataItem = this.ModelData.filter(x => x.Name === input.id)[0];

    if (!this.globalFunctions.isEmpty(modelDataItem)) {
      return modelDataItem;
    }
  }

  //Sync the display of the UI input elements
  public InputView_Sync(): void {

    //A small delay so that user click still catches the correct UI element.
    this.globalFunctions.delay(200).then(() => {

      //Turn off all the flags by default
      this.InputVisibility_Set(false);

      //For testing, turn it all on (faster debugging)
      //this.InputVisibility_Set(true);

      const PayoutDateDU = this.ModelDataUnit_Get({ id: "INP_PayoutDate" });
      if (!this.globalFunctions.isEmpty(PayoutDateDU.Value)) {

        this.PayoutDate = PayoutDateDU.Value;

        if (this.LastRequestedPayoutDate === null || this.LastRequestedPayoutDate != this.PayoutDate) {
          this.PayoutCalculation_Generate();
        }

        //Check if the discharge type & client are selected
        const DischargeTypeDU = this.ModelDataUnit_Get({ id: "INP_DischargeType" });
        const targetClientDU = this.ModelDataUnit_Get({ id: "INP_MultiselectSingle" });

        if (!this.globalFunctions.isEmpty(DischargeTypeDU) && !this.globalFunctions.isEmpty(DischargeTypeDU.Value) && !this.globalFunctions.isEmpty(targetClientDU) && !this.globalFunctions.isEmpty(targetClientDU.Value)) {
          this.MandatoryFieldsCompleted = true;
        }

        //Check if the ShowOptionFieldsDU is checked
        const ShowOptionFieldsDU = this.ModelDataUnit_Get({ id: "INP_ShowOptionalFields" });
        if (!this.globalFunctions.isEmpty(ShowOptionFieldsDU)) {

          if (ShowOptionFieldsDU.IsChecked) {
            this.ShowOptionalFields = true;
          }
        }

        //Sync action buttions
        this.EnableButtons_Sync();
      }
    });
  }

  //Check conditions to enable/disable the action buttons
  public EnableButtons_Sync(): void {

    //Init to false
    this.EnableButtons = false;

    if (!this.globalFunctions.isEmpty(this.PayoutCalculations)) {

      //Ensure that at least one account is selected and mandatory fields are filled out
      if (this.PayoutCalculations.filter(x => x.IsIncluded === true).length > 0 && this.MandatoryFieldsCompleted === true) {

        //Enable Generate and Save buttons
        this.EnableButtons = true;
      }
    }
  }
  //Send the request to generate the payout calculation on the server
  public PayoutCalculation_Generate() {

    //Let's do some basic client side validation
    //Date.GetTime() Returns the numeric value of the specified date as the number of milliseconds since January 1, 1970, 00:00:00 UTC
    //RequestDate will be null on initial run, ignore the comparison 
    if (this.LastRequestedPayoutDate != null && this.LastRequestedPayoutDate === this.PayoutDate) {
      this.notifyService.Error_Show("Please select a different date", "Payout Date Selection");
      return;
    }

    //Check if the payout date selected is in the past
    const payoutJSDate = new Date(this.PayoutDate);
    if (payoutJSDate < this.CurrentDate_Get()) {
      this.notifyService.Error_Show("Please select a valid future date", "Payout Date Selection");

      return;
    }

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Clone the user input payout date into LastRequestedPayoutDate
    this.LastRequestedPayoutDate = JSON.parse(JSON.stringify(this.PayoutDate));

    //Cache the current values 
    if (!this.globalFunctions.isEmpty(this.PayoutCalculations)) {
      this.PayoutCalculationsPrior = JSON.parse(JSON.stringify(this.PayoutCalculations));
    }

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID, DischargeDate: this.PayoutDate };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetPayoutCalculation], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));

          this.PayoutCalculations = response.PayoutCalculationResults;

          //Initialise ShowFixedProductMessage to false
          this.ShowFixedProductMessage = false;

          //Initialise the payout results flag
          this.NoPayouts = false;

          //Check if the payout respose is empty
          if (this.globalFunctions.isEmpty(this.PayoutCalculations)) {

            //Show No Active accounts message and hide the download button
            this.NoPayouts = true;

            this.notifyService.Warning_Show("No active accounts found.", "Payout Calculation");

            //Turn off the fullscreen loading
            this.clientDataStore.SetShowFullscreenLoading(false);
            this.IsGenerateInProgress = false;

            return;
          }

          //Process the payout response
          this.PayoutCalculationsExport = JSON.parse(JSON.stringify(this.PayoutCalculations));

          this.PayoutCalculations.forEach(element => {

            if (!this.globalFunctions.isEmpty(this.PayoutCalculationsPrior)) {

              //Sync the selected accounts from the last cached/prior values
              const matchingPayoutCalcPrior = this.PayoutCalculationsPrior.filter(x => x.AccountID === element.AccountID)[0];
              if (!this.globalFunctions.isEmpty(matchingPayoutCalcPrior)) {
                element.IsIncluded = matchingPayoutCalcPrior.IsIncluded;

                //Loop through the cached fees, if there are any, and update the matching fees
                if (!this.globalFunctions.isEmpty(matchingPayoutCalcPrior.DischargeFeesResults)) {

                  matchingPayoutCalcPrior.DischargeFeesResults.forEach(fee => {

                    //Check if the fee was added by user
                    if (fee.FeeGUID === "{NewGUID}") {

                      //Add the fee into the target list
                      element.DischargeFeesResults.push(fee);
                    }
                    else {

                      //Check the matching fee in the target list
                      if (!this.globalFunctions.isEmpty(element.DischargeFeesResults.filter(x => x.FeeGUID === fee.FeeGUID)[0])) {

                        //Find the index of the matching fee and remove it from the target
                        element.DischargeFeesResults.splice(element.DischargeFeesResults.findIndex(x => x.FeeGUID === fee.FeeGUID), 1);

                        //Add the matching fee from the cache (to include the potential changes made by user) to the target list
                        element.DischargeFeesResults.push(fee);
                      }
                    }
                  });
                }
              }
            }

            //Check if any of the account is linked to fixed product
            if (element.RateType === "Fixed" && !this.ShowFixedProductMessage) {
              this.ShowFixedProductMessage = true;
            }

            //Another attribute to set the tooltip text
            element.ToolTipText = this.ExcludeToolTip;

            //Convert date to DD MMM YYYY
            element.DischargeDate_FMT = this.globalFunctions.getCustomDateFormat(element.DischargeDate, "shortdate", "custom", "YYYY-MM-DD");

            //Convert the amounts to currency format
            element.PrincipalAmount_FMT = this.globalFunctions.customDataTypeParser(element.PrincipalAmount, "currency.2", "aus");
            element.InterestAccrued_FMT = this.globalFunctions.customDataTypeParser(element.InterestAccrued, "currency.2", "aus");
            element.DefaultInterestAccrued_FMT = this.globalFunctions.customDataTypeParser(element.DefaultInterestAccrued, "currency.2", "aus");
            element.UnclearedFunds_FMT = this.globalFunctions.customDataTypeParser(element.UnclearedFunds, "currency.2", "aus");
            element.OffsetBalance_FMT = this.globalFunctions.customDataTypeParser(element.OffsetBalance, "currency.2", "aus");
            element.Fees_FMT = this.globalFunctions.customDataTypeParser(element.Fees, "currency.2", "aus");
            element.TotalPayout_FMT = this.globalFunctions.customDataTypeParser(element.TotalPayout, "currency.2", "aus");
          });

          //Sync the row totals
          this.PayoutTotals_Sync();

          //Add the linked clients for the multiselect option
          if (!this.globalFunctions.isEmpty(response.LinkedClients)) {

            //Clear the options first
            this.ClientMultiselectOptions = [];

            //Loop through each client and add to the multiselect options
            response.LinkedClients.forEach(client => {
              this.ClientMultiselectOptions.push({ name: this.globalFunctions.HTMLUnescape(client.DisplayLabel), GUID: client.ClientGUID });
            });
          }

          this.notifyService.Success_Show("The payout calculation has been generated successfully.", "Payout Calculation");

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
          return;
        }
      });
  }

  //Recalc total payout for an account
  public AccountPayoutTotals_Recalc(accountPayoutResult, formatTotalPayout = true): void {

    //Sum all the individual figures
    accountPayoutResult.TotalPayout = accountPayoutResult.PrincipalAmount + accountPayoutResult.InterestAccrued + accountPayoutResult.DefaultInterestAccrued + accountPayoutResult.UnclearedFunds + accountPayoutResult.OffsetBalance + accountPayoutResult.Fees;

    if (formatTotalPayout) {

      //Formatted value
      accountPayoutResult.TotalPayout_FMT = this.globalFunctions.customDataTypeParser(accountPayoutResult.TotalPayout, "currency.2", "aus");
    }
  }

  //Sync Totals
  public PayoutTotals_Sync(): void {

    //Initialise totals
    this.TotalFacilityPayout = 0;
    this.TotalPrincipal = 0;
    this.TotalInterestAccrued = 0;
    this.TotalDefaultInterestAccrued = 0;
    this.TotalUnclearedFunds = 0;
    this.TotalOffsetBalance = 0;
    this.TotalFees = 0;

    //Loop through all the accounts and sync totals
    this.PayoutCalculations.forEach(element => {

      //The fees might have been modified by user, lets sync it here
      element.Fees = 0;
      element.DischargeFeesResults.filter(x => x.IsIncluded === true).forEach(fee => {
        element.Fees = element.Fees + fee.Fees
      });

      //Update the formatted fees value
      element.Fees_FMT = this.globalFunctions.customDataTypeParser(element.Fees, "currency.2", "aus");

      //Recalculate the total payout for the target account
      this.AccountPayoutTotals_Recalc(element);

      //Now, sync the payout calculations cached for export
      const matchingCachedAccountPayoutCalc = this.PayoutCalculationsExport.filter(x => x.AccountID === element.AccountID)[0];
      if (!this.globalFunctions.isEmpty(matchingCachedAccountPayoutCalc)) {

        //Sync the total fees per account
        matchingCachedAccountPayoutCalc.Fees = element.Fees;

        //Update the total payout
        this.AccountPayoutTotals_Recalc(matchingCachedAccountPayoutCalc, false);
      }

      //Only update the bottom grand totals if the account is included into the payout figure
      if (element.IsIncluded) {
        this.TotalFacilityPayout = this.TotalFacilityPayout + element.TotalPayout;
        this.TotalPrincipal = this.TotalPrincipal + element.PrincipalAmount;
        this.TotalInterestAccrued = this.TotalInterestAccrued + element.InterestAccrued;
        this.TotalDefaultInterestAccrued = this.TotalDefaultInterestAccrued + element.DefaultInterestAccrued;
        this.TotalUnclearedFunds = this.TotalUnclearedFunds + element.UnclearedFunds;
        this.TotalOffsetBalance = this.TotalOffsetBalance + element.OffsetBalance;
        this.TotalFees = this.TotalFees + element.Fees;
      }
    });

    //Format totals for display
    this.TotalFacilityPayoutDisplay = this.globalFunctions.customDataTypeParser(this.TotalFacilityPayout, "currency.2", "aus");
    this.TotalPrincipalDisplay = this.globalFunctions.customDataTypeParser(this.TotalPrincipal, "currency.2", "aus");
    this.TotalInterestAccruedDisplay = this.globalFunctions.customDataTypeParser(this.TotalInterestAccrued, "currency.2", "aus");
    this.TotalDefaultInterestAccruedDisplay = this.globalFunctions.customDataTypeParser(this.TotalDefaultInterestAccrued, "currency.2", "aus");
    this.TotalUnclearedFundsDisplay = this.globalFunctions.customDataTypeParser(this.TotalUnclearedFunds, "currency.2", "aus");
    this.TotalOffsetBalanceDisplay = this.globalFunctions.customDataTypeParser(this.TotalOffsetBalance, "currency.2", "aus");
    this.TotalFeesDisplay = this.globalFunctions.customDataTypeParser(this.TotalFees, "currency.2", "aus");
  }

  //When an account is included or excluded
  public IncludeCheckbox_Click(item): void {

    //Flip the flag and set the tooltip
    if (item.IsIncluded === true) {
      item.IsIncluded = false;
      item.ToolTipText = this.IncludeToolTip;
    }
    else {
      item.IsIncluded = true;
      item.ToolTipText = this.ExcludeToolTip;
    }

    //Sync the totals and action buttons
    this.PayoutTotals_Sync();
    this.EnableButtons_Sync();
  }

  //Account row CSS
  public AccountRowCSS_Get(item): string {

    let cssStr = "";

    //When an account is excluded
    if (item.IsIncluded === false) {
      cssStr = "ExcludedCSS";
    }

    return cssStr;
  }

  //Show fee details icon css
  public FeeIconCSS_Get(item): string {

    let cssStr = "";

    //When an account is excluded
    if (item.IsIncluded === false) {
      cssStr = "BGColorGreyCircularDark"
    }
    else {
      cssStr = "BGColorGreyCircular"
    }

    return cssStr;
  }

  //Save Payout Calculations
  public PayoutCalculation_Save() {

    //Let's do some basic client side validation
    if (this.PayoutCalculations.length === 0) {
      this.notifyService.Error_Show("No payout records detected.", "Save Payout");
      return;
    }

    //Construct PayoutCalculationResults with selected accounts before invoking api
    const payoutCalculationsSelected = this.PayoutCalculations.filter(x => x.IsIncluded === true);
    if (payoutCalculationsSelected.length === 0) {
      this.notifyService.Error_Show("No payout records selected.", "Save Payout");
      return;
    }

    //Get the discharge type, reason and note from the modal data
    const dischargeTypeDU = this.ModelDataUnit_Get({ id: "INP_DischargeType" });
    if (this.globalFunctions.isEmpty(dischargeTypeDU) || this.globalFunctions.isEmpty(dischargeTypeDU.Value)) {
      this.notifyService.Error_Show("Please select a discharge type.", "Save Payout");
      return;
    }

    let dischargeReason = "";
    let dischargeNote = "";

    const showOptionalFieldsDU = this.ModelDataUnit_Get({ id: "INP_ShowOptionalFields" });

    //Set discharge reason and note only if the show optional fields is checked
    if (!this.globalFunctions.isEmpty(showOptionalFieldsDU) && showOptionalFieldsDU.IsChecked) {

      const dischargeReasonDU = this.ModelDataUnit_Get({ id: "INP_DischargeReason" });
      const dischargeNoteDU = this.ModelDataUnit_Get({ id: "INP_DischargeNote" });

      if (typeof (dischargeReasonDU.AutoCompleteControlData["ControlGUID"]) === "undefined" && !this.globalFunctions.isEmpty(JSON.stringify(dischargeReasonDU.AutoCompleteControlData))) {
        this.notifyService.Error_Show("Please select a valid discharge reason or remove it.", "Save Payout");
        return;
      }

      dischargeReason = dischargeReasonDU.Value;
      dischargeNote = dischargeNoteDU.Value;
    }

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID, TotalFacilityPayout: this.TotalFacilityPayout, PayoutCalculationResults: payoutCalculationsSelected, DischargeType: dischargeTypeDU.Value, DischargeReason: dischargeReason, DischargeNote: dischargeNote };

    //LastSaveRequest will be null on initial run, ignore the comparison, otherwise compare the whole request with the last saved one
    if (this.LastSavedPayoutRequest != null && this.LastSavedPayoutRequest === JSON.stringify(apiRequest)) {
      this.notifyService.Error_Show("Payout calculations already saved for this date.", "Save Payout");
      return;
    }

    //Check if any of the linked account within a facility is linked to fixed product. "this.ShowFixedProductMessage" gets set to true if any of the included account is linked to a fixed product
    if (this.ShowFixedProductMessage) {

      //Set BreakCostMissingMessage
      this.BreakCostMissingMessage_Set();
    }

    if (!this.ShowMissingBreakCostMessage) {

      //No confirmation flow required

      //Clone the apirequest into LastRequestedPayoutRequest
      this.LastSavedPayoutRequest = JSON.stringify(apiRequest);

      //Track state of this request, so that we can show the spinner, if necessary.
      this.IsGenerateInProgress = true;
      this.clientDataStore.SetShowFullscreenLoading(true);

      this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.SavePayoutCalculation], apiRequest)
        .subscribe(apiResponse => {
          if (this.globalFunctions.isEmpty(apiResponse)) {
            //There was no response, or an error.
            this.IsGenerateInProgress = false;
            this.clientDataStore.SetShowFullscreenLoading(false);

            //Reset the last saved payout request to allow user to click the save button again
            this.LastSavedPayoutRequest = null;

            return;
          }
          else {
            //Show toast message on success
            this.notifyService.Success_Show("The payout calculation has been saved successfully in the Notes screen.", "Save Payout");

            //Turn off the fullscreen loading
            this.clientDataStore.SetShowFullscreenLoading(false);
            this.IsGenerateInProgress = false;
            return;
          }
        });
    }
    else {

      //Add confirmation before generating a letter
      const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.LoanIndex.AccountID, true, false);

      //Use html content so that we can style it
      dialogRef.DialogRef.componentInstance.htmlContent = this.BreakCostMissingMessage + "<div><p>Are you sure you want to save a payout note?</p></div>"

      dialogRef.DialogRef.afterClosed().subscribe(result => {
        if (result === true) {

          //Clone the apirequest into LastRequestedPayoutRequest
          this.LastSavedPayoutRequest = JSON.stringify(apiRequest);

          //Track state of this request, so that we can show the spinner, if necessary.
          this.IsGenerateInProgress = true;
          this.clientDataStore.SetShowFullscreenLoading(true);

          this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.SavePayoutCalculation], apiRequest)
            .subscribe(apiResponse => {
              if (this.globalFunctions.isEmpty(apiResponse)) {
                //There was no response, or an error.
                this.IsGenerateInProgress = false;
                this.clientDataStore.SetShowFullscreenLoading(false);

                //Reset the last saved payout request to allow user to click the save button again
                this.LastSavedPayoutRequest = null;

                return;
              }
              else {
                //Show toast message on success
                this.notifyService.Success_Show("The payout calculation has been saved successfully in the Notes screen.", "Save Payout");

                //Turn off the fullscreen loading
                this.clientDataStore.SetShowFullscreenLoading(false);
                this.IsGenerateInProgress = false;
                return;
              }
            });
        }
      });
    }
  }

  //Launch Account Fee component
  public AccountFee_Launch(item): void {

    //Show fee details only if the account is included in the payout
    if (item.IsIncluded === true) {

      //Get the component of this modal, and set a property on it
      const accountFeeRef = this.globalFunctions.FeatureModal_Launch(AccountFee, this.globalFunctions.GetFeatureModalConfig('60%', false, true, 'glb_fixedHeightDialogSM'), this.dialog, "Account Fee", item.AccountID, true, false);

      accountFeeRef.DialogRef.componentInstance.AccountPayoutCalculation = this.PayoutCalculations.filter(x => x.AccountID === item.AccountID)[0];
      accountFeeRef.DialogRef.componentInstance.PayoutCalculator = this;
    }
  }

  //Sync the account fee array from the child account fee component
  public AccountFee_Sync(accountID, accountFees): void {
    const matchingAccountPayoutCalc = this.PayoutCalculations.filter(x => x.AccountID === accountID)[0];

    if (!this.globalFunctions.isEmpty(matchingAccountPayoutCalc) && !this.globalFunctions.isEmpty(accountFees)) {
      matchingAccountPayoutCalc.DischargeFeesResults = JSON.parse(JSON.stringify(accountFees));

      //Now sync the totals
      this.PayoutTotals_Sync();
    }
  }

  //Downloads the selected entity into a csv file
  public PayoutCalculations_DownloadCSV(): void {

    const csvArray = [];

    //Loop through the results and perform formatting on data 
    this.PayoutCalculationsExport.forEach(element => {

      //Remove AccoutnGUID from the export
      delete element.AccountGUID;

      //Remove Discharge Fee Results
      delete element.DischargeFeesResults;

      //Convert date to DD MMM YYYY
      element.DischargeDate = this.globalFunctions.getCustomDateFormat(element.DischargeDate, "shortdate", "custom", "YYYY-MM-DD");

      //Convert the amounts to currency format
      element.PrincipalAmount = this.globalFunctions.customDataTypeParser(element.PrincipalAmount, "currency.2", "aus");
      element.InterestAccrued = this.globalFunctions.customDataTypeParser(element.InterestAccrued, "currency.2", "aus");
      element.DefaultInterestAccrued = this.globalFunctions.customDataTypeParser(element.DefaultInterestAccrued, "currency.2", "aus");
      element.UnclearedFunds = this.globalFunctions.customDataTypeParser(element.UnclearedFunds, "currency.2", "aus");
      element.OffsetBalance = this.globalFunctions.customDataTypeParser(element.OffsetBalance, "currency.2", "aus");
      element.Fees = this.globalFunctions.customDataTypeParser(element.Fees, "currency.2", "aus");
      element.TotalPayout = this.globalFunctions.customDataTypeParser(element.TotalPayout, "currency.2", "aus");

      //Push into the array to be used for download
      csvArray.push(element);
    });

    //Call the service to produce and download the csv file
    this.csvDataService.exportToCsv("PayoutCalculations", csvArray);
  }

  //Method to generate payout letter
  public PayoutLetter_Generate(): void {

    //Toast title variable
    const toastTitle = "Generate Payout";

    //Let's do some basic client side validation
    if (this.PayoutCalculations.length === 0) {
      this.notifyService.Error_Show("No payout records detected.", toastTitle);
      return;
    }

    //Check if the PayoutCalculationResults has at lease one selected accounts before invoking api
    if (this.PayoutCalculations.filter(x => x.IsIncluded === true).length === 0) {
      this.notifyService.Error_Show("No payout records selected.", toastTitle);
      return;
    }

    //Check if the target client is selected
    const targetClientDU = this.ModelDataUnit_Get({ id: "INP_MultiselectSingle" });
    if (this.globalFunctions.isEmpty(targetClientDU) || this.globalFunctions.isEmpty(targetClientDU.Value)) {
      this.notifyService.Error_Show("No client selected.", toastTitle);
      return;
    }

    //Get the discharge type, reason and note from the modal data
    const dischargeTypeDU = this.ModelDataUnit_Get({ id: "INP_DischargeType" });
    if (this.globalFunctions.isEmpty(dischargeTypeDU) || this.globalFunctions.isEmpty(dischargeTypeDU.Value)) {
      this.notifyService.Error_Show("Please select a discharge type.", toastTitle);
      return;
    }

    let dischargeReason = "";
    let dischargeNote = "";

    const showOptionalFieldsDU = this.ModelDataUnit_Get({ id: "INP_ShowOptionalFields" });

    //Set discharge reason and note only if the show optional fields is checked
    if (!this.globalFunctions.isEmpty(showOptionalFieldsDU) && showOptionalFieldsDU.IsChecked) {

      const dischargeReasonDU = this.ModelDataUnit_Get({ id: "INP_DischargeReason" });
      const dischargeNoteDU = this.ModelDataUnit_Get({ id: "INP_DischargeNote" });

      if (typeof (dischargeReasonDU.AutoCompleteControlData["ControlGUID"]) === "undefined" && !this.globalFunctions.isEmpty(JSON.stringify(dischargeReasonDU.AutoCompleteControlData))) {
        this.notifyService.Error_Show("Please select a valid discharge reason or remove it.", toastTitle);
        return;
      }

      dischargeReason = dischargeReasonDU.Value;
      dischargeNote = dischargeNoteDU.Value;
    }

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID, TotalFacilityPayout: this.TotalFacilityPayout, PayoutCalculationResults: this.PayoutCalculations, DischargeType: dischargeTypeDU.Value, DischargeReason: dischargeReason, DischargeNote: dischargeNote, ClientGUID: targetClientDU.Value };

    //LastSavedPayoutRequest will be null on initial run, ignore the comparison, otherwise compare the whole request with the last saved one
    if (this.LastPayoutLetterRequest != null && this.LastPayoutLetterRequest === JSON.stringify(apiRequest)) {

      //User has clicked the button again for the same payout request
      this.notifyService.Error_Show("Payout letter generation has already been requested for this date.", toastTitle);
      return;
    }

    //Check if any of the linked account within a facility is linked to fixed product. "this.ShowFixedProductMessage" gets set to true if any of the included account is linked to a fixed product
    if (this.ShowFixedProductMessage) {

      //Set BreakCostMissingMessage
      this.BreakCostMissingMessage_Set();
    }

    //Add confirmation before generating a letter
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.LoanIndex.AccountID, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = this.BreakCostMissingMessage + "<div><p>Are you sure you want to generate a payout letter?</p></div>"

    if (dischargeTypeDU.Value == "Settlement Booked") {

      //Display additional information related to business rules linked to settlement booked discharge type
      dialogRef.DialogRef.componentInstance.htmlContent = "<div>"
        + "<p>Please note that the following actions will be triggered as part of this process: </p>"
        + "<ul class=\"glb_customFlexRow align-items-center\" style=\"margin-left: 1em; text-align: left;\">"
        + "<li class=\"col-12\">Set loan status to Pending Discharge</li>"
        + "<li class=\"col-12\">Anticipated Discharge Date to Payout Date</li>"
        + "<li class=\"col-12\">Disable DD and Redraw, if applicable</li>"
        + "</ul>"
        + "<br><p>If you do not want this to happen, please choose discharge type = Indicative.</p>"
        + this.BreakCostMissingMessage
        + "<p>Are you sure you want to continue?</p>"
        + "</div>";

    }

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Clone the apirequest into LastRequestedPayoutRequest
        this.LastPayoutLetterRequest = JSON.stringify(apiRequest);

        //Track state of this request, so that we can show the spinner, if necessary.
        this.IsGenerateInProgress = true;
        this.clientDataStore.SetShowFullscreenLoading(true);

        this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GenerateResiPayoutLetter], apiRequest)
          .subscribe(apiResponse => {
            if (this.globalFunctions.isEmpty(apiResponse)) {

              //Reset the last saved payout request to allow user to click the generate button again
              this.LastPayoutLetterRequest = null;

              //There was no response, or an error.
              this.IsGenerateInProgress = false;
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {

              //Get the response, and try to display it in the document viewer
              const response = JSON.parse(JSON.stringify(apiResponse));

              //Check the response and process the base64 string
              //Create a blob that can be used to represent the binary version of the file
              if (!this.globalFunctions.isEmpty(response.Base64Document)) {

                //Convert base64 string to Blob
                const blob = this.globalFunctions.base64toBlob(response.Base64Document);

                //Open DocViewer
                const docViewer = this.globalFunctions.FeatureModal_Launch(DocViewer, this.globalFunctions.GetFeatureModalConfig('90%', false, false, "glb_fixedHeightDialog"), this.dialog, "Doc Viewer", this.LoanIndex.AccountID, true, false);
                docViewer.DialogRef.componentInstance.FileBlob = blob;
                docViewer.DialogRef.componentInstance.FileName = response.Name + "." + response.Extension;
                docViewer.DialogRef.componentInstance.FileType = response.Extension;
                docViewer.DialogRef.componentInstance.OriginalFileType = response.OriginalFileType;

                //These 3 datapoints are required for downloading a document from the DocViewer
                docViewer.DialogRef.componentInstance.DocumentGUID = response.GUID;
                docViewer.DialogRef.componentInstance.EntityType = "LoanDocuments";
                docViewer.DialogRef.componentInstance.LoanIndex = this.LoanIndex;

                if (dischargeTypeDU.Value == "Settlement Booked") {

                  //Refresh header section, the status and redraw frozen flag have been updated as part of business rule
                  this.LoanIndex.GetAccountSummary();
                }

                this.globalFunctions.delay(1000).then(() => {

                  //Show toast message on success
                  this.notifyService.Success_Show("The payout letter has been generated successfully.", toastTitle);
                });
              }
              else {

                //Reset the last saved payout request to allow user to click the generate button again
                this.LastSavedPayoutRequest = null;

                //Show failed toast message
                this.notifyService.Warning_Show("Something went wrong while generating a letter. Please try again.", toastTitle);
              }

              //We save the payout when we generate a letter
              this.LastSavedPayoutRequest = this.LastPayoutLetterRequest;

              //Turn off the fullscreen loading
              this.clientDataStore.SetShowFullscreenLoading(false);
              this.IsGenerateInProgress = false;
              return;
            }
          });
      }
    });
  }

  //Set the break cost missing message
  private BreakCostMissingMessage_Set(): void {

    //Initialise BreakCostMissingMessage
    this.BreakCostMissingMessage = "";

    if (!this.globalFunctions.isEmpty(this.PayoutCalculations)) {

      //Initialise ShowMissingBreakCostMessage to false
      this.ShowMissingBreakCostMessage = false;

      //Build alert message
      this.BreakCostMissingMessage = "<br> <div class=\"glb_importantTextSM\"><p>Break cost is missing and may be payable on following fixed rate account(s). Please add the break cost under the fees section if required.</p>" + "<ul class=\"glb_customFlexRow align-items-center\" style=\"margin-left: 1em; text-align: left;\">";

      //Loop through all included accounts
      this.PayoutCalculations.filter(x => x.IsIncluded).forEach(payout => {

        //Applicable to fixed accounts only
        if (payout.RateType === "Fixed") {

          if (!this.globalFunctions.isEmpty(payout.DischargeFeesResults)) {

            //Intialise a variable to check whether the fixed account has break cost transactions added or not 
            let hasBreakCostTxn = false;

            //Get the break cost transaction if it exists
            const breakCostTxn = payout.DischargeFeesResults.filter(x => x.IsIncluded === true && !this.globalFunctions.isEmpty(x.TransactionType) && x.TransactionType.toString().toUpperCase().includes("BREAK COST"))[0];

            if (!this.globalFunctions.isEmpty(breakCostTxn)) {
              hasBreakCostTxn = true;
            }

            //If the account doesn't have break cost, include an account to alert the user
            if (!hasBreakCostTxn) {
              this.BreakCostMissingMessage += "<li class=\"col-12\">" + payout.AccountID + "</li>"

              if (!this.ShowMissingBreakCostMessage) {

                //Set the show missing breakcost message
                this.ShowMissingBreakCostMessage = true;
              }
            }
          }
        }
      });

      //Finalise BreakCostMissingMessage depending on whether to show it or not
      if (!this.ShowMissingBreakCostMessage) {
        this.BreakCostMissingMessage = "";
      }
      else {
        this.BreakCostMissingMessage += "</ul></div><br>"
      }
    }
  }

  //Get current date for initial load
  private CurrentDate_Get(): Date {
    //Set defaults for the Payout Date
    const date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();

    //Now return (no time)
    return new Date(y, m, d);
  }

  //Setting default payout date on initial load
  private Page_Init() {

    if (this.InitialPageLoad) {
      //Default Payout Date to today (current date) in ISO format
      this.PayoutDate = moment(this.CurrentDate_Get(), "DD/MM/YYYY HH:mm A").format('YYYY-MM-DDTHH:mm');
    }
  }
}