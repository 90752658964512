import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { Dashboard } from '@app/Components/Dashboard/Dashboard';
import { TemplateID } from '@app/Global/Models/ClientModels';

@Component({
  selector: 'LenderTasks',
  templateUrl: './LenderTasks.html',
  styleUrls: ['./LenderTasks.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})
export class LenderTasks implements OnInit {

  //Copies of other parent, in case we want to trigger updates on them later
  @Input() Dashboard: Dashboard;

  //Multiselect Input
  @ViewChild('INP_StatusColumn') INPStatusColumn;

  //Local spinner
  public ShowSpinner = false;

  //Used to separate keys when combined into a single identifier. looks like we don't need this anymore. just going to leave it blank.
  public KeySeparator = "_";

  //Used for the button (icon) spinner on the refresh button at the top right (the RefreshDashboardDataAggregated method)
  public RefreshingAggregatedSpinner = true;

  //Used to track if we should show the context action bar
  public ShowContextBar = false;

  //Should we show the Edit Task button (only should be allowed when a single task is highlighted)
  public ShowTaskEditButton = false;

  //The delegator dashboard data from the server (aggregated) for display
  public DashboardAggregatedDisplay;

  //The delegator dashboard data from the server (aggregated) for storing the original dictionary
  public DashboardAggregatedResponse;

  //Cached selected status options as per the primeng multiselect
  public CachedSelectedStatus = [];

  //Status selection options, used by the primeng multiselect
  public CheckboxOptions = [];

  //Selected status options as per the primeng multiselect
  public SelectedStatus = [];

  //Flag to track the status of selectAll checkbox on the primeng multiselect
  public SelectAll = { Value: true };

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  //Store how many columns it has, dynamic as the number of status can change, with new ones added or removed.
  private AggregateColCount = 0;

  //Store the detail data here (first level detail, contains Task Guids, Lender name, Due date and status). this is an dictionary and will contain entries for all elements from the aggregate in delegatorDashboardDataAggregated
  private DashboardDetailDict;

  //To store the last clicked task item
  private LastClickedTaskItem;

  //Store a list of clicked items that are used to send the request to the server. dont really need a list here as we only do single selection at a time here, compared to the shift click support needed by the workflow delegator
  private SelectedTasks = [];

  //Flag to check whether we have received the detail data from the server
  private DetailDataReceived = false;

  //Flag to check whether the chevron has been clicked to load detail data
  private IsDetailLoading = false;

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    public dialog: MatDialog) {

  }

  //Angular constructor
  ngOnInit() {
    this.DashboardAggregated_Refresh();

    //Init the multiselect options
    this.CheckboxOptions = [
      { name: 'Active' },
      { name: 'Awaiting Information' },
      { name: 'Reminder Issued' },
      { name: 'Quality Checking' },
      { name: 'QC Complete' },
      { name: 'Complete' },
    ];

    //To achieve the inversion - default all status displays to true
    for (const item of this.CheckboxOptions) {
      this.SelectedStatus.push(item);
    }
  }

  //Multiselect for ALL
  public MultiSelectAll_Toggle(e): void {

    this.globalFunctions.MultiSelectAll_Toggle(e, this.SelectAll, this.CheckboxOptions, this.SelectedStatus, this.INPStatusColumn);

    //Reset status multiselect
    this.StatusMultiSelect_Reset();

    //Refresh display
    this.DashboardAggregatedDisplay_Refresh();
  }

  //Toggle when we select/unselect individual checkboxes in the list
  public MultiSelect_Toggle(multiSelectToggle = true): void {

    //To tick/untick the select all checkbox
    this.globalFunctions.MultiSelect_Toggle(this.SelectAll, this.CheckboxOptions, this.SelectedStatus);

    //Check if it is being invoked as part of Chevron click (multiselectToggle = false)
    if (multiSelectToggle) {

      //Reset status multiselect
      this.StatusMultiSelect_Reset();
    }

    //Refresh display
    this.DashboardAggregatedDisplay_Refresh();
  }

  //Check if the status is in the selected list. Remove the status column otherwise
  public SelectedStatus_Check(name, delegatorDashboardData): void {
    const targetStatusChecked = this.SelectedStatus.filter(x => x.name === name)[0];
    if (this.globalFunctions.isEmpty(targetStatusChecked)) {
      delete delegatorDashboardData[name];
    }
  }

  //Populate the aggregate data for display and show/hide the status columns based on the flags
  public DashboardAggregatedDisplay_Refresh(clearDetailData = false): void {

    //Unselect the highlighted items if there are any
    this.UIArrays_Clear(clearDetailData);

    //Re-initialise display array on any status checkbox click
    this.DashboardAggregatedDisplay = {};

    //Get keys from the original dictionary (response from the server)
    const aggregateDataKeys = Object.keys(this.DashboardAggregatedResponse);

    let columnCounterIndex = 0;

    //Loop through the aggregate data and hide the columns as needed
    aggregateDataKeys.forEach(key => {

      //Deep clone object for each key into our display dictionary
      this.DashboardAggregatedDisplay[key] = JSON.parse(JSON.stringify(this.DashboardAggregatedResponse[key]));

      const delegatorDashboardData = this.DashboardAggregatedDisplay[key];

      //Remove the status columns based on the selected status checkboxes
      this.SelectedStatus_Check("Complete", delegatorDashboardData);
      this.SelectedStatus_Check("Awaiting Information", delegatorDashboardData);
      this.SelectedStatus_Check("Quality Checking", delegatorDashboardData);
      this.SelectedStatus_Check("QC Complete", delegatorDashboardData);
      this.SelectedStatus_Check("Reminder Issued", delegatorDashboardData);
      this.SelectedStatus_Check("Active", delegatorDashboardData);

      //Run only once
      if (columnCounterIndex === 0) {

        //Update the number of columns for the display. 
        this.AggregateColCount = Object.keys(this.DashboardAggregatedDisplay[key]).length;
        columnCounterIndex++;
      }
    });
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //Refresh the detail from the server when a chevron is clicked, this is a wrapper and performs some cleansing and putting things back together for the next API call
  public DelegatorDashboardDetailDataWrapper_Get(i_key: string, i_subKey: string, identifier: string): void {

    if (this.IsDetailLoading) {

      //Detail data is still loading from previous click, return
      return;
    }

    //Toggle the chevron
    this.TemplateID_Toggle(identifier);

    //Set detail loading to true
    this.IsDetailLoading = true;

    //Cache current selected status
    if (this.CachedSelectedStatus.length <= 0) {
      this.SelectedStatus.forEach(statusOption => {
        this.CachedSelectedStatus.push(statusOption);
      });
    }

    //Strip braces and spaces, otherwise data toggling in the html will not work (ID's wont match)
    const key = this.globalFunctions.StripBracesAndSpaces(i_key);
    const subKey = this.globalFunctions.StripBracesAndSpaces(i_subKey);

    //Init the entry inside the dictionary first
    this.DashboardDetailDict[key + this.KeySeparator + subKey] = { Entity: [], DisplayName: "Detail", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
    this.DashboardDetailDict[key + this.KeySeparator + subKey].Entity = [];

    //Now let's try calling out to the server for some data. turn the loading spinner on for this one
    this.DashboardDetailDict[key + this.KeySeparator + subKey].Spinner = true;

    //Pass it the two keys from the template the we recieved - key = assigned User GUID, and the subKey = status. Status can have spaced, which need to be preserved when sending the request to the server. so supply the raw value here as well. (braces are easy to add, space not so much)
    this.DelegatorDashboardDetailData_Get(this.DashboardDetailDict[key + this.KeySeparator + subKey].Entity, key, subKey, i_subKey);
  }

  //Gets the relevant aggregated data from the server
  public DashboardAggregated_Refresh(assignedToGUID = "", status = ""): void {

    //Turn on the spinners
    this.ShowSpinner = true;
    this.RefreshingAggregatedSpinner = true;

    //TaskAssignedToGUID will ensure that the column is used as the primary key instead of in the results.
    const apiRequest = { ReturnType: "Aggregate", AssignedToGUID: assignedToGUID, Status: status, KeyColumn: "TaskAssignedToGUID", LenderGUID: "" };

    //Invoke the API
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetLenderTasksDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.ShowSpinner = false;
          this.RefreshingAggregatedSpinner = false;
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Reset the dictionary
          this.DashboardAggregatedResponse = {};

          let columnCounterIndex = 0;
          for (const key in response) {

            //Need to unescape some columns
            if (!this.globalFunctions.isEmpty(response[key])) {
              if (!this.globalFunctions.isEmpty(response[key]['Lender'])) {
                response[key]['Lender'] = this.globalFunctions.HTMLUnescape(response[key]['Lender']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['TaskType'])) {
                response[key]['TaskType'] = this.globalFunctions.HTMLUnescape(response[key]['TaskType']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['TaskTypePretty'])) {
                response[key]['TaskTypePretty'] = this.globalFunctions.HTMLUnescape(response[key]['TaskTypePretty']);
              }
            }

            //And fill it
            this.DashboardAggregatedResponse[key] = response[key];

            //See if we need to calc the column count (just do it once)
            if (columnCounterIndex === 0) {

              //Get the first item in the array, work out the length. this is a performance optimization, instead of letting the template calculate this 
              this.AggregateColCount = Object.keys(this.DashboardAggregatedResponse[key]).length;

              //Increment the columnCounterIndex so we don't do this again.
              columnCounterIndex++;
            }
          }

          //Populate the aggregate dictionary for display and show/hide status columns if any of those are checked
          this.DashboardAggregatedDisplay_Refresh(true);

          //Init a new dictionary to store server data (detail). but will be populated later, when the user clicks on the chevrons
          this.DashboardDetailDict = {};

          //Turn the spinners off
          this.ShowSpinner = false;
          this.RefreshingAggregatedSpinner = false;
          return;
        }
      });
  }

  //Shows detail from the local dictionary value inside delegatorDashboardDataDetailDict
  public DetailData_Show(i_key: string, i_subKey: string) {
    const key = this.globalFunctions.StripBracesAndSpaces(i_key);
    const subKey = this.globalFunctions.StripBracesAndSpaces(i_subKey);

    if (this.globalFunctions.isEmpty(this.DashboardDetailDict[key + this.KeySeparator + subKey])) {
      return "none";
    }
    return this.DashboardDetailDict[key + this.KeySeparator + subKey].Entity;
  }

  //Shows detail from the local dictionary inside delegatorDashboardDataDetailDict regarding the spinner status
  public DetailDataSpinner_Check(i_key: string, i_subKey: string): boolean {
    const key = this.globalFunctions.StripBracesAndSpaces(i_key);
    const subKey = this.globalFunctions.StripBracesAndSpaces(i_subKey);
    if (this.globalFunctions.isEmpty(this.DashboardDetailDict[key + this.KeySeparator + subKey])) {

      //This entry has not been initialized. probably never been loaded. just return false for the spinner.
      return false;
    }

    //Return the spinner value
    return this.DashboardDetailDict[key + this.KeySeparator + subKey].Spinner;
  }

  //When using GUIDS to bind to elements in html, we can't have the curly braces { and } or even spaces! use this method to strip them out, as needed
  public BracesAndSpaces_Strip(GUID: string): string {

    //Moved to the globalFuctions library
    return this.globalFunctions.StripBracesAndSpaces(GUID);
  }

  //This preserves the order of key values coming in from server json arrays, used in the pipe on the html template
  public Order_Keep = (a,) => {
    return a;
  }

  //Used to track clicks on each of the expanded task items, whether or not to remove highlights and also shift click support
  public TaskItem_Clicked(event, item): void {

    //Check and unselect action bars on all others
    this.Dashboard.DisableContextActionBars('LenderTasks');

    //Use a temp class to indicate that we are selecting this.
    event.currentTarget.classList.add('newSelection');

    //Now, only add the highlight if this was NOT selected before.
    if (event.currentTarget.classList.contains('glb_highlightSelectedTask') === false) {

      //The existing item from the selectedTasks array needs the highlight removed, if there was one selected before
      if (!this.globalFunctions.isEmpty(this.LastClickedTaskItem)) {
        this.LastClickedTaskItem.classList.remove('glb_highlightSelectedTask');
      }

      //Now we can update it to this one
      this.LastClickedTaskItem = event.currentTarget;

      //Add the highlight
      this.LastClickedTaskItem.classList.add('glb_highlightSelectedTask');

      //Empty the selected task array;
      this.SelectedTasks.length = 0;

      //Now push the new item in into the selectedTasks array. this is where the other functionality is driven from
      this.SelectedTasks.push(item);
    }
    else {

      //It already contains it. the same data unit was clicked! remove it!
      event.currentTarget.classList.remove('glb_highlightSelectedTask');

      //Remove this from the selectedTasks array
      this.SelectedTasks.length = 0;
    }

    //Remove the temporary newSelection class. not sure if this is needed here at this very moment, but might be handy later, when doing copy/paste style operations. leaving it for now.
    event.currentTarget.classList.remove('newSelection');

    //Now check items and flip the context action bar
    if (this.SelectedTasks.length > 0) {
      this.ShowContextBar = true;

      //If its exactly 1, then we can show the edit task button
      if (this.SelectedTasks.length === 1) {
        this.ShowTaskEditButton = true;
      }
      else {
        this.ShowTaskEditButton = false;
      }
    }
    else {
      this.ShowContextBar = false;
    }
  }

  //Used to clear all client side cached items. useful when refreshing.
  public UIArrays_Clear(clearDetailData = true): void {

    //Unhighlight the currently selected task
    if (!this.globalFunctions.isEmpty(this.LastClickedTaskItem)) {
      this.LastClickedTaskItem.classList.remove('glb_highlightSelectedTask');
    }

    //Clear the entire clickedTasks array
    this.SelectedTasks.length = 0;
    this.SelectedTasks = [];

    //Clear out the detailed data dictionary based on the parameter. If we don't do this, then we leave data that may not be needed anymore (e.g. tasks that were reassigned after a save). will cause UI issues when trying to click items afterwards, since they now exist twice.
    //Unless we DONT want to clear UI data, e.g. when the status flags are switched.
    if (clearDetailData) {
      this.DashboardDetailDict = {};

      //Collapse all the Chevrons
      this.globalFunctions.TemplateID_CollapseAll(this.TemplateIdentifiers);
    }

    //Disable the context bar
    this.ShowContextBar = false;
  }

  //Launch the task view
  public TaskView_Launch(): void {

    //It's possible to create a 'Headless' LoanIndex that we can reuse
    const loanIndex = new LoanIndex(this.apiService, this.globalFunctions, this.notifyService, this.dialog, this.clientDataStore);

    //Try to get its basic stuff initialized. send the first selected task from the array. we can split the key into its 2 parts for this request (as we need LoanID and TaskGUID both)
    const loanID = this.SelectedTasks[0].key.split('_')[0]
    const taskGUID = this.SelectedTasks[0].key.split('_')[1]

    //The entity in this case is always LoanTasks
    const entityName = "LoanTasks";

    //Set the fullscreen loading spinner
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Now call the loanIndex initializer, called 'HeadlessMode'. Pass the loanID and taskGUID to specify that it should load this single record from the server, and instantly show the Loan Entity Modify modal (without loan index) for us . It will remove the fullscreen loading spinner when that is done. Pass myself as a script to the Headless Mode
    loanIndex.HeadlessModeInit(loanID, entityName, taskGUID, this);
  }

  //Align the columns for the aggregate table based on how much data is coming in. not used here
  public CssClass_Get(): string {

    //Return class based on aggregate columns count
    let classText = 'glb_customFlexRow col-12 ';

    //Check how many columns inside the object and set the css
    if (this.AggregateColCount > 0) {
      classText += 'row-cols-' + this.AggregateColCount.toString()
    }

    return classText;
  }

  //Limits the text size
  public TextSize_Limit(input, length: number): string {
    return this.globalFunctions.LimitTextSize(input, length);
  }

  //Calls the parent to refresh all Task based components
  public ParentTaskData_Refresh(): void {
    this.Dashboard.RefreshTaskData();
  }

  //To set the col width when a detail data is displayed
  public ColumnCSS_Get(subItem): string {

    //Check if there is any chevron being expanded. If yes, wait until we received the detail data from the server before assigning the col width
    if (!this.globalFunctions.isEmpty(this.TemplateIdentifiers.filter(x => x.IsEnabled)[0]) && this.DetailDataReceived) {

      //First column, set to col-2
      if (subItem.key === "Assigned To") {
        return "col-2"
      }

      //Detail data, col-10
      return "col-10"
    }

    //If there are no expanded chevrons or no detail data received yet, do not apply any changes
    return "";
  }

  //When a user collapses the detail data
  public DetailData_Collapse(identifer): void {

    //Let's not collapse while the detail is still loading
    if (this.IsDetailLoading) {
      return;
    }

    //Toggle the chevron
    this.TemplateID_Toggle(identifer);

    //Bit of delay for the template identifer to get updated based on the click (ngClass)
    this.globalFunctions.delay(10).then(() => {

      //Check if there is any expanded chevron
      if (this.globalFunctions.isEmpty(this.TemplateIdentifiers.filter(x => x.IsEnabled)[0])) {

        //This is the last expanded chevron being collapsed. Reset the associated class variables
        this.DetailDataReceived = false;

        //Sync the SelectedStatus with the CachedSelectedStatus
        this.MultiselectCache_Sync(this.CachedSelectedStatus, this.SelectedStatus, this.INPStatusColumn, true, true);
      }
    });
  }

  //Show/hide tool tip based on the size and the max limit
  public ValueWithinLimit_Check(value, limit): boolean {
    if (!this.globalFunctions.isEmpty(value)) {
      if (value.length > limit) {
        return false;
      }
    }

    return true;
  }

  //Re-sync the cached multiselect items if exists
  private MultiselectCache_Sync(cachedItems, targetItems, targetInput, resetCache = false, refreshAggregate = false): void {

    //Sync the target multiselect list with the Cached item list
    if (cachedItems.length > 0) {

      //Initialise the target list
      targetItems = [];

      //Loop through the cached items and add it to the target list
      cachedItems.forEach(item => {
        targetItems.push(item);
      });

      //Force the target input to update the UI based on selected items
      targetInput.updateModel(targetItems);

      //Reset cache items based on the parameter
      if (resetCache) {
        cachedItems.length = 0;
      }

      //Refresh dashboard based on the parameter
      if (refreshAggregate) {
        this.DashboardAggregatedDisplay_Refresh();
      }

    }
  }

  //Reset chevron and multiselect variables
  private StatusMultiSelect_Reset(): void {

    //Collapse the chevron if there are any expanded ones. We now expand detail data per status.
    this.globalFunctions.TemplateID_CollapseAll(this.TemplateIdentifiers);

    //Initialise the class variables associated with the detail data display
    this.CachedSelectedStatus.length = 0;
    this.DetailDataReceived = false;
  }

  //Gets the relevant detailed data from the server
  private DelegatorDashboardDetailData_Get(fillThis, key_AssignedTo: string, subKey_status = "", subKey_statusRaw = ""): void {

    //Don't forget to reinstate the braces for any keys we send back to the server. in this call, we also know that the primary key (KeyColumn) for the request is TaskGUID. We want this to be placed into the root of each returned entry in the dictionary. Easier for the html template to find the key and use it for future requests.
    const apiRequest = { ReturnType: "Detail", AssignedToGUID: this.globalFunctions.ReinstateBraces(key_AssignedTo), Status: subKey_statusRaw, KeyColumn: "TaskGUID" };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetLenderTasksDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //Turn spinner off
          this.DashboardDetailDict[key_AssignedTo + this.KeySeparator + subKey_status].Spinner = false;
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Reset the dictionary
          fillThis.length = 0;

          //Now loop through and fill all properties
          for (const key in response) {

            //Unescape columns
            if (!this.globalFunctions.isEmpty(response[key])) {
              if (!this.globalFunctions.isEmpty(response[key]['Lender'])) {
                response[key]['Lender'] = this.globalFunctions.HTMLUnescape(response[key]['Lender']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['Type'])) {
                response[key]['Type'] = this.globalFunctions.HTMLUnescape(response[key]['Type']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['Principal Borrower'])) {
                response[key]['Principal Borrower'] = this.globalFunctions.HTMLUnescape(response[key]['Principal Borrower']);
              }
            }

            let value = response[key];

            //We can reparse the dictionary as JSON so that we can adjust any values, as needed. deal with date formats here instead of a html template pipe
            const JSONparsed = JSON.parse(JSON.stringify(value));

            JSONparsed['Start Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Start Date'], 'shortdatetime', 'aus');

            if (subKey_status === 'Complete') {

              //For Complete, its a different column name
              JSONparsed['Complete Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Complete Date'], 'shortdatetime', 'aus');
            }
            else {
              JSONparsed['Due Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Due Date'], 'shortdatetime', 'aus');
            }

            //Put the nicely formatted value back into the dictionary
            value = JSONparsed;

            //And assign it to the local dictionary that was passed by reference.
            fillThis[key] = value;
          }

          //Detail loading is complete
          this.IsDetailLoading = false;

          //We have now received the detail data
          this.DetailDataReceived = true;

          //Push the status of the clicked chevron
          this.SelectedStatus = [];
          this.SelectedStatus.push({ name: subKey_statusRaw });

          //Toggle the primeng multiselect to only display the target status
          this.MultiSelect_Toggle(false);

          //Force the multiselect to update the model. The select all wasn't syncing
          this.INPStatusColumn.updateModel(this.SelectedStatus);

          //Turn spinner off
          this.DashboardDetailDict[key_AssignedTo + this.KeySeparator + subKey_status].Spinner = false;

          //No need to return anything, as the html template has a function call to retrieve it from the referenced dictionary
          return;
        }
      });
  }
}