import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotifyService {

  //Configure the placement of the notification message here
  ToastPosition = 'toast-bottom-right';

  constructor(private toastr: ToastrService) { }
  Success_Show(message: string, title: string) {
    this.ToastR_Config();
    this.toastr.success(message, title, { newestOnTop: true, titleClass: "glb_Font toastTitleStyle", messageClass: "glb_Font" })
  }

  Error_Show(message: string, title: string) {
    this.ToastR_Config();
    //onActivateTick shows the toast on startup
    this.toastr.error(message, title, { onActivateTick: true, newestOnTop: true, titleClass: "glb_Font toastTitleStyle", messageClass: "glb_Font" })
    console.log(message);
  }

  Info_Show(message: string, title: string) {
    this.ToastR_Config();
    this.toastr.info(message, title, { newestOnTop: true, titleClass: "glb_Font toastTitleStyle", messageClass: "glb_Font" })
  }

  Warning_Show(message: string, title: string) {
    this.ToastR_Config();
    this.toastr.warning(message, title, { onActivateTick: true, newestOnTop: true, titleClass: "glb_Font toastTitleStyle", messageClass: "glb_Font" })
  }

  private ToastR_Config() {
    this.toastr.toastrConfig.positionClass = this.ToastPosition;
    //these settings allow toastr to append new toasts properly, without losing any chainged requests that come quickly(e.g. user quickly clicking on login). we want the user to see their password attempts decreasing for each click.
    this.toastr.toastrConfig.maxOpened = 2;
    this.toastr.toastrConfig.newestOnTop = true;
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.timeOut = 5000;
    this.toastr.toastrConfig.extendedTimeOut = 5000;
    this.toastr.toastrConfig.easeTime = 100;
  }
}