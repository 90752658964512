<div id="PG_UserProfile" class="glb_formBGColor text-white glb_Font glb_paddingTopLG glb_paddingBottomLG">
  <div class="col-md-12 col-lg-8 glb_paddingZeroOffset">
    <p class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">User Profile</p>
    <div *ngIf="!ShowSpinner">
      <div class="glb_customFlexRow">
        <div class="glb_customFlexRow">
          <div class="col-12">
            <p class="text-start glb_breakLongWords glb_TitleMD glb_TitleMDMobile">{{ChosenUser.FirstName}} {{ChosenUser.LastName}}</p>
          </div>

          <!-- the input box for updating the role name -->
          <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM">
            <div class="glb_paddingBottomSM">
              <div class="text-white glb_inputFieldLabel glb_customFlexRow">Login ID</div>
              <input class="glb_formInputBox glb_skinLightOutline text-black glb_Font glb_inactiveInputBox" type="text" placeholder="Login ID"
                [(ngModel)]="ChosenUser.UserNumber" readonly>
            </div>
            <div class="glb_paddingBottomSM">
              <div class="text-white glb_inputFieldLabel glb_customFlexRow">First Name</div>
              <input class="glb_formInputBox glb_skinLightOutline text-black glb_Font glb_inactiveInputBox" type="text" placeholder="First Name"
                [(ngModel)]="ChosenUser.FirstName" readonly>
            </div>
            <div class="glb_paddingBottomSM">
              <div class="text-white glb_inputFieldLabel glb_customFlexRow">Last Name</div>
              <input class="glb_formInputBox glb_skinLightOutline text-black glb_Font glb_inactiveInputBox" type="text" placeholder="Last Name"
                [(ngModel)]="ChosenUser.LastName" readonly>
            </div>
            <div class="glb_paddingBottomSM">
              <div class="text-white glb_inputFieldLabel glb_customFlexRow">Email Address</div>
              <input class="glb_formInputBox glb_skinLightOutline text-black glb_Font glb_inactiveInputBox" type="text" placeholder="Email Address"
                [(ngModel)]="ChosenUser.EmailAddress" readonly>
            </div>
            <div class="glb_paddingBottomMD">
              <div class="text-white glb_inputFieldLabel glb_customFlexRow">Mobile Number</div>
              <input class="glb_formInputBox glb_skinLightOutline text-black glb_Font glb_inactiveInputBox" type="text" placeholder="Mobile Number"
                [(ngModel)]="ChosenUser.MobileNumber" readonly>
            </div>
            <!-- buttons -->
            <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_paddingBottomSM">
              <div class="glb_paddingBottomSM d-grid">
                <button tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                  (click)="ChangePassword_Launch(ChangePasswordTemplate)">
                  <span class="glb_Font glb_smallerText">Change Password</span></button>
              </div>
            </div>
            <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_paddingBottomSM">
              <div class="glb_paddingBottomSM d-grid">
                <button tabindex="14" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="Cache_Clear()">
                  <span class="glb_Font glb_smallerText">Clear Cache</span></button>
              </div>
            </div>

            <!-- Button to throw a client side error -->
            <div *ngIf="EnableErrorGenerator" class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
              <div class="glb_paddingBottomSM d-grid">
                <button tabindex="14" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="ClientError_Throw()">
                  <span class="glb_Font glb_smallerText">Throw a client error</span></button>
              </div>
            </div>

            <!-- Button to throw a server side error -->
            <div *ngIf="EnableErrorGenerator" class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
              <div class="glb_paddingBottomSM d-grid">
                <button tabindex="14" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="ServerError_Throw()">
                  <span class="glb_Font glb_smallerText">Throw a server error</span></button>
              </div>
            </div>

          </div>
        </div>
        <div class="header-padding glb_customFlexRow col-12">
          <div class="col-12">
            <div>
              <!-- <div class="text-start glb_breakLongWords" style="padding-right: 15px;">Assigned Roles</div> -->
            </div>
          </div>

          <!-- content loop here -->
          <div *ngFor="let item of ChosenUserRoles" class="card col-12">
            <div class="card-body-lesspadding card-body glb_customFlexRow" [ngClass]="Class_Get('DataPair', ColumnsInBodyRole, WrapOddRows)">
              <div class="glb_customFlexRow justify-content-left align-items-center">
                {{item.RoleName}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserOptions [HideTopHeaderPadding]="true"></UserOptions>
    </div>
  </div>

  <div *ngIf="ShowSpinner" class="col-12 glb_paddingZeroOffset">
    <!-- this is the loading spinner, lets show it while the entity is loading -->
    <div class="glb_customFlexRow">
      <div class="col">
        <div class="glb_customFlexRow justify-content-center glb_paddingBottomSM">
          <div class="spinner-border" style="width: 3rem; height: 3rem;"></div>
        </div>
        <div class="glb_customFlexRow justify-content-center">Loading</div>
      </div>
    </div>
  </div>
</div>
<ng-template #ChangePasswordTemplate>
  <div class="glb_boxedPadding glb_formBGColor glb_Font">
    <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div *ngIf="AllowClose" class="glb_customFlexRow justify-content-end">
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button [disabled]="IsChangingPassword" tabindex="29"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button" (click)="Dialog_Close()">
          <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
        </button>
      </div>
    </div>
    <div class="text-white glb_Font col-sm-12 col-lg-12">
      <p class="glb_TitleMD glb_TitleMDMobile">{{AppName}} Change Password</p>

      <div style="padding-bottom: 2.2em;">
        <p style="padding-bottom: 0.2em;">Please note that your password:</p>
        <li>cannot be same as your previous 10 passwords,</li>
        <li>cannot contain your LoginID, Email, First, Middle or Last Names,</li>
        <li>must be 12 to 50 characters long,</li>
        <li>contain at least one special character,</li>
        <li>contain at least one upper case character,</li>
        <li>at least one numeric digit,</li>
        <li>and contain NO spaces.</li>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left">
        <div class="glb_paddingBottomSM glb_floatParent">
          <input tabindex="22" #CurrentPasswordInput cdkFocusInitial class="glb_loginInputBox glb_skinLightOutline text-white glb_Font"
            [(type)]="InputTypePassword" placeholder="Current password" name="password" [(ngModel)]="CurrentPassword"
            (blur)="PasswordInputs_Verify()">
          <div class="glb_floatTopRight">
            <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)" (mouseup)="PasswordReveal_Toggle(true)"
              class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
              <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
              <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
            </button>
          </div>
        </div>

        <div class="glb_paddingBottomSM glb_floatParent">
          <input tabindex="23" #NewPasswordInput cdkFocusInitial class="glb_loginInputBox glb_skinLightOutline text-white glb_Font"
            [(type)]="InputTypePassword" placeholder="New password" name="password" [(ngModel)]="NewPassword" (blur)="PasswordInputs_Verify()">
          <div class="glb_floatTopRight">
            <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)" (mouseup)="PasswordReveal_Toggle(true)"
              class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
              <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
              <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
            </button>
          </div>
        </div>

        <div class="glb_paddingBottomSM glb_floatParent">
          <input tabindex="24" #ConfirmPasswordInput class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" [(type)]="InputTypePassword"
            placeholder="Confirm password" name="password" [(ngModel)]="ConfirmPassword" (blur)="PasswordInputs_Verify()">
          <div class="glb_floatTopRight">
            <button (mousedown)="PasswordReveal_Toggle(false)" (mouseleave)="PasswordReveal_Toggle(true)" (mouseup)="PasswordReveal_Toggle(true)"
              class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button">
              <fa-icon *ngIf="HidePassword" [icon]="['fal', 'eye-slash']" class="fa-md" style="color:white"></fa-icon>
              <fa-icon *ngIf="!HidePassword" [icon]="['fal', 'eye']" class="fa-md" style="color:white"></fa-icon>
            </button>
          </div>
        </div>

        <div class="glb_paddingBottomSM">
          <input tabindex="25" #TwoFactorCodeInput class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" type="number"
            placeholder="2FA Code" name="twoFactorCode" [(ngModel)]="TwoFactorCode" (keydown.enter)="Password_Change()"
            [ngClass]="{ 'glb_inactiveInputBox': DisableTwoFactorInput}" [disabled]="DisableTwoFactorInput">
        </div>

        <!-- buttons -->
        <div class="glb_customFlexRow row-cols-1 justify-content-left">
          <div *ngIf="!IsChangingPassword" class="d-grid">
            <button tabindex="26" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (keydown.enter)="Password_Change()"
              (click)="Password_Change()">
              <span class="glb_Font glb_smallerText">Change Password</span></button>
          </div>
          <div *ngIf="IsChangingPassword" class="d-grid">
            <button class="glb_standardButton btn glb_BGColorSkin text-white" style="position: relative;">
              <span class="glb_Font glb_hiddenText glb_smallerText">Change Password</span>
              <div class="spinner-border glb_floatingSpinner"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>