<div class="glb_paddingTopLG glb_paddingBottomLG glb_formBGColor text-white glb_Font">
  <div class="glb_paddingOffset col-md-12 col-lg-12">
    <p class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">Account Summary Configurations</p>
  </div>
  <div *ngIf="!ShowSpinner"
    class="glb_forceMarginAuto glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 justify-content-center align-items-center glb_paddingBottomMD">
    <div>
      <input class="glb_formInputBoxDark glb_skinLightOutline glb_Font text-white" cdkFocusInitial tabindex="1" type="name"
        (keyup)='FilterSearchValueKeyUp.next($event)' [(ngModel)]='FilterSearchValue' placeholder="Filter Account Summary Configs">
    </div>
    <div class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">
      <span style="padding-left:0.375em;">
        <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
          (click)="SearchFilter_Clear()">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eraser']" matTooltip="Clear search filter">
          </fa-icon>
        </button>
      </span>
    </div>
  </div>

  <!-- Code to add a chevron with sliding row. this is the CREATE section -->
  <div class="glb_customFlexRow justify-content-end" (click)="globalFunctions.TemplateID_Toggle('CreateAccountSummary', TemplateIdentifiers)">
    <a #CreateAccountSummaryFocus class="glb_chevronToggle justify-content-end glb_customFlexRow col-2 glb_hoverHand">
      <fa-icon [icon]="['fal', 'plus']" [ngClass]="globalFunctions.TemplateID_GetCSS('CreateAccountSummary',true, TemplateIdentifiers)"
        style="color: white;" (click)="CreateAccountSummaryModal_Expand()" matTooltip="Expand">
      </fa-icon>
      <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="globalFunctions.TemplateID_GetCSS('CreateAccountSummary',false,TemplateIdentifiers)"
        style="color: white;" matTooltip="Hide">
      </fa-icon>
    </a>
  </div>

  <div id="CreateAccountSummary" [ngClass]="globalFunctions.TemplateID_GetCSS('CreateAccountSummary', false, TemplateIdentifiers)"
    #CreateAccountSummaryForm style="padding-bottom: 15px;">

    <!-- Sliding content - to create a new summary -->
    <!-- Use headers to define columns -->
    <div class="card card-body-lesspadding card-body">
      <div class="row-cols-2 glb_customFlexRow align-items-center">
        <div class="glb_customFlexRow justify-content-left align-items-left text-dark">
          <mat-form-field class="glb_Font" appearance="outline">
            <input class="glb_Font" type="text" #FocusNewConfigurationName [(ngModel)]="ConfigurationName" matInput
              placeholder="Enter Configuration Name">
          </mat-form-field>
        </div>

        <div class="glb_customFlexRow justify-content-end align-items-center">

          <!-- Save button -->
          <div class="row glb_customFlexRow justify-content-center align-items-center" style="padding-right: 15px;">
            <span style="padding-left:0.375em;">
              <button *ngIf="!IsCreatingAccountSummary"
                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="AccountSummary_Create()">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Insert a New Configuration">
                </fa-icon>
              </button>
            </span>

            <!-- Lets stick a little spinner here, and switch it out when the button is pressed -->
            <span style="padding-left:0.375em;">
              <button *ngIf="IsCreatingAccountSummary"
                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="SearchFilter_Clear()">
                <fa-icon class="fa-fw glb_fixedSizeIcons glb_fastSpin" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
                </fa-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- This is the loading spinner, lets show it while the entity is loading -->
  <div class="row" *ngIf="ShowSpinner">
    <div class="col">
      <div class="glb_customFlexRow justify-content-center">
        <div class="spinner-border" style="width: 3rem; height: 3rem;"></div>
      </div>
      <div class="glb_customFlexRow justify-content-center">Loading...</div>
    </div>
  </div>

  <div *ngIf="!ShowSpinner">
    <div class="itemFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
      <div class="itemBorders" *ngFor="let item of AccountSummaryConfigs | searchfilter " @fadeIn
        style="padding: 0px; padding-top: 15px; padding-bottom: 10px;">
        <a (click)="AccountSummaryConfigDetail_View(item.GUID)" style="cursor: pointer;">
          <div class="glb_customFlexRow col-lg-12">
            <div class="col-12 col-lg-10 text-white align-items-center glb_customFlexRow">
              <div class="itemTitle glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">{{item.Name}}
              </div>
            </div>

            <div class="rightChevron col-0 col-lg-2 glb_customFlexRow justify-content-end align-items-center">
              <!-- Show a chevron, only on lg screens -->
              <div class="d-none d-lg-block">
                <fa-icon [icon]="['fal', 'chevron-right']" size="lg" class="btn align-self-right glb_fixedSizeIcons" style="color:grey;"
                  matTooltip="Click to Launch"></fa-icon>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>