import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NavigationUrls, UsersControllerMethods } from '@app/Global/EnumManager';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { ErrorService } from '@app/Global/Errors/ErrorService';
import { environment } from '@env/environment';
import { TemplateID } from '@app/Global/Models/ClientModels';

@Component({
	selector: 'app-error',
	templateUrl: './Error.html',
	styleUrls: ['./Error.scss']
})

export class ErrorComponent implements OnInit {

	constructor(
		private router: Router,
		private errorService: ErrorService,
		private clientDataStore: ClientDataStore,
		private globalFunctions: GlobalFunctions,
		private apiService: ApiService,
		private notifyService: NotifyService,
	) {
	}

	//This contains the error info. This can be sent this to the server as part of the bug report.
	public data;

	//Store stack trace data serialized into a string
	public stackTraceString = "";

	public showRedirect = false;

	//Store the repro steps here (from the textarea)
	public reproSteps;

	//Are we allowed to bug report (must have access token)
	public allowBugReporting = false;

	//Sending bug report to the server switch
	public sendingBugReport = false;

	//Turn the controls off once the bug report is sent.
	public isBugReportSubmitted = false;

	//Turn the controls off once the bug report is sent.
	public isBugReportCompleted = false;

	//Not sure what this is used for
	//private routeParams;

	//Array to store the identifier GUIDs
	private TemplateIdentifiers: TemplateID[] = [];

	ngOnInit() {
		//this.routeParams = this.activatedRoute.snapshot.queryParams;

		//Get the last error stored on the client
		this.data = this.errorService.getLastError();
		//console.log(this.data);

		//If we have a stack trace, turn it into a string
		if (!this.globalFunctions.isEmpty(this.data.gpsStack)) {
			this.data.gpsStack.forEach(element => {
				//console.log('gpsStack row', element);
				this.stackTraceString = this.stackTraceString.concat(JSON.stringify(element), '\n');
			});
		}
		else if (!this.globalFunctions.isEmpty(this.data.stack)) {
			this.data.stack.forEach(element => {
				//console.log('stack row', element);
				this.stackTraceString = this.stackTraceString.concat(JSON.stringify(element), '\n');
			});
		}

		//console.log('stackTraceString', this.stackTraceString);

		if (this.data.message === "Bad Link") {
			this.showRedirect = true;
		}
		else {
			this.showRedirect = false;
		}

		//Make the search header bar invisible.
		this.clientDataStore.Update_InvisibleSearchBar(true);
		this.clientDataStore.Update_HideSearchBar(true);

		//The login modal should not be launched anymore. this is to allow the user to log in again when they return to the home page.
		this.clientDataStore.LoginModalLaunched = false;

		//We can only bug report if the login data is still intact (Access Token). just check that it still exists
		if (!this.globalFunctions.isEmpty(this.clientDataStore.loginDataDirect.AccessToken)) {
			this.allowBugReporting = true;
		}
	}

	//Go to the home page
	public Home_Go() {

		this.router.navigate([NavigationUrls.Home], { onSameUrlNavigation: 'reload' });

		//Wait a small amount, and then reload the page
		this.globalFunctions.delay(100).then(() => {
			window.location.reload();
		});
	}

	//Go to the home page
	public NewURL_Go() {
		return environment.clientBaseUrl;
	}

	//Create a bug report
	public BugReport_Create() {
		this.sendingBugReport = true;

		//Client side validation passed. now let's construct the request and send it to the server
		const createBugReportRequest = { Title: "", Description: JSON.stringify(this.data), ReproSteps: this.reproSteps };
		const apiRequest = createBugReportRequest;

		this.apiService.APIData_Post(this.apiService.Endpoints.UsersController,
			UsersControllerMethods[UsersControllerMethods.CreateBugReport], apiRequest).subscribe(apiResponse => {
				//console.log("apiResponse", apiResponse);
				if (this.globalFunctions.isEmpty(apiResponse)) {
					this.sendingBugReport = false;
					return;
				}
				else {
					//Get response
					//var createBugResponse = JSON.parse(JSON.stringify(apiResponse));
					//console.log('createBugResponse', createBugResponse);

					this.notifyService.Success_Show("Bug report submitted", "Thank you!");
					this.isBugReportSubmitted = true;
					this.isBugReportCompleted = true;
				}
			});

	}
}