<div class="glb_paddingTopLG glb_paddingBottomLG glb_formBGColor text-white glb_Font">
  <div class="glb_paddingOffset col-md-12 col-lg-12">
    <p class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">Lender Configuration</p>
    <div
      class="glb_forceMarginAuto glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 justify-content-center align-items-center glb_paddingBottomMD">
      <div>
        <!-- this tries to mimic how a mat-form-field-label looks on the autocomplete or control types -->
        <input class="glb_formInputBoxDark glb_skinLightOutline glb_Font text-white" (keyup)='filterSearchValueKeyUp.next($event)'
          [(ngModel)]='filterSearchValue' cdkFocusInitial tabindex="1" type="name" placeholder="Filter Lender Configs">
      </div>
      <div class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">
        <span style="padding-left:0.375em;">
          <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
            (click)="Lender_ClearSearchFilter()">
            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eraser']" matTooltip="Clear search filter">
            </fa-icon>
          </button>
        </span>
      </div>
    </div>

    <!-- sample code to add a chevron with sliding row. this is the CREATE section -->
    <div class="glb_customFlexRow justify-content-end">
      <a (click)="TemplateID_Toggle('LenderConfig_CreateSection')" #LenderConfig_CreateSection
        class="glb_hoverHand justify-content-end glb_customFlexRow col-2">
        <fa-icon [icon]="['fal', 'plus']" class="glb_fixedSizeIcons" [ngClass]="TemplateID_GetCSS('LenderConfig_CreateSection',true)"
          style="color: white;" (click)="LenderConfig_CreateSection_Expand()" matTooltip="Expand">
        </fa-icon>
        <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('LenderConfig_CreateSection',false)" class="glb_fixedSizeIcons"
          style="color: white;" matTooltip="Hide">
        </fa-icon>
      </a>
    </div>

    <div id="LenderConfig_CreateSection" [ngClass]="TemplateID_GetCSS('LenderConfig_CreateSection')" #LenderConfig_CreateForm
      style="padding-bottom: 15px;">
      <!-- sliding content - to create a new record -->
      <div class="card card-body-lesspadding card-body">
        <div class="row-cols-2 glb_customFlexRow align-items-center" style="padding-bottom: 5px;">
          <div class="glb_customFlexRow justify-content-left align-items-left left-padding text-dark">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Lender</div>
            <mat-form-field class="glb_Font" appearance="outline">
              <!-- <mat-label class="glb_Font">Lender</mat-label> -->
              <input #LenderConfig_FocusCreateLenderName (click)="AutoComplete_Client_ApplyFilter(clientInsert_AutoComplete, 'Search',
              insertLenderName.value, 'Lender', true)" (keydown)="AutoComplete_Client_ApplyFilter(clientInsert_AutoComplete, 'Search',
              insertLenderName.value, 'Lender', true)" #insertLenderName type="text" matInput [matAutocomplete]="insertLenderName_autoComplete"
                placeholder="Enter a value to search" [(ngModel)]="createLenderConfiguration_Lender">
              <mat-autocomplete #insertLenderName_autoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, createLenderConfiguration_Lender)" [panelWidth]="500">
                <div *ngFor="let control of AutoComplete_Client_ApplyFilter(clientInsert_AutoComplete, 'Search',
                insertLenderName.value, 'Lender')">
                  <mat-option class="glb_Font" [value]=control>
                    {{control.ControlValue}}
                  </mat-option>
                </div>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <!-- blank is the last column -->
          <div class="glb_customFlexRow justify-content-end align-items-center">
            <!-- save button -->
            <div class="row glb_customFlexRow justify-content-center align-items-center" style="padding-right: 15px;">
              <!-- //put this span inside -->
              <span *ngIf="!isCreatingLenderConfig" style="padding-left:0.375em;">
                <button
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                  (click)="LenderConfig_Create()">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Insert a New Lender Configuration">
                  </fa-icon>
                </button>
              </span>
              <!-- lets stick a little spinner here, and switch it out when the button is pressed -->
              <span *ngIf="isCreatingLenderConfig" style="padding-left:0.375em;">
                <button
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right">
                  <fa-icon class="fa-fw glb_fastSpin glb_fixedSizeIcons" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
                  </fa-icon>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- this is the loading spinner, lets show it while the entity is loading -->
    <div class="row" *ngIf="showSpinner">
      <div class="col">
        <div class="glb_customFlexRow justify-content-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;"></div>
        </div>
        <div class="glb_customFlexRow justify-content-center">Loading...</div>
      </div>
    </div>

    <!-- lender config results are displayed here -->
    <div *ngIf="LenderConfig_IsListEmpty() && !showSpinner">
      <div class="itemFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
        <div class="itemBorders" *ngFor="let item of lenderConfigList | searchfilter | paginate: {id: 'search', itemsPerPage: 9, currentPage:
        currentPage.currentPage}" @fadeIn style="padding: 0px; padding-top: 15px; padding-bottom: 10px;">
          <a (click)="LenderConfig_ViewSpecificLender(item)" style="cursor: pointer;">
            <!-- when ready, put this back in for clicks -->
            <!-- <a (click)="LenderConfig_ViewSpecificLender(item)" style="cursor: pointer;"> -->
            <div class="glb_customFlexRow col-lg-12">
              <div class="col-12 col-lg-10 text-white align-items-center glb_customFlexRow">
                <div class="itemTitle glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">{{item.LenderName}}
                </div>
              </div>

              <div class="rightChevron col-0 col-lg-2 glb_customFlexRow justify-content-end align-items-center">
                <!-- show a chevron, only on lg screens -->
                <div class="d-none d-lg-block">
                  <fa-icon [icon]="['fal', 'chevron-right']" size="lg" class="btn align-self-right glb_fixedSizeIcons" style="color:grey;"
                    matTooltip="Click to Launch"></fa-icon>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="glb_customFlexRow">
          <div class="col">
            <div class="glb_Font glb_customFlexRow justify-content-center">
              <pagination-controls id='search' class="glb_paginatorDark" (pageChange)="Paginator_OnPageChange($event, currentPage)"
                [maxSize]="paging.maxSize" [responsive]="true" [previousLabel]="paging.previousLabel" [nextLabel]="paging.nextLabel">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>