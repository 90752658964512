import { ControlData } from "./EntityModels";

//This is the class definition for the templates being used to expand/hide chevron
export class TemplateID {
    TemplateIdentifierGUID: string;
    IsEnabled: boolean;
}

//To store the autocomplete data
export class AutoCompleteData {
    AutoCompleteLastSearchedValue: string;
    AutoCompleteControlTypeName: string;
    AutoCompleteControlData: ControlData[]
}

//To store the header details. (Used in Workflow Delegator)
export class Header {
    HeaderName: string;
    HeaderSortDirection: number;
}

export class CreateEmailData {
    FromEmailDisplay = "xChange Service";
    EmailTo = "";
    EmailToPlaceholder = "Enter Recipients separated by semicolon (;)";
    ReplyTo = "";
    ReplyToPlaceholder = "Enter Reply To Email Address";
    CCTo = "";
    CCToPlaceholder = "Enter CC Email Addresses";
    ShowCC = true;
    BCCTo = "";
    BCCToPlaceholder = "Enter BCC Email Addresses";
    ShowBCC = false;
    Subject = "";
    SubjectToPlaceholder = "Enter Subject";
    Body = "";
    BodyPlaceholder = "Enter Email Body";
}


//Used by the input forms (e.g. Condition subsequent finalize) model, Input DataUnits have properties that help style and set limits.
export class InputDataUnit {
    GUID = this.NewGUID_Generate();
    //This is the HTML input id
    Name = "";
    DisplayName = "";
    Placeholder = "";
    LabelDisplay = "";
    LabelUnchecked = "";
    LabelChecked = "";
    Question = "";
    Value = "";
    IsResizable = false;
    CSSHeight = "";
    ClearConfirm = false;
    //This is required for primeng datetime picker. It requires the javascript date
    ValueDate: any;
    HTMLInputType = "";
    ValueDisplay: string;
    ControlGUID: string;
    ControlDisplay: string;
    ControlValue: string;
    Order: number;
    AutoCompleteLastSearchedValue: string;
    AutoCompleteControlData = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    Disabled = false;
    IsChecked = false;
    MinDate: Date;
    MinLength: number;
    MaxLength: number;
    MinValue: number;
    MaxValue: number;
    FileName = "";
    ParentGUID = "";
    Type = "";
    DataFieldGUID = "";
    DirectOwnerGUID = "";
    //Setting default to Account Details as it will be true for most of the cases (as of CS task for now)
    DirectOwnerEntityTypeGUID = "8C37B2B3-B999-4F4D-AF9F-DEFEEA2D90BD";
    PrimaryOwnerGUID = "";
    //Setting default to Account Details as it will be true for most of the cases (as of CS task for now)
    PrimaryOwnerEntityTypeGUID = "8C37B2B3-B999-4F4D-AF9F-DEFEEA2D90BD";
    ValidationMessage = "";

    //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
    public constructor(init?: Partial<InputDataUnit>) {
        Object.assign(this, init);
    }

    public NewGUID_Generate(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

//Used for displaying the summary group data (currently being used in Account Summary screen)
export class SummaryGroup {
    GUID = "";
    //This is the HTML input id
    Name = "";
    DisplayOrder;
    CSSClass = "";

    //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
    public constructor(init?: Partial<SummaryGroup>
    ) {
        Object.assign(this, init);
    }
}


//Used for displaying the summary detail data (currently being used in Account Summary screen)
export class SummaryDetail {
    GUID = "";
    SummaryGroupGUID = "";
    Entity = "";
    DisplayName = "";
    Value = "";
    DisplayOrder;
    NavTabName = "";
    CSSClass = "";

    //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
    public constructor(init?: Partial<SummaryDetail>) {
        Object.assign(this, init);
    }
}

//Account fee data class
export class AccountFeeData {
    AccountGUID: string;
    AccountID: number;
    Fees: number;
    //Disable eslint warning on naming convention
    //eslint-disable-next-line @typescript-eslint/naming-convention
    Fees_FMT: string;
    IsIncluded: boolean;
    TransactionType: string;
    TransactionTypeGUID: string;
    TransactionTypeID: number;
    Notes: string;
    FeeGUID: string;

    //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
    public constructor(init?: Partial<AccountFeeData>) {
        Object.assign(this, init);
    }
}

//Side bar button model (used for the Loan Index page)
export class SideBarButton {
    ID = "";
    Icon = "";
    ShowText = true;
    DisplayText = "";
    Tooltip = "";
    //Is this visible via side bar
    IsSideBarVisible = true
    //Use this to delegate the click function that you want to run
    ClickAction;
    //Use this to indicate if the button is active, or should show a spinner instead. Its a method with default that returns false
    Spinner_Show = (() => this.Get_False());

    //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
    public constructor(init?: Partial<SideBarButton>) {
        Object.assign(this, init);
    }

    //A method that returns false, used for delegating a response where, in some cases, a function must be returned. e.g. for checking the Spinner State on the Sidebar buttons, usually we don't need a function to check, so just dummy back something that returns false. Used as the default for the ShowSpinner property above.
    public Get_False() {
        return false;
    }
}

//Task Notifier namespace
export namespace TaskNotifierModels {

    export class Header {
        DisplayName = "";
        Name = "";
        InputType = "";
        Tooltip = "";
        TooltipDisabled = true;

        //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
        public constructor(init?: Partial<Header>) {
            Object.assign(this, init);
        }
    }

    export class Data {
        GUID = "";
        TaskType = "";
        TaskType_Multiselect = [];
        Role_Multiselect = [];
        Role = "";
        FromDisplayName = "";
        ReplyTo = "";
        Recipients = "";
        SubjectPrefix = "";
        BodyTemplate = "";
        Enabled = true;
        TooltipText = "";

        //Client side/display related properties
        EditFlag = false;

        //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
        public constructor(init?: Partial<Data>) {
            Object.assign(this, init);
        }
    }

    export class MultiSelectData {

        Name = "";
        Direction = "";
        Values = "";

        //This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
        public constructor(init?: Partial<MultiSelectData>) {
            Object.assign(this, init);
        }
    }

}


