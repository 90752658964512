import { Component, OnInit } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { StaticDataControllerMethods, UsersControllerMethods } from '@app/Global/EnumManager';
import { NotifyService } from '@app/Services/NotifyService';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'LoanEntityModify',
  templateUrl: './LoanEntityModify.html',
  styleUrls: ['./LoanEntityModify.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class LoanEntityModify {

  constructor(public globalFunctions: GlobalFunctions, private apiService: ApiService, private notifyService: NotifyService, private dialogRef: MatDialogRef<LoanEntityModify>) { }

  //This is the dataRow, which contains data units that the loanIndex prepared for us to show in this template
  public DataRowModal: any = { EntityName: "TBD", DataUnits: null };
  public CustomEntityTitleName: string;

  //A ref to the current modal screen. this is used for passing update request data down
  public LoanIndex: LoanIndex;

  //To show a checkbox to override the task due date
  public ShowOverrideDueDateCheckBox = false;
  public OverrideDueDateCheckBoxState = false;

  //Default the save button text to "Save"
  public SaveButtonText = "Save";

  //Disable the Account Enquiry save button
  public EnableSubmitButton = true;

  //DateTime picker properties
  public DTPMaxDate = new Date(2050, 1, 1);
  public DTPDateFormat = "dd/MM/yy";

  //Unique GUID to track the last autocomplete request
  private LastAutoCompleteRequestGUID: string;

  //This is a custom confirm message passed from the side bar actions
  public CustomConfirmMessage: string;

  //Unique Modal Identifer
  public ModalIdentifier;

  //To show or hide the minimize button
  public ShowMinimizedButton = false;

  //To filter association type
  public AutoCompleteLinkType = "";

  //Hide the datetime picker panel
  public DTP_Close(calendar): void {
    calendar.hideOverlay();
  }

  //Get the top entity title name for this modal, checking whether the default view model name is to be used, or a custom supplied one
  public EntityTitleName_Get(): string {
    if (!this.globalFunctions.isEmpty(this.CustomEntityTitleName)) {
      return this.CustomEntityTitleName;
    }
    else {
      return this.DataRowModal.EntityName;
    }
  }

  //This clears any currently selected dropdown control value for the dataUnit
  public Control_ClearSelected(dataUnit): void {
    dataUnit.SelectedControl = ""
  }

  //Clears data from a single modal edit input area, for the text area style datatype (e.g. string). also used for the date picker
  public TextArea_ClearData(dataUnit): void {
    dataUnit.Value = "";
    dataUnit.ValueDate = null;
  }

  //This clears any currently selected autocomplete control value on the Open Modal screen
  public AutoComplete_ClearSelected(dataUnit, inputVal): void {
    dataUnit.AutoCompleteControlGUID = "";
    dataUnit.AutoCompleteControlDisplayValue = { value: "" };
    inputVal.value = "";

    //Check if it is the Account enquiry screen and Task Type is being cleared
    if (this.CustomEntityTitleName === "Account Enquiry" && dataUnit.Name === "Task Type") {

      //Let's disable the save button
      this.EnableSubmitButton = false;
    }
  }

  //In relation to autocomplete - read the response, return the ControlValue out of it. this allows autocomplete to show the 'pretty' value in the input box
  public AutoComplete_GetPrettyName(value): string {
    if (value.ControlValue != null) {
      return value.ControlValue;
    }
    else {
      return "";
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server
  public AutoComplete_SaveSelected(dataUnit, value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      dataUnit.AutoCompleteControlGUID = value.value.ControlGUID;
    }

    //Call this to show/hide subsequent control fields based on the value
    this.ControlValue_Change(dataUnit);
  }

  //Call this method on control value change for Task Type in Account Enquiry screen
  public ControlValue_Change(dataUnit) {

    //Check if it is the Account enquiry screen
    if (this.CustomEntityTitleName === "Account Enquiry" && dataUnit.Name === "Task Type") {

      //Look up dataUnit for Start Date
      const startDateDataUnit = this.DataRowModal.DataUnits.filter(x => x.Name === "Start Date")[0];

      //Null check
      if (!this.globalFunctions.isEmpty(startDateDataUnit)) {

        //Display Start Date if CS Task
        if (dataUnit.AutoCompleteControlGUID === this.LoanIndex.ConditionSubsequentControlGUID) {
          startDateDataUnit.ClientDisplay = true;
        }
        //Don't display Start Date if not CS Task
        else {
          startDateDataUnit.ClientDisplay = false;
        }
      }

      //Construct a request body that we can post to the server to get the task due date
      const apiRequest = { AccountID: this.LoanIndex.AccountID, TaskTypeGUID: dataUnit.AutoCompleteControlGUID };

      //Call the server, if we have some value to request
      this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetTaskDueDate], apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {

            //Deserialize the api response
            const response = JSON.parse(JSON.stringify(apiResponse));

            //Convert the incoming server ISO date to moment date
            const DTPMomentDate = moment(response.TaskDueDate, "YYYY-MM-DDThh:mm:ss");

            //Look up Task Due data data unit from the DataRowModal
            const dueDateDU = this.DataRowModal.DataUnits.filter(x => x.Name === "Due Date")[0];

            //Set the Task Due date dataunit value to javascript date
            if (dueDateDU != null) {

              //Convert the moment date to javascript date for the primeng timepicker and set the ValueDate property in DataUnit
              dueDateDU.ValueDate = new Date(Number(DTPMomentDate.format("YYYY")), Number(DTPMomentDate.format("MM")) - 1, Number(DTPMomentDate.format("DD")), Number(DTPMomentDate.format("HH")), Number(DTPMomentDate.format("mm")), Number(DTPMomentDate.format("ss")));

              //Enable Save button
              this.EnableSubmitButton = true;
            }
          }
        });
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_ApplyFilter(dataUnit, requestType: string, controlValue: string, seed = false) {
    //console.log('starting autcomplete for controlValue: ', controlValue);
    //console.log('dataUnit.AutoCompleteLastSearchedValue', dataUnit.AutoCompleteLastSearchedValue);

    //Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    // if (controlValue === null) {
    //   return;
    // }

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Role_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (this.globalFunctions.isEmpty(controlValue) && seed && dataUnit.AutoCompleteLastSearchedValue !== "{seed}") {

      //Set the last value to something different so that we allow the seed request to proceed
      dataUnit.AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(dataUnit.AutoCompleteLastSearchedValue)) {
      if ((dataUnit.AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase()) || requestType === "ControlGUID") {

        //Request new data and update the current value so we don't request it again.
        dataUnit.AutoCompleteLastSearchedValue = controlValue;
        //console.log("ReturnAutoCompleteData is requesting data");

        //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
        //Set the class variable to this value
        this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();

        //Make a copy of it for sending to the update request
        const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

        this.AutoComplete_RequestData(requestType, dataUnit.Name, controlValue, dataUnit.AutoCompleteControlData, copyOfGUID);
      }
    }
    else {

      //Not sure if this is used anymore? TOREVIEW
      if (requestType === "ControlGUID") {

        //Request new data and update the current value so we don't request it again.
        dataUnit.AutoCompleteLastSearchedValue = controlValue;
        //console.log("ReturnAutoCompleteData is requesting data");

        //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
        //Set the class variable to this value
        this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();

        //Make a copy of it for sending to the update request
        const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

        this.AutoComplete_RequestData(requestType, dataUnit.Name, controlValue, dataUnit.AutoCompleteControlData, copyOfGUID);
      }
    }

    //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
    if (dataUnit.AutoCompleteControlData != null) {
      return dataUnit.AutoCompleteControlData;
    }
  }

  //Switch the data unit value when a checkbox is clicked
  public CheckBox_Click(dataUnit, event) {
    dataUnit.Value = event.checked;
  }

  //Toggle the override due date timepicker based on the checkbox state
  public OverrideDueDateCheckBox_Toggle(): void {

    //Toggle the checkbox state
    this.OverrideDueDateCheckBoxState = !this.OverrideDueDateCheckBoxState;

    //Look up Task Due data data unit from the DataRowModal
    const overrideDueDateDU = this.DataRowModal.DataUnits.filter(x => x.Name === "Override Due Date")[0];
    if (this.OverrideDueDateCheckBoxState === true) {
      if (overrideDueDateDU != null) {

        //Show the Override Due date time picker
        overrideDueDateDU.HideCreate = false;
        overrideDueDateDU.isHidden = false;
      }
    }
    else {
      if (overrideDueDateDU != null) {

        //Hide the Override Due date time picker
        overrideDueDateDU.HideCreate = true;
        overrideDueDateDU.isHidden = true;
        overrideDueDateDU.ValueDate = null;
        overrideDueDateDU.Value = null;
      }
    }
  }

  //Switch the data unit value when a datepicker is changed
  public DatePicker_Changed(dataUnit, event) {

    //Check if the input date is valid
    if (Date.parse(event.target.value)) {
      dataUnit.Value = event.target.value;
    }
    else {

      //If not, blank out the input
      dataUnit.Value = "";

      //Event target value doesn't get cleared when it fails the validation
      event.target.value = "";
      this.notifyService.Warning_Show("The input date is invalid", "Invalid Date");
    }
  }

  //Used to validate key presses on editing textareas, and also save the value into the dataunit for sending to the server for the request later
  public TextArea_OnKeyPress(event, eventType: string, dataUnit) {

    //console.log('key pressed, input value: ', event.target.value);
    //Where was this character inserted? at event.target.selectionStart
    const characterIndexInserted = event.target.selectionStart;

    //Check the data type, and perform the necessary removal of invalid characters using regex. except string, that has no regex
    if (dataUnit.Type.includes('string') === false) {
      let regExType = null;
      if (dataUnit.Type == 'integer') {
        regExType = this.globalFunctions.regExInteger;
      }
      else if (dataUnit.Type == 'percent') {
        regExType = this.globalFunctions.regExPercent;
      }
      else if (dataUnit.Type.includes('decimal.2') === true) {

        //Decimal can have differing precision. pick the right one
        regExType = this.globalFunctions.regExDecimal2;
      }
      else if (dataUnit.Type.includes('decimal.4') === true) {
        regExType = this.globalFunctions.regExDecimal4;
      }
      else if (dataUnit.Type == 'currency') {

        //Updating the format in place is a lot of work. requires us to store the original unformatted string, and a pretty version.
        //Code that i have done before in xChange mobile. will come back to it if desired. for now lets just use in place, with regex (no formatting applied)
        regExType = this.globalFunctions.regExCurrency;

        //Formatting? i think we may show a separate formatted display to the right? TO DECIDE later
      }
      else if (dataUnit.Type.includes('currency.8') === true) {

        //Higher precision currency
        regExType = this.globalFunctions.regExCurrency8;
      }

      //Now perform the regex test
      if (regExType.test(event.target.value) === false) {

        //Didn't pass regex, remove the last character
        this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

        //Let's parse it into a pretty text that we can display to the user
        let prettyType;
        prettyType = JSON.parse(JSON.stringify(dataUnit.Type));
        let precision = '';
        let prettyTypeDescription = '';

        if (prettyType.includes('.') === true) {
          const splitted = prettyType.split('.');
          prettyType = splitted[0];
          precision = splitted[1];
          prettyTypeDescription = prettyType + " with a maximum of " + precision + " decimal places";
        }
        else {
          prettyTypeDescription = prettyType;
        }

        //What if it was pasted in? and has more issues than just the last entered character? regex it once more, if it doesn't pass, remove the entire thing
        if (regExType.test(event.target.value) === false) {
          event.target.value = "";
        }

        //Now give the user this error message
        this.notifyService.Error_Show("An invalid value was entered. Please review and try again", "Not a valid " + prettyTypeDescription)
      }
      else {
        //We passed regex. now lets do max and min processing for numeric values

        //Check if its too big
        if (parseFloat(event.target.value) > parseFloat(dataUnit.MaxLength)) {

          //Don't update it, its too large. now give the user this error message
          this.notifyService.Error_Show("This value is larger than the maximum of " + dataUnit.MaxLength + ". Please make it smaller and try again", "Invalid value")

          //Didn't pass, remove the last character
          this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

          //If its still too large, put the last value in there.
          if (parseFloat(event.target.value) > parseFloat(dataUnit.MaxLength)) {
            event.target.value = dataUnit.Value;
          }
        }

        //Check if its too small
        else if (parseFloat(event.target.value) < parseFloat(dataUnit.MinLength)) {

          //Don't update it, its too small. now give the user this error message
          this.notifyService.Error_Show("This value is smaller than the minimum of " + dataUnit.MinLength + ". Please make it larger and try again", "Invalid value")

          //Didn't pass, remove the last character
          this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

          //If its still too small, put the last value in there.
          if (parseFloat(event.target.value) < parseFloat(dataUnit.MinLength)) {
            event.target.value = dataUnit.Value;
          }
        }
        else {

          //Number has passed min and max validation. lets put this value into the dataunit value, so that it can be saved later
          dataUnit.Value = event.target.value;
        }
      }
    }
    else {

      //console.log('event.target.value.length', event.target.value.length);
      //console.log('dataUnit.MaxLength', dataUnit.MaxLength);

      //String has no regex, but we stil need to check max. don't worry about min.
      if (event.target.value.length > dataUnit.MaxLength) {

        //Its too long. now give the user this error message
        this.notifyService.Error_Show("This value is longer than the maximum of " + dataUnit.MaxLength + ". Please make it shorter and try again", "Invalid length")

        //Didn't pass, remove the last character
        this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

        //If its still too long, just truncate it (deal with copy and paste)
        if (event.target.value.length > dataUnit.MaxLength) {
          event.target.value = event.target.value.substr(0, dataUnit.MaxLength);
        }

        //And also set the dataunit value to this value. if you don't do this, there is a chance that the user clicks save very quickly, and a blank value is saved on the server. we don't want that
        dataUnit.Value = event.target.value;
      }

      //Dont really need to do min length processing for strings.
      else {

        //Length validation is fine, update the dataUnits value  
        dataUnit.Value = event.target.value;
      }

    }
  }

  //Gets the isUpdatingEntityRequest property on loanIndex, and handles nulls
  public GetLoanIndex_IsUpdatingEntityRequest() {
    if (this.globalFunctions.isEmpty(this.LoanIndex)) {
      return false;
    }
    else {
      return this.LoanIndex.isUpdatingEntityRequest;
    }
  }

  //Disable submit button
  public CSS_Disable(): string {

    if (!this.EnableSubmitButton) {
      return "glb_clearButton";
    }
    return "";
  }

  //Removes a character from the event target string, and resets the location of the selection index
  private LastCharacter_ResetAndRemove(target, characterIndexInserted) {

    //Didn't pass, remove the last character
    target.value = this.globalFunctions.removeStringByIndex(target.value, characterIndexInserted);

    //Try to set the cursor back to where it was
    target.selectionStart = characterIndexInserted - 1;
    target.selectionEnd = characterIndexInserted - 1;
  }

  //Processes the autocomplete data returned by the server and pushes it into the array
  private AutoComplete_ProcessResponse(apiResponse: any[], controlArray): void {
    if (!this.globalFunctions.isEmpty(apiResponse)) {

      //Reset the array, then chuck the data in
      controlArray.length = 0;

      const results = apiResponse;
      for (const key in results) {
        const ControlDataUnit = {
          Entity: results[key].Entity,
          ControlType: results[key].ControlType,
          ControlGUID: results[key].ControlGUID,
          ControlValue: this.globalFunctions.HTMLUnescape(results[key].ControlValue),
        };

        //Push the result in to the control array by ref
        controlArray.push(ControlDataUnit);
      }
    }
    else {

      //No results! empty out the array			
      controlArray.length = 0;
    }
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, autoCompleteRequestGUID: string): void {

    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = { RequestType: requestType, ControlType: controlType, SearchValue: requestValue, AccountID: this.LoanIndex.AccountID, LinkType: this.AutoCompleteLinkType };

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete], apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {

            //Check if we are the latest request, only process the response if so.
            if (autoCompleteRequestGUID === this.LastAutoCompleteRequestGUID) {
              this.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }

}