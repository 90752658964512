//entity related classes, middleware to display content into the client.

//responses from the API
import { RequestTypes } from '../EnumManager'

//DataRow is the fundamental element of an entity - it represents a DATABASE ROW, with a GUID identifier.
export class DataRow {
    AccountID: string;
    Entity: string;
    GUID: string;
    DataUnits: DataUnit[] = [];
    OverlayDataUnits: DataUnit[] = [];
    ShowOverlayDataUnits = false;
    ChildEntity: DataUnit[] = [];
    //lets have a property to help us filter/hide stuff. defaults to false
    isHidden = false;
    //row based styles
    RowStyle = "false";
    //entity name used for buttons
    EntityName = "";
    //entity friendly name as per enum
    EntityFriendlyName = "";
    //used for certain custom control data type signalling (e.g. Internal Department for contact types)
    ControlDataType = "";
    //used when sending updates back to the server
    RequestType: RequestTypes;
    DeleteLock: boolean;
    Parent1GUID: string
    ShowFilePicker: boolean
    //ChildNavTabs
    ChildNavTabs: any;
    IsParentAccount: boolean;

    constructor(AccountID: string, entity: string, GUID: string, RowStyle: string = null, EntityName: string = null, deleteLock: boolean, entityFriendlyName: string = null, controlDataType: string = null, childNavTabs: any = null, isParentAccount = false) {
        this.AccountID = AccountID;
        this.Entity = entity;
        this.GUID = GUID;
        this.DataUnits = [];
        this.ChildEntity = [];
        this.RowStyle = RowStyle;
        this.EntityName = EntityName;
        this.DeleteLock = deleteLock;
        this.EntityFriendlyName = entityFriendlyName;
        this.ControlDataType = controlDataType;
        this.ChildNavTabs = childNavTabs;
        this.IsParentAccount = isParentAccount;
    }
}

//used by the entity model, DataUnits are the COLUMNS inside the database.
export class DataUnit {
    Name: string;
    Value: string;
    //This is required for primeng datetime picker. It requires the javascript date
    ValueDate: any;
    Type: string;
    ValueDisplay: string;
    ControlGUID: string;
    ControlDisplay: string;
    ControlValue: string;
    Order: number;
    Index: number;
    Link: any;
    ChildEntities: DataRow[];
    HasReadAccess = true;
    HasWriteAccess = true;
    DisplaySection: string;
    DisplaySectionAlignment: string;
    EditBoxSize: string;
    EditLock: boolean;
    ShowLabel: boolean;
    HideDisplay: boolean;
    HideEdit: boolean;
    HideCreate: boolean;
    CSSClass: string;
    //A custom filter allows us to filter dropdown selection, e.g. for Account Enquiry
    CustomFilter = '';
    AutoCompleteLastSearchedValue: string;
    AutoCompleteControlData: any[];
    Entity: string;
    GUID: string;
    ChildEntity: DataUnit[];
    Disabled = false;
    MinDate: Date;
    //ClientDisplay represents a property that is used for display/removal when UI changes are made, e.g.Task Type changes trigger Start Date to be hidden/removed
    ClientDisplay = true;
}

//Used by the entity model, entity describes a entity and contains a collection of Entity Rows
export interface Entity {
    AccountID: string
    EntityGUID: string
    RequestType: RequestTypes
    ResponseMessage: string
    //this also now has a list of data units!
    DataUnits: DataUnit[];
    //when a child entity is updated, we need to be able to tell the client how to find its parent.        
    //any other entities that need to be inserted
    Entities: Entity[];
    EntityType: string
    EntityName: string
    PushLocation: string
    EntityIdentifier: { EntityIdentifier: DataUnit[] };
    EntityRows: [EntityRow];
    //when a child entity is updated, we need to be able to tell the client how to find its parent.
    ParentEntityName: string;
    //the GUID of the parent entity row is also needed
    ParentEntityGUID: string;
    //second level entity, if needed
    ParentEntityName2: string;
    ParentEntityGUID2: string;
}

//to identify the entity row (its GUID, and any presentation styles, e.g. bold or ghosting)
export interface EntityIdentifier {
    GUID: string;
    EntityName: string;
    RowStyle: string;
    DeleteLock: boolean;
    EntityFriendlyName: string;
    ControlDataType: string;
    IsParentRecord: boolean;
}

//the actual row of data, contains all the columns (data units)
export interface EntityRow {
    EntityIdentifier: EntityIdentifier;
    DataUnits: DataUnit[];
    OverlayDataUnits: DataUnit[];
    ChildNavTabs: any;
}

//Control Data class
export class ControlData {
    ControlType: string;
    ControlGUID: string;
    ControlValue: string;
    ControlDisplay: string;
    Entity: string;
}

//Entity Types that we can display
export class EntityTypesDisplay {
    Name: string;
    NavDisplayName: string;
}
