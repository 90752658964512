<nav id="PG_LoanNavBar" class="navbar glb_customFlexRow navCustom navbar-dark">

	<div *ngIf="ShowNavBarButton" class="glb_boxedPaddingNarrow">
		<button class="navbar-toggler" type="button" (click)="NavBarVisibilty_Toggle()">
			<span class="navbar-toggler-icon"></span>
		</button>
	</div>

	<div *ngIf="ShowNavBar" id="NavBarContent" class="glb_Font col-12">
		<div class="nav nav-tabs glb_customFlexRow row-cols-2 row-cols-sm-4 row-cols-md-6 row-cols-lg-9 col-lg-12" style="padding-right:0px;">

			<button *ngFor="let entity of EntityTypesDisplay; index as itemIndex"
				class="glb_customFlexRow align-self-stretch navItemBorder nav-item nav-link" id="nav-{{entity.Name}}-tab"
				[ngClass]="CurrentNavBarItem_Get(entity)" (click)="NavBarItem_Clicked($event)">

				<!-- Text -->
				<div class="glb_customFlexRow justify-content-center align-self-center" id="nav-{{entity.Name}}-tab" style="width:100%;">
					<div class="glb_TitleSmall glb_TitleSmallMobile" id="nav-{{entity.Name}}-tab">
						{{entity.NavDisplayName}}
					</div>
				</div>

			</button>
		</div>
	</div>

</nav>