import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { groupBy, sumBy, map } from 'lodash-es';
import moment from 'moment';
import { Dashboard } from '@app/Components/Dashboard/Dashboard';
import { TemplateID } from '@app/Global/Models/ClientModels';

@Component({
  selector: 'AccountManager',
  templateUrl: './AccountManager.html',
  styleUrls: ['./AccountManager.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})
export class AccountManager implements OnInit {
  //the delegator dashboard data from the server (aggregated)
  public delegatorDashboardDataAggregated;
  //store how many columns it has, dynamic as the number of status can change, with new ones added or removed.
  public aggregateDataColumnCount = 0;

  //store the detail data here (first level detail, contains Task Guids, Lender name, Due date and status). this is an dictionary and will contain entries for all elements from the aggregate in delegatorDashboardDataAggregated
  public delegatorDashboardDataDetailDict: { [key: string]: any };

  //local spinner
  public showSpinner = false;
  //reassignTask Spinner
  public showReassignTaskButtonSpinner = false;
  //used to separate keys when combined into a single identifier. looks like we don't need this anymore. just going to leave it blank.
  public keySeparator = "_";
  //used for the button (icon) spinner on the refresh button at the top right (the RefreshDashboardDataAggregated method)
  public refreshingAggregatedSpinner = true;
  //to store the last clicked task item
  public lastClickedTaskItem;
  //store a list of clicked items that are used to send the request to the server. dont really need a list here as we only do single selection at a time here, compared to the shift click support needed by the workflow delegator
  public selectedTasks = [];
  //used to track if we should show the context action bar
  public showContextBar = false;
  //should we show the Edit Task button (only should be allowed when a single task is highlighted)
  public showTaskEditButton = false;

  //the distinct set of Lenders in the aggregate view, using lodash to group by and get sums
  public lodashGroupedLenderArray = [];

  //store whether or not the aggregate returned any data to us
  public noData = false;

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  //Variables for showing the QC Complete and Active Tasks
  public ShowQCCompleteOnly = false;
  public ShowActiveOnly = false;
  public StatusRequest = "";
  private static QCCompleteText = "QC Complete";
  private static ActiveText = "Active";

  //copies of other parent, in case we want to trigger updates on them later
  @Input() dashboard: Dashboard;

  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.RefreshDashboardDataAggregated();
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //formats name and task count nicely for display
  public GetNameAndTaskCount(item) {
    return item.Lender + " (" + item.TaskCount + " tasks)";
  }

  //Toggle the checkbox status and sync the display as per the status request
  public Status_Toggle(): void {

    //Set default to empty, fetch all
    this.StatusRequest = "";

    //Set the status request text if show QCComplete checkbox is clicked
    if (this.ShowQCCompleteOnly === true) {
      this.StatusRequest += AccountManager.QCCompleteText + ",";
    }

    //Set the status request text if show Active checkbox is clicked
    if (this.ShowActiveOnly === true) {
      this.StatusRequest += AccountManager.ActiveText + ",";
    }

    //Strip out the last comma delimiter
    this.StatusRequest = this.StatusRequest.substring(0, this.StatusRequest.length - 1);

    //Refresh the aggregate data
    this.RefreshDashboardDataAggregated();
  }

  //Refreshes the aggregated data. reset any clicked items and the context bar
  public RefreshDashboardDataAggregated() {

    this.refreshingAggregatedSpinner = true;
    //clear existing arrays
    this.delegatorDashboardDataAggregated = [];
    //clear the lender array too
    this.lodashGroupedLenderArray.length = 0;

    //grab aggregate data
    this.GetDashboardDataAggregated(this.delegatorDashboardDataAggregated);

    //init a new dictionary to store server data (detail). but will be populated later, when the user clicks on the chevrons
    this.delegatorDashboardDataDetailDict = {};
    this.ClearUIArrays();
  }

  //gets the relevant aggregated data from the server
  public GetDashboardDataAggregated(fillThis, assignedToGUID = "") {
    this.showSpinner = true;
    //TaskAssignedToGUID will ensure that the column is used as the primary key instead of in the results.
    const apiRequest = { ReturnType: "Aggregate", AssignedToGUID: assignedToGUID, Status: this.StatusRequest, KeyColumn: "RowPrimaryKey" };
    //let apiRequest = { ReturnType: returnType };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetAccountManagerDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.showSpinner = false;
          return;
        }
        else {
          //deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('response', response);
          //reset the dictionary
          fillThis.length = 0;

          let columnCounterIndex = 0;
          for (const key in response) {

            //console.log('response[key]', response[key]);
            // Lender: "First Federal Home Loans Pty Ltd"
            // TaskCount: "1"
            // TaskCountNumber: 1
            // TaskType: "Settlement"
            // TaskTypePretty: "Settlement (1 tasks)"

            //need to unescape some columns
            if (!this.globalFunctions.isEmpty(response[key])) {
              if (!this.globalFunctions.isEmpty(response[key]['Lender'])) {
                response[key]['Lender'] = this.globalFunctions.HTMLUnescape(response[key]['Lender']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['TaskType'])) {
                response[key]['TaskType'] = this.globalFunctions.HTMLUnescape(response[key]['TaskType']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['TaskTypePretty'])) {
                response[key]['TaskTypePretty'] = this.globalFunctions.HTMLUnescape(response[key]['TaskTypePretty']);
              }
            }

            //and fill it
            fillThis[key] = response[key];

            //see if we need to calc the column count (just do it once)
            if (columnCounterIndex === 0) {
              //get the first item in the array, work out the length. this is a performance optimization, instead of letting the template calculate this 
              //console.log('fillThis[key]', fillThis[key]);
              //console.log('fillThis[key] length', Object.keys(fillThis[key]).length);
              this.aggregateDataColumnCount = Object.keys(fillThis[key]).length;
              //increment the columnCounterIndex so we don't do this again.
              columnCounterIndex++;
            }
          }

          //store the response in an array so that we can perform operations on it.
          const responseArray = [];
          //loop through each response object and push it into an array.
          Object.entries(fillThis).forEach(
            ([, value]) => {
              //console.log('TaskCount', value['TaskCount']);
              //console.log('Lender', value['Lender']);
              //convert TaskCount to a number so that we can sum it later using lodash
              value['TaskCountNumber'] = Number(value['TaskCount'])
              //now push this into responseArray so that lodash can sum it properly later
              responseArray.push(value);
            });

          //Using lodash to group the values by lender
          this.lodashGroupedLenderArray = map(groupBy(responseArray, 'Lender'), (v, k) => ({
            Lender: k,
            TaskCount: sumBy(v, 'TaskCountNumber')
          }));

          //Turn the spinners off
          this.showSpinner = false;
          this.refreshingAggregatedSpinner = false;

          //Check if there is any data
          if (this.lodashGroupedLenderArray.length === 0) {
            this.noData = true;
          }
          else {
            this.noData = false;
          }
          return;
        }
      });
  }

  //gets the relevant detailed data from the server
  public GetDashboardDetailData(fillThis, lenderKeyRaw: string, taskTypeKeyRaw = "", lenderKey: string, taskTypeKey = "") {
    //in this call, we know that the primary key (KeyColumn) for the request is TaskGUID. We want this to be placed into the root of each returned entry in the dictionary. Easier for the html template to find the key and use it for future requests.
    //testing
    //console.log('GetDelegatorDashboardDetailData lenderKey', lenderKey);
    //console.log('GetDelegatorDashboardDetailData taskTypeKey', taskTypeKey);

    const apiRequest = { ReturnType: "Detail", LenderGUID: lenderKeyRaw, TaskTypeGUID: taskTypeKeyRaw, KeyColumn: "TaskGUID", Status: this.StatusRequest };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetAccountManagerDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //turn spinner off
          this.delegatorDashboardDataDetailDict[lenderKey + this.keySeparator + taskTypeKey].Spinner = false;
          return;
        }
        else {
          //deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('response:', response);
          //reset the dictionary
          fillThis.length = 0;

          //Now loop through and fill all properties
          for (const key in response) {
            //  console.log('response[key]', response[key]);
            //  Due Date: "23/09/2021"
            //  LoanID: "505048"
            //  Task Status: "Quality Checking"

            //Unescape columns
            if (!this.globalFunctions.isEmpty(response[key])) {
              if (!this.globalFunctions.isEmpty(response[key]['Task Status'])) {
                response[key]['Task Status'] = this.globalFunctions.HTMLUnescape(response[key]['Task Status']);
              }

              if (!this.globalFunctions.isEmpty(response[key]['Principal Borrower'])) {
                response[key]['Principal Borrower'] = this.globalFunctions.HTMLUnescape(response[key]['Principal Borrower']);
              }
            }

            let value = response[key];
            //We can reparse the dictionary as JSON so that we can adjust any values, as needed. deal with date formats here instead of a html template pipe
            const JSONparsed = JSON.parse(JSON.stringify(value));
            //We also want to know if this particular date is overdue, so that we can apply a custom class on it later. so add that.
            const ogDueDate = JSON.stringify(JSONparsed['Due Date']);
            //console.log('og Due Date', ogDate);
            //Get todays date, and the dueDate. use the moment lib.
            const momentNow = moment();
            const momentDueDate = moment(ogDueDate, "DD/MM/YYYY");
            //console.log('momentNow', momentNow);
            //console.log('momentDueDate', momentDueDate);

            const dueDateIsBeforeToday = momentDueDate.isSameOrBefore(momentNow);
            //console.log('dueDateIsBeforeToday', dueDateIsBeforeToday)
            //Now compare it to today
            if (dueDateIsBeforeToday) {
              //Task is overdue. let's place a property inside it. start it with ds_ (convention) so template knows not to show it, using a pipe filter (called displayFilter)
              JSONparsed['ds_IsOverdue'] = true;
            }
            else {
              //task is NOT overdue
              JSONparsed['ds_IsOverdue'] = false;
            }

            //And the original Due Date and Start Date, convert it using the custom global parser (aus format) and display it nicely.
            JSONparsed['Due Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Due Date'], 'shortdatetime', 'aus');
            JSONparsed['Start Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Start Date'], 'shortdatetime', 'aus');

            //Put the nicely formatted value back into the dictionary
            value = JSONparsed;
            //and assign it to the local dictionary that was passed by reference.
            fillThis[key] = value;
          }

          //Turn spinner off
          this.delegatorDashboardDataDetailDict[lenderKey + this.keySeparator + taskTypeKey].Spinner = false;
          //testing
          //console.log('response from detail', this.delegatorDashboardDataDetailDict[lenderKey + this.keySeparator + taskTypeKey]);
          //no need to return anything, as the html template has a function call to retrieve it from the referenced dictionary
          return;
        }
      });
  }

  //refresh the detail from the server when a chevron is clicked, this is a wrapper and performs some cleansing and putting things back together for the next API call
  public GetDashboardDetailDataWrapper(i_key: string) {
    //in this case, the i_key is encoded with the LenderGUID, and then the TaskTypeGUID. pull them apart.
    const lenderKeyRaw = i_key.split('_')[0];
    const taskTypeKeyRaw = i_key.split('_')[1];

    //get the html friendly version of the keys per the index, to use locally on html templates
    const key = this.GetIndexKeyFromString(i_key, "_", 0);
    const subKey = this.GetIndexKeyFromString(i_key, "_", 1);

    //init the entry inside the dictionary first
    this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey] = { Entity: [], DisplayName: "Detail", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
    this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Entity = [];

    //now let's try calling out to the server for some data. turn the loading spinner on for this one
    this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Spinner = true;
    //pass it the two keys from the template the we recieved - key = assigned User GUID, and the subKey = status. Status can have spaced, which need to be preserved when sending the request to the server. so supply the raw value here as well. (braces are easy to add, space not so much)
    this.GetDashboardDetailData(this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Entity, lenderKeyRaw, taskTypeKeyRaw, key, subKey);
  }

  //shows detail from the local dictionary value inside delegatorDashboardDataDetailDict
  public ShowDetailData(i_key: string) {
    //get the keys per the index from the supplied key
    const key = this.GetIndexKeyFromString(i_key, "_", 0);
    const subKey = this.GetIndexKeyFromString(i_key, "_", 1);

    if (this.globalFunctions.isEmpty(this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey])) {
      //console.log('ShowDetailData no data');
      return "none";
    }
    //console.log('ShowDetailData some data');
    //console.log('key + . + subKey]', key + this.keySeparator + subKey);
    //console.log('this.delegatorDashboardDataDetailDict[key + . + subKey]', this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey]);
    return this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Entity;
  }

  //shows detail from the local dictionary inside delegatorDashboardDataDetailDict regarding the spinner status
  public CheckDetailDataSpinner(i_key: string) {
    //get the html friendly version of the keys per the index, to use locally on html templates
    const key = this.GetIndexKeyFromString(i_key, "_", 0);
    const subKey = this.GetIndexKeyFromString(i_key, "_", 1);

    if (this.globalFunctions.isEmpty(this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey])) {
      //this entry has not been initialized. probably never been loaded. just return false for the spinner.
      return false;
    }
    //return the spinner value
    return this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Spinner;
  }

  //method to split a string based on a separator, and returns the stripped part, ready for use locally on a html template
  public GetIndexKeyFromString(str: string, separator: string, index = 0) {
    //split it by the separator, and then provide the value at the requested index.
    return this.globalFunctions.StripBracesAndSpaces(str.split(separator)[index]);
  }

  //when using GUIDS to bind to elements in html, we can't have the curly braces { and } or even spaces! use this method to strip them out, as needed
  public StripBracesAndSpaces(GUID: string) {
    //moved to the globalFuctions library
    return this.globalFunctions.StripBracesAndSpaces(GUID);
  }

  //this preserves the order of key values coming in from server json arrays, used in the pipe on the html template
  public keepOrder = (a,) => {
    return a;
  }

  //used to track clicks on each of the expanded task items, whether or not to remove highlights and also shift click support
  public TaskItem_Clicked(event, item) {

    //check and unselect action bars on all others
    this.dashboard.DisableContextActionBars('AccountManager');

    //console.log('event.currentTarget: ', event.currentTarget);
    //use a temp class to indicate that we are selecting this.
    event.currentTarget.classList.add('newSelection');

    //now, only add the highlight if this was NOT selected before.
    if (event.currentTarget.classList.contains('glb_highlightSelectedTask') === false) {
      //console.log('clicked item', item);
      //the existing item from the selectedTasks array needs the highlight removed, if there was one selected before
      if (!this.globalFunctions.isEmpty(this.lastClickedTaskItem)) {
        this.lastClickedTaskItem.classList.remove('glb_highlightSelectedTask');
      }

      //now we can update it to this one
      this.lastClickedTaskItem = event.currentTarget;
      //add the highlight
      this.lastClickedTaskItem.classList.add('glb_highlightSelectedTask');

      //empty the selected task array;
      this.selectedTasks.length = 0;
      //now push the new item in into the selectedTasks array. this is where the other functionality is driven from
      this.selectedTasks.push(item);
    }
    else {
      //it already contains it. the same data unit was clicked! remove it!
      event.currentTarget.classList.remove('glb_highlightSelectedTask');
      //remove this from the selectedTasks array
      this.selectedTasks.length = 0;
    }

    //remove the temporary newSelection class. not sure if this is needed here at this very moment, but might be handy later, when doing copy/paste style operations. leaving it for now.
    event.currentTarget.classList.remove('newSelection');

    //track what is now left in the lists, useful for debugging
    //console.log('this.lastClickedItem', this.lastClickedTaskItem);
    //console.log('this.selectedTasks', this.selectedTasks);

    //now check items and flip the context action bar
    if (this.selectedTasks.length > 0) {
      this.showContextBar = true;
      //if its exactly 1, then we can show the edit task button
      if (this.selectedTasks.length === 1) {
        this.showTaskEditButton = true;
      }
      else {
        this.showTaskEditButton = false;
      }
    }
    else {
      this.showContextBar = false;
    }
  }

  //Get cell color
  public CellColor_Get(detailItem, subDetailItem): string {
    let cellClass = "";

    //Modify color for QC Complete tasks
    if (subDetailItem.key === "Task Status" && subDetailItem.value === "QC Complete") {

      //Set Font color to White, because red is not visible with other background colors
      cellClass = "statusQCComplete";

      //Check if QC is passed and adjust background color
      if (detailItem.value.ds_QCPassed === "True") {
        cellClass = cellClass + " glb_successBG";
      }
      else {
        cellClass = cellClass + " glb_infoBG";
      }
    }

    return cellClass
  }

  //used to clear all client side cached items. useful when refreshing.
  public ClearUIArrays(clearDetailData = true) {

    //unhighlight the currently selected task
    if (!this.globalFunctions.isEmpty(this.lastClickedTaskItem)) {
      this.lastClickedTaskItem.classList.remove('glb_highlightSelectedTask');
    }

    //clear the entire clickedTasks array
    this.selectedTasks.length = 0;
    this.selectedTasks = [];

    //clear out the detailed data dictionary as well. if we don't do this, then we leave data that may not be needed anymore (e.g. tasks that were reassigned after a save). will cause UI issues when trying to click items afterwards, since they now exist twice.
    //unless we DONT want to clear UI data, e.g. when the DeselectAllTasks button is clicked.
    if (clearDetailData) {
      this.delegatorDashboardDataDetailDict = {};

      //Collapse the chevron
      this.globalFunctions.TemplateID_CollapseAll(this.TemplateIdentifiers);
    }

    //disable the context bar
    this.showContextBar = false;
  }

  //launch the task view
  public LaunchTaskView() {
    //it's possible to create a 'Headless' LoanIndex that we can reuse
    const loanIndex = new LoanIndex(this.apiService, this.globalFunctions, this.notifyService, this.dialog, this.clientDataStore);
    //console.log('loanIndex', loanIndex);

    //try to get its basic stuff initialized. send the first selected task from the array. we can split the key into its 2 parts for this request (as we need LoanID and TaskGUID both)
    const loanID = this.selectedTasks[0].key.split('_')[0]
    const taskGUID = this.selectedTasks[0].key.split('_')[1]
    //the entity in this case is always LoanTasks
    const entityName = "LoanTasks";

    //set the fullscreen loading spinner
    this.clientDataStore.SetShowFullscreenLoading(true);
    //now call the loanIndex initializer, called 'HeadlessMode'. we can pass the loanID and taskGUID to specify that it should load this single records from the server, and instantly show the Edit modal for us. it will remove the fullscreen loading spinner when that is done. as a script so that callbacks. i want some way to detect when save is clicked, and then refresh myself. how to do it? pass myself as a script to the Headless Mode
    //instead of going straight to the LoanEntityModify template, let's try to construct an entity and navigate to that instead. let's try getting loanindex to do it for us.
    loanIndex.HeadlessModeInit(loanID, entityName, taskGUID, this);

    //TODO some destruction or removal of these constructed headless LoanIndex and its related classes may be in order (after the modal is closed, ie. loanIndex.CloseSingleEntityModal)
  }

  //checks properties of a task, and applies any custom display classes to it, if needed
  public GetDisplayClasses(item) {
    //console.log('GetDisplayClasses item', item);
    //console.log('GetDisplayClasses item.value.ds_IsOverdue', item.value.ds_IsOverdue);
    let displayClass = "";

    if (item.value.ds_IsOverdue === true) {

      //Let overdue win. forget Task Status (grey) when its overdue.
      displayClass = ' glb_overdueTask_lightBG';
    }

    return displayClass;
  }

  //align the columns for the aggregate table based on how much data is coming in. not used here
  public GetClass() {
    //console.log('getclass item', item);
    //we could get the length here like this, but its expensive
    //console.log('getclass Object.keys(item).length', Object.keys(item).length);

    //instead, let's get it once when the Aggregate data is requested, and refer to the class variable that its stored in (aggregateDataColumnCount)
    let classText = 'glb_customFlexRow col-12 ';

    //check how many columns inside the object
    if (this.aggregateDataColumnCount === 2) {
      classText += 'row-cols-2'
    }
    else if (this.aggregateDataColumnCount === 3) {
      classText += 'row-cols-3'
    }
    else if (this.aggregateDataColumnCount === 4) {
      classText += 'row-cols-4'
    }
    else if (this.aggregateDataColumnCount === 5) {
      classText += 'row-cols-5'
    }
    else if (this.aggregateDataColumnCount === 6) {
      classText += 'row-cols-6'
    }
    else if (this.aggregateDataColumnCount === 7) {
      classText += 'row-cols-7'
    }

    return classText;
  }

  //calls the parent to refresh all Task based components
  public ParentTaskData_Refresh() {
    this.dashboard.RefreshTaskData();
  }

  //Show/hide tool tip based on the size and the max limit
  public ValueWithinLimit_Check(value, limit): boolean {
    if (!this.globalFunctions.isEmpty(value)) {
      if (value.length > limit) {
        return false;
      }
    }

    return true;
  }
}