import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { MatDialog } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { AccountSummaryDetail } from './AccountSummaryDetail/AccountSummaryDetail';
import { Subject, Subscription, debounceTime, delay, distinctUntilChanged, flatMap, map, of } from 'rxjs';
import { StaticDataControllerMethods } from '@app/Global/EnumManager';
import { TemplateID } from '@app/Global/Models/ClientModels';
import { ClientDataStore } from '@app/Global/ClientDataStore';

@Component({
  selector: 'AccountSummaryConfigurations',
  templateUrl: './AccountSummaryConfigurations.html',
  styleUrls: ['./AccountSummaryConfigurations.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})

export class AccountSummaryConfigurations implements OnInit, OnDestroy {

  //Class properties
  public AccountSummaryConfigs = [];

  //Spinner
  public ShowSpinner = false;

  //Array to store the identifier GUIDs
  public TemplateIdentifiers: TemplateID[] = [];

  //For filtering the account summary configs
  public FilterSearchValue = "";
  public FilterSearchValueKeyUp = new Subject<any>();
  public FilterSubscription: Subscription;
  public CurrentPage: { currentPage: number } = { currentPage: 0 };

  public IsCreatingAccountSummary = false;
  public ConfigurationName = "";

  //View Childs
  @ViewChild('CreateAccountSummaryFocus') CreateAccountSummaryFocus: ElementRef;
  @ViewChild('CreateAccountSummaryForm') CreateAccountSummaryForm: ElementRef;
  @ViewChild('FocusNewConfigurationName') FocusNewConfigurationName;

  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog) {
  }

  //Angular startup method
  ngOnInit() {

    //Grab all lender config on init, so that the template can loop through and show them.
    this.AccountSummaryConfigs_GetAll();

    //This creates a subscription to apply the search filter as per the search box. move this subscription to a class property, and destroy it on ngOnDestroy.
    this.FilterSubscription = this.FilterSearchValueKeyUp.pipe(
      map((event) => (<HTMLInputElement>event.target).value),
      debounceTime(200),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(100)))
    ).subscribe(value => {
      //Lets show the spinner when we start filtering
      if (!this.globalFunctions.isEmpty(value)) {
        //TODO
        //this.Entity_ToggleSpinner(this.EntityName, 0);
      }
      else {
        //Call it directly with a blank string
        this.Config_SearchFilter("");
      }

      //Small delay so that the spinner has a chance to show.
      //This is useful for the user experience, as they get a visual indication that the filtering has completed.
      this.globalFunctions.delay(300).then(() => {
        //Run after a small delay.
        if (!this.globalFunctions.isEmpty(value)) {
          this.Config_SearchFilter(value);
        }
      });
    });

  }

  //NgOnDestroy
  ngOnDestroy() {

    //Destroy the subscription
    if (!this.globalFunctions.isEmpty(this.FilterSubscription)) {
      this.FilterSubscription.unsubscribe();
    }
  }

  //Expand the form to input the configuration name
  public CreateAccountSummaryModal_Expand() {
    this.globalFunctions.delay(1).then(() => { this.FocusNewConfigurationName.nativeElement.focus(); });
  }

  //Invoke API to create new account summary
  public AccountSummary_Create() {

    //Validate it has a proper name
    if (this.globalFunctions.isEmpty(this.ConfigurationName)) {
      this.notifyService.Error_Show("Please Enter valid configuration name", "Configuration Name Missing");
      return;
    }

    this.IsCreatingAccountSummary = true;

    //Client side validation passed. now let's construct the request and send it to the server
    const createAccountSummaryRequest = { ConfigurationName: this.ConfigurationName };
    const apiRequest = createAccountSummaryRequest;
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.CreateAccountSummaryConfiguration], apiRequest)
      .subscribe(apiResponse => {

        //Check for api response
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsCreatingAccountSummary = false;
          return;
        }
        else {

          //Insert this new configuration into the existing config datagrid.
          const createAccountSummaryResponse = JSON.parse(JSON.stringify(apiResponse));

          //Get the first entry in the array (we only expect one claim to be returned)
          const newAccountSummaryConfiguration = createAccountSummaryResponse.ClientAccountSummaryConfigurations[0];

          //Unescape
          newAccountSummaryConfiguration.Name = this.globalFunctions.HTMLUnescape(newAccountSummaryConfiguration.ConfigurationName);

          //Unshift this into the client side array (at the start)
          this.AccountSummaryConfigs.unshift(newAccountSummaryConfiguration);

          //Clear the UI data. should we slide it closed too? hmm
          this.ConfigurationName = "";

          //A notification would be nice
          this.notifyService.Success_Show("Account Summary Configuration Inserted", "Success");
          this.IsCreatingAccountSummary = false;

          //This collapses the role name input text box form
          this.globalFunctions.TemplateID_Toggle("CreateAccountSummary", this.TemplateIdentifiers);

          //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
          this.Paginator_OnPageChange(0, this.CurrentPage, false);

          //Clear search role
          this.SearchFilter_Clear();
        }
      });
  }
  //Get all the account summary configs
  //TODO: Invoke API to get all the configs from the server
  public AccountSummaryConfigs_GetAll() {

    //Show spinner while loading
    this.ShowSpinner = true;
    const apiRequest = {};
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.GetAccountSummaryConfigurations], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.ShowSpinner = false;
          return;
        }
        else {
          //Deserialize
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Store it in the client side array
          this.AccountSummaryConfigs = response.ClientAccountSummaryConfigurations;

          //Loop through and unescape and relevant columns
          this.AccountSummaryConfigs.forEach(e => {
            e.Name = this.globalFunctions.HTMLUnescape(e.ConfigurationName);
          });

          //Turn off the spinner
          this.ShowSpinner = false;
        }
      });
  }

  //Launch modal of chosen account summary config
  public AccountSummaryConfigDetail_View(configGUID: string) {

    //Set full screen loading
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct API request
    const apiRequest = { AccountSummaryConfigurationGUID: configGUID };

    //Invoke API to get the summary details for the chosen config
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.GetAccountSummaryConfigurationDetails], apiRequest)
      .subscribe(apiResponse => {

        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Find the matching summary config
          const matchingConfig = this.AccountSummaryConfigs.filter(x => x.GUID === configGUID)[0];
          if (!this.globalFunctions.isEmpty(matchingConfig)) {

            //Get the component of this modal, and set a property on it
            const accountSummaryConfigDetail = this.globalFunctions.FeatureModal_Launch(AccountSummaryDetail, this.globalFunctions.GetFeatureModalConfig('80%', false, true), this.dialog, "Account Summary Config", 0, true, false);

            //Pass the config summary, details and data units
            accountSummaryConfigDetail.DialogRef.componentInstance.AccountSummaryConfigGUID = matchingConfig.GUID;
            accountSummaryConfigDetail.DialogRef.componentInstance.AccountSummaryConfigName = matchingConfig.Name;
            accountSummaryConfigDetail.DialogRef.componentInstance.AccountSummaryDataUnits = response.AccountSummaryDataUnits;
            accountSummaryConfigDetail.DialogRef.componentInstance.AccountSummaryGroups = response.AccountSummaryConfigurationGroups;
            accountSummaryConfigDetail.DialogRef.componentInstance.LinkedRoles = response.LinkedRoles;
            accountSummaryConfigDetail.DialogRef.componentInstance.AccountSummaryConfiguration = this;
          }

          //Turn off full screen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });
  }

  //Clears data friom the filter search box
  public SearchFilter_Clear(): void {
    this.FilterSearchValue = "";

    //Call it directly with a blank string to reset the search filter
    this.Config_SearchFilter("");
  }

  //This applies a filter based on the search term to the input entity.
  public Config_SearchFilter(searchTerm: string) {

    //Try to catch null or undefined, replace with blank string
    if (this.globalFunctions.isEmpty(searchTerm)) {

      //Force it to an empty value (if null or undefined)
      searchTerm = "";
    }

    //Make sure search term is not null, and greater than 2 characters. also uppercase it
    searchTerm = searchTerm.toUpperCase();
    if (searchTerm != null && searchTerm.length > 2) {

      //Loop through each row in the userList
      for (const key in this.AccountSummaryConfigs) {
        const value = this.AccountSummaryConfigs[key];

        //We want to loop through valid properties here.
        //console.log("user: ", value);
        if (value['Name'].toUpperCase().includes(searchTerm) === true) {
          value.isHidden = false;
        }
        else {
          value.isHidden = true;
        }

      }

      //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
      this.Paginator_OnPageChange(0, this.CurrentPage);
    }
    else if (searchTerm == "") {
      //Remove all the hide filters
      for (const key in this.AccountSummaryConfigs) {
        const value = this.AccountSummaryConfigs[key];

        if (!this.globalFunctions.isEmpty(value)) {
          value.isHidden = false;
        }
      }
    }

    //Turn the spinner off after we have finished with filtering. maybe not needed as this is client side, and should be pretty fast
    //this.EntityDict[EntityName].Spinner = 1;
  }

  //Used by paginator to handle page updates
  public Paginator_OnPageChange(page: number, currentPage, doScrollToBottom = true) {
    currentPage.currentPage = page;

    //Dont scroll to top, bad UI experience. scroll to bottom instead
    if (doScrollToBottom) {
      this.globalFunctions.delay(50).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
  }
}