import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { groupBy, sumBy, map, isEqual, unionWith, union } from 'lodash-es';
import { Dashboard } from '@app/Components/Dashboard/Dashboard';
import { TemplateID } from '@app/Global/Models/ClientModels';
import { ConditionSubsequentDetail } from './ConditionSubsequentDetail/ConditionSubsequentDetail';

@Component({
  selector: 'ConditionSubsequent',
  templateUrl: './ConditionSubsequent.html',
  styleUrls: ['./ConditionSubsequent.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})

export class ConditionSubsequent implements OnInit {

  //Copies of other parent, in case we want to trigger updates on them later
  @Input() Dashboard: Dashboard;

  //Detail components
  @ViewChildren('DetailComponents', { read: ConditionSubsequentDetail }) public DetailComponents: QueryList<ConditionSubsequentDetail>;

  //Raw aggregate data, static array from the server
  public AggregateData;

  //Aggregated data (TaskCount) grouped by lender
  public GroupedLenderData = [];

  //Detail data, processed and stored as a dictionary
  public DetailData: { [key: string]: any };

  //Local spinner
  public ShowSpinner = false;

  //Used to separate keys when combined into a single identifier
  public KeySeparator = "_";

  //Used for the button (icon) spinner on the refresh button at the top right
  public RefreshingAggregatedSpinner = true;

  //Store whether or not the aggregate returned any data to us
  public NoAggregateDataFlag = false;

  //Variables for showing the QC Complete and Active Tasks
  public ShowMyTasksFlag = false;

  //Text display limit
  public TextDisplayLimitUpcoming = 24;
  public TextDisplayLimitFollowup = 16;
  public TextDisplayLimitComplete = 18;
  public TextDisplayLimitOverdue = 16;

  //Store the various header configs
  public HeaderColumnsUpcoming = [];
  public HeaderColumnsFollowup = [];
  public HeaderColumnsComplete = [];
  public HeaderColumnsOverdue = [];

  //Store the aggregation types
  public AggregationTypeUpcoming = "Upcoming";
  public AggregationTypeFollowup = "Followup";
  public AggregationTypeComplete = "Complete";
  public AggregationTypeOverdue = "Overdue";

  //Expanding all flag
  public ExpandAllFlag = false;

  //Store static API request values
  public AggregateTypeValue = "Aggregate";
  public DetailTypeValue = "Detail";
  public AllTypeValue = "{ALL}";

  //DateTime picker properties. Use boxing (using date as an object) to allow passing it by reference to convert it into ISO Format
  public DTPFromDate = { JSDate: null, ISODate: null };
  public DTPToDate = { JSDate: null, ISODate: null };
  public DTPMaxDate = new Date();
  public DTPMinDate = new Date();
  public DTPDateFormat = "dd/MM/yy";
  public DTPDateRangeDays = 115;

  //Store static strings
  public EnableTooltip = "_EnableTooltip";

  //Check if user has edit claim on CS Taskign
  public HasCSEditClaimFlag = false;

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    private csvDataService: CsvDataService = null) {
  }

  ngOnInit() {

    //Set default date from now to next 50 days
    const dateNow = new Date();
    this.DTPFromDate.JSDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());

    const toDate = new Date();
    toDate.setDate(dateNow.getDate() + 50);

    this.DTPToDate.JSDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), 0);

    //Convert into ISO format for processing on server side
    this.globalFunctions.Date_ToISO(this.DTPFromDate);
    this.globalFunctions.Date_ToISO(this.DTPToDate);

    //Limit the allowed max and min dates
    this.DTPMaxDate.setDate(dateNow.getDate() + this.DTPDateRangeDays);
    this.DTPMinDate.setDate(dateNow.getDate() - this.DTPDateRangeDays);

    //Check if the logged in user has access to edit claim on ConditionSubsequentTasking
    if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "ConditionSubsequentTasking", "Edit") === true) {
      this.HasCSEditClaimFlag = true;
    }

    //Initialise the header columns depending on the Aggregation Type. Start with a base set of columns that all views are currently using
    this.HeaderColumnsUpcoming = [
      { Name: "LoanID", DisplayName: "Account ID", DisplayOrder: 1, UnescapeContent: false }
      , { Name: "PrincipalBorrower", DisplayName: "Borrower", DisplayOrder: 2, UnescapeContent: true }
      , { Name: "Assignee", DisplayName: "Assignee", DisplayOrder: 3, UnescapeContent: true }
      , { Name: "TaskStatus", DisplayName: "Task Status", DisplayOrder: 4, UnescapeContent: false }
      , { Name: "TaskStartDate", DisplayName: "Start Date", DisplayOrder: 4.5, UnescapeContent: false }
      , { Name: "TaskDueDate", DisplayName: "Due Date", DisplayOrder: 5, UnescapeContent: false }
      , { Name: "TaskNote", DisplayName: "Task Note", DisplayOrder: 30, UnescapeContent: true }
    ];

    //Follow up has 3 additional columns, clone and add
    this.HeaderColumnsFollowup = JSON.parse(JSON.stringify(this.HeaderColumnsUpcoming));
    this.HeaderColumnsFollowup.push({ Name: "TaskFollowupDate", DisplayName: "Followup Date", DisplayOrder: 10, UnescapeContent: false })
    this.HeaderColumnsFollowup.push({ Name: "LatestUserTaskNote", DisplayName: "Latest File Note", DisplayOrder: 35, UnescapeContent: false })

    //Overdue tasks display would have same columns as Followup
    this.HeaderColumnsOverdue = JSON.parse(JSON.stringify(this.HeaderColumnsFollowup));

    //Let's remove Start Date from Followup
    this.HeaderColumnsFollowup.splice(this.HeaderColumnsFollowup.findIndex(x => x.Name === "TaskStartDate"), 1);

    //Let's remove LatestUserTaskNote from Overdue
    this.HeaderColumnsOverdue.splice(this.HeaderColumnsOverdue.findIndex(x => x.Name === "LatestUserTaskNote"), 1);

    //Complete has 3 different additional columns, clone and add
    this.HeaderColumnsComplete = JSON.parse(JSON.stringify(this.HeaderColumnsUpcoming));
    this.HeaderColumnsComplete.push({ Name: "TaskEndDate", DisplayName: "Completed Date", DisplayOrder: 9, UnescapeContent: false })
    this.HeaderColumnsComplete.push({ Name: "TaskResultType", DisplayName: "Task Result Type", DisplayOrder: 40, UnescapeContent: false })
    this.HeaderColumnsComplete.push({ Name: "LatestUserTaskNote", DisplayName: "Latest File Note", DisplayOrder: 45, UnescapeContent: false })

    //Get the index of Start Date column
    const columnIndex = this.HeaderColumnsComplete.findIndex(x => x.Name === "TaskStartDate")

    //Check for null/empty index
    if (columnIndex !== -1) {

      //Use splice to remove column Start Date for CS Status = Complete
      this.HeaderColumnsComplete.splice(columnIndex, 1);
    }

    //Check header arrays and run any preparation required, e.g. dates are formatted differently, and sort the columns based on DisplayOrder
    this.HeaderColumns_Init();

    //Refresh aggregate content
    this.Aggregate_Refresh();
  }

  //Initialise header columns
  public HeaderColumns_Init(): void {

    //Prepare header columns for all aggregation types
    this.HeaderColumns_Prepare(this.HeaderColumnsUpcoming);
    this.HeaderColumns_Prepare(this.HeaderColumnsFollowup);
    this.HeaderColumns_Prepare(this.HeaderColumnsComplete);
    this.HeaderColumns_Prepare(this.HeaderColumnsOverdue);
  }

  //Do any header data prep work here
  public HeaderColumns_Prepare(headerColumns): void {

    //Loop through each header array item
    headerColumns.forEach(element => {

      //Default it to display the Header Name
      element.DisplayProperty = element.Name;
      element.SortDirection = 1;
      element.SortKey = 0;

      //If it contains the text DATE
      if (element.Name.toUpperCase().includes("DATE") === true) {

        //Update the DisplayProperty to use the prettier formatted _FMT version instead
        element.DisplayProperty = element.Name + "_FMT";
      }
    });

    //Let's sort it as well, this way the ngFor loops in html wont need any custom pipe for sorting.
    headerColumns.sort((n1, n2) => n1.DisplayOrder - n2.DisplayOrder);
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string, enableFlag: boolean = null): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers, enableFlag)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //Formats name and item count nicely for display
  public AggregateCount_Display(item): string {
    return item.Lender + " (" + item.TaskCount + " tasks)";
  }

  //Show the tasks assigned to logged in user
  public MyTasks_Show(): void {

    //Input date validation
    if (!this.Date_IsValid()) {

      //Toggle the checkbox state back to its original state
      this.globalFunctions.delay(10).then(() => {
        this.ShowMyTasksFlag = !this.ShowMyTasksFlag;
      }
      );

      return;
    }

    //Refresh the aggregate data
    this.Aggregate_Refresh();
  }

  //Refreshes the aggregated data
  public Aggregate_Refresh(): void {

    //Input date validation
    if (!this.Date_IsValid()) {
      return;
    }

    //Initialise headers
    this.HeaderColumns_Init();

    this.RefreshingAggregatedSpinner = true;

    //Set Expand all details to false
    this.ExpandAllFlag = false;

    //clear existing arrays
    this.AggregateData = [];
    //clear the lender array too
    this.GroupedLenderData.length = 0;

    //grab aggregate data
    this.Aggregate_Get();

    //Clear out the detailed data dictionary    
    this.DetailData = {};

    //Collapse the chevrons
    this.globalFunctions.TemplateID_CollapseAll(this.TemplateIdentifiers);
  }

  //Clikc to collapse the detail
  public Chevron_Collapse(key: string): void {

    //Uncheck ExpandAll Checkbox if user interacts with the chevron click. E.g. After clicking ExpandAll checkbox, user collapses one or more chevrons.
    if (this.ExpandAllFlag === true) {
      this.ExpandAllFlag = false;
    }

    //Toggle the chevron
    this.TemplateID_Toggle(key);
  }

  //Click to expand the detail
  public Chevron_Expand(key: string): void {

    //Input date validation
    if (!this.Date_IsValid()) {
      return;
    }

    //Toggle the chevron
    this.TemplateID_Toggle(key);

    //Initialise the detail dictionary
    this.DetailData[key] = { Entity: [], DisplayName: this.DetailTypeValue, Spinner: true, Count: -1, InitialLoad: false, InitialLoadCompleted: false };

    //Get data for the matching key
    this.Detail_Get(key);
  }

  //Click to download the details
  public DownloadBTN_Click(lender): void {

    //Input date validation
    if (!this.Date_IsValid()) {
      return;
    }

    //Grab the matching lender data
    const lenderData = this.AggregateData.filter(x => x.Lender === lender.Lender)[0];

    if (!this.globalFunctions.isEmpty(lenderData)) {

      //Keys to invoke details data
      let key1 = "";

      if (lenderData.RowPrimaryKey.includes(this.KeySeparator) === true) {

        //Grab the lender guid
        key1 = lenderData.RowPrimaryKey.split('_')[0];
      }

      //Get data for the matching lender key for all aggregation types
      this.Details_Download(key1);
    }
  }

  //Toggle checkbox for expand all
  public AllDetailData_Expand(): void {

    //Input date validation
    if (this.ExpandAllFlag && !this.Date_IsValid()) {

      //Toggle the checkbox state back to its original state
      this.globalFunctions.delay(10).then(() => {
        this.ExpandAllFlag = !this.ExpandAllFlag;
      }
      );

      return;
    }

    if (this.ExpandAllFlag) {

      //Initialise headers
      this.HeaderColumns_Init();

      //Call Aggregate data with "{ALL}" key value. This will trigger the nested details method to expand
      this.Aggregate_Get(this.AllTypeValue);
    }
    //Collapse all the details
    else {
      //Loop through each aggregate data
      this.AggregateData.forEach(aggregateData => {

        //Initialise the detail dictionary
        this.DetailData[aggregateData.RowPrimaryKey] = { Entity: [], DisplayName: this.DetailTypeValue, Spinner: this.ExpandAllFlag, Count: -1, InitialLoad: false, InitialLoadCompleted: false };

        //Sync the chevron state based on the checkbox state
        this.TemplateID_Toggle(aggregateData.RowPrimaryKey, this.ExpandAllFlag);
      });
    }
  }

  //Return the spinner status
  public DetailSpinner_State(key: string): boolean {

    if (this.globalFunctions.isEmpty(this.DetailData[key])) {

      //Key not initialised in the dictionary
      return false;
    }

    //Return the spinner value
    return this.DetailData[key].Spinner;
  }

  //Get the detail class css based on spinner state
  public DetailCSS_Get(key: string): string {

    let returnClass = "glb_hiddenObjectImmediate";

    if (!this.globalFunctions.isEmpty(this.DetailData[key]) && this.DetailData[key].Spinner === false) {
      returnClass = "";
    }

    //Always return a string
    return returnClass;
  }

  //Hide the datetime picker panel
  public DTP_Close(calendar, date): void {
    this.globalFunctions.Date_ToISO(date);
    calendar.hideOverlay();
  }

  //Validate input date
  public Date_IsValid(): boolean {

    //Sync the JS date to ISODate. If the user doesn't click the OK button on the Calendar picker, we are forcing the dates to resync here
    this.globalFunctions.Date_ToISO(this.DTPFromDate);
    this.globalFunctions.Date_ToISO(this.DTPToDate);

    if (this.globalFunctions.isEmpty(this.DTPFromDate.JSDate)) {
      this.notifyService.Error_Show("Please choose a From Date", "Error");
      return false;
    }

    if (this.globalFunctions.isEmpty(this.DTPToDate.JSDate)) {
      this.notifyService.Error_Show("Please choose a To Date", "Error");
      return false;
    }

    //Compare ISODate here. JSDate might have the seconds component that will fail the check if we run on the same datetime
    if (this.DTPToDate.ISODate < this.DTPFromDate.ISODate) {
      this.notifyService.Error_Show("To Date cannot be before From Date", "Error");
      return false;
    }

    return true;
  }

  //Invoke API to get the aggregate data
  private Aggregate_Get(key: string = null): void {

    this.ShowSpinner = true;

    //Sync the JS date to ISODate. If the user doesn't click the OK button on the Calendar picker, we are forcing the dates to resync here
    this.globalFunctions.Date_ToISO(this.DTPFromDate);
    this.globalFunctions.Date_ToISO(this.DTPToDate);

    const apiRequest = { ReturnType: this.AggregateTypeValue, ShowMyTasksFlag: this.ShowMyTasksFlag, FromDate: this.DTPFromDate.ISODate, ToDate: this.DTPToDate.ISODate };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetConditionSubsequentAggregateDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.RefreshingAggregatedSpinner = false;
          this.ShowSpinner = false;
          this.NoAggregateDataFlag = true;
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Reset the dictionary
          this.AggregateData.length = 0;

          for (const key in response) {

            //Need to unescape some columns
            if (!this.globalFunctions.isEmpty(response[key])) {
              if (!this.globalFunctions.isEmpty(response[key]['Lender'])) {
                response[key]['Lender'] = this.globalFunctions.HTMLUnescape(response[key]['Lender']);
              }
            }

            //And fill it
            this.AggregateData[key] = response[key];
          }

          //Store the response in an array so that we can perform operations on it.
          const responseArray = [];

          //Loop through each response object and push it into an array.
          Object.entries(this.AggregateData).forEach(
            ([, value]) => {

              //Convert TaskCount to a number so that we can sum it later using lodash
              value['TaskCountNumber'] = Number(value['TaskCount'])

              //Now push this into responseArray so that lodash can sum it properly later
              responseArray.push(value);
            });

          //Using lodash to group the values by lender
          this.GroupedLenderData = map(groupBy(responseArray, 'Lender'), (v, k) => ({
            Lender: k,
            TaskCount: sumBy(v, 'TaskCountNumber')
          }));

          //Turn the spinners off
          this.ShowSpinner = false;
          this.RefreshingAggregatedSpinner = false;

          //Check if there is any data
          if (this.GroupedLenderData.length === 0) {
            this.NoAggregateDataFlag = true;
          }
          else {
            this.NoAggregateDataFlag = false;
          }

          //Check if ExpandAll key is being requested and get details data for each aggregation type
          if (!this.globalFunctions.isEmpty(key) && key === this.AllTypeValue) {

            //Clear detail data
            this.DetailData.length = 0;

            //Loop through each aggregate data
            this.AggregateData.forEach(aggregateData => {

              //Initialise the detail dictionary
              this.DetailData[aggregateData.RowPrimaryKey] = { Entity: [], DisplayName: this.DetailTypeValue, Spinner: this.ExpandAllFlag, Count: -1, InitialLoad: false, InitialLoadCompleted: false };

              //Sync the chevron state based on the Expand All checkbox state
              this.TemplateID_Toggle(aggregateData.RowPrimaryKey, this.ExpandAllFlag);
            });

            //Retrieve detail data for all the lender aggregate values
            this.Detail_Get(this.AllTypeValue);
          }

          return;
        }
      });
  }

  //Invoke API to get the detail data (key1 = LenderGUID, key2 = AggregationType)
  private Detail_Get(key: string): void {

    let key1 = key;
    let key2 = key;

    if (key.includes(this.KeySeparator)) {

      //Split the keys
      key1 = key.split('_')[0];
      key2 = key.split('_')[1];
    }

    //Sync the JS date to ISODate. If the user doesn't click the OK button on the Calendar picker, we are forcing the dates to resync here
    this.globalFunctions.Date_ToISO(this.DTPFromDate);
    this.globalFunctions.Date_ToISO(this.DTPToDate);

    //Construct API request with keys
    const apiRequest = { ReturnType: this.DetailTypeValue, LenderGUIDs: key1, AggregationType: key2, ShowMyTasksFlag: this.ShowMyTasksFlag, FromDate: this.DTPFromDate.ISODate, ToDate: this.DTPToDate.ISODate };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetConditionSubsequentDetailDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //Turn spinner off
          if (key1 === this.AllTypeValue) {

            //Turn spinner off for all primary keys
            this.AggregateData.forEach(aggregateData => {
              this.DetailData[aggregateData.RowPrimaryKey].Spinner = false;
            });
          }
          else {
            //Target specific key to to turn off the spinner
            this.DetailData[key1 + this.KeySeparator + key2].Spinner = false;
          }
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Now loop through the array and process
          for (const key in response) {

            const rowPrimaryKey = response[key]['LenderGUID'] + this.KeySeparator + response[key]['AggregationType'];

            //Initialise a dictionary for a key if it hasn't been done before by the caller
            if (this.globalFunctions.isEmpty(this.DetailData[rowPrimaryKey])) {
              this.DetailData[rowPrimaryKey] = { Entity: [], DisplayName: this.DetailTypeValue, Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
            }

            if (!this.globalFunctions.isEmpty(response[key])) {

              let textDisplayLimit = this.TextDisplayLimitUpcoming;
              let matchingHeader = null;

              //Get matching aggregation type header data and text display limits
              if (response[key]['AggregationType'] === this.AggregationTypeUpcoming) {
                matchingHeader = this.HeaderColumnsUpcoming;
                textDisplayLimit = this.TextDisplayLimitUpcoming;
              }
              else if (response[key]['AggregationType'] === this.AggregationTypeFollowup) {
                matchingHeader = this.HeaderColumnsFollowup;
                textDisplayLimit = this.TextDisplayLimitFollowup;
              }
              else if (response[key]['AggregationType'] === this.AggregationTypeComplete) {
                matchingHeader = this.HeaderColumnsComplete;
                textDisplayLimit = this.TextDisplayLimitComplete;
              }
              else if (response[key]['AggregationType'] === this.AggregationTypeOverdue) {
                matchingHeader = this.HeaderColumnsOverdue;
                textDisplayLimit = this.TextDisplayLimitOverdue;
              }

              //Get the properties of each row
              const rowProperties = Object.entries(response[key]);

              //Loop through each row property
              rowProperties.forEach(
                ([rowKey, rowValue]) => {

                  //Find the matching header column
                  const matchingHeaderData = matchingHeader.filter(x => x.Name === rowKey)[0];

                  //If we found one, we can process data based on the header config
                  if (matchingHeaderData != null) {

                    //Default tooltip to false, by inject a matching property suffixed with _EnableTooltip
                    response[key][rowKey + this.EnableTooltip] = false;

                    //Check if we need to create a display friendly property for date type columns
                    if (matchingHeaderData.Name.toUpperCase().includes("DATE") === true) {

                      //Create a key with _FMT suffix
                      const rowKeyFMT = rowKey + "_FMT";

                      //Create the pretty date property with a _FMT suffix
                      response[key][rowKeyFMT] = this.globalFunctions.getCustomDateFormat(response[key][rowKey], "shortdate", "custom", "YYYY-MM-DD");

                      //Re-evaluate tooltip requirement for dates based on the formatted column
                      if (response[key][rowKeyFMT] != null && response[key][rowKeyFMT].length > textDisplayLimit) {

                        //Enable the tooltip
                        response[key][rowKey + this.EnableTooltip] = true;
                      }
                    }
                    else {
                      //Not a date type column, continue with other processing
                      //Check if we should enable the tooltip (text is too long to fit)
                      if (response[key][rowKey] != null && response[key][rowKey].length > textDisplayLimit) {

                        //Enable the tooltip
                        response[key][rowKey + this.EnableTooltip] = true;
                      }

                      //Check if we need to unescape content
                      if (matchingHeaderData.UnescapeContent === true) {

                        //Mutate content, unescape HTML entities
                        response[key][rowKey] = this.globalFunctions.HTMLUnescape(rowValue);
                      }
                    }
                  }
                });
            }

            //Push the processed detail row into the child entity of the target dictionary
            this.DetailData[rowPrimaryKey].Entity.push(response[key]);
          }

          if (key1 === this.AllTypeValue) {

            //Loop through all aggregate data
            this.AggregateData.forEach(aggregateData => {

              //Turn off the target spinner before refreshing as the DOM must render before looking for the child component
              this.DetailData[aggregateData.RowPrimaryKey].Spinner = false;

              //Loop through each upcoming tasks
              this.DetailComponents.forEach(component => {

                //Refresh the child view
                component.DetailData_Sync();
              }

              );
            });
          }

          //Refresh the target child detail data
          else {

            //Turn off the target spinner before refreshing as the DOM must render before looking for the child component
            this.DetailData[key].Spinner = false;

            //Loop through each child view
            this.DetailComponents.forEach(component => {

              //For the matching key
              if (component.RowPrimaryKey === key) {

                //Refresh the child view
                component.DetailData_Sync();
              }
            }
            );
          }

          return;
        }
      });
  }

  //Invoke API to get the detail data for download (key1 = LenderGUID, key2 = {ALL})
  private Details_Download(key: string): void {

    const key1 = key;
    const key2 = this.AllTypeValue;

    //Sync the JS date to ISODate. If the user doesn't click the OK button on the Calendar picker, we are forcing the dates to resync here
    this.globalFunctions.Date_ToISO(this.DTPFromDate);
    this.globalFunctions.Date_ToISO(this.DTPToDate);

    //Construct API request with keys
    const apiRequest = { ReturnType: this.DetailTypeValue, LenderGUIDs: key1, AggregationType: key2, ShowMyTasksFlag: this.ShowMyTasksFlag, FromDate: this.DTPFromDate.ISODate, ToDate: this.DTPToDate.ISODate };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetConditionSubsequentDetailDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //No data found
          this.notifyService.Info_Show("No data", "Info");
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Array to store data for CSV download
          const csvArray = [];

          let matchingHeader = null;

          //Set matchingHeader to union of all distinct headers
          matchingHeader = unionWith(this.HeaderColumnsUpcoming, this.HeaderColumnsFollowup, this.HeaderColumnsComplete, this.HeaderColumnsOverdue, isEqual);

          //Now loop through the response and process
          for (const key in response) {

            if (!this.globalFunctions.isEmpty(response[key])) {

              //Delete GUID columns from CSV download
              delete response[key].TaskGUID;
              delete response[key].LenderGUID;

              //Get the properties of each row
              const rowProperties = Object.entries(response[key]);

              //Loop through each row property
              rowProperties.forEach(
                ([rowKey, rowValue]) => {

                  //Find the matching header column
                  const matchingHeaderData = matchingHeader.filter(x => x.Name === rowKey)[0];

                  //If we found one, we can process data based on the header config
                  if (matchingHeaderData != null) {

                    //Process date columns
                    if (matchingHeaderData.Name.toUpperCase().includes("DATE") === true) {

                      if (!this.globalFunctions.isEmpty(response[key][rowKey])) {

                        //Check if the datetime has millisecond component
                        const targetIndex = response[key][rowKey].indexOf(".");

                        //Remove the millisecond due to EXCEL showing only the time component, instead of a proper datetime
                        if (targetIndex > 0) {

                          response[key][rowKey] = response[key][rowKey].substring(0, targetIndex);
                        }

                        //Remove T to format it as date in Excel
                        response[key][rowKey] = response[key][rowKey].replace("T", " ");
                      }
                    }

                    //Not a date type column, continue with other processing
                    else {

                      //Check if we need to unescape content
                      if (matchingHeaderData.UnescapeContent === true) {

                        //Mutate content, unescape HTML entities
                        response[key][rowKey] = this.globalFunctions.HTMLUnescape(rowValue);
                      }
                    }
                  }
                });
            }

            csvArray.push(response[key]);
          }

          //Call the service to produce and download the csv file
          this.csvDataService.exportToCsv("ConditionSubsequentData", csvArray);
          return;
        }
      });
  }
}