import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ClientDataStore, StoreItemTypes } from '@app/Global/ClientDataStore';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { map, debounceTime, switchMap, tap, distinctUntilChanged, take } from 'rxjs/operators';
import { ApiService } from '@app/Services/APIService';
import { AccountsControllerMethods, NavigationUrls, UsersControllerMethods } from '@app/Global/EnumManager';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { MatDialog } from '@angular/material/dialog';
import { LoginModal } from '@app/Components/Loan/LoginModal/LoginModal';
import { NotifyService } from '@app/Services/NotifyService';
import { environment } from '@env/environment';
import { timer } from 'rxjs';
import { Documentation } from '@app/Components/Documentation/Documentation';

const SearchButtonId = 'search';

@Component({
	selector: 'SearchBar',
	templateUrl: './SearchBar.html',
	styleUrls: ['./SearchBar.scss'],
})

export class SearchBar implements OnInit, OnDestroy {

	//adds a warning message before user tried to leave or refresh the page using the browser 'refresh' button
	@HostListener('window:beforeunload', ['$event'])
	beforeUnload($event) {
		//if(this.hasChanges) $event.returnValue='Your data will be lost!';
		if (!this.globalFunctions.isEmpty(this.clientDataStore.loginDataDirect.AccessToken)) {
			$event.returnValue = 'You will lose your current session if you refresh. Are you sure that you want to do this?';
		}
	}


	//use this to detect activity and mark the users last active time
	@HostListener('document:click', ['$event'])
	@HostListener('document:mousedown', ['$event'])
	@HostListener('document:keypress', ['$event'])
	@HostListener('document:touchstart', ['$event'])
	//fires a lot. maybe dont use this one?
	//@HostListener('document:mousemove', ['$event'])
	@HostListener('document:scroll', ['$event']) onEvent(event): void {
		//logic here
		//console.log('event', event);
		this.OnUserActivity();
	}

	//the last time the use was active
	private LastUserActiveTime: Date;
	//fires on any event on the page, and update the user active time.
	private OnUserActivity(): void {
		this.LastUserActiveTime = new Date();
	}

	public userDisplayName: string;
	public loading: boolean;
	public isLoggingOut: boolean;
	public searchTerm = new Subject<string>();
	//store the last searched term, so that we can compare (and not fire if it hasn't changed)
	public lastSearchTerm: string;

	public noResultsFound: boolean;
	public HitErrorPage = false;

	public enableRoleManagement = false;
	public enableUserManagement = false;
	public enableReportBuilder = false;
	public enableLenderConfiguration = false;
	public EnableAccountSummaryConfiguration = false;

	//Data from the subscriptions that get put into local class variables
	public hideSearchBar = false;
	public invisibleSearchBar = false;
	public navigationUrls = NavigationUrls;

	//Is EOD Running
	public IsReadOnlyMode = false;

	//env details
	public environmentName = environment.environmentName;
	public appName = this.globalFunctions.GetApplicationName();

	//User Options
	public HideDischargedLoansInSearch = false;

	//Do we show the nav bar? Used by the button when on small mobile screens
	public ShowNavBar = true;
	//To display the nav bar button on small screen
	public ShowNavBarButton = false;

	//Subscription array
	private Subscriptions: Subscription[] = [];

	//store loginData from the client Data store, and init the class variables in the object shape that the server expects
	public loginData = {
		LoginID: "", UserPassword: "", UserDisplayName: "", UserEmail: "", UserLoggedInStatus: false, UserMobile: "", AccessToken: "", isRefreshingToken: false
	}

	constructor(private apiService: ApiService, private clientDataStore: ClientDataStore, private router: Router, private globalFunctions: GlobalFunctions, private matDialog: MatDialog, private notifyService: NotifyService, private dialog: MatDialog) {
	}

	ngOnInit() {
		//let's check and set the correct hostname for the api.
		this.apiService.BaseURL_Set();
		const isLoggedIn = this.globalFunctions.LoginCheck();

		//Init the observable, otherwise null error
		this.clientDataStore.UpdateUserOptionData({});

		//Subscribe to the User Options: HideDischargedLoansInSearch & userOptionData
		this.Subscriptions.push(this.clientDataStore.UserOptionData
			.subscribe(x => {
				this.HideDischargedLoansInSearch = x.HideDischargedLoansInSearch;
			})
		)

		//subscribe to changes for the hide search bar variable. id like to use async pipe instead of having to create a subscription here, but i haven't figured out how to make it work after a router link click.
		//not sure what this one is doing anymore. TODO REVIEW
		this.Subscriptions.push(this.clientDataStore.HideSearchBar
			.subscribe(x => {
				this.hideSearchBar = x;
			})
		)

		//Check whether the EOD is running or not
		this.Subscriptions.push(this.clientDataStore.CheckReadOnlyMode
			.subscribe(x => {
				this.IsReadOnlyMode = x;
			})
		)

		//this is whether or not the search bar is visible. should probably create a second subscription for this
		this.Subscriptions.push(this.clientDataStore.InvisibleSearchBar
			.subscribe(x => {
				this.invisibleSearchBar = x;
			})
		)

		//subscription to get latest loginData
		this.Subscriptions.push(this.clientDataStore.LoginData
			.subscribe(x => {
				if (!this.globalFunctions.isEmpty(x)) {
					this.loginData = x;
					this.userDisplayName = x.UserDisplayName;

					//console.log(this.clientDataStore.ClientClaims);
					//check if the logged in user has User Management Admin
					if (!this.globalFunctions.isEmpty(this.clientDataStore.ClientClaims)) {

						if (this.clientDataStore.ClientClaims.filter(x => x.Name == "UserAdministrator").length > 0) {
							this.enableUserManagement = true;
						}

						//check if the logged in user has Role Management Admin
						if (this.clientDataStore.ClientClaims.filter(x => x.Name == "RoleAdministrator").length > 0) {
							this.enableRoleManagement = true;
						}

						if (this.clientDataStore.ClientClaims.filter(x => x.Name == "ReportBuilder").length > 0) {
							//enable the workflow delegator 
							this.enableReportBuilder = true;
						}
						else {
							this.enableReportBuilder = false;
						}

						if (this.clientDataStore.ClientClaims.filter(x => x.Name == "LenderConfiguration").length > 0) {
							//enable the workflow delegator 
							this.enableLenderConfiguration = true;
						}
						else {
							this.enableLenderConfiguration = false;
						}

						if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "AccountSummaryConfiguration", "Read")) {
							//Enable the Account Summary Screen
							this.EnableAccountSummaryConfiguration = true;
						}
						else {
							this.EnableAccountSummaryConfiguration = false;
						}

					}
				}
			})
		)

		//subscription to get latest error data
		this.Subscriptions.push(this.clientDataStore.HitErrorPageSub
			.subscribe(x => {
				if (!this.globalFunctions.isEmpty(x)) {
					//this should help us sync the home button display, when an error is hit
					this.HitErrorPage = x;
				}
			})
		)

		//search bar starts off as hidden
		this.clientDataStore.Update_InvisibleSearchBar(true);

		//error page starts off as hidden
		this.clientDataStore.Update_HitErrorPageSub(false);

		//now check if the user is logged in
		if (!isLoggedIn) {
			//But we have a few pages where we allow the user to nav without logging in. e.g. the password reset page, user invite, Home, etc
			if (location.pathname.toUpperCase().includes(this.navigationUrls.PasswordReset.toString().toUpperCase())
				|| location.pathname.toUpperCase().includes(this.navigationUrls.UserInvite.toString().toUpperCase())
				|| location.pathname.toUpperCase().includes(this.navigationUrls.Home.toString().toUpperCase())
				|| location.pathname.toUpperCase().includes(this.navigationUrls.TwoFactorReset.toString().toUpperCase())
			) {
				//these are public pages that don't launch the login modal. but the search bar should be disabled, or even hidden.
				this.clientDataStore.Update_HideSearchBar(true);
				this.clientDataStore.Update_InvisibleSearchBar(true);
			}
			else {
				//before we think about launching the login modal, check if it is already launched
				if (this.clientDataStore.LoginModalLaunched) {
					//its already launched, dont launch a new one
					return;
				}

				//now indicate that we are going to launch it
				this.clientDataStore.LoginModalLaunched = true;

				//user is not logged in. the page is locked, needs a user login. launch the login modal here
				const modalConfig: any = {
					backdrop: 'static',
					keyboard: false,
					animated: true,
					ignoreBackdropClick: true,
					class: 'modal-lg modal-xl',
					height: '300px',
					width: '900px',
					//this stops the dialog from being closed on a backdrop click
					disableClose: true,
					panelClass: 'glb_overlayOnTop'
				};

				const dialogRef = this.globalFunctions.FeatureModal_Launch(LoginModal, modalConfig, this.matDialog, "Login Modal", 0, true, false);
				//use html content so that we can style it
				dialogRef.DialogRef.componentInstance.HtmlContent = "Please log in";

				//After the dialog is closed, we can try to refresh
				dialogRef.DialogRef.afterClosed().subscribe(result => {
					if (result) {

						//Return to the same page.
						let targetURL = [this.router.url];

						if (this.router.url.toUpperCase().includes("HOME") || this.router.url === "/") {
							//Go to the dashboard. home has nothing
							targetURL = [NavigationUrls.Dashboard]
						}

						//Tell router to reload the component, even if the URL is the same
						this.router.navigate(targetURL, { onSameUrlNavigation: 'reload' });

						//Set the display name of the user in the search bar
						this.userDisplayName = this.clientDataStore.loginDataDirect.UserDisplayName;
					}
				});

				//don't hide the search bar on startup
				this.clientDataStore.Update_HideSearchBar(false);
			}
		}
		else {
			//don't hide the search bar on startup
			this.clientDataStore.Update_HideSearchBar(false);
			//set the display name of the user in the search bar
			this.userDisplayName = this.clientDataStore.loginDataDirect.UserDisplayName;
		}

		//no matter what, we have to subscribe to get search results from the search bar
		this.Search();

		//start the silent refresh token subscription. this will run every x minutes and update refresh and access tokens.
		this.CreateSilentRefreshTokenSubscription();

		//To display the nav bar button on small screen
		this.NavBarButton_ToggleVisibility(null);
	}

	ngOnDestroy() {
		if (!this.globalFunctions.isEmpty(this.Subscriptions)) {
			this.Subscriptions.forEach(subscription => {
				subscription.unsubscribe();
			});
		}
	}

	//TODO review this, and why its needed
	public searchForm = new UntypedFormGroup({
		search: new UntypedFormControl('', Validators.required),
	});

	//when the search section is focused, navigate back to the Account Search page
	public FocusKeywords() {
		//return to search so that any prior value is popped back
		this.router.navigate([NavigationUrls.AccountSearch], { state: { Link: 'ReturnToSearch' } });
	}

	//Process a search based on the keywords
	public SearchKeywords() {
		this.searchTerm.next(this.searchForm.get(SearchButtonId).value)
	}

	//Unused. Could be used to make a "Clear" search text button, if desired
	public ClearKeywords() {
		this.searchForm.controls["search"].setValue("");
	}

	//navigate to a url, retaining state. Used for returning home
	public NavToURLWithState(link: string, incomingState: string) {
		this.router.navigate([link], { state: { Link: incomingState } });
	}

	//This is the Search subscription
	public Search() {
		//if its the same search term, and we shouldn't flip the switches...yet. what if the user presses home or end? or some other non character key?

		//handy later when we stop using observable
		// if (this.lastSearchTerm !== this.searchForm.get(SearchButtonId).value) {

		this.searchTerm.pipe(
			map((char: any) => {
				//console.log("char: ", char);
				//console.log("lastSearchTerm: ", this.lastSearchTerm);
				if (char.length > 3 && this.lastSearchTerm !== char) {
					this.clientDataStore.UpdateIsSearchingAccounts(true);
					this.noResultsFound = false;
				}
				else {
					//grab the current loading screen param (so we can know if a search is currently in progress)
					let loadingScreen = false;
					this.clientDataStore.IsSearchingAccounts.pipe(take(1)).subscribe(value => {
						loadingScreen = value
					});

					//check if the loading screen is up. if so, and the last search term matches, repop the last saved result
					if (char.length > 0 && this.lastSearchTerm === char && loadingScreen) {
						//console.log("same search term and loading screen is on. reload the result.");
						//its the same as the prior search term. check and pop out the cached prior results
						const prev = JSON.parse(sessionStorage.getItem(StoreItemTypes[StoreItemTypes.PreviousSearchResults]));
						if (prev != null && prev != "") {
							//console.log("restoring prior results to search page");
							this.clientDataStore.UpdateSearchResults(prev);
						}
					}
				}

				//we still need to return a value for the pipe
				return char;
			}
			),
			//debounce delays function call by x milliseconds, useful for search box or filter type input.
			debounceTime(10),
			//stops the observable from firing when the result is the same
			distinctUntilChanged(),

			switchMap(term => {
				//the search page is not seeing this change! how can we get this to work? ok we have a subscription to this on the search page now.
				this.loading = true;
				//update the keyword in the store, so that the other page can see the update and respond accordingly (show the no result screen, if needed)
				this.clientDataStore.AddTypedItem(StoreItemTypes.SearchKeywords, term)

				//store this as the last search term.
				this.lastSearchTerm = term;

				const apiRequest = { Query: "", HideDischargedLoans: this.HideDischargedLoansInSearch };
				apiRequest.Query = term;
				//unfortunately, this still fires, even when the query is blank. I want to stop this. TODO
				return this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.Search], apiRequest)
					.pipe(
						tap(data => {
							if (this.globalFunctions.isEmpty(data)) { return; }
							this.noResultsFound !== (data == null);
						}));
			})
		).subscribe((accounts: any[]) => {
			this.loading = false;
			if (!this.globalFunctions.isEmpty(accounts)) {
				accounts.map(account => {
					//make a copy of AccountID into the AccountID_NoHighlights property, so that we can use it for inserting into the route (URL) address bar later. prevents the markcustom tag from bleeding into there. make sure to copy the value using .valueOf(), instead of assigning a reference to the same variable!
					account.AccountID_NoHighlights = account.AccountID.valueOf();
					if (this.globalFunctions.isEmpty(account)) { return null }
					return account;
				})
			}

			this.clientDataStore.UpdateSearchResults(accounts);
			//the search page is not seeing this change! how can we get this to work? ok we have a subscription to this on the search page now.
			this.clientDataStore.UpdateIsSearchingAccounts(false);
		})
	}

	//logging out
	public Logout() {
		//short message
		this.notifyService.Info_Show("Logging out", "Please wait");
		this.clientDataStore.SetShowFullscreenLoading(true);
		this.LogoutServer();
	}

	public LogoutCleanup() {
		//get rid of the access token, and then go to the home page.
		this.clientDataStore.loginDataDirect.AccessToken = "";
		this.clientDataStore.loginDataDirect.UserDisplayName = "";
		this.clientDataStore.loginDataDirect.UserEmail = "";
		this.clientDataStore.loginDataDirect.UserLoggedInStatus = false;
		this.clientDataStore.loginDataDirect.UserPassword = "";
		this.clientDataStore.loginDataDirect.LoginID = "";

		//Clear session storage (not local storage, that contains some more persistent info)
		sessionStorage.clear();

		//Short message
		this.notifyService.Info_Show("Redirecting...", "Please wait");

		//Tell router to reload the component, even if the URL is the same
		this.router.navigate([NavigationUrls.Home], { onSameUrlNavigation: 'reload' });

		//Wait a small amount, and then reload the page
		this.globalFunctions.delay(100).then(any => {
			window.location.reload();
		});
	}

	//attempts to perform a logout
	public LogoutServer(): void {
		if (this.globalFunctions.isEmpty(this.loginData.AccessToken) || this.globalFunctions.isEmpty(this.loginData.LoginID)) {
			this.notifyService.Warning_Show("You are not logged in", "Already Logged Out");
			return;
		}

		const apiRequest = { AccessToken: this.loginData.AccessToken, LoginID: this.loginData.LoginID };
		this.isLoggingOut = true;
		this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.Logout], apiRequest)
			.subscribe(apiResponse => {
				if (this.globalFunctions.isEmpty(apiResponse)) {
					this.isLoggingOut = false;
					//just do the local logout/cleanup
					this.LogoutCleanup();
					return;
				}
				else {
					//deserialize it into an class that we can understand
					const response = JSON.parse(JSON.stringify(apiResponse));

					//Check the log out response
					if (response.ResponseMessage === "You have logged out successfully") {

						//Clear out login data
						this.loginData.AccessToken = "";
						this.loginData.UserDisplayName = "";
						this.loginData.UserLoggedInStatus = false;
						this.clientDataStore.ClientClaims = "";
						this.clientDataStore.EntityTypesDisplay = "";
						this.clientDataStore.EntityTypes = "";
						this.clientDataStore.ClaimNames = "";

						//Show the search bar
						this.clientDataStore.Update_InvisibleSearchBar(false);

						//Why don't we just stick it into the global client store
						this.clientDataStore.loginDataDirect = this.loginData;
						this.clientDataStore.UpdateLoginData(this.loginData);

						//Session storage has nothing that needs to be persisted between different login sessions, so just purge it
						sessionStorage.clear();

						//If you want to clear all local storage cache too
						//localStorage.clear();

						//Remove who was last logged in
						localStorage.removeItem(StoreItemTypes[StoreItemTypes.LastLoggedInUser]);

						//A notification might be a nice touch here
						this.notifyService.Success_Show(response.ResponseMessage, "Success");
						this.isLoggingOut = false;

						this.LogoutCleanup();
					}
				}
			});
	}

	//store the silent login subscription
	private silentRefreshTokenSubscription: Subscription;

	//creates the silent refresh token subscription. let's start this as soon as the user hits the app, and use a bool to switch if we make the api call or not.
	private CreateSilentRefreshTokenSubscription(): void {
		//start the silent refresh token update in 20 minutes, and then run it every 20 minutes (timer wants values in milliseconds)
		this.silentRefreshTokenSubscription = timer(1200000, 1200000).pipe(
			//this.silentRefreshTokenSubscription = timer(5000, 2000).pipe(
			map((x) => this.GetRefreshToken())
		).subscribe();
	}

	//performs server call to update refresh and access tokens
	private GetRefreshToken() {
		//so we can't do this unless the user is logged in.
		//what about the refresh token itself? if that is missing, we can't do it either. get it from session storage
		const refreshToken = sessionStorage.getItem(StoreItemTypes[StoreItemTypes.RefreshToken]);

		if (this.loginData.UserLoggedInStatus === false || this.globalFunctions.isEmpty(this.loginData.AccessToken) || this.globalFunctions.isEmpty(this.loginData.LoginID) || this.globalFunctions.isEmpty(refreshToken) || this.clientDataStore.HitErrorPage) {
			//console.log("User not logged in, cannot refresh token");
			//user not logged in, cannot refresh token
			return;
		}

		//here we can check the LastUserActiveTime, and prevent the RefreshToken from being fired if we have no activity after an extended period of time.
		let timeObject = new Date();
		//minus the active period, for testing let's say 20 seconds (1000 milliseconds times 20)
		//timeObject = new Date(timeObject.getTime() - (1000 * 20));

		//for the final figure, we could decide based on a server based claim (TODO) 
		//for now let's put 4 hours (60 seconds * 60 minutes * 4 hours)
		timeObject = new Date(timeObject.getTime() - (1000 * 60 * 60 * 4));

		//console.log('timeObject', timeObject);
		//console.log('LastUserActiveTime', this.LastUserActiveTime);

		if (timeObject > this.LastUserActiveTime) {
			//inactive, do not refresh token. discard it and log out.
			//console.log('User inactive');
			this.Logout();
			return;
		}
		else {
			//console.log('User within active period, allow refresh token');
		}

		//create the body of the request
		const apiRequest = { AccessToken: this.loginData.AccessToken, LoginID: this.loginData.LoginID, RefreshToken: refreshToken };

		//indicate that we are refreshing token. this might help other requests to wait until its completed. But would have to go into the client data store, for APIService to see it.
		this.loginData.isRefreshingToken = true;
		this.clientDataStore.UpdateLoginData(this.loginData);

		//console.log('apiRequest', apiRequest);

		this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.RefreshToken], apiRequest)
			.subscribe(apiResponse => {
				if (this.globalFunctions.isEmpty(apiResponse)) {
					//something went wrong.
					//even if there was no response, refreshing token has completed
					this.loginData.isRefreshingToken = false;
					this.clientDataStore.UpdateLoginData(this.loginData);

					//now force a logout
					this.Logout();

					//nothing more to do
					return;
				}
				else {
					//deserialize it into an class that we can understand
					const response = JSON.parse(JSON.stringify(apiResponse));
					//console.log('got a new access token from the server');
					//console.log('response', response);

					//put the access token data into the LoginData
					this.loginData.AccessToken = response.AccessToken;
					//this.loginData.RefreshToken = response.RefreshToken;
					//set the new Access and Refresh Tokens in the loginDataDirect on Client Data Store too
					this.clientDataStore.loginDataDirect = this.loginData;

					//Check if EOD is running
					this.clientDataStore.Update_IsReadOnlyMode(response.IsReadOnlyMode);

					//let's pop the refresh token into session storage
					sessionStorage.setItem(StoreItemTypes[StoreItemTypes.RefreshToken], response.RefreshToken);

					//refreshing token has completed
					this.loginData.isRefreshingToken = false;
					//and now push it into the observable in the client data store so others can 'detect' its change
					this.clientDataStore.UpdateLoginData(this.loginData);
					//For testing that a page reload works. Set the timer on the silentRefreshTokenSubscription to a low value, e.g. 30 seconds (30000 milliseconds)
					//console.log("Pretending that session is expired");
					//this.globalFunctions.LaunchLoginModal(true, "Your session has expired, please log in");
					return;
				}
			});
	}

	//Use this to catch any window resize changes, and then update any styles or behaviour
	@HostListener('window:resize', ['$event']) private Window_OnResize(event): void {
		this.NavBarButton_ToggleVisibility(event);
	}

	//Toggles the Nav bar visibilty
	public NavBarVisibilty_Toggle() {
		this.ShowNavBar = !this.ShowNavBar;
	}

	//Launch the user guide page
	public UserGuide_Launch() {

		//Launch user guide
		this.globalFunctions.FeatureModal_Launch(Documentation, this.globalFunctions.GetFeatureModalConfig("95%", false, false, "glb_fixedHeightDialog"), this.dialog, "User Guide", 0, false, true);
	}

	//Set the show Nav Bar button flags based on the screen size
	private NavBarButton_ToggleVisibility(event: UIEvent | null): void {
		if (this.globalFunctions.Window_IsGreaterThanSize(event, GlobalFunctions.MinSizeMD)) {
			//Force the nav bar visiblity to on, as on large screens the button to toggle it will disappear
			this.ShowNavBar = true;
			this.ShowNavBarButton = false;
		}
		else {
			//Force the nav bar visiblity to false, as on small screens it takes up too much space
			this.ShowNavBar = false;
			this.ShowNavBarButton = true;
		}
	}
}