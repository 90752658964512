<div id="PG_LoanSearch">
    <div *ngIf="(resultsCounter !== 0)">
        <div class="glb_customFlexRow">
            <div class="">
                <h2 class="searchHeading">Search Results</h2>
                <small class="text-white">{{resultsCounter}} results</small>
            </div>
        </div>

        <div id="LBL_SearchResults">
            <div class="searchResultFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                <div class="searchResultBorders" *ngFor="let account of searchResults | paginate: {id: 'search', itemsPerPage: 9, currentPage:
                    currentPage}" @fadeIn style="padding: 0px; padding-top: 15px; padding-bottom: 10px;">
                    <div class="glb_customFlexRow col-lg-12 rowOverride">
                        <div class="col-12 col-lg-10 paddingOverride">
                            <a routerLinkActive="active" (click)="SelectAccount(account)">
                                <div class="glb_customFlexRow glb_hoverHand">
                                    <div class="searchResultTitle flex row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                        <div>
                                            <fa-icon *ngIf="account.IsParent" [icon]="['fal', 'crown']" size="lg"
                                                class="glb_boxedPaddingNarrow align-self-right" style="color:#ff9284"
                                                matTooltip="This is a Parent Account"></fa-icon>
                                            <span class="searchResultTitle"
                                                [innerHTML]="account.PrincipalBorrower + ' (Principal Borrower)' | sanitizeHtml">
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div class="glb_customFlexRow glb_hoverHand">
                                    <div class="searchResultAccountID justify-content-left text-start col-12 col-xs-2 col-sm-6 col-md-4 col-lg-6"
                                        [innerHTML]="account.AccountID + ' (' + account.ForeignIdentifier + account.LoanCustomFields + ')' | sanitizeHtml">
                                    </div>
                                    <div class="searchResultProduct text-start text-sm-end
                                    text-md-end text-lg-end col-12 col-xs-12 col-sm-6 col-md-8 col-lg-6"
                                        [innerHTML]="account.ProductName | sanitizeHtml"></div>
                                    <div
                                        class="searchResultProduct justify-content-left justify-content-xs-left justify-content-right text-start text-sm-start text-md-start text-lg-start col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        Principal Balance: {{account.PrincipalBalance | currency}}</div>
                                    <div class="searchResultProduct justify-content-xs-left justify-content-right text-start text-sm-end
                                        text-md-end text-lg-end col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6"
                                        [innerHTML]="'Status: ' + account.LoanStatus | sanitizeHtml"></div>
                                </div>
                            </a>

                            <div *ngIf="account.LinkedAccounts.length > 0" class="glb_customFlexRow text-white">
                                <div class="glb_customFlexRow glb_TitleMD glb_paddingLeft glb_paddingTopMD col-12">Linked Accounts</div>
                                <div class="glb_customFlexRow glb_paddingLeft col-12">
                                    <div class="glb_customFlexRow glb_boxedPadding glb_paddingLeft col-12">
                                        <div class="glb_customFlexRow glb_boxedPaddingNarrow glb_paddingLeft col-12">
                                            <div *ngFor="let linkedAccount of account.LinkedAccounts"
                                                class="col-6 glb_paddingZeroOffset glb_allBordersSkin glb_boxedPaddingNarrow">
                                                <a routerLinkActive="active" (click)="SelectAccount(linkedAccount)">
                                                    <div *ngIf="linkedAccount.PrincipalBorrower.length > 0" class="glb_customFlexRow glb_hoverHand">
                                                        <div class="searchResultTitle flex row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1"
                                                            [innerHTML]="linkedAccount.PrincipalBorrower + ' (Principal Borrower)' | sanitizeHtml">
                                                        </div>
                                                    </div>
                                                    <div class="glb_customFlexRow glb_hoverHand">
                                                        <div
                                                            class="searchResultAccountID justify-content-left text-start col-12 col-xs-2 col-sm-6 col-md-4 col-lg-6">
                                                            <div>
                                                                <fa-icon *ngIf="linkedAccount.IsParent" [icon]="['fal', 'crown']"
                                                                    class="glb_boxedPaddingNarrow align-self-right" style="color:#ff9284"
                                                                    matTooltip="This is a Parent Account"></fa-icon>
                                                                <span class="searchResultAccountID"
                                                                    [innerHTML]="linkedAccount.AccountID + ' (' + linkedAccount.ForeignIdentifier + linkedAccount.LoanCustomFields +')' | sanitizeHtml">
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="searchResultProduct text-start text-sm-end text-md-end text-lg-end col-12 col-xs-12 col-sm-6 col-md-8 col-lg-6"
                                                            [innerHTML]="linkedAccount.ProductName | sanitizeHtml"></div>
                                                        <div
                                                            class="searchResultProduct justify-content-left justify-content-xs-left justify-content-right text-start text-sm-start text-md-start text-lg-start col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                            Principal Balance: {{linkedAccount.PrincipalBalance | currency}}</div>
                                                        <div class="searchResultProduct justify-content-xs-left justify-content-right text-start text-sm-end text-md-end text-lg-end col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6"
                                                            [innerHTML]="'Status: ' + linkedAccount.LoanStatus | sanitizeHtml"></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="rightChevron col-0 col-lg-2 d-flex justify-content-end align-items-center">
                            <a routerLinkActive="active" (click)="SelectAccount(account)">
                                <!-- show a chevron, only on lg screens -->
                                <div class="d-none d-lg-block glb_hoverHand">
                                    <fa-icon [icon]="['fal', 'chevron-right']" size="lg" type="button" class="btn align-self-right"
                                        style="color:grey; margin-right: -10px;" matTooltip="Click to Launch Account"></fa-icon>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>

                <div class="glb_customFlexRow">
                    <div class="col">
                        <div class="glb_Font d-flex justify-content-center">
                            <pagination-controls class="glb_paginatorDark" id='search' (pageChange)="onPageChange($event)" [maxSize]="paging.maxSize"
                                [responsive]="true" [previousLabel]="paging.previousLabel" [nextLabel]="paging.nextLabel">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="IsSearchingLoans">
        <div class="glb_customFlexRow">
            <div class="col">
                <div class="glb_Font d-flex justify-content-center text-white">
                    Loading ...
                </div>
            </div>
        </div>
        <!-- this is the loading spinner -->
        <div class="glb_customFlexRow">
            <div class="col">
                <div class="d-flex justify-content-center">
                    <div class="loadingio-spinner-spinner-z47l50pe76s">
                        <div class="ldio-2q8e2dt3kyi">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="(emptyResultSet) && keywords != null">
    <div class="glb_customFlexRow">
        <div id="LBL_NoSearchResults" class="glb_Title glb_Title justify-content-start noResultHeading">
            <span>No results...Please try another search term</span>
        </div>
    </div>
</div>

<div *ngIf="showLastSearchedLoans && !IsSearchingLoans && emptyResultSet">
    <div class="glb_customFlexRow">
        <div class="">
            <h2 class="searchHeading">Recently viewed accounts</h2>
            <small class="text-white">{{lastClickedAccounts.length}} accounts viewed</small>
        </div>
    </div>

    <div>
        <div class="searchResultFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
            <div class="searchResultBorders" *ngFor="let recentlyClickedAccount of lastClickedAccounts | paginate: {id: 'lastClicked', itemsPerPage: 9, currentPage:
            currentPage}" style="padding: 0px; padding-top: 15px; padding-bottom: 10px;" @fadeIn>
                <a routerLinkActive="active" (click)="SelectAccount(recentlyClickedAccount)">
                    <div class="glb_customFlexRow col-lg-12 rowOverride glb_hoverHand">
                        <div class="col-12 col-lg-10 paddingOverride">
                            <div class="glb_customFlexRow">
                                <div class="searchResultTitle glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1"
                                    [innerHTML]="recentlyClickedAccount.PrincipalBorrower + ' (Principal Borrower)' | sanitizeHtml"> </div>
                            </div>
                            <div class="glb_customFlexRow">
                                <div class="searchResultAccountID justify-content-left text-start col-12 col-xs-2 col-sm-6 col-md-4 col-lg-6"
                                    [innerHTML]="recentlyClickedAccount.AccountID + ' (' + recentlyClickedAccount.ForeignIdentifier + recentlyClickedAccount.LoanCustomFields + ')' | sanitizeHtml">
                                </div>
                                <div class="searchResultProduct text-start text-sm-end
                                text-md-end text-lg-end col-12 col-xs-12 col-sm-6 col-md-8 col-lg-6"
                                    [innerHTML]="recentlyClickedAccount.ProductName | sanitizeHtml"></div>
                                <div class="searchResultProduct justify-content-left justify-content-xs-left justify-content-right text-start text-sm-start
                                    text-md-start text-lg-start col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    Principal
                                    Balance: {{recentlyClickedAccount.PrincipalBalance | currency}}</div>
                                <div class="searchResultProduct justify-content-xs-left justify-content-right text-start text-sm-end
                                    text-md-end
                                    text-lg-end col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6"
                                    [innerHTML]="'Status: ' + recentlyClickedAccount.LoanStatus | sanitizeHtml"></div>
                            </div>
                        </div>

                        <div class="rightChevron col-0 col-lg-2 d-flex justify-content-end align-items-center">
                            <!-- show a chevron, only on lg screens -->
                            <div class="d-none d-lg-block">
                                <fa-icon [icon]="['fal', 'chevron-right']" size="lg" type="button" class="btn align-self-right"
                                    style="color:grey; margin-right: -10px;" matTooltip="Click to Launch Account"></fa-icon>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <div class="glb_customFlexRow">
                <div class="col">
                    <div class="glb_Font d-flex justify-content-center">
                        <pagination-controls class="glb_paginatorDark" id='lastClicked' (pageChange)="onPageChange($event)" [maxSize]="paging.maxSize"
                            [responsive]="true" [previousLabel]="paging.previousLabel" [nextLabel]="paging.nextLabel">
                        </pagination-controls>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>