import { Component, ElementRef, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { StaticDataControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { AfterViewInit } from '@angular/core';
import { TemplateID, TaskNotifierModels } from '@app/Global/Models/ClientModels';
import { Editor } from 'primeng/editor';
import { ControlData } from '@app/Global/Models/EntityModels';
import { RichTextEditor } from '@app/Components/User/LenderConfigurations/RichTextEditor/RichTextEditor';
import Quill from 'quill';

@Component({
  selector: 'LenderConfiguration',
  templateUrl: './LenderConfiguration.html',
  styleUrls: ['./LenderConfiguration.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class LenderConfiguration implements AfterViewInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<LenderConfiguration>
  ) {

    //Construct a copy of task notifier 
    this.TaskNotifier = new TaskNotifier(globalFunctions, notifyService, dialog, clientDataStore, apiService);
  }

  //TODO use this to focus stuff on html template
  @ViewChild('inputDefaultAssignee') inputDefaultAssignee: ElementRef;

  //Create lender config section. used for collapsing the section after the a new entry has been created
  @ViewChild('LenderConfigTaskAssignee_CreateSection') LenderConfigTaskAssignee_CreateSection: ElementRef;

  //Create lender config form, used in combination with above for collapsing the section after the a new entry has been created
  @ViewChild('LenderConfigTaskAssignee_CreateForm') LenderConfigTaskAssignee_CreateForm: ElementRef;

  //Create role to account summary config mapping section, used for expanding/collapsing the section
  @ViewChild('RoleToAccountSummaryMapping_CreateSection') RoleToAccountSummaryMapping_CreateSection: ElementRef;

  //Create role to account summary config mapping form
  @ViewChild('LenderConfigAccountSummary_CreateForm') LenderConfigAccountSummary_CreateForm: ElementRef;

  //Multiselect single Inputs for Role and Account Summary
  @ViewChild('INP_RoleMultiselectSingle') INPRoleMultiselectSingle;
  @ViewChild('INP_SummaryConfigMultiselectSingle') INPSummaryConfigMultiselectSingle;

  //Parent that can close me
  public parentLenderConfigs;

  //CS reminder quill editor
  public QuillCSReminder: any;

  //CS reminder quill editor
  public QuillCSFollowup: any;

  //Unique Modal Identifer
  public ModalIdentifier;

  //A clone of the chosen lender config for editing
  public chosenLenderConfigClone = { LenderGUID: '', LenderName: '', DefaultAssigneeGUID: '', DefaultAssigneeName: '', GUID: '', DefaultSLADays: 0, LenderIndustryType: '', MaxLenderAdminCount: 0, EmailReplyTo: '', EmailCCTo: '', EmailSubject_CS_Reminder: '', EmailTemplate_CS_Reminder: '', EmailSubject_CS_Followup: '', EmailTemplate_CS_Followup: '', FromEmailDisplay: '' };

  //This is used to track the last AutoComplete Request that was sent to the server, and checked when processing the response, to ensure that we are processing only the latest one sent
  public lastAutoCompleteRequestGUID: string;

  //The initial page load spinner
  public showSpinner = false;

  //Do we want to allow editing on this component fields
  public editEnabled = false;

  //For tracking if a server update request is in progress
  public isUpdateInProgress = false;

  public isCreatingLenderConfig = false;

  //Stores a copy of the lender config task type>assignee records locally
  public lenderConfigTaskTypeAssignee = [];

  //To track state for the spinner related to retrieving task type config records on intial request
  public isRetrievingConfigTasks = true;

  //Is any row based assignee input box editable? only allow one row at a time. this class variable will help us track the state across rows
  public isTaskTypeAssigneeBeingEdited = false;

  public ShowReminderVariables = false;
  public ShowFollowupVariables = false;

  public EmailVariables: any;

  //A task notifier class variable
  public TaskNotifier: TaskNotifier;

  //Variables linked to Account Summary Screen
  //Available options for role selection
  public RoleMultiselectSingleOptions;

  //To store the selected role from the multiselect
  public RoleSelectedMultiselectOptions;

  //Available options for account summary config selection
  public SummaryConfigMultiselectSingleOptions;

  //To store the selected config from the multiselect
  public SummaryConfigSelectedMultiselectOptions;

  //Flag to track whether the create new mapping is completed or not
  public IsCreatingRoleSummaryConfigMapping = false;

  //To track state for the spinner related to retrieving the mappings from the server
  public IsRetrievingRoleSummaryMappings = true;

  //To store the list of role summary config mappings
  public RoleSummaryConfigs = [];

  //Account Summary initialised
  public IsAccountSummaryInitialised = false;

  //To store the lender roles for multiselect in Task Notifier
  public LenderRoles;

  //Used by paginator
  public paging = {
    maxSize: 10,
    previousLabel: "",
    nextLabel: ""
  }

  public currentPage: { currentPage: number } = { currentPage: 0 };

  //The default assignee data used to bind on the input in the html. need a separate class variable to store and track its state as a Control Value Object. This needs to default to the shape of the control data, or we get null errors on the first run of autocomplete
  public defaultAssignee_AutoCompleteModel = this.DefaultAutoComplete_Get();

  //This one is for default assignee to a task type
  public defaultTaskTypeAssignee_AutoCompleteModel = this.DefaultAutoComplete_Get();

  //And this one is for updating an assignee on an existing record
  public defaultTaskTypeAssigneeUpdate_AutoCompleteModel = this.DefaultAutoComplete_Get();

  //This one is for default alternate assignee to a task type
  public defaultTaskTypeAlternateAssignee_AutoCompleteModel = this.DefaultAutoComplete_Get();

  //And this one is for updating an alternate assignee on an existing record
  public defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel = this.DefaultAutoComplete_Get();

  //And this one is for updating a default SLA days on a existing lender config record
  public defaultTaskTypeSLAUpdate = 0;

  //The default task type data used to bind on the input in the html. need a separate class variable to store and track its state as a Control Value Object. This needs to default to the shape of the control data, or we get null errors on the first run of autocomplete
  public defaultTaskType_AutoCompleteModel = this.DefaultAutoComplete_Get();

  //And this one is for updating a SLA days on a new lender config task type record
  public TaskTypeSLACreate = 0;

  //Name of the currently displayed nav tab
  public CurrentNavItem: string;

  //This is used to control Autocomplete querying against the server. This is needed to bind a default to the input element CLICK events on the form, otherwise you get null errors. This is Client lookup specific, hence the Client prefix in the property names
  public defaultAssignee_AutoCompleteRequest = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //this one is for when creating a default assignee to a task type
  public defaultTaskTypeAssignee_AutoCompleteRequest = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //This one is for when UPDATING an existing default assignee on a task type
  public defaultTaskTypeAssigneeUpdate_AutoCompleteRequest = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //This one is for when creating a alternate assignee to a task type
  public defaultTaskTypeAlternateAssignee_AutoCompleteRequest = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //This one is for when UPDATING an existing alternate assignee on a task type
  public defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteRequest = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //this one is for when creating a new task type config
  public defaultTaskType_AutoCompleteRequest = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Task Type', Client_AutoCompleteControlData: []
  };

  //To check if it is the initial load
  private InitialPageLoad = true;

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  //This will be called after the view has been loaded
  ngAfterViewInit() {

    //Run it on initial load only
    if (this.InitialPageLoad) {

      //We need to call the server for the task type>assignee data.
      //We still need a short delay even this method is added on ngAfterViewInit
      this.globalFunctions.delay(50).then(() => {
        this.LenderConfigTaskAssignee_Retrieve();
      });

      //Set class variable initialPageLoad to false so that it won't force the payout date to current date
      this.InitialPageLoad = false;
    }

    //Initialise CS Reminder Quill editor
    this.QuillCSReminder = new Quill('#quill-editor_CSReminder', {
      modules: {
        toolbar: '#toolbar_CSReminder'
      },
      placeholder: 'Compose an email template...',
      theme: 'snow',
    });

    //Set cs reminder quill content with the value being passed, if exists
    if (!this.globalFunctions.isEmpty(this.chosenLenderConfigClone.EmailTemplate_CS_Reminder)) {
      this.QuillCSReminder.setContents(this.QuillCSReminder.clipboard.convert({ html: this.chosenLenderConfigClone.EmailTemplate_CS_Reminder }));
    }

    //Initialise CS Followup Quill editor
    this.QuillCSFollowup = new Quill('#quill-editor_CSFollowup', {
      modules: {
        toolbar: '#toolbar_CSFollowup'
      },
      placeholder: 'Compose an email template...',
      theme: 'snow',
    });

    //Set cs followup quill content with the value being passed, if exists
    if (!this.globalFunctions.isEmpty(this.chosenLenderConfigClone.EmailTemplate_CS_Followup)) {
      this.QuillCSFollowup.setContents(this.QuillCSFollowup.clipboard.convert({ html: this.chosenLenderConfigClone.EmailTemplate_CS_Followup }));
    }

    //Sync the editing of Quill editor
    this.QuillEditorEdit_Toggle();

    //Validate uploaded image size in quill editor
    this.globalFunctions.QuillImage_Validate(this.QuillCSReminder, this.notifyService);
    this.globalFunctions.QuillImage_Validate(this.QuillCSFollowup, this.notifyService);
  }

  //Toggle when we an item is selected from the role multiselect input. Allowed = Single only
  public RoleMultiSelectSingle_Toggle(e) {

    //Empty the array if there is any selection option already
    if (!this.globalFunctions.isEmpty(this.RoleSelectedMultiselectOptions)) {
      this.RoleSelectedMultiselectOptions.length = 0;
    }

    //Set the new value in the selection option
    this.RoleSelectedMultiselectOptions.push(e.itemValue);

    //Hide the panel on selection
    if (!this.globalFunctions.isEmpty(this.INPRoleMultiselectSingle)) {
      this.INPRoleMultiselectSingle.hide();
    }
  }

  //Toggle when we an item is selected from the summary config multiselect input. Allowed = Single only
  public SummaryConfigMultiSelectSingle_Toggle(e) {

    //Empty the array if there is any selection option already
    if (!this.globalFunctions.isEmpty(this.SummaryConfigSelectedMultiselectOptions)) {
      this.SummaryConfigSelectedMultiselectOptions.length = 0;
    }

    //Set the new value in the selection option
    this.SummaryConfigSelectedMultiselectOptions.push(e.itemValue);

    //Hide the panel on selection
    if (!this.globalFunctions.isEmpty(this.INPSummaryConfigMultiselectSingle)) {
      this.INPSummaryConfigMultiselectSingle.hide();
    }
  }

  //Initialise account summary
  public AccountSummary_Init(): void {

    //Reset multiselect options
    this.RoleMultiselectSingleOptions = [];
    this.SummaryConfigMultiselectSingleOptions = [];

    //Construct the request
    const apiRequest = { LenderConfigurationGUID: this.chosenLenderConfigClone.GUID };

    //Flag for the spinner
    this.IsRetrievingRoleSummaryMappings = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.GetRoleSummaryConfiguration], apiRequest)
      .subscribe(apiResponse => {

        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsRetrievingRoleSummaryMappings = false;
          return;
        }
        else {

          //Get the response
          const getRoleSummaryConfigResponse = JSON.parse(JSON.stringify(apiResponse));

          //Loop through each lender roles and initialise RoleMultiselectSingleOptions
          getRoleSummaryConfigResponse.LenderRoles.forEach(role => {
            this.RoleMultiselectSingleOptions.push({ name: role.Name, ControlGUID: role.GUID });
          });

          //Loop through each lender roles and initialise SummaryConfigMultiselectSingleOptions
          getRoleSummaryConfigResponse.AccountSummaryConfigurations.forEach(summary => {
            this.SummaryConfigMultiselectSingleOptions.push({ name: summary.Name, ControlGUID: summary.GUID });
          });

          //Loop through the mappings and add it to the role summary config array
          getRoleSummaryConfigResponse.RoleSummaryConfigurations.forEach(mapping => {

            //Get the matching role name
            let matchingRoleName = "";
            const matchingRole = this.RoleMultiselectSingleOptions.filter(x => x.ControlGUID === mapping.RoleGUID)[0];
            if (!this.globalFunctions.isEmpty(matchingRole)) {
              matchingRoleName = matchingRole.name;
            }

            //Get the matching summary config name
            let matchingSummaryName = "";
            const matchingAccountSummary = this.SummaryConfigMultiselectSingleOptions.filter(x => x.ControlGUID === mapping.AccountSummaryConfigurationGUID)[0];
            if (!this.globalFunctions.isEmpty(matchingAccountSummary)) {
              matchingSummaryName = matchingAccountSummary.name;
            }

            //Push it to the array
            this.RoleSummaryConfigs.push({ GUID: mapping.GUID, RoleGUID: mapping.RoleGUID, RoleName: matchingRoleName, SummaryConfigGUID: mapping.GUID, SummaryConfigName: matchingSummaryName });
          });

          //Turn off the spinner
          this.IsRetrievingRoleSummaryMappings = false;

          //Flag to indicate that the account summary screen has been initialised. To avoid invoking api multiple times
          this.IsAccountSummaryInitialised = true;
        }
      });
  }

  //Method to create new mapping
  public RoleSummaryConfig_Create() {

    //Validate the inputs, role selection
    if (this.globalFunctions.isEmpty(this.RoleSelectedMultiselectOptions)) {
      this.notifyService.Error_Show("Please select a role", "Invalid");
      return;
    }
    const selectedRole = this.RoleSelectedMultiselectOptions[0];

    //Validate account summary config selection
    if (this.globalFunctions.isEmpty(this.SummaryConfigSelectedMultiselectOptions)) {
      this.notifyService.Error_Show("Please select a configuration", "Invalid");
      return;
    }
    const selectedConfig = this.SummaryConfigSelectedMultiselectOptions[0];

    //Construct API request
    const apiRequest = { LenderConfigurationGUID: this.chosenLenderConfigClone.GUID, RoleGUID: selectedRole.ControlGUID, AccountSummaryConfigurationGUID: selectedConfig.ControlGUID };

    //Turn on full screen loading
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Invoke API to insert the role summary mapping for the chosen lender config
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.CreateRoleSummaryConfiguration], apiRequest)
      .subscribe(apiResponse => {

        //API Response
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Unshift this into the client side array (at the start)
          this.RoleSummaryConfigs.unshift({ GUID: response.RoleSummaryConfiguration.GUID, RoleGUID: response.RoleSummaryConfiguration.RoleGUID, RoleName: this.RoleName_Get(response.RoleSummaryConfiguration.RoleGUID), SummaryConfigGUID: response.RoleSummaryConfiguration.AccountSummaryConfigurationGUID, SummaryConfigName: this.AccountSummaryName_Get(response.RoleSummaryConfiguration.AccountSummaryConfigurationGUID) });

          //Empty the selected role and config
          this.SummaryConfigSelectedMultiselectOptions.length = 0;
          this.RoleSelectedMultiselectOptions.length = 0;
          this.SummaryConfigSelectedMultiselectOptions = [];
          this.RoleSelectedMultiselectOptions = [];

          //A notification would be nice
          this.notifyService.Success_Show("Role Account Summary mapping created successfully", "Success");

          //Turn off full screen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });
  }

  //Get the role name for the guid
  public RoleName_Get(guid) {
    let roleName = "";
    const matchingRole = this.RoleMultiselectSingleOptions.filter(x => x.ControlGUID === guid)[0];
    if (!this.globalFunctions.isEmpty(matchingRole)) {
      roleName = matchingRole.name;
    }

    return roleName;
  }

  //Get the account summary configuration name for the guid
  public AccountSummaryName_Get(guid) {
    let configSummaryName = "";
    const matchingSummaryConfig = this.SummaryConfigMultiselectSingleOptions.filter(x => x.ControlGUID === guid)[0];
    if (!this.globalFunctions.isEmpty(matchingSummaryConfig)) {
      configSummaryName = matchingSummaryConfig.name;
    }

    return configSummaryName;
  }

  //Delete existing mapping
  public RoleSummaryMapping_Delete(guid) {

    //Do a confirm screen for this
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to delete this mapping?"
    //console.log('record', record);

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Turn the global loading screen on
        this.clientDataStore.SetShowFullscreenLoading(true);

        //Construct the request
        const apiRequest = { LenderConfigurationGUID: this.chosenLenderConfigClone.GUID, RoleAccountSummaryMappingGUID: guid };

        this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.DeleteRoleSummaryConfiguration], apiRequest)
          .subscribe(apiResponse => {

            if (this.globalFunctions.isEmpty(apiResponse)) {
              //Turn the global loading screen off
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {

              //Find the index to splice from the array
              const matchingConfigIndex = this.RoleSummaryConfigs.findIndex(x => x.GUID === guid);
              this.RoleSummaryConfigs.splice(matchingConfigIndex, 1);

              //Turn the global loading screen off
              this.clientDataStore.SetShowFullscreenLoading(false);

              //Notification
              this.notifyService.Success_Show("The role summary config mapping has been removed", "Success");
            }
          });
      }
    });
  }

  //To insert the clicked variable into the quill editor
  public Variable_Click(quillEditor, variable): void {

    const selection = quillEditor.getSelection(true);

    //The last parameter, the Source = "user", is required for ngModel to update after the text has been inserted (https://github.com/quilljs/quill/issues/2509)
    quillEditor.insertText(selection.index, variable, 'user');
  }

  //To toggle the display variables
  public ShowVariables_Toggle(emailTemplate = "Reminder") {

    if (emailTemplate === "Reminder") {
      this.ShowReminderVariables = !this.ShowReminderVariables;
    }
    else if (emailTemplate === "Followup") {
      this.ShowFollowupVariables = !this.ShowFollowupVariables;
    }
  }

  //Whether to enable or disable the template for editting
  public QuillEditorEdit_Toggle(): void {
    this.QuillCSReminder.enable(this.editEnabled);
    this.QuillCSFollowup.enable(this.editEnabled);
  }

  //Checks the name of the supplied entity, and supplies the css to fade IN the content (make it visible with a short fade in animation using css keyframes)
  public CurrentNavItem_Show(navTabName: string): string {
    if (navTabName === this.CurrentNavItem) {

      //If we are showing the content, supply the css keyframes that fade it in
      return 'glb_keyFrameFadeIn';
    }
    return '';
  }

  //Checks the name of the supplied entity, and supplies the css to just REMOVE the content for unmatching items. Used on parent items to completely remove it from rendering (display: none)
  public CurrentNavItem_ForceRemove(navTabName: string): string {
    if (navTabName !== this.CurrentNavItem)
      return 'glb_hiddenObjectImmediate';
    else return '';
  }

  //When a nav bar item is clicked, show the relevant nav tab
  public NavBarItem_Clicked(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    const eventTargetID = eventTarget.id;

    //Update the currently visible nav tab
    this.CurrentNavItem = eventTargetID;

    //Let's initialise account summary screen if the nav tab gets clicked
    if (this.CurrentNavItem === "BTN_AccountSummaryConfig") {
      if (this.IsAccountSummaryInitialised === false) {

        //TODO: Create a method that invokes api to get the mappings and available roles/account summary configs
        //Initialise multiselect options
        this.AccountSummary_Init();
      }
    }
  }

  //Get and highlight the currently active nav bar item
  public CurrentNavBarItem_Get(navTab: string): string {
    if (this.CurrentNavItem === navTab)
      return 'active'
    else return ''
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //Retrieve Lender Config Task Assignee
  public LenderConfigTaskAssignee_Retrieve() {

    //Construct the request
    const apiRequest = { LenderConfigurationGUID: this.chosenLenderConfigClone.GUID };
    //console.log('apiRequest', apiRequest);

    this.isRetrievingConfigTasks = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.GetLenderConfigurationTaskAssignee], apiRequest)
      .subscribe(apiResponse => {
        //console.log("apiResponse", apiResponse);

        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isRetrievingConfigTasks = false;
          return;
        }
        else {
          //Get the response
          const getLenderConfigTaskAssigneeResponse = JSON.parse(JSON.stringify(apiResponse));
          //console.log('getLenderConfigTaskAssigneeResponse', getLenderConfigTaskAssigneeResponse);

          //Loop through and unescape all required columns
          getLenderConfigTaskAssigneeResponse.forEach(element => {
            element.LenderName = this.globalFunctions.HTMLUnescape(element.LenderName);
            element.DefaultAssigneeName = this.globalFunctions.HTMLUnescape(element.DefaultAssigneeName);
            element.DefaultTaskTypeName = this.globalFunctions.HTMLUnescape(element.DefaultTaskTypeName);
          });

          //Just set the array here to this value we retrieved, so it can be displayed in the page
          this.lenderConfigTaskTypeAssignee = getLenderConfigTaskAssigneeResponse;
          //And sort it
          this.lenderConfigTaskTypeAssignee.sort((a, b) => a.DefaultTaskTypeName.localeCompare(b.DefaultTaskTypeName));

          this.isRetrievingConfigTasks = false;
        }
      });
  }

  //This method is used when a edit request on a single row is clicked, where the user wants to start modifying the assignee on a task type
  public LenderConfigTaskAssignee_Modify(taskTypeAssignee) {
    //Allow local editing an assignee on a individually selected task type.
    //console.log('taskTypeAssignee', taskTypeAssignee);

    //We also want to set the autocomplete data needed for the input box and later, the update request to server (if needed)
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlGUID = taskTypeAssignee.DefaultAssigneeGUID;
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlValue = taskTypeAssignee.DefaultAssigneeName;
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlDisplay = taskTypeAssignee.DefaultAssigneeName;
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlType = 'Employee';

    //Set the default SLA days prior to edit
    this.defaultTaskTypeSLAUpdate = taskTypeAssignee.SLADays;

    //Also set the initial autocomplete data to some random value. this is so that it will send a new autocomplete as soon as the user clicks on it.
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteRequest.Client_AutoCompleteLastSearchedValue = '{reset}'

    //Alternate assignee
    //We also want to set the autocomplete data needed for the input box and later, the update request to server (if needed)
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

    //Only fill if there is a non null, otherwise on the page we see '[object] object'
    if (!this.globalFunctions.isEmpty(taskTypeAssignee.AlternateAssigneeGUID)) {
      this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlGUID = taskTypeAssignee.AlternateAssigneeGUID;
      this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlValue = taskTypeAssignee.AlternateAssigneeName;
      this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlDisplay = taskTypeAssignee.AlternateAssigneeName;
    }
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlType = 'Employee';

    //Also set the initial autocomplete data to some random value. this is so that it will send a new autocomplete as soon as the user clicks on it.        
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteRequest.Client_AutoCompleteLastSearchedValue = '{reset}'

    //Here we want to indicate on this row that the input box should now appear.
    //Flick the edit switch for it on.
    taskTypeAssignee.AllowEditing = true;
    //And disallow edit buttons on others from appearing
    this.isTaskTypeAssigneeBeingEdited = true;
  }

  //This method is used when a edit request is cancelled
  public LenderConfigTaskAssignee_Cancel(taskTypeAssignee) {
    //Reset the autocomplete data
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlGUID = '';
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlValue = '';
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlDisplay = '';
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlType = 'Employee';

    //Reset the SLA Days
    taskTypeAssignee.SLADays = this.defaultTaskTypeSLAUpdate;

    //Also set the initial autocomplete data to some random value. this is so that it will send a new autocomplete as soon as the user clicks on it.
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteRequest.Client_AutoCompleteLastSearchedValue = '{cancel}'

    //Flick the edit switch for this row to off.
    taskTypeAssignee.AllowEditing = false;

    //And allow all edit buttons on others to appear
    this.isTaskTypeAssigneeBeingEdited = false;
  }

  //This one actually sends the request to the server to modify it, to save the new assignee for this Task Type
  public LenderConfigTaskAssignee_Edit(record) {

    //Validate that an assignee has been selected
    if (this.globalFunctions.isEmpty(this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlGUID)) {
      this.notifyService.Error_Show("Please select an Assignee", "Error - Missing Assignee");
      return;
    }

    //Validate that an assignee has been selected
    if (this.globalFunctions.isEmpty(this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlGUID)) {
      this.notifyService.Error_Show("Please select an Alternate Assignee", "Error - Missing Alternate Assignee");
      return;
    }

    //Validate that the values have been changed
    if (this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlGUID === record.DefaultAssigneeGUID && this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlGUID === record.AlternateAssigneeGUID && this.defaultTaskTypeSLAUpdate === record.SLADays) {
      this.notifyService.Error_Show("Please select a new value", "Error - Same values");
      return;
    }

    //Turn the global loading screen on
    this.clientDataStore.SetShowFullscreenLoading(true);

    //The GUID tells the server which record we want to target. use the local autocomplete model variable
    const apiRequest = { LenderConfigurationTaskTypeGUID: record.GUID, LenderConfigurationGUID: record.LenderConfigGUID, DefaultAssigneeGUID: this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlGUID, AlternateAssigneeGUID: this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlGUID, SLADays: record.SLADays };
    //console.log('LenderConfigTaskAssignee_Edit apiRequest', apiRequest);

    //Send the request to the server to fulfill
    this.apiService
      .APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.UpdateLenderConfigurationTaskAssignee], apiRequest)
      .subscribe((apiResponse) => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //Turn the global loading screen off
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }

        //Process the update on the client side. find the row in the variable, and replace it.
        //Get the response
        const updateLenderConfigTaskAssigneeResponse = JSON.parse(JSON.stringify(apiResponse));
        //console.log('updateLenderConfigTaskAssigneeResponse', updateLenderConfigTaskAssigneeResponse);
        const updatedRecord = updateLenderConfigTaskAssigneeResponse[0];

        //Unescape
        updatedRecord.DefaultTaskTypeName = this.globalFunctions.HTMLUnescape(updatedRecord.DefaultTaskTypeName);
        updatedRecord.DefaultAssigneeName = this.globalFunctions.HTMLUnescape(updatedRecord.DefaultAssigneeName);
        updatedRecord.AlternateAssigneeName = this.globalFunctions.HTMLUnescape(updatedRecord.AlternateAssigneeName);

        //Find the index of the matching record in the parent array
        const matchedRecord = this.lenderConfigTaskTypeAssignee.findIndex(x => x.GUID === updatedRecord.GUID);
        //console.log('matchedRecord', matchedRecord);

        //And use splice to remove and replace it in the parent array
        this.lenderConfigTaskTypeAssignee.splice(matchedRecord, 1, updatedRecord);

        //Notification is nice
        this.notifyService.Success_Show("Lender Config Task Type Record Updated", "Success");

        //Turn the global loading screen off
        this.clientDataStore.SetShowFullscreenLoading(false);

        //Flick the edit switch for this to off.
        record.AllowEditing = false;

        //And allow edit buttons on others from appearing again
        this.isTaskTypeAssigneeBeingEdited = false;
      });
  }

  //This one actually sends the request to the server to modify the alternate mode switch
  public LenderConfigTaskAssignee_AlternateModeSwitch(record) {

    //Turn the global loading screen on
    this.clientDataStore.SetShowFullscreenLoading(true);
    //console.log('record', record);

    //Deal with null, convert it to false
    let alternateMode = false;
    if (record.AlternateMode === null) {
      //console.log('alternatemode defaulted to False');
      alternateMode = false;
    }
    else {
      //console.log('alternatemode used record value');
      alternateMode = record.AlternateMode;
    }

    //console.log('alternateMode', alternateMode);
    //console.log('!alternateMode', !alternateMode);

    //Send the alternate mode in the opposite direction (!alternateMode), as we recieve on the click event its current value. We want to send the opposite value so that the server will switch it
    const apiRequest = { LenderConfigurationTaskTypeGUID: record.GUID, LenderConfigurationGUID: record.LenderConfigGUID, AlternateMode: alternateMode };
    //console.log('apiRequest', apiRequest);

    //Send the request to the server to fulfill
    this.apiService
      .APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.UpdateLenderConfigurationTaskAssigneeAlternateMode], apiRequest)
      .subscribe((apiResponse) => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //Something went wrong, flip the checkbox back
          if (record.AlternateMode == true) {
            record.AlternateMode = false;
          }
          else {
            record.AlternateMode = true;
          }

          //Turn the global loading screen off
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }

        //Process the update on the client side. find the row in the variable, and replace it.
        //Get the response
        //const response = JSON.parse(JSON.stringify(apiResponse));
        //console.log('updateLenderConfigTaskAssigneeResponse', updateLenderConfigTaskAssigneeResponse);
        //const updatedRecord = response[0];

        //Notification is nice
        this.notifyService.Success_Show("Alternate Mode Config Updated", "Success");

        //Turn the global loading screen off
        this.clientDataStore.SetShowFullscreenLoading(false);
      });
  }

  //This one actually sends the request to the server to delete an existing Task Type>Assignee record
  public LenderConfigTaskAssignee_Delete(record) {
    //Do a confirm screen for this
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to delete this <b>" + 'record' + "</b>?"
    //console.log('record', record);

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        //Turn the global loading screen on
        this.clientDataStore.SetShowFullscreenLoading(true);

        //The GUID tells the server which record we want to target
        const apiRequest = { LenderConfigurationTaskTypeGUID: record.GUID, LenderConfigurationGUID: record.LenderConfigGUID };

        //Send the request to the server to fulfill
        this.apiService
          .APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.DeleteLenderConfigurationTaskAssignee], apiRequest)
          .subscribe((response) => {
            if (this.globalFunctions.isEmpty(response)) {
              //Allow edit buttons on others from appearing again
              this.isTaskTypeAssigneeBeingEdited = false;
              //Turn the global loading screen off
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }

            //Process the delete on the client side. find the row in the variable, and remove it.
            //Find the index of the matching record in the parent array
            const matchedRecord = this.lenderConfigTaskTypeAssignee.findIndex(x => x.GUID === record.GUID);
            //console.log('matchedRecord', matchedRecord);

            //And use splice to remove it in the parent array
            this.lenderConfigTaskTypeAssignee.splice(matchedRecord, 1);

            //Notification is nice
            this.notifyService.Success_Show("Lender Config Task Type Record Deleted", "Success");

            //Turn the global loading screen off
            this.clientDataStore.SetShowFullscreenLoading(false);

            //Allow edit buttons on others from appearing again
            this.isTaskTypeAssigneeBeingEdited = false;
          });
      }
    });
  }

  //Filter control data based on some incoming input, client side only
  public ControlData_Filter(controlType = '', controlFilter = '') {
    let filteredControlData = [];
    //console.log('controlType', controlType);
    //console.log('controlFilter', controlFilter);

    if (this.globalFunctions.isEmpty(controlFilter)) {
      //Grabs Control data, no filters
      filteredControlData = this.clientDataStore.ControlData.filter(x => x.ControlType == controlType);
    }
    else {
      //Grabs Control data, but applies a custom filter. at the moment just the one (account enquiry)
      filteredControlData = this.clientDataStore.ControlData.filter(x => x.ControlType == controlType && this.clientDataStore.AccountEnquiryControlGUIDArray.includes(x.ControlGUID));
    }

    //console.log('filteredControlData', filteredControlData);
    return filteredControlData;
  }

  //May use this later to select the first field in the expanded section when creating a new task type assignee record
  public LenderConfigTaskAssignee_CreateSection_Expand() {
    //TODO
  }

  //Create a new lender config task type to assignee record on the server  
  public LenderConfigTaskAssignee_Create() {
    //Let's do some basic client side validation
    //Task type must be selected
    if (this.globalFunctions.isEmpty(this.defaultTaskType_AutoCompleteModel.ControlGUID)) {
      this.notifyService.Error_Show("Please select a task type", "Error - Missing Task Type");
      return;
    }

    //Assignee must be selected
    if (this.globalFunctions.isEmpty(this.defaultTaskTypeAssignee_AutoCompleteModel.ControlGUID)) {
      this.notifyService.Error_Show("Please select a valid Assignee", "Error - Missing Assignee");
      return;
    }

    //Alternate assignee must be selected
    if (this.globalFunctions.isEmpty(this.defaultTaskTypeAlternateAssignee_AutoCompleteModel.ControlGUID)) {
      this.notifyService.Error_Show("Please select a valid Alternate Assignee", "Error - Missing Alternate Assignee");
      return;
    }

    //SLA Days must be greater or equal to zero
    if (this.TaskTypeSLACreate < 0) {
      this.notifyService.Error_Show("Please select a valid positive SLA Days", "Error - Negative SLA Days");
      return;
    }

    //Track state of this request, so that we can show the spinner, if necessary.
    this.isUpdateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Let's construct the request and send it to the server. need to collect up all the fields we know about
    const apiRequest = { LenderConfigurationGUID: this.chosenLenderConfigClone.GUID, DefaultAssigneeGUID: this.defaultTaskTypeAssignee_AutoCompleteModel.ControlGUID, AlternateAssigneeGUID: this.defaultTaskTypeAlternateAssignee_AutoCompleteModel.ControlGUID, DefaultTaskTypeGUID: this.defaultTaskType_AutoCompleteModel.ControlGUID, SLADays: this.TaskTypeSLACreate };
    //console.log('apiRequest', apiRequest);

    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.CreateLenderConfigurationTaskAssignee], apiRequest)
      .subscribe(apiResponse => {
        //console.log("apiResponse", apiResponse);
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isUpdateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Insert this new record into the existing assignee/task types datagrid.
          const createLenderConfigResponse = JSON.parse(JSON.stringify(apiResponse));

          //Get the first entry in the array (we only expect one record to be returned)
          const newRecord = createLenderConfigResponse[0];
          //console.log('newRecord', newRecord);

          //Unescape
          newRecord.DefaultAssigneeName = this.globalFunctions.HTMLUnescape(newRecord.DefaultAssigneeName);
          newRecord.DefaultTaskTypeName = this.globalFunctions.HTMLUnescape(newRecord.DefaultTaskTypeName);
          this.lenderConfigTaskTypeAssignee.push(newRecord);

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);

          //Reinit this page (parameter: false to skip refreshing the task notifier everytime we refresh other lender config data)
          this.Page_Init(false);

          //A notification would be nice
          this.notifyService.Success_Show("Lender Config Task Type Created", "Success");
          this.isUpdateInProgress = false;

          //This collapses the create section
          this.TemplateID_Toggle("LenderConfigTaskAssignee_CreateSection");
        }
      });
  }

  //Update a lender config on the server
  public LenderConfig_Update() {

    let updatedColumns = "";

    //Let's do some basic client side validation
    //Don't allow really large SLA
    if (this.chosenLenderConfigClone.DefaultSLADays > 1000 || this.chosenLenderConfigClone.DefaultSLADays < -1000) {
      this.notifyService.Error_Show("This value is invalid - it must be between -1000 and 1000", "Error - Default SLA");
      return;
    }

    //SLA should be numeric
    if (!this.globalFunctions.IsNumeric(this.chosenLenderConfigClone.DefaultSLADays, true)) {
      this.notifyService.Error_Show("This value is not numeric", "Error - Default SLA");
      return;
    }

    //Max Lender Admin Count
    if (!this.globalFunctions.IsNumeric(this.chosenLenderConfigClone.MaxLenderAdminCount, true)) {
      this.notifyService.Error_Show("This value is not numeric", "Error - Max Number of Lender Administrator");
      return;
    }

    //Check if the LenderIndustryType is empty
    if (this.globalFunctions.isEmpty(this.chosenLenderConfigClone.LenderIndustryType)) {
      this.notifyService.Error_Show("Please choose a value", "Error - Lender Industry Type");
      return;
    }

    //Track state of the update lender config request, so that we can show the spinner, if necessary.
    this.isUpdateInProgress = true;


    //Check and add the modified columns
    if (this.defaultAssignee_AutoCompleteModel.ControlGUID != this.parentLenderConfigs.chosenLenderConfig.DefaultAssigneeGUID) {
      updatedColumns = updatedColumns + "DefaultAssigneeGUID,";
    }

    if (this.chosenLenderConfigClone.DefaultSLADays != this.parentLenderConfigs.chosenLenderConfig.DefaultSLADays) {
      updatedColumns = updatedColumns + "DefaultSLADays,";
    }

    if (this.chosenLenderConfigClone.LenderIndustryType != this.parentLenderConfigs.chosenLenderConfig.LenderIndustryType) {
      updatedColumns = updatedColumns + "LenderIndustryType,";
    }

    if (this.chosenLenderConfigClone.MaxLenderAdminCount != this.parentLenderConfigs.chosenLenderConfig.MaxLenderAdminCount) {
      updatedColumns = updatedColumns + "MaxLenderAdminCount,";
    }

    if (this.chosenLenderConfigClone.EmailReplyTo != this.parentLenderConfigs.chosenLenderConfig.EmailReplyTo) {
      updatedColumns = updatedColumns + "EmailReplyTo,";
    }
    if (this.chosenLenderConfigClone.EmailCCTo != this.parentLenderConfigs.chosenLenderConfig.EmailCCTo) {
      updatedColumns = updatedColumns + "EmailCCTo,";
    }

    if (this.chosenLenderConfigClone.EmailSubject_CS_Reminder != this.parentLenderConfigs.chosenLenderConfig.EmailSubject_CS_Reminder) {
      updatedColumns = updatedColumns + "EmailSubject_CS_Reminder,";
    }

    //Get the Reminder email template
    this.chosenLenderConfigClone.EmailTemplate_CS_Reminder = this.QuillCSReminder.getSemanticHTML();

    if (this.chosenLenderConfigClone.EmailTemplate_CS_Reminder != this.parentLenderConfigs.chosenLenderConfig.EmailTemplate_CS_Reminder) {
      updatedColumns = updatedColumns + "EmailTemplate_CS_Reminder,";
    }

    if (this.chosenLenderConfigClone.EmailSubject_CS_Followup != this.parentLenderConfigs.chosenLenderConfig.EmailSubject_CS_Followup) {
      updatedColumns = updatedColumns + "EmailSubject_CS_Followup,";
    }

    //Get the Followup email template
    this.chosenLenderConfigClone.EmailTemplate_CS_Followup = this.QuillCSFollowup.getSemanticHTML();

    if (this.chosenLenderConfigClone.EmailTemplate_CS_Followup != this.parentLenderConfigs.chosenLenderConfig.EmailTemplate_CS_Followup) {
      updatedColumns = updatedColumns + "EmailTemplate_CS_Followup,";
    }

    if (this.chosenLenderConfigClone.FromEmailDisplay != this.parentLenderConfigs.chosenLenderConfig.FromEmailDisplay) {
      updatedColumns = updatedColumns + "FromEmailDisplay,";
    }

    //Strip out the last comma delimiter
    if (updatedColumns.indexOf(',') > 0) {
      updatedColumns = updatedColumns.substring(0, updatedColumns.length - 1);
    }

    //Let's construct the request and send it to the server. need to collect up all the fields we know about
    const apiRequest = { GUID: this.chosenLenderConfigClone.GUID, LenderGUID: this.chosenLenderConfigClone.LenderGUID, DefaultAssigneeGUID: this.defaultAssignee_AutoCompleteModel.ControlGUID, DefaultSLADays: this.chosenLenderConfigClone.DefaultSLADays, LenderIndustryType: this.chosenLenderConfigClone.LenderIndustryType, MaxLenderAdminCount: this.chosenLenderConfigClone.MaxLenderAdminCount, EmailReplyTo: this.chosenLenderConfigClone.EmailReplyTo, EmailCCTo: this.chosenLenderConfigClone.EmailCCTo, EmailSubject_CS_Reminder: this.chosenLenderConfigClone.EmailSubject_CS_Reminder, EmailTemplate_CS_Reminder: this.chosenLenderConfigClone.EmailTemplate_CS_Reminder, EmailSubject_CS_Followup: this.chosenLenderConfigClone.EmailSubject_CS_Followup, EmailTemplate_CS_Followup: this.chosenLenderConfigClone.EmailTemplate_CS_Followup, FromEmailDisplay: this.chosenLenderConfigClone.FromEmailDisplay, UpdatedColumns: updatedColumns };
    //console.log('apiRequest', apiRequest);

    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.UpdateLenderConfiguration], apiRequest)
      .subscribe(apiResponse => {
        //console.log("apiResponse", apiResponse);
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isUpdateInProgress = false;
          return;
        }
        else {
          //Deserialize response
          const updateLenderConfigResponse = JSON.parse(JSON.stringify(apiResponse));

          //Get the first entry of the LenderConfigList property from the response
          const updatedRecord = updateLenderConfigResponse.LenderConfigList[0];

          //HTML Unescaping
          this.globalFunctions.LenderConfig_Unescape(updatedRecord);

          //Update the chosen ones with this
          this.parentLenderConfigs.chosenLenderConfig = JSON.parse(JSON.stringify(updatedRecord));
          this.chosenLenderConfigClone = JSON.parse(JSON.stringify(updatedRecord));

          //Reinit this page (parameter: false to skip refreshing the task notifier everytime we refresh other lender config data)
          this.Page_Init(false);

          //A notification would be nice
          this.notifyService.Success_Show("Lender Config Updated", "Success");
          this.isUpdateInProgress = false;

          //Hide editing again
          this.editEnabled = false;

          //Sync the editing of Quill editor
          this.QuillEditorEdit_Toggle();
        }
      });

  }

  //Delete a lender config on the server
  public LenderConfig_Delete() {

    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to delete this Lender Configuration: <b>" + this.chosenLenderConfigClone.LenderName + "</b>?<br>"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Track state of the update lender config request, so that we can show the spinner, if necessary.
        this.isUpdateInProgress = true;

        //Use full screen loading to prevent user from doing anything silly.
        this.clientDataStore.SetShowFullscreenLoading(true);

        //Let's construct the request and send it to the server. need to collect up all the fields we know about
        const apiRequest = { GUID: this.chosenLenderConfigClone.GUID, LenderGUID: this.chosenLenderConfigClone.LenderGUID };
        //console.log('apiRequest', apiRequest);

        this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.DeleteLenderConfiguration], apiRequest)
          .subscribe(apiResponse => {
            //console.log("apiResponse", apiResponse);
            if (this.globalFunctions.isEmpty(apiResponse)) {
              this.isUpdateInProgress = false;
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {
              //var updateLenderConfigResponse = JSON.parse(JSON.stringify(apiResponse));

              //Find the index of the matching record in the parent array
              const matchedRecord = this.parentLenderConfigs.lenderConfigList.findIndex(x => x.GUID === this.chosenLenderConfigClone.GUID);
              //console.log('matchedRecord', matchedRecord);

              //And use splice to remove and replace it in the parent array
              this.parentLenderConfigs.lenderConfigList.splice(matchedRecord, 1);

              //A notification would be nice
              this.notifyService.Success_Show("Lender Config Deleted", "Success");
              this.isUpdateInProgress = false;

              //Hide editing again
              this.editEnabled = false;

              //Sync the editing of Quill editor
              this.QuillEditorEdit_Toggle();

              //Remove loading screen
              this.clientDataStore.SetShowFullscreenLoading(false);

              //Close this modal
              this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);
            }
          });
      }
    }
    );
  }

  //Used by paginator to handle page updates
  public Paginator_OnPageChange(page: number, currentPage, doScrollToBottom = true) {
    currentPage.currentPage = page;

    //Dont scroll to top, bad UI experience. scroll to bottom instead
    if (doScrollToBottom) {
      this.globalFunctions.delay(50).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue). This is currently specific to Client type Autocomplete. Will look to make it more generic, where possible (TODO).
  public AutoComplete_Client_ApplyFilter(autocompleteRecord, requestType: string, controlValue: string, fieldType: string, seed = false) {
    //console.log('AutoComplete_Client_ApplyFilter controlValue', controlValue);
    //console.log('AutoComplete_Client_ApplyFilter autocompleteRecord', autocompleteRecord);

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Client_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (this.globalFunctions.isEmpty(controlValue) && seed && autocompleteRecord.Client_AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      autocompleteRecord.Client_AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
      //console.log('seeding');
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteLastSearchedValue)) {
      if ((autocompleteRecord.Client_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {

        //Request new data and update the current value so we don't request it again.
        autocompleteRecord.Client_AutoCompleteLastSearchedValue = controlValue;

        //We want this to have a slight delay on key press. so that we don't trigger a server request too quickly as keys are being pressed.
        this.globalFunctions.delay(350).then(() => {

          //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
          this.lastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();

          //Make a copy of it for sending to the update request
          const copyOfGUID = JSON.parse(JSON.stringify(this.lastAutoCompleteRequestGUID));

          //Request a new set of values from the server
          this.AutoComplete_RequestData(requestType, autocompleteRecord.Client_AutoCompleteControlTypeName, controlValue, autocompleteRecord.Client_AutoCompleteControlData, fieldType, copyOfGUID);
        });
      }
    }

    //Don't place an else here, or seeding will always ask server for new data.
    //The only minor issue is that you need to press or click twice when backspacing to a blank string from the last character. not sure why it behaves this way. If you don't, it won't trigger the seed

    //Check if the control data is non null
    if (!this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteControlData)) {

      //Just return the array, even though its not synchronous. the subscription will come later and update it for us.
      return autocompleteRecord.Client_AutoCompleteControlData;
    }
  }

  //For Material to access global functions, we need to use a get and arrow syntax to bring context along.
  public get AutoComplete_GetPrettyName() {
    return (value) => this.globalFunctions.AutoComplete_GetPrettyName(value);
  }

  //Saves the autocomplete value locally on a click, for later use. provide a local class property to bind to, via html
  public AutoComplete_SaveSelectedControlRecord(value, localBind) {
    //We are mutating the passed in object by reference. Disabling the eslint warning for unused variables on this one
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    localBind = this.globalFunctions.AutoComplete_SaveSelectedControlRecord(value, localBind);

    //TESTING pass to the global method that mutates its properties for us, without modifying its reference. it didnt seem to work. leave it.
    //this.globalFunctions.AutoComplete_SaveSelectedControlRecord(value, localBind);
  }

  public LenderConfig_EnableEditing() {
    this.editEnabled = true;

    //Sync the editing of Quill editor
    this.QuillEditorEdit_Toggle();

    //Focus on the first item in the form
    this.globalFunctions.delay(1).then(() => { this.inputDefaultAssignee.nativeElement.focus(); });
  }

  public LenderConfig_DiscardChanges() {

    //Disable input fields
    this.editEnabled = false;

    //Sync the editing of Quill editor
    this.QuillEditorEdit_Toggle();

    this.isUpdateInProgress = false;

    //A notification would be nice
    this.notifyService.Info_Show("Changes are discarded", "Cancelled");

    //Reset back to the value from the parent (parameter: false to skip refreshing the task notifier everytime we refresh other lender config data)
    this.Page_Init(false);
  }

  public Page_Init(initialiseTaskNotifier = true) {

    //Reset the local copy of the value from the parent
    this.chosenLenderConfigClone = JSON.parse(JSON.stringify(this.parentLenderConfigs.chosenLenderConfig));

    //We need to reset the local autocomplete value as well so that the html template sees it
    this.defaultAssignee_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.defaultAssignee_AutoCompleteModel.ControlGUID = this.globalFunctions.Deep_Clone(this.chosenLenderConfigClone.DefaultAssigneeGUID);
    this.defaultAssignee_AutoCompleteModel.ControlValue = this.globalFunctions.Deep_Clone(this.chosenLenderConfigClone.DefaultAssigneeName);
    this.defaultAssignee_AutoCompleteModel.ControlDisplay = this.globalFunctions.Deep_Clone(this.chosenLenderConfigClone.DefaultAssigneeName);
    this.defaultAssignee_AutoCompleteModel.ControlType = 'Employee';

    this.defaultTaskTypeAssignee_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.defaultTaskTypeAssignee_AutoCompleteModel.ControlGUID = '';
    this.defaultTaskTypeAssignee_AutoCompleteModel.ControlValue = '';
    this.defaultTaskTypeAssignee_AutoCompleteModel.ControlDisplay = '';
    this.defaultTaskTypeAssignee_AutoCompleteModel.ControlType = 'Employee';

    //Setting the default for the edit component. this needs to be updated when a edit button is clicked on a row, and set to that value
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlGUID = '';
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlValue = '';
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlDisplay = '';
    this.defaultTaskTypeAssigneeUpdate_AutoCompleteModel.ControlType = 'Employee';

    this.defaultTaskTypeAlternateAssignee_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.defaultTaskTypeAlternateAssignee_AutoCompleteModel.ControlGUID = '';
    this.defaultTaskTypeAlternateAssignee_AutoCompleteModel.ControlValue = '';
    this.defaultTaskTypeAlternateAssignee_AutoCompleteModel.ControlDisplay = '';
    this.defaultTaskTypeAlternateAssignee_AutoCompleteModel.ControlType = 'Employee';

    //Setting the default for the edit component. this needs to be updated when a edit button is clicked on a row, and set to that value
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlGUID = '';
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlValue = '';
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlDisplay = '';
    this.defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel.ControlType = 'Employee';

    //Set the default for the task type auto complete
    this.defaultTaskType_AutoCompleteModel = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "Task Type" };

    //Gen a new autocomplete request guid so that it will get requested on the next run
    this.lastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();

    //And reset the last searched value
    this.defaultAssignee_AutoCompleteRequest = {
      Client_AutoCompleteLastSearchedValue: this.globalFunctions.Deep_Clone(this.chosenLenderConfigClone.DefaultAssigneeName),
      Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
    };

    //Reset the last searched value
    this.defaultTaskType_AutoCompleteRequest = {
      Client_AutoCompleteLastSearchedValue: '',
      Client_AutoCompleteControlTypeName: 'Task Type', Client_AutoCompleteControlData: []
    };


    //Initial page load items go here
    if (this.InitialPageLoad) {

      //Set the default active nav tab
      this.CurrentNavItem = 'BTN_StandardConfig';
    }

    //Set the email variables
    this.EmailVariables = this.clientDataStore.EmailVariables;

    //Hide the display of email variables
    this.ShowReminderVariables = false;
    this.ShowFollowupVariables = false;

    //Set the Lender Configuration GUID in Task Notifier
    this.TaskNotifier.LenderConfigurationGUID = this.chosenLenderConfigClone.GUID;
    this.TaskNotifier.LenderRoles = this.LenderRoles;

    //Load default Lender Config Task Notifier only on the initial load
    if (initialiseTaskNotifier) {

      //Initialise task notifier
      this.TaskNotifier.Page_Init();

      //Retrieve task notifier data from the server
      this.TaskNotifier.Data_Retrieve();
    }
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, fieldType: string, autoCompleteRequestGUID: string): void {
    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = this.globalFunctions.AutoComplete_PrepareAPIRequest(requestType, controlType, requestValue, fieldType);

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, apiRequest.AutoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {

            //This helps with flow, so we only resolve the last request.
            if (autoCompleteRequestGUID === this.lastAutoCompleteRequestGUID) {

              //console.log('processing response from server');
              this.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }

  //Processes the autocomplete data returned by the server and pushes it into the supplied array
  private AutoComplete_ProcessResponse(apiResponse, controlArray): void {
    this.globalFunctions.AutoComplete_ProcessResponse(apiResponse, controlArray);
  }

  //Get default control data
  private DefaultAutoComplete_Get() {
    return { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
  }
}

//Task notifier class
export class TaskNotifier {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    private clientDataStore: ClientDataStore,
    private apiService: ApiService) {
  }

  public Data = [];
  public RowCache = null;
  public Headers = [];
  public TextSize = 40;
  public EditMode = false;
  public InsertMode = false;
  public TaskTypeOptions = [];
  public RoleOptions = [];
  public IsProcessing = false;
  public LenderConfigurationGUID;
  public RTEButtonText = "";
  public LenderRoles;

  //DisableTooltip
  public readonly SuffixDisableTooltip = "_DisableTooltip";

  //Multiselect
  public readonly Multiselect = "_Multiselect";

  //Tooltip
  public readonly Tooltip = "_Tooltip";

  //Display text
  public readonly SuffixDisplay = "_Display";

  //Edit flag
  public readonly SuffixEditFlag = "EditFlag";

  //Disable Tooltip text
  public readonly DisableTooltipText = "Click to disable";

  //Enable Tooltip text
  public readonly EnableTooltipText = "Click to enable";

  //Enabled Tooltip text
  readonly EnabledToolTipText = "Enabled";

  //Disabled Tooltip text
  readonly DisabledToolTipText = "Disabled";

  //Init
  public Page_Init(): void {

    //Reset headers array
    this.Headers = [];

    //Fill an array for Notifier Headers
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'Task Type', Name: 'TaskType', InputType: 'Multiselect', Tooltip: 'The Task Notifier will trigger when a journal note is created on the selected Task Types', TooltipDisabled: false }));
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'From Display Name', Name: 'FromDisplayName', InputType: 'String', Tooltip: 'This is how the sender name will be displayed in external email clients. Please note that in your internal MS Outlook it will display as "xChange Service"', TooltipDisabled: false }));
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'Reply To', Name: 'ReplyTo', InputType: 'String' }));
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'Subject', Name: 'SubjectPrefix', InputType: 'String', Tooltip: 'Subject may contain template variables in the format {@AccountID}. Please refer to the email template variables', TooltipDisabled: false }));
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'Role - Recipients', Name: 'Role', InputType: 'Multiselect', Tooltip: 'All members of the selected roles will receive an email notification', TooltipDisabled: false }));
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'Email - Recipients', Name: 'Recipients', InputType: 'String', Tooltip: 'All recipients here will also be emailed, please use semicolon (;) to separate multiple entries', TooltipDisabled: false }));
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'Email Template', Name: 'EmailTemplate', InputType: 'Button', Tooltip: 'This is the content of the email that users will see. It may contain multiple template variables, please use the Rich Text Editor to configure the template', TooltipDisabled: false }));
    this.Headers.push(new TaskNotifierModels.Header({ DisplayName: 'Action', Name: 'Action', InputType: 'Button' }));

    //Initialise tasktype and role options for primeng multiselect
    this.MultiSelectOptions_Init();
  }

  //Initialise task notifier data, targetIndex > 0 means to replace a target row
  public TaskNotifier_Init(taskNotifierRows, targetIndex = -1): void {

    if (!this.globalFunctions.isEmpty(taskNotifierRows)) {

      let spliceAllowed = false;

      //Allow splicing if targetIndex is provided and the list only contains one record
      if (targetIndex > -1 && taskNotifierRows.length === 1) {
        spliceAllowed = true;
      }

      //Initialise Task Notifier Data Array
      taskNotifierRows.forEach(taskNotifierData => {

        const targetTaskTypes = [];
        taskNotifierData.TaskTypes.forEach(taskType => {
          targetTaskTypes.push({ ControlDisplay: taskType.ControlDisplay, ControlGUID: taskType.ControlGUID });
        });

        const targetRoles = [];
        taskNotifierData.RecipientRoles.forEach(role => {
          targetRoles.push({ ControlDisplay: role.ControlDisplay, ControlGUID: role.ControlGUID });
        });

        //Construct a task notifier data
        const newTaskNotifierData = new TaskNotifierModels.Data({ GUID: taskNotifierData.GUID, TaskType_Multiselect: targetTaskTypes, Role_Multiselect: targetRoles, FromDisplayName: this.globalFunctions.HTMLUnescape(taskNotifierData.FromDisplayName), ReplyTo: taskNotifierData.EmailReplyTo, Recipients: this.globalFunctions.HTMLUnescape(taskNotifierData.EmailTo), SubjectPrefix: this.globalFunctions.HTMLUnescape(taskNotifierData.EmailSubject), Enabled: taskNotifierData.Enabled, BodyTemplate: taskNotifierData.EmailTemplate, [this.SuffixEditFlag]: false });

        //Update the task notifier data
        if (spliceAllowed) {

          //Replace the target row
          this.Data.splice(targetIndex, 1, newTaskNotifierData);
        }
        else {

          //Push the data to the end
          this.Data.push(newTaskNotifierData);
        }
      });
    }

    //Sync the UI
    this.ClientData_Sync(this.Data, this.Headers, this.TextSize);
  }

  //Initialise multiselect options
  public MultiSelectOptions_Init(): void {

    //Reset multiselect options
    this.TaskTypeOptions = [];
    this.RoleOptions = [];

    //Due to Primeng related bug,we need to create a copy of Display column first, IMPORTANT - this must be done after primeng has been initialised
    this.ControlData_Filter('Task Type', 'Account Enquiry').forEach(item => {
      this.TaskTypeOptions.push({ ControlDisplay: item.ControlDisplay, ControlGUID: item.ControlGUID });
    });

    //Init role options
    if (!this.globalFunctions.isEmpty(this.LenderRoles)) {
      this.LenderRoles.forEach(role => {
        this.RoleOptions.push({ ControlDisplay: role.ControlDisplay, ControlGUID: role.ControlGUID });
      });
    }
  }

  //Get column CSS based on column type
  public ColumnCSS_Get(header): string {

    let cssString = "";

    //Alignment logic
    if (header.InputType === "String" || header.InputType === "RichTextEditor" || header.InputType === "Multiselect") {
      cssString += " justify-content-start";
    }
    else if (header.Name === "Action") {
      cssString += " justify-content-end";
    }
    else {
      cssString += " justify-content-center";
    }
    return cssString;
  }

  //Get child column CSS based on column type
  public ColumnCSSChild_Get(header): string {

    let cssString = "col-12";

    if (header.Name === "TaskType") {
      cssString = "col-10";
    }

    return cssString;
  }

  //Disable create new button
  public CreateCSS_Disable(): string {

    if (this.EditMode) {
      return "taskNotifierAnchor_Disable";
    }
    return "";
  }

  //Get CSS class for string columns
  public StringColCSS_Get(item): string {

    //Check if in read only mode
    if (!item[this.SuffixEditFlag]) {

      return "glb_inactiveInputBox";
    }
    else {
      return "";
    }
  }

  //Create task notifier
  public Row_Create(): void {

    //Enable Insert and Edit mode as we are sharing the UI 
    this.InsertMode = true;
    this.EditMode = true;

    //Add a new placeholder row
    this.Data.unshift({ TaskType: '', Role: '', FromDisplayName: '', ReplyTo: '', Recipients: '', CC: '', SubjectPrefix: '', BodyTemplate: '', Enabled: true, [this.SuffixEditFlag]: true });

    //Resync the display due to the new row inserted
    this.ClientData_Sync(this.Data, this.Headers, this.TextSize);
  }

  //Edit button click
  public Row_Edit(item): void {

    this.RowEdit_Toggle(item, true);
  }

  //Launch up email template modal
  public RowEmail_View(item): void {

    //Email template modal
    const richTextEditorComponent = this.globalFunctions.FeatureModal_Launch(RichTextEditor, this.globalFunctions.GetFeatureModalConfig('50%'), this.dialog, "Rich Text Editor", 0, true, false);

    //Pass the relevent parent data to the child
    richTextEditorComponent.DialogRef.componentInstance.EditEnabled = this.EditMode;
    richTextEditorComponent.DialogRef.componentInstance.Content = item.BodyTemplate;
    richTextEditorComponent.DialogRef.componentInstance.ParentComponent = this;
    richTextEditorComponent.DialogRef.componentInstance.PageHeader = "Task Notifier Email Template";
  }

  //Sync email template from the child
  public RichTextContent_Sync(content) {

    //Identify the row being edited
    const matchingRow = this.Data.filter(x => x[this.SuffixEditFlag] === true)[0];

    if (!this.globalFunctions.isEmpty(matchingRow)) {
      matchingRow.BodyTemplate = content;
    }
  }

  //This toggles edit flag on row and the class
  private RowEdit_Toggle(item, cacheFlag = false): void {

    //Cache the item if requested
    if (cacheFlag) {
      this.RowCache = JSON.parse(JSON.stringify(item));
    }

    //Flip the edit row and class level flags 
    item[this.SuffixEditFlag] = !item[this.SuffixEditFlag];
    this.EditMode = !this.EditMode;

    //Call method that implements display logic
    this.ClientData_Sync(this.Data, this.Headers, this.TextSize);
  }

  //Save task notifier
  public Row_Save(item, itemIndex): void {

    if (this.InsertMode) {

      //Create Task Notifier
      this.TaskNotifier_Create(item);
    }
    else {

      //Update Task Notifier
      this.TaskNotifier_Update(item, itemIndex);
    }
  }

  //Delete task notifier
  public Row_Delete(row): void {

    //Check if in edit mode
    if (this.EditMode) {

      //Display a confirmer message
      const confirmDialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);
      confirmDialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to delete this Task Notifier?";

      confirmDialogRef.DialogRef.afterClosed().subscribe(result => {
        if (result === true) {

          //Turn the global loading screen on
          this.clientDataStore.SetShowFullscreenLoading(true);

          //The GUID tells the server which record we want to target
          const apiRequest = { GUID: row.GUID, LenderConfigurationGUID: this.LenderConfigurationGUID };

          //Send the request to the server to fulfill
          this.apiService
            .APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.DeleteTaskNotifier], apiRequest)
            .subscribe((response) => {
              if (this.globalFunctions.isEmpty(response)) {

                //Turn the global loading screen off
                this.clientDataStore.SetShowFullscreenLoading(false);

                return;
              }

              //Process the delete on the client side. find the row in the variable, and remove it.
              //Find the index of the matching record in the parent array
              const matchedRecord = this.Data.findIndex(x => x.GUID === row.GUID);
              //console.log('matchedRecord', matchedRecord);

              //And use splice to remove it in the parent array
              this.Data.splice(matchedRecord, 1);

              //Notification is nice
              this.notifyService.Success_Show("Task Notifier Record Deleted", "Success");

              //Turn the global loading screen off
              this.clientDataStore.SetShowFullscreenLoading(false);

              //Turn edit mode off - class level
              this.EditMode = false;

              //Call method that implements display logic due to email template button text not being refreshed
              this.ClientData_Sync(this.Data, this.Headers, this.TextSize);
            });
        }
      });
    }
  }

  //Cancel editing
  public RowEditCancel_Click(index): void {

    const rowClone = JSON.parse(JSON.stringify(this.Data[index]));

    //The row edit flag was cached before edit mode was applied, so lets set it to false
    rowClone[this.SuffixEditFlag] = false;

    //Check if there have been any changes, ignore confirmer flow if no changes
    if (JSON.stringify(rowClone) === JSON.stringify(this.RowCache) || this.globalFunctions.isEmpty(this.RowCache)) {

      //Cancel the row edit
      this.RowEdit_Cancel(index);
    }
    else {

      let emailTemplateChangeMsg = "";

      //Check if the email template has been modified
      if (rowClone.BodyTemplate !== this.RowCache.BodyTemplate) {
        emailTemplateChangeMsg = " (including Email Template)";
      }

      //Display a confirmer message
      const confirmDialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);
      confirmDialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to discard your changes" + emailTemplateChangeMsg + "?";

      confirmDialogRef.DialogRef.afterClosed().subscribe(result => {
        if (result === true) {

          //Cancel the row edit
          this.RowEdit_Cancel(index);
        }
      });
    }
  }

  //Retrieve task notifier configurations
  public Data_Retrieve() {

    //Construct the request
    const apiRequest = { LenderConfigurationGUID: this.LenderConfigurationGUID };

    //Initialise the data array before pushing the task notifier data from the server
    this.Data.length = 0;

    this.IsProcessing = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.GetLenderConfigurationTaskNotifier], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsProcessing = false;
          return;
        }
        else {

          //Get the response
          const taskNotifierData = JSON.parse(JSON.stringify(apiResponse));

          //TODO: Process the response
          //Loop through and unescape all required columns
          // getLenderConfigTaskNotifierResponse.forEach(element => {
          //   element.LenderName = this.globalFunctions.HTMLUnescape(element.LenderName);
          //   element.DefaultTaskTypeName = this.globalFunctions.HTMLUnescape(element.DefaultTaskTypeName);
          // });

          //Initialise the task notifier class data
          this.TaskNotifier_Init(taskNotifierData);

          //TODO: And sort it if required
          //this.lenderConfigTaskTypeAssignee.sort((a, b) => a.DefaultTaskTypeName.localeCompare(b.DefaultTaskTypeName));

          this.IsProcessing = false;
        }
      });
  }

  //Cancel the row edit
  private RowEdit_Cancel(index): void {

    //Disable edit mode
    this.EditMode = false;

    //Revert the state of the item back to the cached version
    //And use splice to remove and replace it in the parent array
    if (this.InsertMode) {
      //Remove the newly inserted row from the grid
      this.Data.splice(0, 1);
    }
    else {
      //Restore from cache
      this.Data.splice(index, 1, JSON.parse(JSON.stringify(this.RowCache)));
    }

    //Disable insert mode
    this.InsertMode = false;

    //Display an info toast
    this.notifyService.Info_Show("Changes have been discarded", "Cancelled");

    //Call method that implements display logic
    this.ClientData_Sync(this.Data, this.Headers, this.TextSize);
  }

  //Server side update for the Enabled flag 
  public RowEnabled_Toggle(item): void {

    //Set tooltip text based on checkbox state
    this.RowEnabledTooltip_Sync(item);

    //TODO: further server side code
  }

  //Sync tooltip based on enabled checkbox state
  private RowEnabledTooltip_Sync(item): void {

    if (item.Enabled === true) {
      item.TooltipText = this.DisableTooltipText;

      //If edit mode is not enabled, show the actual state: Enabled
      if (!item[this.SuffixEditFlag]) {
        item.TooltipText = this.EnabledToolTipText;
      }
    }
    else {
      item.TooltipText = this.EnableTooltipText;

      //If edit mode is not enabled, show the actual state: Disabled
      if (!item[this.SuffixEditFlag]) {
        item.TooltipText = this.DisabledToolTipText;
      }
    }
  }

  //Filter control data based on some incoming input, client side only
  public ControlData_Filter(controlType = '', controlFilter = ''): ControlData[] {

    let filteredControlData = [];

    if (this.globalFunctions.isEmpty(controlFilter)) {
      //Grabs Control data, no filters
      filteredControlData = this.clientDataStore.ControlData.filter(x => x.ControlType == controlType);
    }
    else {
      //Grabs Control data, but applies a custom filter. at the moment just the one (account enquiry)
      filteredControlData = this.clientDataStore.ControlData.filter(x => x.ControlType == controlType && this.clientDataStore.AccountEnquiryControlGUIDArray.includes(x.ControlGUID));
    }

    return filteredControlData;
  }

  //Group selected items at the top
  public RowMultiselect_Group(options, selectedItems): void {

    //Check for empty 
    if (!this.globalFunctions.isEmpty(options) && options.length > 0 && !this.globalFunctions.isEmpty(selectedItems) && selectedItems.length > 0) {

      //Loop through all the selected items and add those on the top of options
      selectedItems.forEach(item => {

        const selectedOption = options.filter(x => x.ControlGUID === item.ControlGUID)[0];

        if (!this.globalFunctions.isEmpty(selectedOption)) {

          //Remove the selected item from the list
          options.splice(options.indexOf(selectedOption), 1);

          //Now add the same selected item on the top 
          options.unshift(selectedOption);
        }
      })
    }
  }

  //Update a Task Notifier on the server
  public TaskNotifier_Update(row, rowIndex) {

    //Compare cached row and current row, if no diffs then don't do anything
    if (JSON.stringify(row) === JSON.stringify(this.RowCache)) {

      this.notifyService.Warning_Show("No changes have been detected", "Warning");
      return;
    }

    //Check if no task types then don't send the request to server
    if (this.globalFunctions.isEmpty(row.TaskType_Multiselect)) {

      this.notifyService.Warning_Show("Please select at least one Task Type", "Warning");
      return;
    }

    //Pass this array to server in a new request class property
    let addedTaskTypeItems = "";
    let removedTaskTypeItems = "";
    const multiSelectItems = [];

    //Task Type Multiselect, identify all changed items including unselected
    if (JSON.stringify(row.TaskType_Multiselect) !== JSON.stringify(this.RowCache.TaskType_Multiselect)) {

      //Get comma separated string containing newly added items
      addedTaskTypeItems = this.Array_Merge(row.TaskType_Multiselect, this.RowCache.TaskType_Multiselect, "ControlGUID", "ControlGUID");

      //Get comma separated string containing removed items
      removedTaskTypeItems = this.Array_Merge(this.RowCache.TaskType_Multiselect, row.TaskType_Multiselect, "ControlGUID", "ControlGUID");
    }

    multiSelectItems.push(new TaskNotifierModels.MultiSelectData({ Name: 'TaskType', Direction: 'Add', Values: addedTaskTypeItems }));
    multiSelectItems.push(new TaskNotifierModels.MultiSelectData({ Name: 'TaskType', Direction: 'Remove', Values: removedTaskTypeItems }));

    //TODO: Roles Multiselect
    //Pass this array to server in a new request class property
    let addedRoleItems = "";
    let removedRoleItems = "";

    //Role Multiselect, identify all changed items including unselected
    if (JSON.stringify(row.Role_Multiselect) !== JSON.stringify(this.RowCache.Role_Multiselect)) {

      //Get comma separated string containing newly added items
      addedRoleItems = this.Array_Merge(row.Role_Multiselect, this.RowCache.Role_Multiselect, "ControlGUID", "ControlGUID");

      //Get comma separated string containing removed items
      removedRoleItems = this.Array_Merge(this.RowCache.Role_Multiselect, row.Role_Multiselect, "ControlGUID", "ControlGUID");
    }

    multiSelectItems.push(new TaskNotifierModels.MultiSelectData({ Name: 'Role', Direction: 'Add', Values: addedRoleItems }));
    multiSelectItems.push(new TaskNotifierModels.MultiSelectData({ Name: 'Role', Direction: 'Remove', Values: removedRoleItems }));

    //Track updated columns in an object
    const updatedColumnsRef = { UpdatedColumns: "" };

    //Check if Task Type has been added or removed
    if (!this.globalFunctions.isEmpty(addedTaskTypeItems) || !this.globalFunctions.isEmpty(removedTaskTypeItems)) {
      updatedColumnsRef.UpdatedColumns += "TaskTypeGUIDs,";
    }

    //Check if Role has been added or removed
    if (!this.globalFunctions.isEmpty(addedRoleItems) || !this.globalFunctions.isEmpty(removedRoleItems)) {
      updatedColumnsRef.UpdatedColumns += "RoleGUIDs,";
    }

    this.UpdatedColumns_Sync(row, "FromDisplayName", "FromEmailDisplay", updatedColumnsRef);
    this.UpdatedColumns_Sync(row, "ReplyTo", "EmailReplyTo", updatedColumnsRef);
    this.UpdatedColumns_Sync(row, "Recipients", "EmailTo", updatedColumnsRef);
    this.UpdatedColumns_Sync(row, "CC", "EmailCCTo", updatedColumnsRef);
    this.UpdatedColumns_Sync(row, "SubjectPrefix", "EmailSubject", updatedColumnsRef);
    this.UpdatedColumns_Sync(row, "BodyTemplate", "EmailTemplate", updatedColumnsRef);
    this.UpdatedColumns_Sync(row, "Enabled", "Enabled", updatedColumnsRef);

    //Strip out the last comma delimiter
    if (updatedColumnsRef.UpdatedColumns.indexOf(',') > 0) {

      updatedColumnsRef.UpdatedColumns = updatedColumnsRef.UpdatedColumns.substring(0, updatedColumnsRef.UpdatedColumns.length - 1);
    }

    if (this.globalFunctions.isEmpty(updatedColumnsRef.UpdatedColumns)) {

      this.notifyService.Warning_Show("No changes have been detected", "Warning");
      return;
    }

    //Let's construct the request and send it to the server. need to collect up all the fields we know about
    //TODO: Input bad data and test various scenario, sending Maple role GUID for BDMIT lender

    //Set full screen loading during the update
    this.clientDataStore.SetShowFullscreenLoading(true);

    const apiRequest = { GUID: row.GUID, LenderConfigurationGUID: this.LenderConfigurationGUID, MultiSelectItems: multiSelectItems, FromEmailDisplay: row.FromDisplayName, EmailReplyTo: row.ReplyTo, EmailTo: row.Recipients, EmailCCTo: row.CC, EmailSubject: row.SubjectPrefix, EmailTemplate: row.BodyTemplate, Enabled: row.Enabled, UpdatedColumns: updatedColumnsRef.UpdatedColumns };

    //Invoke API
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.UpdateTaskNotifier], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //API response
          const updateTaskNotifierResponse = JSON.parse(JSON.stringify(apiResponse));

          //Update the row with the server data
          this.TaskNotifier_Init(updateTaskNotifierResponse.TaskNotifiers, rowIndex);

          //A notification would be nice
          this.notifyService.Success_Show("Task Notifier Updated", "Success");
          this.clientDataStore.SetShowFullscreenLoading(false);

          //Turn edit mode off - class level , row level and resync the display
          this.RowEdit_Toggle(row);
        }
      });
  }

  //Create a Task Notifier on the server
  public TaskNotifier_Create(row) {

    //Check if no task types then don't send the request to server
    if (this.globalFunctions.isEmpty(row.TaskType_Multiselect)) {

      this.notifyService.Warning_Show("Please select at least one Task Type", "Warning");
      return;
    }

    //Pass comma delimited multiselect GUIDs to server in a new request class property
    const taskTypeGUIDs = this.ArrayColumn_Concat(row.TaskType_Multiselect, "ControlGUID");
    const roleGUIDs = this.ArrayColumn_Concat(row.Role_Multiselect, "ControlGUID");

    //Set full screen loading during the update
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct api request
    const apiRequest = { LenderConfigurationGUID: this.LenderConfigurationGUID, TaskTypeGUIDs: taskTypeGUIDs, RoleGUIDs: roleGUIDs, Enabled: row.Enabled, FromEmailDisplay: row.FromDisplayName, EmailReplyTo: row.ReplyTo, EmailTo: row.Recipients, EmailCCTo: row.CC, EmailSubject: row.SubjectPrefix, EmailTemplate: row.BodyTemplate };

    //Invoke API
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.CreateTaskNotifier], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //API response
          const createTaskNotifierResponse = JSON.parse(JSON.stringify(apiResponse));

          //Reset the insert mode, Client_Sync is being invoked on TaskNotifier_Init method
          this.InsertMode = false;
          this.EditMode = false;

          //Target Index = 0 means to replace the insert placeholder row with the server data
          this.TaskNotifier_Init(createTaskNotifierResponse.TaskNotifiers, 0);

          //A notification would be nice
          this.notifyService.Success_Show("Task Notifier created", "Success");

          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });
  }

  //Init client side properties
  private ClientData_Init(data, headers): void {

    //Loop through all rows
    data.forEach(row => {

      //Loop through headers
      headers.forEach(header => {

        //For multi select, lets initialise an empty array
        if (header.InputType === "Multiselect") {
          row[header.Name + this.Multiselect] = [];
        }

      });
    });
  }

  //Inject client side properties that help with display 
  private ClientData_Sync(data, headers, textLimit = 30): void {

    //Sync Rich Text Editor button text
    this.RTEButtonText = "View";

    if (this.EditMode) {
      this.RTEButtonText = "Edit";
    }

    //Loop through all rows
    data.forEach(row => {

      //Loop through headers
      headers.forEach(header => {

        //For multi select, lets initialise an empty array
        if (header.InputType === "Multiselect" && this.globalFunctions.isEmpty(row[header.Name + this.Multiselect])) {
          row[header.Name + this.Multiselect] = [];
        }

        //Inject a new property called PropertyName_ShowTooltip
        row[header.Name + this.SuffixDisableTooltip] = false;

        //Inject a new property called PropertyName_Display
        row[header.Name + this.SuffixDisplay] = this.globalFunctions.LimitTextSize(row[header.Name], textLimit);

        //Inject a new property for Multiselect tooltip
        row[header.Name + this.Multiselect + this.Tooltip] = this.MultiSelectTooltip_Retrieve(row[header.Name + this.Multiselect]);

        //Inject a new property for Multiselect tooltip disabled
        row[header.Name + this.Multiselect + this.SuffixDisableTooltip] = this.MultiSelectTooltip_Disabled(row, header.Name);

        //Workout length and determine if it's < max limit
        if (!this.globalFunctions.isEmpty(row[header.Name]) && row[header.Name].length < textLimit) {
          row[header.Name + this.SuffixDisableTooltip] = true;
        }

      });

      //Sync tooltip for enabled checkbox
      this.RowEnabledTooltip_Sync(row);
    });
  }

  //Get list of selected items in primeng multiselect
  private MultiSelectTooltip_Retrieve(item): string {

    let tooltip = "";

    if (!this.globalFunctions.isEmpty(item)) {

      //Run for loop to read the array (in reverse) since the client appends the selected item in LIFO manner
      for (let index = item.length - 1; index >= 0; index--) {
        tooltip = tooltip + '• ' + item[index].ControlDisplay + '\n';
      }
    }

    return tooltip;
  }

  //Check logic for displaying primeng multiselect tooltip
  private MultiSelectTooltip_Disabled(row, headerName): boolean {

    //Array of selected items
    const item = row[headerName + this.Multiselect];

    //Check if number of selected items > 1 and row is in readonly mode, then display tooltip
    if (!this.globalFunctions.isEmpty(item) && (item.length > 1 && row[this.SuffixEditFlag] === false)) {

      //Show tooltip
      return false;
    }
    else {
      return true;
    }
  }

  //Check if a column is updated and reflect in updatedColumns object
  private UpdatedColumns_Sync(row, clientColumn, serverColumn, updatedColumns): void {

    if (row[clientColumn] !== this.RowCache[clientColumn]) {
      updatedColumns.UpdatedColumns = updatedColumns.UpdatedColumns + serverColumn + ",";
    }
  }

  //Returns a string of items not found in lookup array
  private Array_Merge(loopArray, lookupArray, lookupKey, displayProperty): string {

    let mergedItems = "";

    //Loop through each item in row cache
    loopArray.forEach(item => {

      //Lookup matching item in Row
      const rowLookup = lookupArray.filter(x => x[lookupKey] === item[lookupKey])[0];

      //Check if empty
      if (this.globalFunctions.isEmpty(rowLookup)) {
        mergedItems += item[displayProperty] + ",";
      }
    });

    //Strip out the last comma delimiter
    if (mergedItems.indexOf(',') > 0) {

      mergedItems = mergedItems.substring(0, mergedItems.length - 1);
    }

    return mergedItems;
  }

  //Returns a concatenated string of keyValue from an array
  private ArrayColumn_Concat(loopArray, keyValue): string {

    let returnStr = "";

    //Loop through each item in row
    loopArray.forEach(item => {

      returnStr = returnStr + item[keyValue] + ",";
    });

    //Strip out the last comma delimiter
    if (returnStr.indexOf(',') > 0) {

      returnStr = returnStr.substring(0, returnStr.length - 1);
    }

    return returnStr;
  }
}