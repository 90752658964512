<br>
<div id="PG_Home" class="glb_customFlexRow row-cols-lg-1 justify-content-center">
    <div class="text-white text-center glb_Title glb_TitleMobile glb_Font">
        Welcome to {{AppName}}
    </div>
</div>
<p></p>
<br>
<br>
<br>
<div class="glb_customFlexRow glb_Font col-12 row-cols-lg-1 text-center justify-content-center">

    <div class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
        <div *ngIf="!LoginData.UserLoggedInStatus" class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_paddingBottomSM d-grid">
            <button id="BTN_LaunchLogin" tabindex="1" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (keydown.enter)="LoginModal_Launch()" (keydown.space)="LoginModal_Launch()" (click)="LoginModal_Launch()">
                <span class="glb_Font glb_smallerText">Log In</span>
            </button>
        </div>
    </div>

    <div *ngIf="LoginData.UserLoggedInStatus"
        class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_paddingBottomSM d-grid">
            <button id="GoToSearch" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="URLWithState_Navigate(NavigationUrls.AccountSearch)">
                <span class="glb_Font glb_smallerText">Go To Search</span>
            </button>
        </div>
    </div>

    <div *ngIf="LoginData.UserLoggedInStatus"
        class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_paddingBottomSM d-grid">
            <button id="BTN_LaunchDashboard" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="URLWithState_Navigate(NavigationUrls.Dashboard, 'ReturnToDashboard')">
                <span class="glb_Font glb_smallerText">Go To Dashboard</span>
            </button>
        </div>
    </div>

    <div *ngIf="LoginData.UserLoggedInStatus && ShowPendingSettlements"
        class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_paddingBottomSM d-grid">
            <button id="BTN_LaunchPendingSettlements" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="URLWithState_Navigate(NavigationUrls.Settlements, 'ReturnToDashboard')">
                <span class="glb_Font glb_smallerText">Pending Settlements</span>
            </button>
        </div>
    </div>

    <div *ngIf="LoginData.UserLoggedInStatus && ShowSLAReporting"
        class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_paddingBottomSM d-grid">
            <button id="BTN_LaunchSLAReporting" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="URLWithState_Navigate(NavigationUrls.SLAReporting, 'ReturnToDashboard')">
                <span class="glb_Font glb_smallerText">SLA Reporting</span>
            </button>
        </div>
    </div>

    <div *ngIf="LoginData.UserLoggedInStatus"
        class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_paddingBottomSM d-grid">
            <button tabindex="4" class="glb_skinLightOutline btn" (click)="UserGuide_Launch()">
                <span class="glb_Font glb_smallerText glb_alternateColor">User Guide</span>
            </button>
        </div>
    </div>
</div>

<br>
<br>
<br>
<br>
<br>
<br>

<!-- Release notes -->
<div *ngIf="LoginData.UserLoggedInStatus" class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
    <div class="glb_PaddingHorizontalMD col-xs-12 col-sm-12 col-md-12 col-lg-10 glb_paddingBottomSM glb_paddingTopLG glb_allBordersLight"
        style="overflow-y:auto; max-height:400px;">
        <div class="text-white glb_TitleLG glb_paddingBottomLG">Release Notes</div>
        <div class="text-white">
            <div *ngFor="let releaseNote of ReleaseNotes" class="glb_customFlexRow justify-content-start">

                <div *ngIf="ShowPrivateNotes || releaseNote.IsPublic" class="glb_customFlexRow col-12 glb_boxedPadding bottomBorder">

                    <div
                        class="align-items-center glb_customFlexRow glb_breakLongWords glb_Font col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 justify-content-start">

                        <!-- Row for a release note -->
                        <div class="align-items-center glb_customFlexRow col-12 glb_SMPaddingBottomSM">

                            <!-- Date -->
                            <div class="glb_XSBold align-self-center col-6">
                                {{releaseNote.Date}}
                            </div>

                            <!-- Internal Label -->
                            <div *ngIf="ShowPrivateNotes" class="align-items-center glb_customFlexRow col-6">

                                <!-- Private -->
                                <div *ngIf="!releaseNote.IsPublic">

                                    <!-- Wrapper for background styling -->
                                    <div class="glb_boxedPaddingNarrow glb_roundedBorderSM privateNote">

                                        <!-- Text for label -->
                                        <span class="glb_Font">Internal</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Release note text -->
                    <div class="glb_customFlexRow glb_Font col-sm-12 col-md-6 col-lg-8 col-xl-9 justify-content-start"
                        [innerHTML]="releaseNote.Text | sanitizeHtml">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>