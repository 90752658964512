import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { StaticDataControllerMethods, UsersControllerMethods } from '@app/Global/EnumManager';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { TemplateID } from '@app/Global/Models/ClientModels';

@Component({
  selector: 'UserManagement',
  templateUrl: './UserManagement.html',
  styleUrls: ['./UserManagement.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class UserManagement implements OnInit, OnDestroy {

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog,
    private csvDataService: CsvDataService = null) {
  }

  //These are for focusing on the assign role
  //For the form
  @ViewChild('assignRoleFocus') assignRoleFocus: ElementRef;
  @ViewChild('assignRoleForm') assignRoleForm: ElementRef;
  //This one is to focus on the first item
  @ViewChild('focusNewRoleName') focusNewRoleName;

  //These are for focusing on the link type filter
  @ViewChild('addLinkTypeFilterFocus') addLinkTypeFilterFocus: ElementRef;
  @ViewChild('addLinkTypeFilterForm') addLinkTypeFilterForm: ElementRef;
  //This one is to focus on the first item
  @ViewChild('linkTypeInputVal') linkTypeInputVal;

  //The user list from the server
  public userList;
  public UserListExport;
  public dialogRef;

  //For storing the clicked on user, their claims and roles
  public chosenUser;
  public currentChosenUser;
  public chosenUserRoles;
  public chosenUserLinkTypeFilters;

  //These headers are for the Role section
  public TableHeaders_Role: string[] = ['TBD'];
  public ColumnsInBody_Role = 2;

  public TableHeaders_UserLinkTypeFilters: string[] = ['TBD'];
  public ColumnsInBody_UserLinkTypeFilters = 3;

  public CombineLabelAndData = true;
  public WrapOddRows = false;
  public isCreatingUser = false;

  //Used by the HostListener (window resize event) to adjust some text alignment when screen size changes. default it to -1 so that it doesn't get used, until its needed.
  public ColumnWrapping = -1;
  public ColumnsInTab = 1;

  public showSpinner = false;

  //Track state of any server requests, so that we can show the spinner, if necessary.
  public isPerformingServerRequest = false;

  public userSaveEnabled = false;

  public showDeleteUserButton = false;
  public showSampleUserCheckBox = false;

  public subscription: Subscription;

  //Used for creation of a user. this could be wrapped into a single object class.
  public createUser_FirstName: string;
  public createUser_LastName: string;
  public createUser_Email: string;
  public createUser_Mobile: string;
  public createUser_CloneFromUserGUID: string;

  public showGoogleAuthSetupButton = false;
  public isUpdatingUser = false;

  public createUserLaunched = false;

  //Spinner for user invitation clicks
  public isUserInviteRequestClicked = false;

  public isDiscardingUserDetails = false;

  //Spinner for user invitation clicks
  public isGoogleSetupClicked = false;

  //Spinner for Password Reset clicks
  public isSendPasswordResetLinkClicked = false;

  //Hide Disabled Users
  public HideDisabledUsers = true;

  //To store the guid of the user being cloned
  public CloneFromUserGUID = "";
  public IsCloning = false;

  //Used by paginator for config, size of the pages, etc.
  public paging = {
    maxSize: 10,
    previousLabel: "",
    nextLabel: ""
  }

  //Unique Modal Identifer
  public ModalIdentifier;

  //Unique Modal Identifer
  public ViewUserModalIdentifier;

  //Used by paginator to control what page is currently clicked
  public currentPage: number;

  //Used for the filter search.
  public filterSearchValue = "";
  public filterSearchValueKeyUp = new Subject<any>();

  //Bind variable created for clearing the client on changing the link type. this is bound to the input element that contains the autocomplete, and needs to conform to the specific ControlData return type.
  //Data structure for link type autocomplete
  public createUserLinkTypeFilter_LinkTypeBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "", LinkType_AutoCompleteLastSearchedValue: "", LinkType_AutoCompleteControlTypeName: "", LinkType_AutoCompleteControlData: [] };
  public createUserLinkTypeFilter_LinkType;

  //Data structure for client autocomplete
  public createUserLinkTypeFilter_ClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "", Client_AutoCompleteControlDisplayValue: "", Client_AutoCompleteLastSearchedValue: "", Client_AutoCompleteControlTypeName: "", Client_AutoCompleteControlData: [] };
  public createUserLinkTypeFilter_Client;

  //Use this to store the last value sent to the server. used for tracking and not firing a queued request if another one has come in its place. its not perfect, but good enough for our type ahead use case (since a user can only really type in one field at a time, this can be reused)
  public lastSearchClientValue: string

  public lastAutoCompleteRequestGUID: string;

  //For a new role insertion, store the autocomplete data for the assoc claim to be inserted (link type and client)
  public roleInsert_AutoComplete = {
    Role_AutoCompleteLastSearchedValue: '', Role_AutoCompleteControlTypeName: 'Role', Role_AutoCompleteControlData: []
  };

  //Bind variable created for clearing the Role. this is bound to the input element that contains the autocomplete, and needs to conform to the specific ControlData return type.
  public insertRole_RoleBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  //Does the user have access to read only mode? This will disable any edit functionality on the UI
  public ReadOnlyMode = true;

  ngOnInit() {

    //Check if the user has an edit claim on User Management
    this.ReadOnlyMode = !this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "UserAdministrator", "Edit");

    //Let's grab the users on init, so that the template can loop through and show them.
    this.Users_Get();
    this.userSaveEnabled = false;

    //Fill headers for the modal
    this.TableHeaders_Role.length = 0;
    this.TableHeaders_Role.push("Role");
    this.TableHeaders_Role.push(" ");
    //Update the number of columns
    this.ColumnsInBody_Role = this.TableHeaders_Role.length;

    //Fill headers for the modal
    this.TableHeaders_UserLinkTypeFilters.length = 0;
    this.TableHeaders_UserLinkTypeFilters.push("UserLinkTypeFilter");
    this.TableHeaders_UserLinkTypeFilters.push(" ");

    //Update the number of columns, this is different. its actually 3
    this.ColumnsInBody_UserLinkTypeFilters = 3;

    //This creates a subscription to apply the search filter as per the search box. 
    this.subscription = this.filterSearchValueKeyUp.pipe(
      map((event) => (<HTMLInputElement>event.target).value),
      debounceTime(50),
      distinctUntilChanged(),
      mergeMap(search => of(search).pipe(delay(50)))
    ).subscribe(value => {

      if (this.globalFunctions.isEmpty(value)) {

        //Call it directly with a blank string if no value
        this.UserFilter_Search("");
      }

      //Small delay so that the spinner has a chance to show. this is useful for the user experience, as they get a visual indication that the filtering has completed.
      this.globalFunctions.delay(50).then(() => {

        //Run after a small delay.
        if (!this.globalFunctions.isEmpty(value)) {
          this.UserFilter_Search(value);
        }
      });
    });
  }

  ngOnDestroy() {
    if (!this.globalFunctions.isEmpty(this.subscription)) {
      this.subscription.unsubscribe();
    }
  }

  //Clear attributes related to cloning
  public UserClone_Clear() {
    this.IsCloning = false;
    this.CloneFromUserGUID = "";
  }

  //Prepare form for cloning
  public UserEdit_Clone(): void {

    //Add confirmation before cloning a user
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to clone this user: <b>" + this.chosenUser.FirstName + " " + this.chosenUser.LastName + "</b>?"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        //Lets store the GUID of the user being cloned
        this.CloneFromUserGUID = this.currentChosenUser.UserGUID;

        //This will hide the elements not required for cloning, eg. Reset Password, 2FA resend
        this.IsCloning = true;

        //Clear the Current Chosen User details, to allow user to input new user details
        this.currentChosenUser.UserName = "";
        this.currentChosenUser.FirstName = "";
        this.currentChosenUser.LastName = "";
        this.currentChosenUser.EmailAddress = "";
        this.currentChosenUser.MobileNumber = "";
        this.currentChosenUser.UserNumber = "TBD - cloning from " + this.chosenUser.FirstName + " " + this.chosenUser.LastName;

        this.currentChosenUser.UserGUID = "";
        this.currentChosenUser.ClientGUID = "";

        //To enable the create button and the input fields
        this.userSaveEnabled = true;

        //Deep clone the current user into chosen user
        this.chosenUser = JSON.parse(JSON.stringify(this.currentChosenUser));
      }
    }
    );
  }

  public UserEdit_Enable() {
    this.userSaveEnabled = true;
  }

  //Hide or show disabled users when the checkbox is clicked
  public DisabledUsers_Hide(event) {
    this.HideDisabledUsers = event.checked;

    this.UserFilter_Search(this.filterSearchValue);
  }

  //Clone User
  public User_Clone(): void {

    //Set the create user attributes as the method uses these variables
    this.createUser_FirstName = this.currentChosenUser.FirstName;
    this.createUser_LastName = this.currentChosenUser.LastName;
    this.createUser_Email = this.currentChosenUser.EmailAddress;
    this.createUser_Mobile = this.currentChosenUser.MobileNumber;

    //Call the create method
    this.User_Create(this.ViewUserModalIdentifier);
  }

  //Create user
  public User_Create(modalIdentifier = "") {
    //Validate it first. 
    if (this.globalFunctions.isEmpty(this.createUser_FirstName)) {
      this.notifyService.Error_Show("Please Enter valid first name", "First Name Missing");
      return;
    }
    if (this.globalFunctions.isEmpty(this.createUser_LastName)) {
      this.notifyService.Error_Show("Please Enter valid last name", "Last Name Missing");
      return;
    }
    if (this.globalFunctions.isEmpty(this.createUser_Email)) {
      this.notifyService.Error_Show("Please Enter valid Email Address", "Email Address Missing");
      return;
    }
    if (this.globalFunctions.isEmpty(this.createUser_Mobile)) {
      this.notifyService.Error_Show("Please Enter valid Mobile", "Mobile Missing");
      return;
    }

    this.isCreatingUser = true;

    this.createUser_CloneFromUserGUID = "";

    if (this.IsCloning) {

      //Send the Clone From User GUID if cloning request
      this.createUser_CloneFromUserGUID = this.CloneFromUserGUID;

      if (this.globalFunctions.isEmpty(this.createUser_CloneFromUserGUID)) {
        this.notifyService.Error_Show("Please chose a valid user to clone", "Invalid");
        return;
      }
    }

    //Client side validation passed. now let's construct the request and send it to the server
    const createUserRequest = { FirstName: this.createUser_FirstName, LastName: this.createUser_LastName, EmailAddress: this.createUser_Email, MobileNumber: this.createUser_Mobile, CloneFromUserGUID: this.createUser_CloneFromUserGUID };
    const apiRequest = createUserRequest;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.CreateUser], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isCreatingUser = false;
          //Nothing required
          return;
        }
        else {
          //Insert this new user into the existing role datagrid.          
          const createUserResponse = JSON.parse(JSON.stringify(apiResponse));
          //Get the first entry in the array (we only expect one user to be returned)
          const newUser = createUserResponse.Users[0];

          newUser.LastName = this.globalFunctions.HTMLUnescape(newUser.LastName);
          newUser.FirstName = this.globalFunctions.HTMLUnescape(newUser.FirstName);
          newUser.UserName = this.globalFunctions.HTMLUnescape(newUser.UserName);
          newUser.EmailAddress = this.globalFunctions.HTMLUnescape(newUser.EmailAddress);
          newUser.MobileNumber = this.globalFunctions.HTMLUnescape(newUser.MobileNumber);
          newUser.CreaterName = this.globalFunctions.HTMLUnescape(newUser.CreaterName);

          //Unshift this into the client side array (at the start)
          this.userList.unshift(newUser);

          //Clear the UI data. should we slide it closed too? hmm
          this.createUser_FirstName = "";
          this.createUser_LastName = "";
          this.createUser_Email = "";
          this.createUser_Mobile = "";
          this.createUser_CloneFromUserGUID = "";

          //Check if the invitation has been sent already (for cloning request)
          if (createUserResponse.InvitationSent === true) {

            //Get the standard confirmer config
            const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

            //Default message that is displayed
            const confirmMessage = createUserResponse.ResponseMessage;

            //Use html content so that we can style it
            dialogRef.DialogRef.componentInstance.htmlContent = confirmMessage;

            //Hide the No Button
            dialogRef.DialogRef.componentInstance.ShowNoButton = false;

            //Change the Yes button text to "OK"
            dialogRef.DialogRef.componentInstance.YesButtonText = "Ok";

            //A notification would be nice
            this.notifyService.Success_Show("User Cloned", "Success");
          }
          else {

            //A notification would be nice
            this.notifyService.Success_Show("User Inserted", "Success");
          }

          //Call it directly with a blank string
          this.UserFilter_Clear();

          //If create new user request, modalIdentifier will be empty
          if (this.globalFunctions.isEmpty(modalIdentifier)) {
            modalIdentifier = this.ModalIdentifier;
          }

          //Close the modal
          this.Dialog_Close(modalIdentifier);

          //Send user to the first user list page
          this.Page_OnChange(0, false);
          this.isCreatingUser = false;
        }
      });

  }

  //Pop up create user dialog box
  public User_CreateModal(template: TemplateRef<any>) {

    //Now flip it on
    this.createUserLaunched = true;

    //Generate a unique identifier
    this.ModalIdentifier = this.globalFunctions.GenerateFastGUID();

    //Wrapper needed to avoid circular dependencies
    this.dialogRef = this.globalFunctions.FeatureModal_Launch(template, this.globalFunctions.GetFeatureModalConfig('40%'), this.dialog, "Create User", 0, true, false, this.ModalIdentifier);
    //Use html content so that we can style it. this is no longer in the component instance, since we are using a wrapper, but we can pass it down via an @input!
    //dialogRef.componentInstance.htmlContent = headerMessage;
    //we can also pass in other styling requests. like if the close button should exist
    //dialogRef.componentInstance.allowClose = allowClose

    //After the dialog is closed, we can try to refresh


  }

  //Update a users details
  public User_Update() {
    this.isUpdatingUser = true;
    //There are many other elements on the screen that can be interacted with while the update is in progress. its a little difficult to lock them all, so lets just use the full screen loading spinner
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Target entity value is in event.value
    const updateUser = { UserGUID: this.currentChosenUser.UserGUID, FirstName: this.currentChosenUser.FirstName, LastName: this.currentChosenUser.LastName, EmailAddress: this.currentChosenUser.EmailAddress, MobileNumber: this.currentChosenUser.MobileNumber, IsDisabled: this.currentChosenUser.IsDisabled, IsSampleUser: this.currentChosenUser.IsSampleUser };

    const apiRequest = updateUser;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UpdateUser], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isUpdatingUser = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Any updates needed client side? dont think so.
          const getResponse = JSON.parse(JSON.stringify(apiResponse));

          //HTML unescape
          getResponse.User.LastName = this.globalFunctions.HTMLUnescape(getResponse.User.LastName);
          getResponse.User.FirstName = this.globalFunctions.HTMLUnescape(getResponse.User.FirstName);
          getResponse.User.UserName = this.globalFunctions.HTMLUnescape(getResponse.User.UserName);
          getResponse.User.EmailAddress = this.globalFunctions.HTMLUnescape(getResponse.User.EmailAddress);
          getResponse.User.MobileNumber = this.globalFunctions.HTMLUnescape(getResponse.User.MobileNumber);
          getResponse.User.CreaterName = this.globalFunctions.HTMLUnescape(getResponse.User.CreaterName);

          //Replace user details 
          const matchedUser = this.userList.findIndex(x => x.UserGUID === this.currentChosenUser.UserGUID);

          //Update chosenUser details with the updated one
          this.chosenUser = JSON.parse(JSON.stringify(this.currentChosenUser));

          //And use splice to remove it
          this.userList.splice(matchedUser, 1, getResponse.User);

          //A notification would be nice
          this.notifyService.Success_Show("User Details Updated", "Success");

          //Turn the spinner off
          this.userSaveEnabled = false;

          //Set showGoogleAuthSetupButton
          this.showGoogleAuthSetupButton = true;
          this.isUpdatingUser = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });

  }

  //Discard save changes
  public User_DiscardChanges() {
    this.isDiscardingUserDetails = true;
    //Turn the spinner off
    this.userSaveEnabled = false;
    //A notification would be nice
    this.notifyService.Success_Show("User detail changes are discarded", "Success");

    this.isDiscardingUserDetails = false;

    //Close the dialog if we are discarding the cloning request
    if (this.IsCloning) {
      this.Dialog_Close(this.ViewUserModalIdentifier);
    }
    else {
      //Reset current user
      this.currentChosenUser = JSON.parse(JSON.stringify(this.chosenUser));
    }

  }

  public User_Delete(targetUser) {
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to delete this user: <b>" + targetUser.UserName + "</b>?"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.clientDataStore.SetShowFullscreenLoading(true);
        //Construct a request body that we can post to the server
        const apiRequest = { UserGUID: targetUser.UserGUID };
        //Call the server, if we have some value to request
        if (targetUser != null) {
          this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.DeleteUser], apiRequest)
            .subscribe(apiResponse => {
              if (this.globalFunctions.isEmpty(apiResponse)) {
                this.clientDataStore.SetShowFullscreenLoading(false);
                return;
              }
              else {
                //Deserialize it into an class that we can understand
                const getResponse = JSON.parse(JSON.stringify(apiResponse));
                this.notifyService.Success_Show(getResponse.ResponseMessage, "Success");

                //We need to remove this on the client side. 
                const matchedUser = this.userList.findIndex(x => x.UserGUID === targetUser.UserGUID)

                //And use splice to remove it
                this.userList.splice(matchedUser, 1);
                this.clientDataStore.SetShowFullscreenLoading(false);
                //Close the modal
                this.Dialog_Close(this.ViewUserModalIdentifier);
              }
            });
        }
      }
    }
    );
  }

  //Invite user to register and create a password for themselves
  public User_Invite() {
    this.isUserInviteRequestClicked = true;
    const apiRequest = { UserGUID: this.chosenUser.UserGUID };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.InviteUser], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isUserInviteRequestClicked = false;
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          this.notifyService.Success_Show(response.ResponseMessage, "Success");

          //Store it client side
          this.isUserInviteRequestClicked = false;
        }
      });
  }

  //Invoke server endpoint to reset 2FA
  public UserTwoFactorSetupReset_Invoke(): void {
    this.isGoogleSetupClicked = true;

    const apiRequest = { UserGUID: this.chosenUser.UserGUID };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.SetupGoogleAuthentication], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isGoogleSetupClicked = false;
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          this.notifyService.Success_Show(response.ResponseMessage, "2FA Setup Link Sent");

          //Store it client side
          this.isGoogleSetupClicked = false;
        }
      });
  }

  //Click button to reset the 2FA for a user
  public UserTwoFactorSetupReset_Click(): void {

    //Adding a confirmer flow
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to reset the two factor code for this user?"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Invoke server endpoint to reset 2FA
        this.UserTwoFactorSetupReset_Invoke();
      }
    });
  }

  //After a click on a single user, use this method to ask the server for this users roles, and launches the modal
  public User_View(template: TemplateRef<any>, chosenUser) {
    //Set it on class in the index. this will be used for update requests to the server    
    this.chosenUser = chosenUser;
    //Deep clone chosen user to avoid model data to be affected before save
    this.currentChosenUser = JSON.parse(JSON.stringify(this.chosenUser));
    this.UserData_Get(this.chosenUser.UserGUID, template);

    //Work out if the delete button should be hidden. based on claim.
    this.showDeleteUserButton = false;
    if (!this.globalFunctions.isEmpty(this.clientDataStore.ClientClaims)) {
      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "AMALEmployee").length > 0) {
        //Now check the delete claim.
        const AMALEmployeeClaim = this.clientDataStore.ClientClaims.filter(x => x.Name == "AMALEmployee")[0];
        if (AMALEmployeeClaim.Delete === true) {
          //Enable the delete user button
          this.showDeleteUserButton = true;
        }
      }
    }

    //Work out if the Is Sample user checkbox should be hidden. based on claim.
    this.showSampleUserCheckBox = false;
    if (!this.globalFunctions.isEmpty(this.clientDataStore.ClientClaims)) {
      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "SampleUserAdministration").length > 0) {
        //Now check the delete claim.
        const sampleUserAdministrationClaim = this.clientDataStore.ClientClaims.filter(x => x.Name == "SampleUserAdministration")[0];
        if (sampleUserAdministrationClaim.Edit === true) {
          //Enable the Is Sample User check box
          this.showSampleUserCheckBox = true;
        }
      }
    }
  }

  //Invoke endpoint to reset the password for a user
  public PasswordResetLink_Invoke(): void {

    this.isSendPasswordResetLinkClicked = true;

    const apiRequest = { UserNumber: this.chosenUser.UserNumber };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.PasswordResetLinkForUser], apiRequest)
      .subscribe(apiResponse => {

        if (this.globalFunctions.isEmpty(apiResponse)) {

          this.isSendPasswordResetLinkClicked = false;
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          this.notifyService.Success_Show(response.ResponseMessage, "Password Reset Link Sent");

          //Store it client side
          this.isSendPasswordResetLinkClicked = false;
        }
      });
  }

  //Click button to reset the password for a user
  public PasswordResetLink_Click(): void {

    //Adding a confirmer flow
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to reset the password for this user?"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Invoke server endpoint to reset password
        this.PasswordResetLink_Invoke();
      }
    });
  }

  //Gets all users from the server, used on entry to this component
  private Users_Get() {
    this.showSpinner = true;
    const apiRequest = { NA: "" };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetUsers], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.showSpinner = false;
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          //Store it client side
          this.userList = response.UserList;
          //We need to unescape HTML encoding on firstname, lastname, email and mobile.
          this.userList.forEach(e => {
            //console.log(e);
            e.LastName = this.globalFunctions.HTMLUnescape(e.LastName);
            e.FirstName = this.globalFunctions.HTMLUnescape(e.FirstName);
            e.UserName = this.globalFunctions.HTMLUnescape(e.UserName);
            e.EmailAddress = this.globalFunctions.HTMLUnescape(e.EmailAddress);
            e.MobileNumber = this.globalFunctions.HTMLUnescape(e.MobileNumber);
            e.CreaterName = this.globalFunctions.HTMLUnescape(e.CreaterName);
          });

          //This search will apply the hide disabled user state filter on load
          this.UserFilter_Search("");
          this.showSpinner = false;
        }
      });
  }

  //Wrapper of isempty to allow local html template to call it. this is needed to stop the template from calling it when it is empty (before user data is populated from server), or we get null errors.
  public UserList_IsEmpty() {
    if (this.globalFunctions.isEmpty(this.userList)) {
      return false;
    }
    return true;
  }

  //To change the page for the paginator
  public Page_OnChange(page: number, doScroll = true) {
    this.currentPage = page;
    //Dont scroll to top, bad UI experience. scroll to bottom instead
    if (doScroll) {
      this.globalFunctions.delay(50).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });

    }
  }

  //Clears data from the filter search box
  public UserFilter_Clear(): void {
    this.filterSearchValue = "";
    //Call it directly with a blank string to reset the search filter
    this.UserFilter_Search("");
  }

  //This applies a filter based on the search term to the input entity.
  private UserFilter_Search(searchTerm: string) {
    //Unselected any data units (they might get hidden by the filter)
    //this.DataUnit_RemoveSelected();

    //Try to catch null or undefined, replace with blank string
    if (this.globalFunctions.isEmpty(searchTerm)) {
      //Force it to an empty value (if null or undefined)
      searchTerm = "";
    }

    //console.log("running search filter for: ", searchTerm);
    //Make sure search term is not null, and greater than 2 characters. also uppercase it
    searchTerm = searchTerm.toUpperCase();
    //console.log("EntitySearchFilter firing for: ", EntityName, " - ", searchTerm);
    if (searchTerm != null && searchTerm.length > 2) {
      //Loop through each row in the userList
      for (const key in this.userList) {
        const value = this.userList[key];

        if (this.HideDisabledUsers && value["IsDisabled"] === true) {
          value.isHidden = true;
        }
        else {
          //We want to loop through valid properties here.
          //console.log("user: ", value);
          if (value['UserNumber'].toUpperCase().includes(searchTerm) === true || value['EmailAddress'].toUpperCase().includes(searchTerm) === true || value['RoleName'].toUpperCase().includes(searchTerm) === true || value['UserName'].toUpperCase().includes(searchTerm) === true || value['MobileNumber'].toUpperCase().includes(searchTerm) === true) {
            value.isHidden = false;
          }
          else {
            value.isHidden = true;
          }
        }
      }

    }
    else if (searchTerm == "") {
      //Remove all the hide filters
      for (const key in this.userList) {
        const value = this.userList[key];

        if (!this.globalFunctions.isEmpty(value)) {
          if (this.HideDisabledUsers && value["IsDisabled"] === true) {
            value.isHidden = true;
          }
          else {
            value.isHidden = false;
          }
        }
      }
    }

    //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
    this.Page_OnChange(0, false);
  }

  //Get a single users data (including assigned roles)
  private UserData_Get(userGUID: string, template): void {
    this.clientDataStore.SetShowFullscreenLoading(true);
    //Reset the UI role controls
    this.AssignRole_ClearUIData();

    const getUserData = { UserGUID: userGUID };
    const apiRequest = getUserData;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetUserData], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const getUserClaimsResponse = JSON.parse(JSON.stringify(apiResponse));
          //Get roles
          this.chosenUserRoles = getUserClaimsResponse.ClientUserRoles;
          this.chosenUserLinkTypeFilters = getUserClaimsResponse.ClientUserLinkTypeFilters;

          //We need to unescape HTML encoding on the rolename
          //console.log('this.chosenUserRoles', this.chosenUserRoles);
          this.chosenUserRoles.forEach(e => {
            //console.log(e);
            e.RoleName = this.globalFunctions.HTMLUnescape(e.RoleName);
          });

          this.showGoogleAuthSetupButton = true;

          this.ViewUserModalIdentifier = this.globalFunctions.GenerateFastGUID();

          //Wrapper needed to avoid circular dependencies
          this.dialogRef = this.globalFunctions.FeatureModal_Launch(template, this.globalFunctions.GetFeatureModalConfig('60%'), this.dialog, "User Data", 0, true, false, this.ViewUserModalIdentifier);
          this.userSaveEnabled = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });
  }

  //Hides the user data dialog
  public Dialog_Close(modalIdentifier) {

    //Clear the attributes related to cloning
    this.UserClone_Clear();

    //Close the modal
    this.globalFunctions.FeatureModal_Close(modalIdentifier, false);
  }

  //Remove a link type filter
  public UserLinkTypeFilter_Delete(userLinkTypeFilterGUID: string) {
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);
    const linkTypeFilter = this.chosenUserLinkTypeFilters.filter(x => x.LinkTypeFilterGUID === userLinkTypeFilterGUID)[0];

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to remove this link type filter: <b>" + linkTypeFilter.LinkTypeName + "</b> from the user?"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.clientDataStore.SetShowFullscreenLoading(true);
        //Construct a request body that we can post to the server
        const apiRequest = { UserGUID: this.chosenUser.UserGUID, LinkTypeFilterGUID: userLinkTypeFilterGUID };
        //Call the server, if we have some value to request
        if (userLinkTypeFilterGUID != null) {
          this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.RemoveUserLinkTypeFilter], apiRequest)
            .subscribe(apiResponse => {
              if (this.globalFunctions.isEmpty(apiResponse)) {
                this.clientDataStore.SetShowFullscreenLoading(false);
                return;
              }
              else {
                //Deserialize it into an class that we can understand
                const getResponse = JSON.parse(JSON.stringify(apiResponse));
                this.notifyService.Success_Show(getResponse.ResponseMessage, "Success");
                //We need to remove this on the client side.
                const chosenLinkTypeFilter = this.chosenUserLinkTypeFilters.findIndex(x => x.LinkTypeFilterGUID === userLinkTypeFilterGUID)
                //And use splice to remove it
                this.chosenUserLinkTypeFilters.splice(chosenLinkTypeFilter, 1);
                this.clientDataStore.SetShowFullscreenLoading(false);
              }
            });
        }
      }
    }
    );

  }

  public UserLinkTypeFilter_Create() {
    //console.log('createUserLinkTypeFilter_LinkType', this.createUserLinkTypeFilter_LinkType);
    //console.log('createUserLinkTypeFilter_Client', this.createUserLinkTypeFilter_Client);
    //Client side validation - ensure that the above two are not empty.
    if (this.globalFunctions.isEmpty(this.createUserLinkTypeFilter_LinkType) || this.globalFunctions.isEmpty(this.createUserLinkTypeFilter_Client)) {
      this.notifyService.Error_Show("Please choose a valid Link Type and Target Client", "Missing Values");
      return;
    }

    //To switch the button to the inactive spinner version
    this.isPerformingServerRequest = true;

    //Client side validation passed. now let's construct the request and send it to the server
    const assignUserLinkTypeFilter = { UserGUID: this.chosenUser.UserGUID, LinkTypeGUID: this.createUserLinkTypeFilter_LinkType, TargetClientGUID: this.createUserLinkTypeFilter_Client };

    const apiRequest = assignUserLinkTypeFilter;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.AssignUserLinkTypeFilter], apiRequest)
      .subscribe(apiResponse => {
        //console.log("apiResponse", apiResponse);
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isPerformingServerRequest = false;
          //Nothing required
          return;
        }
        else {
          //Insert this new claim into the existing client claims datagrid.
          const response = JSON.parse(JSON.stringify(apiResponse));
          //Get the first entry in the array (we only expect one claim to be returned)
          const newLinkTypeFilter = response.ClientUserLinkTypeFilters[0];
          newLinkTypeFilter.TargetClientName = this.globalFunctions.HTMLUnescape(newLinkTypeFilter.TargetClientName);
          newLinkTypeFilter.LinkTypeName = this.globalFunctions.HTMLUnescape(newLinkTypeFilter.LinkTypeName);

          console.log('this.chosenUserLinkTypeFilters', this.chosenUserLinkTypeFilters);

          //Unshift this into the client side array (at the start)
          this.chosenUserLinkTypeFilters.unshift(newLinkTypeFilter);

          //Clear the UI data.
          this.UserLinkType_ClearUIData();

          //A notification would be nice
          this.notifyService.Success_Show("Link Type Filter Created", "Success");
          this.isPerformingServerRequest = false;

          //Now toggle the chevron to collapse the link type filter input text box form
          this.TemplateID_Toggle("addLinkTypeFilterForm");
        }
      });
  }

  //In relation to autocomplete - read the response, return the ControlValue out of it. this allows autocomplete to show the 'pretty' value in the input box
  public AutoComplete_GetPrettyName(value): string {
    if (value === null) {
      return "";
    }
    else {
      if (value.ControlValue != null) {
        return value.ControlValue;
      }
      else {
        return value;
      }
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server (for the create option!)
  public AutoComplete_SaveSelectedLinkType_ForCreate(value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //For the create version, just save it into the local variable.
      this.createUserLinkTypeFilter_LinkType = value.value.ControlGUID;
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server
  public AutoComplete_SaveSelectedClient(value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //Just save it locally.
      this.createUserLinkTypeFilter_Client = value.value.ControlGUID;
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_LinkType_ApplyFilter(linkTypeItem, requestType: string, controlValue: string, fieldType: string, seed = false) {
    //console.log('AutoComplete_LinkType_ApplyFilter.controlValue', controlValue);

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Role_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (controlValue === "" && seed && linkTypeItem.LinkType_AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      linkTypeItem.LinkType_AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(linkTypeItem.LinkType_AutoCompleteLastSearchedValue)) {
      if ((linkTypeItem.LinkType_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {
        //Request new data and update the current value so we don't request it again.
        linkTypeItem.LinkType_AutoCompleteLastSearchedValue = controlValue;

        //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
        //Set the class variable to this value
        this.lastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
        //Make a copy of it for sending to the update request
        const copyOfGUID = JSON.parse(JSON.stringify(this.lastAutoCompleteRequestGUID));

        //console.log('requesting autocomplete for: ', controlValue);
        this.AutoComplete_RequestData(requestType, "Association Type", controlValue
          , linkTypeItem.LinkType_AutoCompleteControlData, fieldType, copyOfGUID, "LenderAssociations");
      }
    }

    //Check if the control data is non null
    if (linkTypeItem.LinkType_AutoCompleteControlData != null) {
      //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
      return linkTypeItem.LinkType_AutoCompleteControlData;
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_Client_ApplyFilter(assocClaim, requestType: string, controlValue: string, fieldType: string) {
    //Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    if (controlValue === null || this.globalFunctions.isEmpty(controlValue)) {
      return;
    }

    if (assocClaim.Client_AutoCompleteLastSearchedValue != null) {
      if ((assocClaim.Client_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {
        //Request new data and update the current value so we don't request it again.
        assocClaim.Client_AutoCompleteLastSearchedValue = controlValue;
        //Store the last value in the class so that we can have some basic flow control.
        this.lastSearchClientValue = controlValue;
        //We want this to have a slight delay on key press. so that we don't trigger a server request too quickly as keys are being pressed. just put a small delay.
        this.globalFunctions.delay(350).then(() => {
          //check if the value is still the same
          if (this.lastSearchClientValue === controlValue) {
            //Run the search on the server, only if the value is still the same. if its changed, then another one is coming, and just leave this one.
            this.AutoComplete_RequestData(requestType, assocClaim.Client_AutoCompleteControlTypeName, controlValue, assocClaim.Client_AutoCompleteControlData, fieldType, this.lastAutoCompleteRequestGUID);
          }
          else {
            //Search term has changed, another one is coming. cancel this. or just return whatever is there now
            if (assocClaim.Client_AutoCompleteControlData != null) {
              //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
              return assocClaim.Client_AutoCompleteControlData;
            }
          }
        });
      }
    }

    //Check if the control data is non null
    if (assocClaim.Client_AutoCompleteControlData != null) {
      //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
      return assocClaim.Client_AutoCompleteControlData;
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_Role_ApplyFilter(roleInsert_AutoComplete, requestType: string, controlValue: string, fieldType: string, seed = false) {
    // console.log('starting autcomplete for controlValue: ', controlValue);
    // Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    // if (this.globalFunctions.isEmpty(controlValue)) {
    //   return;
    // }

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Role_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (controlValue === "" && seed && roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
    }

    //console.log('roleInsert_AutoComplete', roleInsert_AutoComplete);
    //console.log('roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue', roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue);

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue)) {
      if ((roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {
        //Request new data and update the current value so we don't request it again.
        roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue = controlValue;

        //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
        //Set the class variable to this value
        this.lastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
        //Make a copy of it for sending to the update request
        const copyOfGUID = JSON.parse(JSON.stringify(this.lastAutoCompleteRequestGUID));

        //console.log('requesting autocomplete for: ', controlValue);
        this.AutoComplete_RequestData(requestType, roleInsert_AutoComplete.Role_AutoCompleteControlTypeName, controlValue
          , roleInsert_AutoComplete.Role_AutoCompleteControlData, fieldType, copyOfGUID);
      }
    }

    //Check if the control data is non null
    if (roleInsert_AutoComplete.Role_AutoCompleteControlData != null) {
      //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
      return roleInsert_AutoComplete.Role_AutoCompleteControlData;
    }
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, fieldType: string, autoCompleteRequestGUID: string, linkType: string = ""): void {
    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = { RequestType: requestType, ControlType: controlType, SearchValue: requestValue, LinkType: linkType };

    //Need to flip the endpoint here, dependant on the field that we are looking up.
    let autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete];

    if (fieldType === "LinkType") {
      autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete];
    }
    else if (fieldType === "Client") {
      autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Client];
    }
    else if (fieldType === "Role") {
      autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Role];
    }

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, autoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {
            if (autoCompleteRequestGUID === this.lastAutoCompleteRequestGUID) {
              this.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }

  //Processes the autocomplete data returned by the server and pushes it into the array
  private AutoComplete_ProcessResponse(apiResponse, controlArray): void {
    if (!this.globalFunctions.isEmpty(apiResponse)) {
      //Dont process if the passed in control array is empty.
      if (!this.globalFunctions.isEmpty(controlArray)) {
        //Setting the length of an array to zero it the fastest way to clear an array in TS.
        controlArray.length = 0;
      }

      const results = apiResponse;
      for (const key in results) {
        const ControlDataUnit = {
          Entity: results[key].Entity,
          ControlType: results[key].ControlType,
          ControlGUID: results[key].ControlGUID,
          ControlValue: this.globalFunctions.HTMLUnescape(results[key].ControlValue),
        };
        //Push the result in to the control array by ref
        controlArray.push(ControlDataUnit);
      }
    }
    else {
      //No results! empty out the array, only when it is non empty
      if (!this.globalFunctions.isEmpty(controlArray)) {
        controlArray.length = 0;
      }
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server (for the insert)
  public AutoComplete_SaveSelectedRole_ForInsert(value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //Save it into the local Bind variable.
      this.insertRole_RoleBind = { ControlDisplay: value.value.ControlValue, ControlGUID: value.value.ControlGUID, ControlValue: value.value.ControlValue, ControlType: "" };
    }
  }

  //Set cursor in the input text box
  public AssignRoleForm_Expand() {
    this.globalFunctions.delay(1).then(() => { this.focusNewRoleName.nativeElement.focus(); });
  }

  //Set cursor in the first input text box
  public UserLinkTypeForm_Expand() {
    this.globalFunctions.delay(1).then(() => { this.linkTypeInputVal.nativeElement.focus(); });
  }

  //Method to create/insert the new role
  public UserRole_Assign() {
    //Validate it first. make sure a role type is chosen
    if (this.globalFunctions.isEmpty(this.insertRole_RoleBind) || this.globalFunctions.isEmpty(this.insertRole_RoleBind.ControlGUID)) {
      this.notifyService.Error_Show("Please choose a valid Role", "Role Missing");
      return;
    }

    //Let's add confirmation before assigning a new role
    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to assign this new role: <b>" + this.insertRole_RoleBind.ControlDisplay + "</b> to <b>" + this.chosenUser.FirstName + " " + this.chosenUser.LastName + "?"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        //To switch the button to the inactive spinner version
        this.isPerformingServerRequest = true;

        //Client side validation passed. now let's construct the request and send it to the server
        const assignUserRole = { UserGUID: this.chosenUser.UserGUID, RoleGUID: this.insertRole_RoleBind.ControlGUID };

        const apiRequest = assignUserRole;
        this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.AssignUserRole], apiRequest)
          .subscribe(apiResponse => {
            //console.log("apiResponse", apiResponse);
            if (this.globalFunctions.isEmpty(apiResponse)) {
              this.isPerformingServerRequest = false;
              //Nothing required
              return;
            }
            else {
              //Insert this new claim into the existing client claims datagrid.
              const response = JSON.parse(JSON.stringify(apiResponse));

              //Get the first entry in the array (we only expect one claim to be returned)
              const newRole = response.ClientUserRoles[0];
              newRole.RoleName = this.globalFunctions.HTMLUnescape(newRole.RoleName);

              //Clear the chosenUserRoles array and add the returned assigned role
              this.chosenUserRoles = [];
              this.chosenUserRoles[0] = newRole;

              //Clear the UI data.
              this.AssignRole_ClearUIData();

              //A notification would be nice
              this.notifyService.Success_Show("Role Assigned", "Success");
              this.isPerformingServerRequest = false;
              //Now toggle the chevron to collapse the role name input text box form
              this.TemplateID_Toggle("insertRole");
            }
          });
      }
    });
  }

  //Resets all the UI controls for the assign role section
  public AssignRole_ClearUIData() {
    //So, looks like to get angular material autocomplete to refresh on the html element, we have to push a new OBJECT into the variable
    this.insertRole_RoleBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
  }

  //Resets all the UI controls for the insert link type filter section
  public UserLinkType_ClearUIData() {
    //So, looks like to get angular material autocomplete to refresh on the html element, we have to push a new OBJECT into the variable

    //Reset the link type filter data
    this.createUserLinkTypeFilter_LinkTypeBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "", LinkType_AutoCompleteLastSearchedValue: "", LinkType_AutoCompleteControlTypeName: "", LinkType_AutoCompleteControlData: [] };
    this.createUserLinkTypeFilter_LinkType = "";

    //Reset the client type filter data
    this.createUserLinkTypeFilter_ClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "", Client_AutoCompleteControlDisplayValue: "", Client_AutoCompleteLastSearchedValue: "", Client_AutoCompleteControlTypeName: "", Client_AutoCompleteControlData: [] };
    this.createUserLinkTypeFilter_Client = "";
  }

  //Gets the class based on entity setup. this is needed as direct binding inside the html template seems to run into some runtime bugs (might be a limit related to the length of the conditions)
  public CssClass_Get(Type: string, ColumnsInBody: number, WrapOddRows: boolean = null, isOdd: boolean = null, isEven: boolean = null, CombineLabelAndData: boolean = null
    , itemIndex: number = null, arrayCount: number = null, rowStyle: string = null
  ) {
    //Testing performance, how often is this firing.
    //console.log("getClass firing for:", Type);

    //For data pairs (label + value)
    if (Type == 'DataPair') {
      let returnClass = "";
      //Check if any row specific style need to be applied to the data.
      if (rowStyle === "ghosted") {
        returnClass = returnClass + "ghosted ";
      }
      if (rowStyle === "rowBold") {
        returnClass = returnClass + "rowBold ";
      }
      if (rowStyle === "addToBase") {
        returnClass = returnClass + "addToBase ";
      }

      if (ColumnsInBody === 1) {
        return returnClass + "row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1";
      }
      if (!WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-2';
      }
      if (WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-2 row-cols-md-2 row-cols-sm-2 row-cols-lg-2';
      }
      if (ColumnsInBody === 7) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-8';
      }
      if (ColumnsInBody === 6) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-6 row-cols-lg-6';
      }
      if (ColumnsInBody === 5) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-5 row-cols-lg-5';
      }
      if (ColumnsInBody === 4) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4';
      }
      if (ColumnsInBody === 3) {
        return returnClass + "row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1";
      }
      if (ColumnsInBody === 8) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-8';
      }
    }

    //For the actual value display
    else if (Type == 'ValueDisplay') {
      if (((isOdd || !WrapOddRows) || CombineLabelAndData) && ColumnsInBody < 4) {
        return 'dataClass text-lg-end text-md-end text-sm-end text-xs-start';
      }
      if (isEven && (WrapOddRows && !CombineLabelAndData && ColumnsInBody >= 0 && ColumnsInBody < 4)) {
        return 'labelClass text-lg-start text-md-start text-sm-start';
      }
      if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping === 0 && itemIndex <= (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'text-start';
      }
      if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping === 0 && itemIndex > (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'text-end';
      }
    }

    //For table header values
    else if (Type == 'TableHeaderValues') {
      if (((isOdd || !WrapOddRows) || CombineLabelAndData) && ColumnsInBody < 4) {
        return 'labelClass text-lg-end text-md-end text-sm-end text-xs-start';
      }
      if (isEven && (WrapOddRows && !CombineLabelAndData && ColumnsInBody >= 0 && ColumnsInBody < 4)) {
        return 'labelClass text-lg-start text-md-start text-sm-start';
      }
      if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping === 0 && itemIndex <= (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'labelClass text-start';
      }
      if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping === 0 && itemIndex > (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'labelClass text-end';
      }
    }
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //For activating the clicked nav item index. Activate first item by default
  public NavTab_GetClass() {
    let returnClass = "";
    //This is needed to dynamically set the first nav tab as active
    returnClass += " show active";

    return returnClass;
  }

  //Downloads the selected entity into a csv file
  public Users_DownloadCSV(): void {

    const csvArray = [];

    this.UserListExport = JSON.parse(JSON.stringify(this.userList));

    //Loop through the results and perform formatting on data 
    this.UserListExport.forEach(element => {

      //Ignore filtered out users and disabled users if Hide Disabled checkbox is checked
      if (element.isHidden === false) {

        //Remove Client and User GUIDs from the export
        delete element.UserGUID;
        delete element.ClientGUID;

        //Remove the flags used in the UI display
        delete element.isHidden;
        delete element.IsSampleUser;
        delete element.IsLocked;

        //Remove T from the timestamps on download so that excel can read it a datetime
        if (!this.globalFunctions.isEmpty(element.LastPasswordChangeDate)) {
          element.LastPasswordChangeDate = element.LastPasswordChangeDate.replace("T", " ");
        }

        //Push into the array to be used for download
        csvArray.push(element);
      }
    });

    //Call the service to produce and download the csv file
    this.csvDataService.exportToCsv("UserList", csvArray);
  }
}