<div #AccountSummaryDetailModal id="AccountSummaryDetailID" class="glb_boxedPadding glb_formBGColor text-white glb_Font">

  <!-- Close modal-->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom">
      <button id="BTN_CloseSummaryConfigDetails" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline"
        type="button" (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
        <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
      </button>
    </div>
  </div>

  <!-- Account Summary Configuration Details Page -->
  <div class="glb_Font header-padding glb_customFlexRow">
    <div class="header-padding glb_customFlexRow col-12">
      <div class="col-12  glb_customFlexRow">
        <div
          class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG col-8 glb_customFlexRow">
          <div class="glb_customFlexRow">
            {{AccountSummaryConfigName}}
          </div>
          <div class="glb_customFlexRow justify-content-start align-content-center ">
            <button class="col-6 btn glb_clickableIconButtonStyleNone text-white" (click)="ConfigNameEdit_CreateModal(ConfigSummaryTemplate)">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit Configuration Name">
              </fa-icon>
            </button>
          </div>
        </div>
        <div *ngIf="EditEnabled" class="glb_customFlexRow col-4 justify-content-end">
          <div class="buttonPadding">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="ConfigurationDetailsChanges_Save()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Save your changes">
              </fa-icon>
            </button>
          </div>
          <div *ngIf="NumMappedRoles === 0" class="buttonPadding">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="Configuration_Delete()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete this Account Summary Configuration">
              </fa-icon>
            </button>
          </div>
          <div class="buttonPadding">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="ConfigurationDetailsChanges_Discard()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'ban']" matTooltip="Click to Discard your changes">
              </fa-icon>
            </button>
          </div>
        </div>
        <div *ngIf="!EditEnabled" class="glb_customFlexRow col-4 justify-content-end">
          <div class="buttonPadding">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="Configuration_Clone()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone this Account Summary Configuration">
              </fa-icon>
            </button>
          </div>
          <div class="buttonPadding">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="AccountSummaryConfig_EnableEditing()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit this Account Summary Configuration">
              </fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Page details (note) -->
  <div class="glb_Font glb_customFlexRow col-12">
    <p>This screen allows user to configure an Account Summary screen with multiple sections/groups with different configurable data
      units.
    </p>
    <p>Note: The preview screen displays the way the account summary screen will be rendered with the current configuration.
    </p>
  </div>

  <div class="glb_customFlexRow col-12 justify-content-start d-block glb_Font glb_paddingTopSM">
    <div *ngIf="this.NumMappedRoles > 0">
      <a class="mappedRole" (click)="ConfigMappedRoles_Launch(ViewRoleList)" style="cursor: pointer;">
        {{MappedRolesTextDisplay}}
      </a>
    </div>
    <div *ngIf="this.NumMappedRoles === 0" class="text-white">
      This summary configuration is not mapped to any roles yet.
    </div>
  </div>

  <!-- Configuration Details -->
  <div class="col-12 glb_customFlexRow">
    <div class="col-6 glb_customFlexRow glb_boxedPadding glb_verticalBorderRight">
      <div class="col-12 glb_customFlexRow">
        <div class="col-4 glb_boxedPadding">
          <div class="glb_FontSizeSummary glb_paddingBottomMD glb_FontLabelDisplay">Configurable Data Units
            <fa-icon [icon]="['fal', 'circle-info']" class="fa-fw"
              matTooltip="Drag and drop the configurable data units to the account summary display groups"></fa-icon>
          </div>
          <div class="col-12 glb_customFlexRow" *ngFor="let duGroup of DataUnitGroups_Get()">
            <div cdkDropList id="{{duGroup.GroupGUID}}" [cdkDropListData]="duGroup.DetailGUIDs"
              [cdkDropListConnectedTo]="ConnectedToAccountSummaryGroups" class="item-list glb_allBordersGreyRoundedSM glb_customFlexRow col-12"
              (cdkDropListDropped)="DraggedItem_Dropped($event)" [cdkDropListDisabled]="EditingDisabled_Check()">
              <div class="glb_boxedPadding glb_customFlexRow col-12">
                <div class="item-box glb_customFlexRow glb_LabelFontDark col-12" *ngFor="let du of duGroup.DetailGUIDs" cdkDrag>
                  {{DataUnitName_Get(du)}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 glb_boxedPadding">
          <div class="glb_FontSizeSummary glb_paddingBottomMD glb_FontLabelDisplay">Account Summary Display Groups
            <fa-icon [icon]="['fal', 'circle-info']" class="fa-fw"
              matTooltip="List of account summary groups to configure the display of data units"></fa-icon>
          </div>
          <div class="glb_customFlexRow summaryGroupScrollBar glb_boxedPadding">
            <div class="col-12 glb_customFlexRow" *ngFor="let sectionGroup of AccountSummaryGroups_Get()">
              <div class="col-12 glb_customFlexRow glb_paddingBottomMD">
                <div class="glb_FontSizeSummary col-10 align-content-center">{{sectionGroup.DisplayName}}</div>
                <div *ngIf="EditEnabled" class="glb_customFlexRow justify-content-end align-content-center col-2">
                  <button class="btn glb_clickableIconButtonStyleNone text-white glb_paddingZeroOffset"
                    (click)="Group_CreateModal(GroupTemplate, sectionGroup.GroupGUID)">
                    <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit Group Name">
                    </fa-icon>
                  </button>
                  <button class="btn glb_clickableIconButtonStyleNone text-white glb_paddingZeroOffset"
                    (click)="RemoveSection_Click(sectionGroup.GroupGUID)">
                    <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Remove">
                    </fa-icon>
                  </button>
                </div>
                <div *ngIf="!EditEnabled" class="glb_customFlexRow justify-content-end align-content-center col-2">
                  <button class="col-6 btn glb_clickableIconButtonStyleNone">
                    <fa-icon class="fa-fw glb_fixedSizeIcons glb_iconColorTransparent" [icon]="['fal', 'edit']" matTooltip="">
                    </fa-icon>
                  </button>
                </div>

                <div cdkDropList id="{{sectionGroup.GroupGUID}}" [cdkDropListData]="sectionGroup.DetailGUIDs"
                  [cdkDropListConnectedTo]="ConnectedToAccountSummaryGroups" class="item-list glb_allBordersGreyRoundedSM glb_customFlexRow col-12"
                  (cdkDropListDropped)="DraggedItem_Dropped($event)" [cdkDropListDisabled]="EditingDisabled_Check()">
                  <div class="glb_boxedPadding glb_customFlexRow">
                    <div class="item-box glb_LabelFontDark col-12" *ngFor="let du of sectionGroup.DetailGUIDs" cdkDrag>
                      {{DataUnitName_Get(du)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="EditEnabled" class="col-12 glb_customFlexRow justify-content-end">
        <div class="glb_PaddingHorizontalXS">
          <button id="BTN_AddNewSection" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
            (click)="Group_CreateModal(GroupTemplate)">
            <span class="glb_Font glb_smallerText">Add New Display Group</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-6 glb_customFlexRow glb_boxedPadding">
      <div class="col-12 glb_boxedPadding">
        <div class="glb_FontSizeSummary glb_paddingBottomMD glb_FontLabelDisplay">Preview Only
          <fa-icon [icon]="['fal', 'circle-info']" class="fa-fw" matTooltip="Account summary screen preview with sample data"></fa-icon>
        </div>
        <div class="col-12 glb_customFlexRow glb_allBordersGreyRoundedSM">
          <div *ngFor="let summaryGroup of AccountSummaryGroups_Get(); let i=index" class="glb_boxedPaddingXS col-12 glb_customFlexRow">
            <div class="glb_PaddingHorizontalSM col-12 glb_customFlexRow">
              <div class="col-12 glb_customFlexRow">
                <div class="glb_PaddingVerticalMD col-12 glb_customFlexRow">
                  <div *ngFor="let duGUID of summaryGroup.DetailGUIDs" class="col-12 glb_boxedPaddingXS glb_customFlexRow">
                    <div class="col-12 glb_customFlexRow glb_FontLabelDisplay glb_FontSizeSummary">

                      <div class="col-6 col-lg-4 justify-content-start glb_customFlexRow glb_LabelFontDark">
                        <div>
                          {{DataUnitDisplayName_Get(duGUID)}}
                        </div>
                      </div>
                      <div class="col-6 col-lg-8 glb_customFlexRow glb_Font glb_textAlignEnd">
                        <div class="col-11 justify-content-end glb_customFlexRow">
                          {{DataUnitSampleValue_Get(duGUID)}}
                        </div>
                        <div class="col-1 glb_customFlexRow justify-content-end">
                          <fa-icon id="EL_ChevronID" class="glb_hoverHand" [icon]="['fal', 'chevron-right']" matTooltip="">
                          </fa-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Role mapped to the config-->
  <ng-template #ViewRoleList>
    <div class="glb_boxedPadding glb_formBGColor glb_Font">
      <!-- Close modal button -->
      <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom">
          <button tabindex="30" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
            (click)="Dialog_Close(ConfigMappedRolesModalIdentifier)">
            <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
          </button>
        </div>
      </div>
      <div class="glb_formBGColor glb_Font">
        <div class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">List of mapped roles</div>
      </div>
      <div class="itemBorders glb_boxedPaddingNarrow" *ngFor="let item of LinkedRoles | paginate: {id: 'mappedRolesList', itemsPerPage: 10, currentPage:
      MappedRoleListCurrentPage.currentPage}">
        <div class="glb_Font glb_customFlexRow col-lg-12 glb_paddingZeroOffset">
          <div class="col-12 col-lg-10 glb_boxedPaddingXS">
            <div class="itemTitle glb_paddingZeroOffset glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">{{item}}
            </div>
          </div>
        </div>
      </div>
      <div class="glb_customFlexRow">
        <div class="col">
          <div class="glb_Font glb_customFlexRow justify-content-center">
            <pagination-controls id='mappedRolesList' class="glb_paginatorDark" (pageChange)="Page_OnChange($event, MappedRoleListCurrentPage, false)"
              [maxSize]="MappedRolePaging.MaxSize" [responsive]="true" [previousLabel]="MappedRolePaging.PreviousLabel"
              [nextLabel]="MappedRolePaging.NextLabel">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Pop up dialog for adding new group-->
  <ng-template #GroupTemplate>
    <div class="glb_boxedPadding glb_formBGColor glb_Font">
      <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom">
          <button tabindex="30" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
            (click)="Dialog_Close(GroupModalIdentifier)">
            <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
          </button>
        </div>
      </div>
      <div class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">{{GroupTemplateTitle}}</div>
      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
        <!-- <div class="glb_paddingBottomSM">
          <div class="text-white glb_inputFieldLabel glb_customFlexRow">Group Name</div>
          <input cdkFocusInitial tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Group Name"
            [(ngModel)]="GroupName">
        </div> -->
        <div class="glb_paddingBottomSM">
          <div class="text-white glb_inputFieldLabel glb_customFlexRow">Group Name</div>
          <input tabindex="12" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Display Name"
            [(ngModel)]="GroupDisplayName">
        </div>
      </div>
      <div *ngIf="IsCreatingNewGroup" class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
          <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="AddNewSection_Click()">
            <span class="glb_Font glb_smallerText">Add New Group</span></button>
        </div>
      </div>
      <div *ngIf="!IsCreatingNewGroup" class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
          <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="GroupUpdate_Click()">
            <span class="glb_Font glb_smallerText">Update</span></button>
        </div>
      </div>
      <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
          <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
            (click)="Dialog_Close(GroupModalIdentifier)">
            <span class="glb_Font glb_smallerText">Cancel</span></button>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- Account Summary Config Template -->
  <ng-template #ConfigSummaryTemplate>
    <div class="glb_boxedPadding glb_formBGColor glb_Font">
      <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom">
          <button tabindex="30" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
            (click)="Dialog_Close(ConfigNameModalIdentifier)">
            <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
          </button>
        </div>
      </div>
      <div class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">Update Configuration Name</div>
      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
        <div class="glb_paddingBottomSM">
          <div class="text-white glb_inputFieldLabel glb_customFlexRow">Configuration Name</div>
          <input class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Account Summary Configuration Name"
            [(ngModel)]="ConfigurationSummaryName">
        </div>
      </div>
      <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
          <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
            (click)="ConfigNameUpdate_Click(ConfigNameModalIdentifier)">
            <span class="glb_Font glb_smallerText">Update</span></button>
        </div>
      </div>
      <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
          <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="Dialog_Close(ConfigNameModalIdentifier)">
            <span class="glb_Font glb_smallerText">Cancel</span></button>
        </div>
      </div>
    </div>
  </ng-template>
</div>