import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { LoanIndex } from '../LoanIndex/LoanIndex';
import { DataRow, DataUnit } from '@app/Global/Models/EntityModels';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import moment from 'moment';

@Component({
	selector: 'LoanHeader',
	templateUrl: './LoanHeader.html',
	styleUrls: ['./LoanHeader.scss']
})
export class LoanHeader implements OnInit, OnDestroy {

	//we need a ref to the loan component so that we can trigger events, e.g. grab new tab data from the server
	@Input() loanIndex: LoanIndex;

	//local copies of what we want to display in this component
	//left these 2 as the original observable style. will likely change it later.
	public AccountID: Observable<string>;
	public PrincipalBalance: Observable<string>;

	//use string values
	public ProductName: string;
	public PrincipalBorrower: string;
	public LoanStatus: string;

	//subscription for getting latest data from the client data store
	private accountSubscription: Subscription;

	constructor(public clientDataStore: ClientDataStore, public globalFunctions: GlobalFunctions) {
	}

	ngOnInit(): void {
		//this subscription ensure that we get the latest loan that was clicked on via the search screen.
		this.accountSubscription = this.clientDataStore.SelectedAccount.subscribe((account: any) => {
			if (account != null) {
				this.LoadAccount(account);
			}
		});
	}

	ngOnDestroy() {
		if (this.accountSubscription != null) {
			this.accountSubscription.unsubscribe();
		}
	}

	public LoadAccount(account: any) {
		this.AccountID = of(account.AccountID);
		this.PrincipalBalance = of(account.PrincipalBalance);

		//Bind the loan values to the class variables
		this.PrincipalBorrower = account.PrincipalBorrower;

		//Show Status: label only if we are not highlighting the loan status
		if ((!this.globalFunctions.isEmpty(this.loanIndex.LoanInArrears) && this.loanIndex.LoanInArrears) || (!this.globalFunctions.isEmpty(this.loanIndex.LoanInPendingDischarge) && this.loanIndex.LoanInPendingDischarge)) {
			this.LoanStatus = account.LoanStatus;
		}
		else {
			this.LoanStatus = "Status: " + account.LoanStatus;
		}

		this.ProductName = account.ProductName;
	}

	//Get CSS for highlighting text based on the class properties
	public LoanStatusCSS_Get(): string {

		//CSS to highlight the text
		const cssStr = "glb_highlightLabelSM glb_highlightLabelPadding";

		if (!this.globalFunctions.isEmpty(this.loanIndex.LoanInArrears) && this.loanIndex.LoanInArrears) {
			return cssStr + " glb_highlightLabelArrears";
		}
		else if (!this.globalFunctions.isEmpty(this.loanIndex.LoanInPendingDischarge) && this.loanIndex.LoanInPendingDischarge) {
			return cssStr + " glb_highlightLabelPendingDischarge";
		}

		//No need to highlight, return empty string
		return "";
	}

	//Check if Redraw is frozen on LoanIndex
	public CheckRedrawFrozen() {

		//We need to verify that Loan Index has been loaded first
		if (!this.globalFunctions.isEmpty(this.loanIndex)) {

			//Now check if redraw is frozen using the class variable
			if (this.loanIndex.RedrawFrozen) {
				return true;
			}
		}

		return false;
	}

	public AccountEnquiry() {
		//console.log('AccountEnquiry firing');
		//the entity in this case is always LoanTasks
		const entityName = "LoanTasks";

		//just use the same loan index, and launch the create modal page directly (using Entity_OpenModifyTemplate)
		const dataRow = new DataRow(this.loanIndex.AccountID, entityName, '', '', '', true);
		dataRow.EntityName = entityName;
		dataRow.AccountID = this.loanIndex.AccountID;

		//we want to default some values, and then hide them from the user. construct a new array of dataunits to store only the columns we are interested in. this will get items pushed into it as needed
		const iDataUnits = [];

		const chosenEntityName = entityName;
		//lookup the entity from the types so we can get the enum name needed for the lookup against the ClientViewModel later
		const chosenEntityLookup = this.clientDataStore.EntityTypes.filter(x => x.Name == chosenEntityName)[0];
		const chosenEntityEnumName = chosenEntityLookup.Name;
		//console.log('chosenEntityEnumName', chosenEntityEnumName);
		const chosenEntityFriendlyName = chosenEntityLookup.FriendlyName;
		//console.log('chosenEntityFriendlyName', chosenEntityFriendlyName);
		dataRow.EntityFriendlyName = chosenEntityFriendlyName;

		//create an array of all the data units we want to fill
		let dataUnitArray = [];

		//lookup the corresponding view model record
		let viewModelRecords = [];
		viewModelRecords = this.clientDataStore.ClientViewModelData.filter(x => x.Entity == chosenEntityEnumName);
		//console.log('viewModelRecords', viewModelRecords);

		//now loop through each view model and construct data units that we want for this particular style of 'Enquire' request, with adjusted defaults
		Object.entries(viewModelRecords.filter(x => !x.EditLock && (x.PropertyType !== 'entity'))).forEach(([rowkey, value]) => {
			//console.log('value', value);
			//construct a new dataunit to place into the form
			const createDataUnit = new DataUnit();

			//name, type and order are important
			createDataUnit.Name = value.PropertyName;
			createDataUnit.Type = value.PropertyType;
			createDataUnit.Order = value.DisplayOrder;
			createDataUnit.EditBoxSize = value.EditBoxSize;
			createDataUnit.Value = '';
			createDataUnit.ValueDisplay = '';
			createDataUnit.ControlGUID = '';
			createDataUnit.ControlDisplay = '';

			//Flag to determine if we need to add the data unit being processed
			let pushDataUnit = true;

			//Some data units we want to be auto filled with some value here, and then hidden on the form.
			if (value.PropertyName === 'Start Date') {
				//set it to todays date, with MM first
				const momentNow = moment();
				//Format the date to make it compatible with the mat-datepicker on the UI. 
				createDataUnit.Value = momentNow.format("MM/DD/YYYY");
				//Default HideCreate to false, as we want to control it's display from the client now
				createDataUnit.HideCreate = false;
				createDataUnit.ClientDisplay = false;
			}
			else if (value.PropertyName === 'Completed Date') {
				//doesnt need a default value
				createDataUnit.Value = "";
				createDataUnit.HideCreate = true;
			}
			else if (value.PropertyName === 'Due Date') {

				//Disable the due date
				createDataUnit.Disabled = true;
			}
			else if (value.PropertyName === 'Override Due Date') {

				//Hide override due date by default. It will be enabled later via checkbox
				createDataUnit.HideCreate = true;
				createDataUnit.MinDate = new Date();
			}
			else if (value.PropertyName === 'QC Passed') {

				//Don't add QC Passed
				pushDataUnit = false;
			}
			else if (value.PropertyName === 'Follow Up Date') {

				//Don't add Followup Date
				pushDataUnit = false;
			}
			else if (value.PropertyName === 'Assigned To') {
				//for this one, we should just set the 'Internal Department' association record on the loan. This is an autocomplete style record.
				//console.log('EntityDict', this.loanIndex.EntityDict['LoanAssociations'].Entity);
				const assocDataRows = this.loanIndex.EntityDict['LoanAssociations'].Entity;
				//console.log('assocDataRows', assocDataRows);

				//let internalDepartmentDataRow = null;
				let internalDepartmentDataUnit = null;
				let internalDepartmentClientName = null;
				let internalDepartmentClientGUID = null;

				//looks like we will need to loop through each data row, and then loop through each data unit.
				Object.entries(assocDataRows).forEach(
					([rowkey, value]) => {
						//console.log('assocDataRows element', value);
						//console.log('assocDataRows element dataunits', value['DataUnits']);

						//this is the guid for Internal Department
						const internalDepartmentSearch = value['DataUnits'].filter(x => x.Name == 'Association Type' && x.Value == '{9e6948a2-1559-4c0d-96c7-3bcc70fda7ea}')[0];

						if (!this.globalFunctions.isEmpty(internalDepartmentSearch)) {
							//console.log('we found an internal dept', internalDepartmentSearch);
							internalDepartmentDataUnit = internalDepartmentSearch;
							//internalDepartmentDataRow = value;
							//get the name of the client for the value display
							internalDepartmentClientName = value['DataUnits'].filter(x => x.Name == 'Client')[0].ValueDisplay;
							internalDepartmentClientGUID = value['DataUnits'].filter(x => x.Name == 'Client')[0].ControlGUID;
							//console.log('internalDepartmentClientName', internalDepartmentClientName);
						}
					});

				if (!this.globalFunctions.isEmpty(internalDepartmentDataUnit)) {
					createDataUnit.ControlGUID = internalDepartmentClientGUID;
					createDataUnit.ControlDisplay = internalDepartmentClientName;
					createDataUnit.Value = internalDepartmentClientGUID;
					createDataUnit.ValueDisplay = internalDepartmentClientName;
				}
				else {
					//we didnt find an internal dept. leave it blank
					createDataUnit.Value = '';
				}

				createDataUnit.HideCreate = true;
			}
			else if (value.PropertyName === 'Task Status') {
				//this is a control guid and value display. look it up from client data store
				const controlValue = this.clientDataStore.ControlData.filter(x => x.ControlType == 'Task Status' && x.ControlDisplay == 'Active')[0];
				//console.log('controlValue', controlValue);
				createDataUnit.ControlGUID = controlValue.ControlGUID;
				createDataUnit.ControlDisplay = controlValue.ControlDisplay;
				createDataUnit.Value = controlValue.ControlGUID;
				createDataUnit.ValueDisplay = controlValue.ControlDisplay;

				createDataUnit.HideCreate = true;
			}
			else if (value.PropertyName === 'Task Type') {
				//this is a drop down. but we want to apply a custom filter on the available choices. 
				//The way to do this is to give the data unit a custom filter name. On the other end, in LoanIndex, it will check and apply the filter there.
				createDataUnit.CustomFilter = 'AccountEnquiry';
			}

			//Add if the flag is true
			if (pushDataUnit === true) {
				dataUnitArray.push(createDataUnit);
			}
		});

		//reorder it correctly, based on the Order field in the view model
		dataUnitArray = dataUnitArray.sort((n1, n2) => n1.Order - n2.Order);

		//data units are ready, push them into the dataRow		
		dataRow.DataUnits = dataUnitArray;
		//console.log('iDataUnits', iDataUnits);

		//unselect any highlighted fields, this is to prevent a bug when returning from an account enquiry: https://dev.azure.com/amal-asset-management-limited/xchange/_workitems/edit/1271/
		this.loanIndex.DataUnit_RemoveSelected();

		//labelName is only for single data unit. not the case here, so leave it empty. and this is like a 'clone' request, so enable that flag
		this.loanIndex.Entity_OpenModifyTemplate(dataRow, '', entityName, true, null, null, 'Account Enquiry', true)
	}
}