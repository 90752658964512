import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

//Import all the component pages needed for routing
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { LoanSearch } from '@app/Components/Loan/LoanSearch/LoanSearch';
import { ErrorComponent } from '@app/Components/Error/Error';
import { HomeComponent } from '@app/Components/Home/Home';
import { Dashboard } from '@app/Components/Dashboard/Dashboard';
import { NavigationUrls } from '@app/Global/EnumManager';
import { PasswordReset } from '@app/Components/User/PasswordReset/PasswordReset';
import { UserInvite } from '@app/Components/User/UserInvite/UserInvite';
import { TwoFactorReset } from '@app/Components/User/TwoFactorReset/TwoFactorReset';
import { UserManagement } from '@app/Components/User/UserManagement/UserManagement';
import { UserProfile } from '@app/Components/User/UserProfile/UserProfile';
import { RoleManagement } from '@app/Components/User/RoleManagement/RoleManagement';
import { WorkflowDelegator } from '@app/Components/User/WorkflowDelegator/WorkflowDelegator';
import { LenderConfigurations } from '@app/Components/User/LenderConfigurations/LenderConfigurations';
import { Settlements } from '@app/Components/Settlements/Settlements';
import { SLAReporting } from '@app/Components/SLAReporting/SLAReporting';
import { AccountSummaryConfigurations } from '@app/Components/User/AccountSummaryConfigurations/AccountSummaryConfigurations';

const routes: Routes = [
	{ path: '', component: HomeComponent },

	{ path: NavigationUrls.AccountSearch.toString(), component: LoanSearch },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.AccountSearch.toString().toLowerCase(), component: LoanSearch },
	{ path: NavigationUrls.AccountSearch.toString().toUpperCase(), component: LoanSearch },

	{ path: NavigationUrls.Dashboard.toString(), component: Dashboard },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.Dashboard.toString().toLowerCase(), component: Dashboard },
	{ path: NavigationUrls.Dashboard.toString().toUpperCase(), component: Dashboard },

	{ path: NavigationUrls.PasswordReset.toString(), component: PasswordReset },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.PasswordReset.toString().toLowerCase(), component: PasswordReset },
	{ path: NavigationUrls.PasswordReset.toString().toUpperCase(), component: PasswordReset },

	{ path: NavigationUrls.UserInvite.toString(), component: UserInvite },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.UserInvite.toString().toLowerCase(), component: UserInvite },
	{ path: NavigationUrls.UserInvite.toString().toUpperCase(), component: UserInvite },

	{ path: NavigationUrls.TwoFactorReset.toString(), component: TwoFactorReset },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.TwoFactorReset.toString().toLowerCase(), component: TwoFactorReset },
	{ path: NavigationUrls.TwoFactorReset.toString().toUpperCase(), component: TwoFactorReset },

	{ path: NavigationUrls.UserManagement.toString(), component: UserManagement },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.UserManagement.toString().toLowerCase(), component: UserManagement },
	{ path: NavigationUrls.UserManagement.toString().toUpperCase(), component: UserManagement },

	{ path: NavigationUrls.UserProfile.toString(), component: UserProfile },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.UserProfile.toString().toLowerCase(), component: UserProfile },
	{ path: NavigationUrls.UserProfile.toString().toUpperCase(), component: UserProfile },

	{ path: NavigationUrls.RoleManagement.toString(), component: RoleManagement },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.RoleManagement.toString().toLowerCase(), component: RoleManagement },
	{ path: NavigationUrls.RoleManagement.toString().toUpperCase(), component: RoleManagement },

	{ path: NavigationUrls.WorkflowDelegator.toString(), component: WorkflowDelegator },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.WorkflowDelegator.toString().toLowerCase(), component: WorkflowDelegator },
	{ path: NavigationUrls.WorkflowDelegator.toString().toUpperCase(), component: WorkflowDelegator },

	{ path: NavigationUrls.Error.toString(), component: ErrorComponent },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.Error.toString().toLowerCase(), component: ErrorComponent },
	{ path: NavigationUrls.Error.toString().toUpperCase(), component: ErrorComponent },

	{ path: NavigationUrls.Account.toString(), component: LoanIndex },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.Account.toString().toLowerCase(), component: LoanIndex },
	{ path: NavigationUrls.Account.toString().toUpperCase(), component: LoanIndex },

	{ path: NavigationUrls.LenderConfigurations.toString(), component: LenderConfigurations },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.LenderConfigurations.toString().toLowerCase(), component: LenderConfigurations },
	{ path: NavigationUrls.LenderConfigurations.toString().toUpperCase(), component: LenderConfigurations },

	{ path: NavigationUrls.Settlements.toString(), component: Settlements },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.Settlements.toString().toLowerCase(), component: Settlements },
	{ path: NavigationUrls.Settlements.toString().toUpperCase(), component: Settlements },

	{ path: NavigationUrls.SLAReporting.toString(), component: SLAReporting },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.SLAReporting.toString().toLowerCase(), component: SLAReporting },
	{ path: NavigationUrls.SLAReporting.toString().toUpperCase(), component: SLAReporting },

	{ path: NavigationUrls.AccountSummaryConfigurations.toString(), component: AccountSummaryConfigurations },
	//Let lowercase or uppercase work too
	{ path: NavigationUrls.AccountSummaryConfigurations.toString().toLowerCase(), component: AccountSummaryConfigurations },
	{ path: NavigationUrls.AccountSummaryConfigurations.toString().toUpperCase(), component: AccountSummaryConfigurations },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
	exports: [RouterModule]
})
export class AppRoutingModule { }

//This class has been adapted from:
//https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular
//To allow pages/Components to be reloaded, and also a new feature - caching entire pages.
export class CustomReuseStrategy implements RouteReuseStrategy {
	//This is a new feature - we can place page names from the NavigationUrls enum here, and they become cached for the lifetime of the app!
	//Below is an example, where the Dashboard and Search pages are cached. While valuable in reducing API calls, some pages it may not make sense to do this. e.g. search results is an excellent candidate for app lifetime caching, whereas the Dashboard might be better to refresh on each visit. Let's discuss and decide later on this topic.
	//RoutesToCache: string[] = [NavigationUrls.Dashboard.toString(), NavigationUrls.AccountSearch.toString()];

	//Going to leave it empty for now though, as review shows there are some caveats and bugs that need to be addressed.
	//First, we need to consider page caching in between login sessions. This might leak data in an unintended fashion between different users. We can avoid this by using window.location.reload() while logging out, but what if a different user logs after being presented with a login screen in the middle of a session? We would need to consider some way of clearing the StoredRouteHandles variable below after each login attempt. Not sure how to do this yet.
	//In addition, I also discovered an unfortunate bug with ngx-paginator. It doesn't appear to retain its state properly when cached, I think its a design issue, in the way it stores it's content. When I used my own template (e.g. charts), it seems to work fine. The solution I can think of here involves moving away from ngx-paginator, and using a different library, e.g. primng. Something to consider in the future.
	RoutesToCache: string[] = [];

	//These are references to the actual cached pages. They can contain multiple components (e.g. its nested children, if showing multiple components). Clearing this would be ideal at each login, but not sure if possible. We need to DI this class and clear this dictionary.
	StoredRouteHandles = new Map<string, DetachedRouteHandle>();

	//These are the standard angular methods that we are overriding
	//Decides if the route should be stored. 
	//Disable eslint as we don't control the method name (it's part of the angular library)
	//eslint-disable-next-line @typescript-eslint/naming-convention
	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		//Here we check if the page/route we are hitting should be cached, as per the statically declared values in the RoutesToCache dictionary
		return this.RoutesToCache.indexOf(route.routeConfig.path) > -1;
	}

	//Store the information for the route we're leaving/destroying
	//Disable eslint as we don't control the method name (it's part of the angular library)
	//eslint-disable-next-line @typescript-eslint/naming-convention
	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		this.StoredRouteHandles.set(route.routeConfig.path, handle);
	}

	//Return true if we have a stored route object for the next route
	//Disable eslint as we don't control the method name (it's part of the angular library)
	//eslint-disable-next-line @typescript-eslint/naming-convention
	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		//Had to add a null check here, otherwise it would fail in our app
		if (route.routeConfig !== null) {
			//We are checking if we have an existing page in the cache that we can pop out for reuse here
			return this.StoredRouteHandles.has(route.routeConfig.path);
		}
		return false;
	}

	//If we returned true in shouldAttach(), now return the actual route data for restoration
	//Disable eslint as we don't control the method name (it's part of the angular library)
	//eslint-disable-next-line @typescript-eslint/naming-convention
	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
		return this.StoredRouteHandles.get(route.routeConfig.path);
	}

	//Reuse the route if we're going to and from the same route
	//Disable eslint as we don't control the method name (it's part of the angular library)
	//eslint-disable-next-line @typescript-eslint/naming-convention
	shouldReuseRoute(): boolean {
		//Use these lines instead if you want to reuse the same component when navigating to the same url. We don't actually want this, as we want the same url to reload
		//Add this to the parameters
		//shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		//And uncomment this
		//return future.routeConfig === curr.routeConfig;

		//Have to return false if you want to be able to refresh pages (when vising the same url). This works in tandem with the onSameUrlNavigation: 'reload' that is passed to the router.navigate calls throughout the app
		return false;
	}
}