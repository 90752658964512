import { Component, OnInit, ViewChildren, QueryList, ViewChild, EventEmitter } from '@angular/core';
import { DashboardCalendar, IChartDef, ILabelColor } from '@app/Components/Dashboard/DashBoardCalendar';
import { ApiService } from '@app/Services/APIService';
import moment from 'moment';
import { ChartComponent } from '@app/Components/Dashboard/Charts/CustomChart';
import { Router } from '@angular/router';
import { AccountsControllerMethods, RouteActions } from '@app/Global/EnumManager';
import { ClientDataStore, StoreItemTypes } from '@app/Global/ClientDataStore';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { QualityCheckingQueue } from '@app/Components/User/QualityCheckingQueue/QualityCheckingQueue';
import { WorkflowDelegator } from '@app/Components/User/WorkflowDelegator/WorkflowDelegator';
import { AccountManager } from '@app/Components/User/AccountManager/AccountManager';
import { LenderTasks } from '@app/Components/User/LenderTasks/LenderTasks';
import { ConditionSubsequent } from '@app/Components/User/ConditionSubsequent/ConditionSubsequent';

@Component({
  selector: 'Dashboard',
  templateUrl: './Dashboard.html',
  styleUrls: ['./Dashboard.scss']
})
export class Dashboard implements OnInit {
  constructor(private apiService: ApiService, private router: Router, private clientDataStore: ClientDataStore, private globalFunctions: GlobalFunctions) {
    //This is called even when we hit this after a reroute. So grab and update the router state here. Will be used used later in onInit to determine whether or not to reuse cache, or grab fresh data.
    this.SetRouterState();
    //console.log("Dashboard.navState: ", this.navState);

    //Init child chart props, it might be empty on startup and give null errors
    this.ChildChartProps = {};

    this.LabelColor.push({ label: "Current", color: "lightgreen", hovercolor: "lightgreen" });
    this.LabelColor.push({ label: "Loan Discharged", color: "brown", hovercolor: "brown" });
    this.LabelColor.push({ label: "Arrears", color: "red", hovercolor: "red" });
    this.LabelColor.push({ label: "default", color: "#00adef", hovercolor: "white" });
    this.LabelColor.push({ label: "Current - No Interest Accrual", color: "blue", hovercolor: "blue" });
    this.LabelColor.push({ label: "Loan Withdrawn", color: "purple", hovercolor: "purple" });
    this.LabelColor.push({ label: "Pending Settlement", color: "aqua", hovercolor: "aqua" });
    this.LabelColor.push({ label: "Ready for Settlement", color: "aqua", hovercolor: "aqua" });
    this.LabelColor.push({ label: "Loan Discharged - Own Funds", color: "brown", hovercolor: "brown" });
    this.LabelColor.push({ label: "Current - No Interest Accrual", color: "brown", hovercolor: "brown" });
    this.LabelColor.push({ label: "Loan Discharged - Refinance", color: "brown", hovercolor: "brown" });
    this.LabelColor.push({ label: "Loan Discharged - Sale", color: "brown", hovercolor: "brown" });
    this.LabelColor.push({ label: "Manual Adjustment", color: "orange", hovercolor: "orange" });
    this.LabelColor.push({ label: "Portfolio Handed Back", color: "grey", hovercolor: "grey" });
  }

  ngOnInit(): void {
    //enable the search bar again.
    this.clientDataStore.Update_HideSearchBar(false);
    this.ChildChartProps = {};
    //start with no charts, leave it on false
    this.NoCharts = false;

    //let's check what other dashboard entities we can load. we can check the claims returned by login data, and if it contains certain keys, then we can load certain entities (e.g. the workflow delegator)
    //console.log('this.clientDataStore.ClientClaims', this.clientDataStore.ClientClaims);
    if (!this.globalFunctions.isEmpty(this.clientDataStore.ClientClaims)) {

      //Track if we have any content on the dashboard.
      let anyDashboardContent = false;

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "WorkflowDelegator").length > 0) {
        //Enable the workflow delegator 
        this.EnableWorkflowDelegator = true;
        anyDashboardContent = true;
      }
      else {
        this.EnableWorkflowDelegator = false;
      }

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "ShowAllChartLabels").length > 0) {
        //Enable the chart labels
        this.ShowAllDataLabels = true;
      }

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "AccountManager").length > 0) {
        //Enable the Account Manager 
        this.EnableAccountManager = true;
        anyDashboardContent = true;
      }
      else {
        this.EnableAccountManager = false;
      }

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "LenderTasks").length > 0) {
        //Enable the lender tasks
        this.EnableLenderTasks = true;
        anyDashboardContent = true;
      }
      else {
        this.EnableLenderTasks = false;
      }

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "ConditionSubsequentTasking").length > 0) {
        //Enable the condition subsequent dashboard
        this.EnableConditionSubsequent = true;
        anyDashboardContent = true;
      }
      else {
        this.EnableConditionSubsequent = false;
      }

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "AccountEnquiries").length > 0) {
        this.EnableAccountEnquiries = true;
        anyDashboardContent = true;
      }
      else {
        this.EnableAccountEnquiries = false;
      }

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "QualityCheckingQueue").length > 0) {
        //enable the workflow delegator 
        this.EnableQualityCheckingQueue = true;
        anyDashboardContent = true;
      }
      else {
        this.EnableQualityCheckingQueue = false;
      }

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "ChartViewer").length > 0) {
        //enable the chart viewer
        this.EnableChartViewer = true;
        this.SyncChartAggregateData();
        anyDashboardContent = true;
      }
      else {
        this.EnableChartViewer = false;
        this.ShowSpinner = false;
      }

      //check if we loaded any dashboard content at all
      if (!anyDashboardContent) {
        //and lets show a empty message if there is nothing
        this.NoCharts = true;
      }
    }
    else {
      //we have no claims, so no data! set spinner to false
      this.ShowSpinner = false;
      //and lets also to show a no charts message
      this.NoCharts = true;
    }
  }

  //This lets us access a collection of child components inside this parent. these are the charts.
  @ViewChildren('chart', { read: ChartComponent }) private ChartComponents: QueryList<ChartComponent>;

  //Child components that are all displayed on the dashboard. Use these refs so that we can bind and refresh them from callbacks
  @ViewChild('QualityCheckingQueue') QualityCheckingQueue: QualityCheckingQueue;
  @ViewChild('WorkflowDelegator') WorkflowDelegator: WorkflowDelegator;
  @ViewChild('AccountManager') AccountManager: AccountManager;
  @ViewChild('LenderTasks') LenderTasks: LenderTasks;
  @ViewChild('ConditionSubsequent') ConditionSubsequent: ConditionSubsequent;
  //Currently not used
  //@ViewChild('AccountEnquiries') accountEnquiries: AccountEnquiries;

  //Should we show the main spinner
  public ShowSpinner = true;

  //Make an array to store the chart definitions
  public ChartDefs: Array<IChartDef> = new Array<IChartDef>();
  public LabelColor: Array<ILabelColor> = new Array<ILabelColor>();

  //How long to delay before starting to draw the chartJS component
  public ChartDrawDelay = 50;

  //This is used to indicate if no charts were provided by the server
  public NoCharts = false;

  //The arrays for periods are here
  //Historical data
  public DashboardArrayHistorical: DashboardCalendar[] = new Array<DashboardCalendar>();
  //Future dated data
  public DashboardArrayFuture: DashboardCalendar[] = new Array<DashboardCalendar>();

  //Store an array of unique lenders
  public LenderArray: string[] = new Array<string>();
  //Child array which we can establish 2 way binding with chart children. used to show or hide charts based on a property on the child chart component
  public ChildChartProps: { [key: string]: any };

  //Whether or not we want to show the custom dashboard components
  public EnableWorkflowDelegator = false;
  public EnableAccountManager = false;
  public EnableQualityCheckingQueue = false;
  public EnableChartViewer = false;
  public EnableLenderTasks = false;
  public EnableAccountEnquiries = false;
  public EnableConditionSubsequent = false;
  public ShowAllDataLabels = false;

  //Used by the custom paginator for lender charts
  public PaginatorConfig = {
    id: 'ChartPaginator',
    itemsPerPage: 1,
    currentPage: 1,
    previousLabel: '«',
    nextLabel: '»',
    directionLinks: true,
    trackByIndex(index: number) {
      return index;
    },
    autoHide: true,
    responsive: true,
    screenReaderPageLabel: '',
    screenReaderCurrentLabel: '',
    screenReaderPaginationLabel: '',
    pageBoundsCorrection: new EventEmitter<number>()
  };

  //Get the lender name based on the paginator current page, try to get a consistent max character length
  public GetLenderNameFromPage(pageNumber: number): string {
    if (!this.globalFunctions.isEmpty(pageNumber)) {
      //console.log('this.LenderArray[pageNumber]', this.LenderArray[pageNumber]);
      //Get the name of the lender from the Array, based on the page index
      let lenderName = this.LenderArray[pageNumber - 1];

      //See if we want to add some dots to indicate that its a longer name
      let addDots = false;

      //Is it greater than the limit
      if (lenderName.length > 40) {
        //Yes, are are going to add some dots
        addDots = true;
      }

      //Now get the first 40 characters
      lenderName = lenderName.substring(0, 40);

      //Check if we want to add some dots to the display to indicate its a longer name
      if (addDots) {
        lenderName = lenderName + '...'
      }

      return lenderName;
    }
    else {
      //If no page number, just return the original label
      return pageNumber.toString();
    }
  }

  //Cache date - the last time the dashboard data was retrieved from the server
  private CacheDate: Date;

  //Other shared chart related config
  //Let's go back as far as we need. make it 24 months - the server will just have have we need to see
  private static readonly StartPeriodHistorical: number = -24;
  //calculate the end period - which is just up to today (or today + 1 month).
  private static readonly EndPeriodHistorical: number = 1;

  //Future data - starts from today, and goes up to 24 months ahead
  private static readonly StartPeriodFuture: number = 0;

  //24 months ahead
  private static readonly EndPeriodFuture: number = 24;

  //How long the dashboard data is held before it is pulled again from the server. reduce this number for testing (maybe 15 seconds). Should just turn it into a config value and read it from environment.ts file. TODO. currently set to 3 hours directly here
  private static readonly CacheExpiryLengthSeconds: number = 10800;

  //Array that contains the monthly dashboard data. This one gets cached and is used as a 'staging' array to populate into the historical or future ones
  private DashboardArray: DashboardCalendar[] = new Array<DashboardCalendar>();

  //For storing and refreshing the router state on this component
  private NavState: string;

  //Checks if we need to ask server for new chart data, or if we can use the cache. Will kick off a refresh of chart data
  public SyncChartAggregateData() {
    //If returning, check and see if we can reuse cache
    if (this.NavState === RouteActions[RouteActions.ReturnToDashboard]) {
      //Grab the last cache date
      const prevCacheDate = JSON.parse(sessionStorage.getItem(StoreItemTypes[StoreItemTypes.DashboardDataCacheDate]));
      //console.log("Prev dashboardCacheDate: ", prevCache);

      if (prevCacheDate != null && prevCacheDate != "") {
        //console.log("restoring prior dashboard cache date");
        this.CacheDate = prevCacheDate;
      }

      const dashboardArrayPrev = JSON.parse(sessionStorage.getItem(StoreItemTypes[StoreItemTypes.DashboardData]));
      //console.log("Prev dashboardArray: ", prev);
      if (dashboardArrayPrev != null && dashboardArrayPrev != "") {
        //console.log("restoring prior dashboardArray data");
        this.DashboardArray = dashboardArrayPrev;
        //We need to call the process method to parse it into the filtered array.
        this.ProcessDataIntoFilteredArrays(dashboardArrayPrev);
      }
      else {
        //We have no data. set cache date to empty to force it to redownload
        this.CacheDate = null;
      }

      //Refresh data now
      this.RefreshData();
    }
    else {
      //If we are hitting this page for the first time, just get the data
      this.GetAPIDashboardSubscription(AccountsControllerMethods[AccountsControllerMethods.GetLenderDashboardData]);
    }
  }

  //Gets the state from the router, so that we can use it to modify our load process. e.g. don't reload ODS data and just use cache, if we are returning to the dashboard page
  private SetRouterState() {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras?.state as {
      Link: string;
    };

    if (state != null) {
      this.NavState = state.Link;
    }
  }

  //Init and fill the chart definitions
  private InitChartDefs() {
    //Let's initialize the chart definitions. could potentially come from as server call. at the moment we refill the array every call. could cache this as well, if we wanted to.
    this.ChartDefs = new Array<IChartDef>();
    this.ChartDefs.push({ chartName: "Principal Balances", chartColumns: "PrincipalBalance_lbl_Principal_Balances", formatCurrency: true, chartType: 'bar', chartDataStyle: 'regular', isStacked: true, groupByPortfolio: false });
    this.ChartDefs.push({ chartName: "Total Arrears Balances", chartColumns: "TotalArrearsBalance_lbl_Total_Arrears_Balances", formatCurrency: true, chartType: 'bar', chartDataStyle: 'regular', isStacked: true, groupByPortfolio: false });
    this.ChartDefs.push({ chartName: "Settlement Balances", chartColumns: "PrincipalBalance_lbl_Settlement_Balances", formatCurrency: true, chartType: 'bar', chartDataStyle: 'regular', isStacked: true, groupByPortfolio: false });
    this.ChartDefs.push({ chartName: "Maturity Balances", chartColumns: "PrincipalBalance_lbl_Maturity_Balances", formatCurrency: true, chartType: 'bar', chartDataStyle: 'regular', isStacked: true, groupByPortfolio: false });
    this.ChartDefs.push({ chartName: "Loan Status By Lender", chartColumns: "Status_", formatCurrency: false, chartType: 'bar', chartDataStyle: 'regular', isStacked: true, groupByPortfolio: true });
    this.ChartDefs.push({ chartName: "Status Breakdown By Lender (Current Period)", chartColumns: "Status_", formatCurrency: false, chartType: 'pie', chartDataStyle: 'pivot', isStacked: false, groupByPortfolio: true })
  }

  //Checks and retrieves new data from the server. if needed
  public RefreshData(triggerRedraw = false) {
    //console.log("calling RefreshData");
    //let's check and see if the cache is stale. subtract the expiry seconds, if its older than that, request new data 
    //console.log("this.GetJScriptTime(this.cacheDate", this.GetJScriptTime(this.cacheDate));
    //console.log("this.GetJScriptTime(now less x seconds", this.GetJScriptTime(moment(new Date()).subtract(Dashboard.CacheExpiryLengthSeconds, 'seconds')));

    if (this.globalFunctions.getJScriptTime(this.CacheDate) <
      this.globalFunctions.getJScriptTime(moment(new Date()).subtract(Dashboard.CacheExpiryLengthSeconds, 'seconds'))) {
      //console.log("Dashboard cache is stale, getting new data...")
      this.GetAPIDashboardSubscription(AccountsControllerMethods[AccountsControllerMethods.GetLenderDashboardData], triggerRedraw);
    }
    else {
      //cache is pretty fresh, so we can reuse data. call RefreshCharts instead
      //console.log("Dashboard cache is fresh enough, reusing existing data...")
      this.RefreshCharts(triggerRedraw);
    }
  }

  //Kicks off a refresh of the display of all child charts
  public RefreshCharts(triggerRedraw = false) {
    //console.log("calling RefreshCharts");

    //Refresh the charts. On init, we need a small delay. For some reason, the dashboard variable is not injected in first time around.
    this.globalFunctions.delay(this.ChartDrawDelay).then(any => {

      //Loop through each chart component and construct the chart
      this.ChartComponents.forEach(component => {
        if (triggerRedraw) {
          component.DisplayCharts();
        }
      });

    });

    this.ShowSpinner = false;
  }

  //Get a child component property
  public GetChildProperty(propertyName: string) {
    if (this.ChildChartProps != null && Object.keys(this.ChildChartProps).length > 0) {
      if (!this.globalFunctions.isEmpty(this.ChildChartProps) && !this.globalFunctions.isEmpty(this.ChildChartProps[propertyName])) {
        return !this.ChildChartProps[propertyName].isHidden;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  //Disable/unslect all items except the selected component (to remove action bars of other items)
  public DisableContextActionBars(targetItem: any) {
    if (!this.globalFunctions.isEmpty(this.WorkflowDelegator) && targetItem != 'WorkflowDelegator') {
      this.WorkflowDelegator.AllTasks_Deselect(true);
    }

    if (!this.globalFunctions.isEmpty(this.QualityCheckingQueue) && targetItem != 'QualityCheckingQueue') {
      this.QualityCheckingQueue.ClearUIArrays(false);
    }

    if (!this.globalFunctions.isEmpty(this.AccountManager) && targetItem != 'AccountManager') {
      this.AccountManager.ClearUIArrays(false);
    }

    if (!this.globalFunctions.isEmpty(this.LenderTasks) && targetItem != 'LenderTasks') {
      this.LenderTasks.UIArrays_Clear(false);
    }

    //Currently disabled
    // if (!this.globalFunctions.isEmpty(this.accountEnquiries) && targetItem != 'AccountEnquiries') {
    //   this.accountEnquiries.ClearUIArrays(false);
    // }
  }

  //Refresh any child task based components that exist
  public RefreshTaskData(refreshWorkflowDelegator = true) {
    //console.log('calling Dashboard.RefreshTaskData')

    //Workflow delegator may not need updating, it refreshes itself after a task is reassigned
    if (!this.globalFunctions.isEmpty(this.WorkflowDelegator) && refreshWorkflowDelegator) {
      this.WorkflowDelegator.DashboardAggregated_Refresh();
    }

    if (!this.globalFunctions.isEmpty(this.QualityCheckingQueue)) {
      this.QualityCheckingQueue.RefreshDashboardDataAggregated();
    }

    if (!this.globalFunctions.isEmpty(this.AccountManager)) {
      this.AccountManager.RefreshDashboardDataAggregated();
    }

    if (!this.globalFunctions.isEmpty(this.LenderTasks)) {
      this.LenderTasks.DashboardAggregated_Refresh();
    }

    if (!this.globalFunctions.isEmpty(this.ConditionSubsequent)) {
      this.ConditionSubsequent.Aggregate_Refresh();
    }

    //Currently not used
    // if (!this.globalFunctions.isEmpty(this.accountEnquiries)) {
    //   this.accountEnquiries.RefreshDashboardDataAggregated();
    // }
  }

  //Paginator page change method
  public OnPageChange(page: number): void {
    this.PaginatorConfig.currentPage = page;

    //This sets the scroll of the bottom of the page. This is because the charts are displayed last, and as you page through them, the page might redraw, sending you up a little bit. This way, we can anchor against the bottom, where the paginator is located, even after mulitple clicks
    this.globalFunctions.delay(this.ChartDrawDelay + 20).then(any => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  //Calls the server for chart data (from ODS)
  private GetAPIDashboardSubscription(itemType: string, triggerRedraw = false): void {
    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, itemType).subscribe(apiResponse => {
      //maybe we should check the response here? how do we stop execution otherwise? just check null and stop
      if (this.globalFunctions.isEmpty(apiResponse)) {
        //get rid of the spinner
        this.ShowSpinner = false;
        //but we also want to show a no charts message? maybe?
        //this.noCharts = true;
        return;
      }
      return this.ProcessAPIDashboardResponse(apiResponse, triggerRedraw);
    });
  }

  //Receives the dashboard data response from the server, stores it into cache.
  private ProcessAPIDashboardResponse(apiResponse, triggerRedraw = false): void {
    //Dont forget to use stringify on it first to remove the invalid JSON tokens
    //Parse it straight into the target array we need
    this.DashboardArray = <DashboardCalendar[]>JSON.parse(JSON.stringify(apiResponse));
    //Now we have an array of the DataType we are interested in.
    //console.log("ProcessAPIDashboardResponse.this.dashboardArray: ", this.dashboardArray);

    //Need to unescape some columns
    this.DashboardArray.forEach(item => {
      item.LenderName_lbl_Lender_Name = this.globalFunctions.HTMLUnescape(item.LenderName_lbl_Lender_Name);
      item.PortfolioName_lbl_Portfolio_Name = this.globalFunctions.HTMLUnescape(item.PortfolioName_lbl_Portfolio_Name);
    });

    //This processes it into the filtered arrays that the charts use
    this.ProcessDataIntoFilteredArrays(this.DashboardArray);

    //Lets update the cache date
    this.CacheDate = new Date();

    //Persist the cacheDate and array of dashboard data into the store
    sessionStorage.setItem(StoreItemTypes[StoreItemTypes.DashboardData], JSON.stringify(this.DashboardArray));
    sessionStorage.setItem(StoreItemTypes[StoreItemTypes.DashboardDataCacheDate], JSON.stringify(this.CacheDate));

    //Fefresh the charts
    this.RefreshCharts(triggerRedraw);
  }

  //This processes the dashboard data into the filtered arrays that the child chart components will eventually consume
  private ProcessDataIntoFilteredArrays(dashboardArray: DashboardCalendar[]) {
    //We may get data for multiple lenders. we need to dynamically determine the unique number of lender data supplied, and 
    //Loop through that client side for the display. each lender will display a fixed set of chart types.

    //Using triple dot ... projects it into an array.
    this.LenderArray = [...new Set(dashboardArray.map(item => item.LenderName_lbl_Lender_Name))];

    //Sort it by lender name, if we want
    this.LenderArray = this.LenderArray.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    });
    //console.log("ProcessDataIntoFilteredArrays.this.lenderArray: ", this.lenderArray);

    //Init the chart definitions
    this.InitChartDefs();

    //Lets filter and create 2 versions. one for historical data, and one for future.
    let startDate = this.globalFunctions.monthAdder(Dashboard.StartPeriodHistorical);
    let endDate = this.globalFunctions.monthAdder(Dashboard.EndPeriodHistorical);

    //Clone the dashboard data here
    let clonedDashData = this.globalFunctions.deepClone<DashboardCalendar[]>(dashboardArray);

    //Chuck it into a filtered array, based on the date period that we want.
    this.DashboardArrayHistorical = clonedDashData.filter((item: DashboardCalendar) => {
      return this.globalFunctions.getJScriptTime(item.CalendarDate) >= this.globalFunctions.getJScriptTime(startDate)
        && this.globalFunctions.getJScriptTime(item.CalendarDate) <= this.globalFunctions.getJScriptTime(endDate)
    });
    //console.log("ProcessDataIntoFilteredArrays.this.dashboardArrayHistorical: ", this.dashboardArrayHistorical)

    //Future data - starts from today, and goes up to 24 months ahead
    startDate = this.globalFunctions.monthAdder(Dashboard.StartPeriodFuture);

    //Calculate the end period
    endDate = this.globalFunctions.monthAdder(Dashboard.EndPeriodFuture);
    clonedDashData = this.globalFunctions.deepClone<DashboardCalendar[]>(dashboardArray);

    //Chuck it into a filtered array, based on the date period that we want.
    this.DashboardArrayFuture = clonedDashData.filter((item: DashboardCalendar) => {
      return this.globalFunctions.getJScriptTime(item.CalendarDate) >= this.globalFunctions.getJScriptTime(startDate)
        && this.globalFunctions.getJScriptTime(item.CalendarDate) <= this.globalFunctions.getJScriptTime(endDate)
    });
    //console.log("ProcessDataIntoFilteredArrays.this.dashboardArrayFuture: ", this.dashboardArrayFuture)
  }
}