<div id="PG_DocumentGenerator" class="glb_formBGColor glb_Font text-white glb_paddingBottomMD" [ngClass]="ModalIdentifier">

  <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow">
    <div class="glb_headerBarBGDark glb_customFlexRow justify-content-end col-12" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button id="BTN_MinimizeResiPayout" tabindex="4"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.MinimizedDialogs_Add(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'compress-alt']" class="fa-md glb_iconColorDark" matTooltip="Click to minimize"></fa-icon>
        </button>
        <button id="BTN_CloseDocumentGenerator" tabindex="3"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'times']" class="fa-md glb_iconColorDark"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="glb_customFlexRow glb_boxedPadding">
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">
        <div class="col-8 glb_paddingZeroOffset">
          <div class="glb_TitleLG glb_TitleLGMobile text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
            Document Generator
          </div>
        </div>

        <br>
        <br>

        <div class="glb_customFlexRow justify-content-left glb_paddingBottomSM col-12">
          <div class="glb_paddingBottomSM glb_paddingZeroOffset col-10 col-lg-6">
            <div class="glb_inputFieldLabel glb_customFlexRow text-white">Select a document template</div>
            <div class="glb_customFlexRow row-cols-1 justify-content-left align-items-left text-dark">
              <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-dark-custom mat-autocomplete-custom" appearance="outline">
                <input #FocusDocumentName id="INP_DocumentTemplateGUID" tabindex="1" class="glb_loginInputBox glb_skinLightOutline"
                  (click)="AutoComplete_ControlDocument_ApplyFilter(DocumentInsertAutoComplete, 'Search', insertDocument.value, 'Control Document Template', true)"
                  (keydown)="AutoComplete_ControlDocument_ApplyFilter(DocumentInsertAutoComplete, 'Search', insertDocument.value, 'Control Document Template', true)"
                  #insertDocument type="text" matInput [matAutocomplete]="insertDocument_autoComplete" [(ngModel)]="InsertDocumentBind"
                  placeholder="Enter a value">
                <mat-autocomplete #insertDocument_autoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                  (optionSelected)="AutoComplete_SaveSelectedTemplate_ToGenerate($event.option.value)">
                  <div
                    *ngFor="let control of AutoComplete_ControlDocument_ApplyFilter(DocumentInsertAutoComplete, 'Search', insertDocument.value, 'Control Document Template')">
                    <mat-option class="glb_Font" [value]=control> {{control.ControlValue}} </mat-option>
                  </div>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <!-- Button to clear content inside the input box, spans a div of its own -->
          <div class="glb_customFlexRow justify-content-end align-items-center" style="padding-bottom: 8px;">
            <div class="col-2 glb_paddingBottomSM glb_paddingZeroOffset">
              <button class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                type="button" matTooltip="Click to Clear" (click)="Control_ClearSelected()">
                <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <br>
        <br>
        <br>
        <br>
        <br>

        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-10">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_customFlexRow">Note: Documents generated via this button are also saved against the "Documents" Tab of this
              Account.</div>
          </div>
        </div>

        <!-- Generate button -->
        <div class="glb_customFlexRow row-cols-1 justify-content-left col-12">
          <div class="glb_paddingZeroOffset col-12 col-lg-6">
            <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
              <div *ngIf="!IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
                <button id="BTN_GenerateDocument" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                  (click)="Document_Generate()">
                  <span class="glb_Font glb_smallerText">Generate</span></button>
              </div>
              <div *ngIf="IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
                <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                  <span class="glb_Font glb_hiddenText glb_smallerText">Generate</span>
                  <div class="spinner-border glb_floatingSpinner"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>