<div id="INP_FormInputDataUnit" class="col-12 glb_customFlexNoWrapRow">
    <div class="glb_customFlexRow align-self-end glb_flexGrow">
        <div #INP_DataUnit id="{{ID}}" class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white text-start glb_maxWidth">
            {{LookupDataItem.DisplayName}}
        </div>

        <!-- This is for textarea type inputs -->
        <!-- (change) and (blur) ensure that when the input is modified or clicked away, we can refire our Input Sync -->
        <!-- (click) and (input) ensure that when the input has any key stroke (including delete or backspace), we can refire our Input Helper Sync -->
        <textarea *ngIf="LookupDataItem.HTMLInputType==='textarea'" id="DU_TextArea" class="glb_textArea_SM glb_Font glb_maxWidth"
            [ngClass]="Input_Style()" placeholder="{{LookupDataItem.Placeholder}}" [ngModel]="ModelDataUnit_Get(INP_DataUnit).ValueDisplay"
            (change)="TextArea_OnBlur(INP_DataUnit, OP_DataUnit)" (focus)="TextArea_OnFocus(INP_DataUnit)"
            (blur)="TextArea_OnBlur(INP_DataUnit, OP_DataUnit)" (input)="TextArea_Sync(INP_DataUnit, OP_DataUnit, $event)"
            (click)="InputHelperView_Sync(INP_DataUnit, OP_DataUnit, $event)" [disabled]="LookupDataItem.Disabled"></textarea>

        <!-- This is for dropdown type inputs. Currently using mat-select and mat-option -->
        <mat-form-field *ngIf="LookupDataItem.HTMLInputType==='dropdown'" appearance="fill" class="glb_Font glb_paddingZeroOffset">
            <mat-select id="DU_MatSelect" #matSelect class="glb_Font glb_formInputBoxDropDown_SM glb_skinLightOutline text-dark"
                [ngClass]="Input_Style()" [(value)]="ModelDataUnit_Get(INP_DataUnit).Value"
                (selectionChange)="ModelDataUnit_Update(INP_DataUnit, $event)" placeholder="{{LookupDataItem.Placeholder}}"
                [disabled]="LookupDataItem.Disabled" (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                <div *ngFor="let option of DropdownOptions">
                    <mat-option class="glb_Font" value={{option.ControlGUID}}>
                        {{option.ControlValue}}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>

        <!-- This is for autocomplete inputs. Currently using mat-autocomplete -->
        <mat-form-field *ngIf="LookupDataItem.HTMLInputType==='autocomplete'"
            class="glb_Font mat-autocomplete-light-custom glb_roundedBorderSM glb_entityBackground glb_autoCompleteInput_SM glb_paddingZeroOffset mat-autocomplete-custom"
            appearance="outline">
            <input (click)="AutoComplete_Client_ApplyFilter(AutoCompleteData, 'Search',
                                                              INP_Autocomplete.value, AutoCompleteData.Client_AutoCompleteControlTypeName, true)"
                (keydown)="AutoComplete_Client_ApplyFilter(AutoCompleteData, 'Search',
                                                              INP_Autocomplete.value, AutoCompleteData.Client_AutoCompleteControlTypeName, true)"
                (blur)="AutoComplete_OnLeave()" #INP_Autocomplete type="text" matInput [matAutocomplete]="INP_Mat_AutoComplete"
                placeholder="Enter a value to search" class="mat-form-field-autocompleteplaceholder"
                [ngModel]="ModelDataUnit_Get(INP_DataUnit).AutoCompleteControlData" (ngModelChange)="ModelDataUnit_Update(INP_DataUnit, $event)">
            <mat-autocomplete #INP_Mat_AutoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName" [panelWidth]="500">
                <div *ngFor="let control of AutoComplete_Client_ApplyFilter(AutoCompleteData, 'Search',
                                                                INP_Autocomplete.value, AutoCompleteData.Client_AutoCompleteControlTypeName)">
                    <mat-option class="glb_Font" [value]=control>
                        {{control.ControlValue}}
                    </mat-option>
                </div>
            </mat-autocomplete>
        </mat-form-field>

        <!-- This is a primeng multiselect-->
        <p-multiSelect class="col-12" *ngIf="LookupDataItem.HTMLInputType==='p-multiselect-single'" #INP_MultiselectSingle id="DU_MultiselectSingle"
            [options]="MultiselectSingleOptions" [(ngModel)]="SelectedMultiselectSingleOptions" optionLabel="name" [appendTo]="'body'" [filter]="true"
            [virtualScroll]="true" [virtualScrollItemSize]="40" [resetFilterOnHide]="true" [selectAll]="false" [showToggleAll]="false"
            [showClear]="false" [showHeader]="true" [disabled]="false" placeholder="Choose option"
            (onChange)="MultiSelectSingle_Toggle($event)"></p-multiSelect>

        <!-- This is a primeng file uploader -->
        <p-fileUpload *ngIf="LookupDataItem.HTMLInputType==='p-fileupload'" #INP_FileUpload chooseIcon="pi pi-folder" [multiple]="false"
            maxFileSize="15728640" (onSelect)="FileSelected_Process($event, INP_FileUpload)" (onRemove)="File_Unselect()" [showCancelButton]="false"
            [showUploadButton]="false" chooseLabel=" " class="p-customFileUpload glb_maxWidth">
            <ng-template pTemplate="content">
            </ng-template>
        </p-fileUpload>

        <!-- For the primeng calendar datetime picker -->
        <div *ngIf="LookupDataItem.HTMLInputType==='date'"
            class="col-12 glb_floatParent glb_customFlexRow justify-content-center align-items-center glb_maxWidth">
            <p-calendar #INP_Date id="DU_DTPDate" [(ngModel)]="DTPDate.JSDate" class="DTPInputGrid glb_maxWidth" [showTime]="false"
                [showSeconds]="false" [maxDate]="DTPMaxDate" [minDate]="DTPMinDate" [showIcon]="false" [showButtonPanel]="false"
                [showButtonBar]="true" [inline]="false" [showOtherMonths]="false" [hideOnDateTimeSelect]="false" [touchUI]="false"
                [dateFormat]="DTPDateFormat" [hourFormat]="12" [appendTo]="'body'" baseZIndex="5000" inline="false"
                inputStyleClass="DTPInputStyle glb_Font" placeholder="{{LookupDataItem.Placeholder}}" (onClose)="DTP_OnClose(INP_Date)"
                (onShow)="DTP_Focus()" (onSelect)="DTP_OnClose(INP_Date)" [disabled]="LookupDataItem.Disabled">
            </p-calendar>
            <div class="floatTopRightCalendarTask">
                <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button" (click)="INP_Date.toggle()">
                    <fa-icon [icon]="['fal', 'calendar']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </span>
            </div>
        </div>

        <!-- For mandatory Yes/No questions -->
        <div *ngIf="LookupDataItem.HTMLInputType==='yesno'" class="glb_customFlexRow glb_inputBackgroundColor glb_inputSelectButton">
            <div class="glb_Font glb_customFlexRow text-white align-items-center">
                {{LookupDataItem.Question}}</div>
            <p-selectButton class="glb_boxedPaddingNarrow p-customChooseOptions" [options]="YesNoOptions"
                [ngModel]="ModelDataUnit_Get(INP_DataUnit).Value" [multiple]="false" optionLabel="name" optionValue="value" [allowEmpty]="false"
                (onChange)="OptionSelection_Click(INP_DataUnit, OP_DataUnit, $event)"></p-selectButton>
        </div>

        <!-- For checkbox items -->
        <div *ngIf="LookupDataItem.HTMLInputType==='checkbox'" class="glb_customFlexRow">
            <span class="noLeftPadding hasPadding input-group-text glb_paddingZeroOffsetRight glb_RemoveBGandBorder">
                <mat-checkbox class="glb_Font mat-checkbox-dark-custom" [ngModel]="ModelDataUnit_Get(INP_DataUnit).IsChecked"
                    [(checked)]="ModelDataUnit_Get(INP_DataUnit).IsChecked" (change)="Checkbox_Toggle(INP_DataUnit)">
                    {{LookupDataItem.LabelDisplay}}</mat-checkbox>
            </span>
        </div>

    </div>

    <!-- Clear button -->
    <div *ngIf="ShowClearButton" class="justify-content-start align-self-end glb_customFlexRow">
        <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white text-start glb_maxWidth"></div>
        <div class="glb_PaddingHorizontalXXS">
            <button class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_PaddingHorizontalXXS glb_BGColorClear"
                matTooltip="Click to Clear" (click)="InputDataClear_Click(INP_DataUnit)">
                <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
            </button>
        </div>
    </div>
    <div *ngIf="!ShowClearButton" class="justify-content-start align-self-end glb_customFlexRow">
        <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white text-start glb_maxWidth"></div>
        <div class="glb_PaddingHorizontalXXS">
        </div>
    </div>
</div>

<!-- Overlay panel -->
<p-overlayPanel #OP_DataUnit id="OP_DataUnit" styleClass="glb_primeng_overlayPanel_auto glb_primeng_overlayPanel_default">
    <div class="glb_customFlexRow">
        <div class="col-12 glb_boxedPaddingXS">
            <div class="glb_Font text-dark">
                {{InputHelperText}}</div>
        </div>
    </div>
</p-overlayPanel>