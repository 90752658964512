<div id="PG_StatementGenerator" class="glb_paddingBottomMD glb_formBGColor text-white glb_Font" [ngClass]="ModalIdentifier">

  <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow">
    <div class="glb_headerBarBGDark glb_customFlexRow justify-content-end col-12" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button id="BTN_MinimizeStatementGenerator" tabindex="4"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.MinimizedDialogs_Add(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'compress-alt']" class="fa-md glb_iconColorDark" matTooltip="Click to minimize"></fa-icon>
        </button>
        <button id="BTN_CloseStatementGenerator" tabindex="30"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'times']" class="fa-md glb_iconColorDark"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="glb_customFlexRow glb_boxedPadding">
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">
        <div class="col-8 glb_paddingZeroOffset">
          <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
            Statement Generator
          </div>
        </div>

        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">From Date</div>

            <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left">
              <div class="glb_paddingZeroOffset col-10 col-lg-6">
                <input id="INP_FromDate" [matDatepicker]="fromDatePicker" tabindex="1"
                  class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="From Date" [(ngModel)]="FromDate"
                  (dateChange)="Date_OnChange()" [max]="MaxToDate">
              </div>
              <div class="glb_paddingZeroOffset col-2">
                <mat-datepicker-toggle class="mat-datepicker-dark-custom" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker (opened)="globalFunctions.DatePicker_Opened(fromDatePicker)"
                  (closed)="globalFunctions.MatDatePicker_Close()"></mat-datepicker>
              </div>
            </div>
          </div>
        </div>

        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">To Date</div>

            <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left">
              <div class="glb_paddingZeroOffset col-10 col-lg-6">
                <input id="INP_ToDate" [matDatepicker]="toDatePicker" tabindex="2" class="glb_formInputBox glb_skinLightOutline text-black glb_Font"
                  type="text" placeholder="To Date" [(ngModel)]="ToDate" (dateChange)="Date_OnChange()" [max]="MaxToDate">
              </div>
              <div class="glb_paddingZeroOffset col-2">
                <mat-datepicker-toggle class="mat-datepicker-dark-custom" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker (opened)="globalFunctions.DatePicker_Opened(toDatePicker)"
                  (closed)="globalFunctions.MatDatePicker_Close()"></mat-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left">
          <div class="glb_customFlexRow justify-content-left">
            <mat-checkbox id="CB_AllowBorrower" [(ngModel)]="AllowBorrowerAccess" class="glb_Font glb_FontSize mat-checkbox-dark-custom"
              matTooltip="This will make the statement available to borrowers via Borrower XCHANGE">Borrower
              Access</mat-checkbox>
          </div>
        </div>
        <br>
        <br>


        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_customFlexRow">Note: Statements generated via this button are also saved against the "Documents" Tab of this
              Account.</div>
          </div>
        </div>

        <!-- Generate button -->
        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
          <div class="col-12 col-lg-6">
            <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
              <div *ngIf="!IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
                <button id="BTN_GenerateStatement" tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                  (click)="Statement_Generate()">
                  <span class="glb_Font glb_smallerText">Generate</span></button>
              </div>
              <div *ngIf="IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
                <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                  <span class="glb_Font glb_hiddenText glb_smallerText">Generate</span>
                  <div class="spinner-border glb_floatingSpinner"></div>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>