import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Controllers, APIResponseStatus, UsersControllerMethods } from '../Global/EnumManager';
import { ErrorService } from '@app/Global/Errors/ErrorService';
import { NotifyService } from '@app/Services/NotifyService';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ClientDataStore } from '@app/Global/ClientDataStore';

@Injectable({
	providedIn: 'root'
})

export class ApiService {
	//This needs to be read from the host name
	private BaseUrl = environment.apiBaseUrl;

	public ErrorService = this.injector.get(ErrorService);
	public Endpoints = new Controllers();
	private LoginData;

	constructor(private http: HttpClient,
		private notifyService: NotifyService,
		private injector: Injector,
		private globalFunctions: GlobalFunctions,
		private clientDataStore: ClientDataStore,
	) {
		//Get a subscription to LoginData
		this.clientDataStore.LoginData
			.subscribe(x => {
				this.LoginData = x;
			})
	}

	public BaseURL_Set() {
		//Just use the url from config
		this.BaseUrl = environment.apiBaseUrl;
	}

	//Standard way to parse api reponses.
	public APIResponse_Parse(response): APIResponseStatus {
		const typedStatus: APIResponseStatus = APIResponseStatus[response.Status as keyof typeof APIResponseStatus];
		return typedStatus;
	}

	//Makes any post request to the server api, returns as an array of anything. let the caller consume and parse to a model. also handles errors.
	public APIData_Post(controller: string, actionType: string, body: any = []): Observable<any[]> {

		//Don't post requests if we are refreshing the token
		if (!this.globalFunctions.isEmpty(this.LoginData) && actionType != UsersControllerMethods[UsersControllerMethods.RefreshToken] && this.LoginData.isRefreshingToken === true) {

			//console.log("refeshing token, please wait");
			this.notifyService.Warning_Show("Token refreshing, please try again", "Refreshing Token");

			//Use a dummy observable that responds with null
			return this.APIRequest_Dummy()
				.pipe(
					map(() => {
						return null;
					}))
		}

		//Don't make the request if we have hit an error, unless its a bug reporting request
		if (this.clientDataStore.HitErrorPage && actionType != UsersControllerMethods[UsersControllerMethods.CreateBugReport]) {

			//Use a dummy observable that responds with null
			return this.APIRequest_Dummy()
				.pipe(
					map(() => {
						return null;
					}))
		}

		//NOTE: Server response uses pascal case properties but client only uses camel case
		return this.APIRequest_Post(this.BaseUrl + controller + "/" + actionType, body)
			.pipe(
				map(response => {
					const apiResponseStatus = this.APIResponse_Parse(response).toString();

					if (apiResponseStatus === APIResponseStatus[APIResponseStatus.Error]) {

						const errorMessage = response.Error;
						console.log("errorMessage: ", errorMessage);
						const errorFriendly = response.ErrorFriendly;
						const errorToast = response.ErrorToast;

						//Kill any mat dialogs (e.g. login might be visible)
						this.globalFunctions.CloseAllMatDialogs();

						//So that we don't keep throwing the error message, check the HitErrorPage variable
						if (!this.clientDataStore.HitErrorPage) {

							//Flip it so we don't do this again
							this.clientDataStore.HitErrorPage = true;
							this.clientDataStore.Update_HitErrorPageSub(true);

							//Log a custom error
							this.ErrorService.LogCustomError(errorMessage, errorFriendly, errorToast);
						}
						return null;
					}
					else if (apiResponseStatus === APIResponseStatus[APIResponseStatus.NonFatalWarning]) {

						//Should we write errorMessage somewhere? its not being used atm
						//const errorMessage = response.Error;

						//These two are (sent to the toast)
						const errorFriendly = response.ErrorFriendly;
						const errorToast = response.ErrorToast;
						this.notifyService.Warning_Show(errorFriendly, errorToast);

						//Check if the warnings are related to Two Factor Authentication verification
						if (response.ErrorToast === "Google Authentication retry limit exceeded") {
							//Close all dialogs
							this.globalFunctions.CloseAllMatDialogs();
						}
					}
					else if (apiResponseStatus === APIResponseStatus[APIResponseStatus.SessionExpired]) {

						//Session is expired, we want to launch the login modal
						//Dont bother with the warning if we are already on the login modal
						if (!this.clientDataStore.LoginModalLaunched) {
							//Should we write errorMessage somewhere? its not being used atm
							//const errorMessage = response.Error;

							//These two are (sent to the toast)
							const errorFriendly = response.ErrorFriendly;
							const errorToast = response.ErrorToast;
							this.notifyService.Warning_Show(errorFriendly, errorToast);
						}

						//Reset access token and login in status client side
						this.clientDataStore.loginDataDirect.UserLoggedInStatus = false;
						this.clientDataStore.loginDataDirect.AccessToken = "";

						//Launch login. Now we can ask the global function library to launch a login modal for us. pass false so that we don't trigger a page refresh after a successful login. This is a nicer user experience - they can continue any work where they left off. e.g. a large piece of editing on the edit/create screen.
						this.globalFunctions.LaunchLoginModal(false, "Your session has expired, please log in");
					}
					else {

						//Response unknown, or OK.
						return JSON.parse(response.Data) as Array<any>;
					}
				}
				));
	}

	//makes the actual call to the remote server
	private APIRequest_Post(url: string, body): Observable<any> {

		//Inject the access token as a header, if it is available
		let accessToken = '';

		if (!this.globalFunctions.isEmpty(this.clientDataStore.loginDataDirect.AccessToken)) {
			accessToken = this.clientDataStore.loginDataDirect.AccessToken;
		}

		const headers = new HttpHeaders({
			'AccessToken': accessToken,
			'XV8OriginName': 'XV8'
		});

		return this.http.post(url, body, { headers: headers });
	}

	//dummy observable that responds with null
	private APIRequest_Dummy(): Observable<any> {

		//Uses a local dummy observable to return a result of null in an observable format
		return this.DummyObservable;
	}

	private DummyObservable = new Observable(observer => {
		observer.next(null);
		observer.complete();
	})

	//Route the user to an error page
	public Error_Go(errorMessage: string, errorToast: string, errorFriendly: string) {
		this.ErrorService.LogCustomError(errorMessage, errorFriendly, errorToast);
	}
}