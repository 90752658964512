<div class="glb_boxedPadding glb_formBGColor text-white">
  <!-- lets wrap the title and entity buttons into a flex -->
  <div class="glb_forceMarginAuto glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
    <!-- show the entity name when the spinner is off -->
    <div class="glb_sectionTitle1">Account Enquiries
    </div>
    <div class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">
      <!-- show tasks for all users or just the logged in user -->
      <div class="glb_rightPaddingNarrow">
        <div class="glb_customFlexRow justify-content-left align-items-center" style="padding-bottom: 6px;">
          <!-- (change)="CheckBox_Click(dataUnit, $event)" -->
          <mat-checkbox class="glb_Font mat-checkbox-dark-custom" [(ngModel)]="showMyTasksOnly" [(checked)]="showMyTasksOnly"
            (change)="RefreshDashboardDataAggregated()">
            Only Show My Enquiries</mat-checkbox>
        </div>
      </div>

      <div *ngIf="!refreshingAggregatedSpinner" class="glb_rightAlignedContentPadding">
        <button class="glb_clickableIconButtonPadding2 glb_clickableIconButtonStyle glb_clickableIconButtonMD align-self-right"
          (click)="RefreshDashboardDataAggregated()">
          <fa-icon [icon]="['fal', 'sync-alt']" class="fa-fw glb_fixedSizeIcons glb_clickableIconButtonColorDark" matTooltip="Click to Refresh">
          </fa-icon>
        </button>
      </div>
      <div *ngIf="refreshingAggregatedSpinner" class="glb_rightAlignedContentPadding">
        <button class="glb_clickableIconButtonPadding2 glb_clickableIconButtonStyle glb_clickableIconButtonMD btn-secondary align-self-right">
          <fa-icon [icon]="['fal', 'compact-disc']" class="fa-fw glb_fastSpin glb_fixedSizeIcons glb_clickableIconButtonColorDark"
            matTooltip="Loading...Please wait"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- spinner for the element here -->
  <div *ngIf="showSpinner" class="glb_forceMarginAuto glb_customFlexRow col-12 justify-content-center">
    <div class="glb_customFlexRow justify-content-center">
      <div class="glb_customFlexRow spinner-border" style="width: 3rem; height: 3rem;"></div>
    </div>
  </div>

  <div *ngIf="!showSpinner && noData"
    class="glb_forceMarginAuto glb_allBordersSkin glb_PaddingHorizontalMD glb_customFlexRow col-12 justify-content-center">
    <div class="glb_Font glb_customFlexRow justify-content-center">
      <div class="glb_customFlexRow glb_boxedPadding">
        No Data
      </div>
    </div>
  </div>

  <div *ngIf="!showSpinner && !noData" class="glb_allBordersSkin glb_Font glb_PaddingHorizontalMD">
    <div *ngFor="let item of delegatorDashboardDataAggregated | keyvalue: keepOrder; let i = index" [ngClass]="GetClass()">
      <!-- displays the header row -->
      <ng-container *ngIf="i == 0">
        <div *ngFor="let subItem of item.value | keyvalue: keepOrder" class="glb_bottomHeaderBorderSkin">
          <div class="customCardBody">
            <!-- key contains the column name -->
            {{subItem.key}}
          </div>
        </div>
      </ng-container>
      <div *ngFor="let subItem of item.value | keyvalue: keepOrder">
        <div class="customCardBody">
          <!-- only show the assigned column with a full column width. others will have a chevron -->
          <div *ngIf="subItem.key==='Assigned To'">
            <div [innerHTML]="subItem.value | sanitizeHtml">
            </div>
          </div>

          <!-- other columns (like the status) will have a chevron to expand and show more content -->
          <div *ngIf="subItem.key!=='Assigned To'">
            <div class="glb_customFlexRow">
              <div class="glb_paddingZeroOffset col-6 col-md-3" [innerHTML]="subItem.value | sanitizeHtml">
              </div>
              <!-- this is the chevron -->
              <div class="glb_paddingZeroOffset glb_customFlexRow col-6 col-md-9 justify-content-center justify-content-md-start"
                *ngIf="subItem.key!=='Assigned To' && subItem.value!=='0'">
                <!-- have to give this a unique href name or it will clash with other components, e.g. the Lender Tasks. So append AE to the beginning -->
                <a (click)="TemplateID_Toggle(subItem.key+keySeparator+item.key)" class="glb_hoverHand">
                  <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS(subItem.key+keySeparator+item.key,true)"
                    class="glb_chevronStyleLight" matTooltip="Expand" matTooltipPosition="left"
                    (click)="GetDelegatorDashboardDetailDataWrapper(item.key, subItem.key)">
                  </fa-icon>
                  <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS(subItem.key+keySeparator+item.key,false)"
                    class="glb_chevronStyleLight" matTooltip="Hide" matTooltipPosition="left"></fa-icon>
                </a>
              </div>
            </div>

            <!-- and here is the sliding content -->
            <!-- subItem.key is the parent client GUID, and item.key is the status -->
            <div [ngClass]="TemplateID_GetCSS(subItem.key+keySeparator+item.key)" class="glb_customFlexRow">
              <!-- I am some sliding content -->
              <!-- check if the spinner for this data point should show -->
              <div *ngIf="CheckDetailDataSpinner(item.key, subItem.key)" class="glb_customFlexRow col-12 justify-content-center">
                <div class="glb_customFlexRow justify-content-center">
                  <div class="spinner-border" style="width: 1rem; height: 1rem;"></div>
                </div>
              </div>

              <!-- now show detail content (header only, pick first row and show keys) -->
              <div class="glb_customFlexRow row-cols-4 col-12 glb_paddingZeroOffset">
                <ng-container *ngFor="let detailItem of ShowDetailData(item.key, subItem.key) | keyvalue: keepOrder; let di = index">
                  <!-- first row only! -->
                  <ng-container *ngIf="di == 0">
                    <div *ngFor="let subDetailItem of detailItem.value | keyvalue: keepOrder" class="glb_allBordersSkin">
                      <div *ngIf="di == 0" class="customCardBodyTighter glb_breakLongWords">
                        {{subDetailItem.key}}
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <!-- now show detail content - rows -->
              <!-- store the GUID of the task in the id (detailItem.key) into its local id, and combine the GUID of the parent and its status (ClientGUID + separator + Status) it as custom attribute called data-parentguid. this will allow us to reference it later on clicks -->
              <div id={{detailItem.key}} attr.data-parentguid={{StripBracesAndSpaces(item.key)}}{{keySeparator}}{{StripBracesAndSpaces(subItem.key)}}
                *ngFor="let detailItem of ShowDetailData(item.key, subItem.key) | keyvalue: keepOrder; let di = index"
                (click)="TaskItem_Clicked($event, detailItem)" class="glb_customFlexRow row-cols-4 col-12 glb_paddingZeroOffset">
                <div *ngFor="let subDetailItem of detailItem.value | keyvalue: keepOrder" class="glb_allBordersSkin">
                  <div *ngIf="di >= 0" class="customCardBodyTighter glb_breakLongWords" matTooltip="{{subDetailItem.value}}"
                    matTooltipPosition="left">
                    <!-- perhaps we can limit this to the first 30 characters -->
                    {{LimitTextSize(subDetailItem.value, 30)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- let's put the floating context action bar here -->
  <div class="glb_ActionBarParent glb_ActionBarParentMobile glb_customFlexRow justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-center
  align-items-end" *ngIf="showContextBar" @fadeIn @fadeOut>

    <span *ngIf="showTaskEditButton" style="padding-left:0.375em;">
      <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right"
        (click)="LaunchTaskView()">
        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']" matTooltip="Click to View the User Task">
        </fa-icon>
      </button>
    </span>

    <!-- a small separator/gap between other buttons and the close/cancel button -->
    <span style="padding-left:0.375em;">
      <div class="align-self-right">
        &nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </span>

    <!-- a close/cancel button -->
    <span style="padding-left:0.375em;">
      <button (click)="ClearUIArrays(false)"
        class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClear glb_clickableIconButtonLG align-self-right">
        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'times']" matTooltip="Click to Close">
        </fa-icon>
      </button>
    </span>

  </div>
</div>