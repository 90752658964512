import { Component, OnInit, Input, HostListener } from '@angular/core';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { EntityTypesDisplay } from '@app/Global/Models/EntityModels';
import { ApiService } from '@app/Services/APIService';

@Component({
  selector: 'LoanNavBar',
  templateUrl: './LoanNavBar.html',
  styleUrls: ['./LoanNavBar.scss']
})

export class LoanNavBar implements OnInit {

  constructor(private globalFunctions: GlobalFunctions, private clientDataStore: ClientDataStore, private apiService: ApiService) { }

  //We need a ref to the loan component so that we can trigger events, e.g. grab new tab data from the server
  @Input() LoanIndex: LoanIndex;

  //Entity Types Display Modal
  public EntityTypesDisplay: EntityTypesDisplay;

  //Do we show the nav bar? Used by the button when on small mobile screens
  public ShowNavBar = true;

  //To display the nav bar button on small screen
  public ShowNavBarButton = false;

  //Track if the user has made their first click
  public UserFirstClick = false;

  ngOnInit(): void {
    this.EntityTypesDisplay = this.clientDataStore.EntityTypesDisplay;
    this.Account_GetNavTabs(Number(this.LoanIndex.AccountID));

    this.NavBarButton_ToggleVisibility(null);
  }

  //Toggles the Nav bar visibilty
  public NavBarVisibilty_Toggle() {
    this.ShowNavBar = !this.ShowNavBar;
  }

  public Account_GetNavTabs(accountID: number) {
    //Head to the first tab based on what we know already (based on login entity types)
    this.LoanIndex.firstNavItem = this.EntityTypesDisplay[0].Name;
    this.LoanIndex.CurrentNavItem = 'nav-' + this.LoanIndex.firstNavItem + '-tab';
    const apiRequest = { AccountID: accountID };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetAccountNavTabs], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) { return; }
        else {
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Head to the actual first entity that we can view, based on the loan that we are looking at. usually its the same. but sometimes not (e.g. when a user has two different roles).
          this.EntityTypesDisplay = response.NavTabEntityTypes;

          //We only want to go here though, if the user has not clicked something yet
          if (!this.UserFirstClick) {
            //Indicate that this is the first tab we want to visit in the class properties
            this.LoanIndex.firstNavItem = this.EntityTypesDisplay[0].Name;
            this.LoanIndex.CurrentNavItem = 'nav-' + this.LoanIndex.firstNavItem + '-tab';

            //We need to trigger loading this first entity's data from the server. This is in case the user doesn't have access to the default account details entity, and is sent to a different entity straight away. e.g. AMAL trustee role can only see the documents tab. So we need to trigger a load (method will check and skip if already loaded)          
            this.LoanIndex.EntityData_Load(this.LoanIndex.CurrentNavItem, this.LoanIndex.firstNavItem);
          }
        }
      });
  }

  //When a nav bar item is clicked, show the relevant nav tab
  public NavBarItem_Clicked(event: Event) {

    //Indicate that the user has now clicked on something, use this to prevent navigating to another entity
    this.UserFirstClick = true;

    const eventTarget = event.target as HTMLInputElement;
    const eventTargetID = eventTarget.id;

    //Turn off the context bar whenever we switch to another tab
    this.LoanIndex.DataUnit_RemoveSelected();

    //Sync the display of Payout Calculator buttons if we have clicked the transaction nav tab
    if (eventTargetID === 'nav-LoanTransactions-tab') {

      //Target the transaction component
      const transactionComponent = this.LoanIndex.LoanEntityScripts.filter(component => 'nav-' + component.EntityName + '-tab' == eventTargetID)[0];

      if (!this.globalFunctions.isEmpty(transactionComponent)) {

        //Sync the display of Payout Calculator buttons
        transactionComponent.PayoutCalculatorButton_Show();
      }
    }

    //Load Entity Data from non preloaded entities
    this.globalFunctions.OnDemandEntities.filter(x => x.IsParent === true).forEach(entity => {
      this.LoanIndex.EntityData_Load(eventTargetID, entity.EntityName);
    },
    );

    //Update the currently visible nav tab
    this.LoanIndex.CurrentNavItem = eventTargetID;
  }

  //Get and highlight the currently active nav bar item
  public CurrentNavBarItem_Get(entity: EntityTypesDisplay) {
    if (this.LoanIndex.CurrentNavItem === 'nav-' + entity.Name + '-tab')
      return 'active'
    else return ''
  }

  //Use this to catch any window resize changes, and then update any styles or behaviour
  @HostListener('window:resize', ['$event']) private Window_OnResize(event): void {
    this.NavBarButton_ToggleVisibility(event);
  }

  //Set the show Nav Bar button flags based on the screen size
  private NavBarButton_ToggleVisibility(event: UIEvent | null): void {
    if (this.globalFunctions.Window_IsGreaterThanSize(event, GlobalFunctions.MinSizeSM)) {
      //Force the nav bar visiblity to on, as on large screens the button to toggle it will disappear
      this.ShowNavBar = true;
      this.ShowNavBarButton = false;
    }
    else {
      //Force the nav bar visiblity to false, as on small screens it takes up too much space
      this.ShowNavBar = false;
      this.ShowNavBarButton = true;
    }
  }
}