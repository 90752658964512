import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'body'
})
export class BodyPipe implements PipeTransform {

  transform(values: any, ...args: any[]): any {
    //this pipe also now ignore hidden values
    return values.filter((item) => item.DisplaySection !== 'Header' && !item.HideDisplay);
  }
}

@Pipe({
  name: 'searchfilter'
  , pure: false
})
export class SearchFilterPipe implements PipeTransform {

  transform(values: any, ...args: any[]): any {
    //this pipe also now ignore hidden values
    //console.log("searchfilterpipe");
    //return values;
    return values.filter((item) => !item.isHidden);
  }
}

@Pipe({
  name: 'entityFilter'
})
export class EntityFilterPipe implements PipeTransform {

  transform(values: any, entityName?: string): unknown {
    return values.filter(item => item.Entity === entityName);
  }
}

//a display filter used by some dashboards, e.g. the account manager. just matches inner for loop content with the outer ngFor loop. you can supply the property that you want to match on
@Pipe({
  name: 'customPropertyFilter'
})
export class CustomPropertyFilterPipe implements PipeTransform {

  transform(values: any, propertyName?: string, propertyValue?: string): unknown {
    return values.filter(item => item.value[propertyName] === propertyValue);
  }
}

//A display filter for array property
@Pipe({
  name: 'customArrayValueFilter'
})
export class CustomArrayValueFilter implements PipeTransform {

  transform(values: any, propertyName?: string, propertyValue?: string): unknown {
    return values.filter(item => item[propertyName] === propertyValue);
  }
}

//a display filter used by the account manager dashboard. any property starting with _ds is meant to be hidden.
@Pipe({
  name: 'displayFilter'
})
export class DisplayFilterPipe implements PipeTransform {

  transform(values: any, incomingVal?: string): unknown {
    return values.filter(item => !item.key.startsWith(incomingVal));
  }
}

@Pipe({
  name: 'controlFilter'
})
export class ControlFilterPipe implements PipeTransform {

  transform(values: any, controlValue?: string): unknown {
    return values.filter(item => item.ControlValue.toUpperCase().includes(controlValue.toUpperCase()));
  }
}

@Pipe({
  name: 'header'
})
export class HeaderPipe implements PipeTransform {

  transform(values: any[], ...args: any[]): any {
    //console.log("pipe" + values[0].DisplaySection);
    //var temp =values.filter((item) => item.DisplaySection === 'Header');
    //console.log(temp);
    return values.filter((item) => item.DisplaySection === 'Header' && !item.HideDisplay);
  }

}

@Pipe({
  name: 'editlock'
})
export class EditLockPipe implements PipeTransform {

  transform(values: any, ...args: any[]): any {
    return values.filter(item => item.EditLock === 'true');
  }
}

//this pipe is useful when we have some html content being returned from the server or injected inside data, and we want to preserve style tags/formatting. e.g. markcustom - in text highlighting when filtering search terms.
@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {
  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}