<div id="PG_SLAReportingSummary" class="glb_boxedPadding glb_formBGColor text-white glb_Font col-12" style="height:100%; width:100%">

  <div>
    <div *ngIf="SLAReporting.ShowSpinner">
      <div class="glb_customFlexRow justify-content-center">
        <div class="spinner-border glb_spinnerLight glb_spinnerMD">
        </div>
      </div>
    </div>
    <div *ngIf="!SLAReporting.ShowSpinner && !AggregateDataExists && !SLAReporting.AreInputsActive"
      class="glb_allBordersSkin glb_Font glb_PaddingHorizontalMD">
      <div class="glb_customFlexRow justify-content-center">
        <div class="glb_boxedPadding">
          No data to display.
        </div>
      </div>
    </div>
    <div *ngIf="!SLAReporting.ShowSpinner && !AggregateDataExists && SLAReporting.AreInputsActive"
      class="glb_allBordersSkin glb_Font glb_PaddingHorizontalMD">
      <div class="glb_customFlexRow justify-content-center">
        <div class="glb_boxedPadding">
          Please select the inputs above and click Generate.
        </div>
      </div>
    </div>
    <div *ngIf="!SLAReporting.ShowSpinner && AggregateDataExists">
      <div class="chartHeight glb_paddingBottomMD">
        <canvas #ChartID id="ChartID" (click)="PieChart_Click($event)"></canvas>
      </div>
      <div class="col-12 justify-content-end align-items-center glb_customFlexRow glb_paddingBottomSM">
        <span>
          <button id="BTN_DownloadSLAAggregate"
            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right"
            (click)="Tasks_DownloadCSV()">
            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
            </fa-icon>
          </button>
        </span>
      </div>
      <div id="SLAReportingSummaryID" *ngIf="!ShowSpinner" class="glb_allBordersSkin glb_Font glb_PaddingHorizontalMD">
        <div *ngIf="ShowNavTab">
          <div *ngFor="let item of AggregateData | keyvalue: Order_Keep; let itemIndex = index" [ngClass]="CssClass_Get()">
            <!-- displays the header row -->
            <ng-container *ngIf="itemIndex === 0">
              <div *ngFor="let subItemHeader of item.value | keyvalue: Order_Keep; let subItemHeaderIndex = index" class="glb_bottomHeaderBorderSkin">
                <div>
                  <div class="glb_customFlexRow" [ngClass]="CssClassSubItem_Get(subItemHeaderIndex)" style="font-weight: 500;">
                    <div class="glb_paddingZeroOffset"><!-- key contains the column name -->
                      {{subItemHeader.key}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div *ngFor="let subItemDetail of item.value | keyvalue: Order_Keep; let subItemIndex = index">
              <div>
                <div class="glb_customFlexRow" [ngClass]="CssClassSubItem_Get(subItemIndex)">
                  <a id="LNK_LaunchDrillthrough_{{subItemIndex}}" class="glb_paddingZeroOffset"
                    [innerHTML]="TextDisplay_Limit(subItemDetail.value,subItemIndex) | sanitizeHtml"
                    (click)="Grid_Click(item, subItemDetail, subItemIndex)" [ngClass]="CssHyperlink_Get(subItemDetail, subItemIndex)"
                    [matTooltip]="ToolTipText_Get(subItemDetail.value, subItemIndex)" matTooltipPosition="right"
                    [matTooltipDisabled]="item.DisablePBToolTip">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>