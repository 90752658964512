
export class DashboardCalendar {
  CalendarDate: Date
  //we can't have spaces in the property names. change it in server, replace it with a token that can be easily replaced here in client
  LenderName_lbl_Lender_Name: string
  PortfolioName_lbl_Portfolio_Name: string
  PrincipalBalance_lbl_Principal_Balance: number
  TotalArrearsBalance_lbl_Total_Arrears_Balance: number
  PrincipalBalance_lbl_Settlements_Balance: number
  PrincipalBalance_lbl_Maturity_Balance: number
  Status_lbl_Current: number
  Status_lbl_Loan_Discharged: number
  Status_lbl_Loan_Discharged__MIN__Own_Funds: number
  Status_lbl_Current__MIN__No_Interest_Accrual: number
  Status_lbl_Arrears: number
  Status_lbl_Loan_Discharged__MIN__Refinance: number
  Status_lbl_Loan_Discharged__MIN__Sale: number
  Status_lbl_Loan_Withdrawn: number
  Status_lbl_Manual_Adjustment: number
  Status_lbl_Pending_Settlement: number
  Status_lbl_Portfolio_Handed_Back: number
  Status_lbl_Ready_for_Settlement: number
}

export interface ILabelColor {
  label: string;
  color: string;
  hovercolor: string;
}
export interface IChartDef {
  chartName: string;
  chartColumns: string;
  formatCurrency: boolean;
  chartType: string;
  chartDataStyle: string;
  isStacked: boolean;
  groupByPortfolio: boolean;
}
export class DateValue {
  public constructor(date: string, value: number) {
    this.dateVal = date;
    this.value = value;
  }
  public dateVal: string;
  public value: number;
}

export class DataSets {
  Datasets: DataSet[];
  public push = (dataset: DataSet) => this.Datasets.push(dataset);
  public merge = (dataset1: DataSet, dataset2: DataSet) =>

    new DataSet(
      [].concat(dataset1.label, dataset2.label),
      [].concat(dataset1.data, dataset2.data),
      [].concat(dataset1.borderColor, dataset2.borderColor),
      [].concat(dataset1.backgroundColor, dataset2.backgroundColor),
      [].concat(dataset1.hoverBackgroundColor, dataset2.hoverBackgroundColor),
      [].concat(dataset1.showAllDataLabels, dataset2.showAllDataLabels)
    );
}

export class DataSet {

  constructor(label: any, data: any, borderColor: any, backgroundColor: any, hoverBackgroundColor: any, showDataLabel: any) {
    this.label = label;
    this.data = data;
    this.borderColor = borderColor;
    this.backgroundColor = backgroundColor;
    this.hoverBackgroundColor = hoverBackgroundColor;
    this.showAllDataLabels = showDataLabel;
  }
  label: string | string[];
  data: number[];
  borderColor: string | string[];
  backgroundColor: string | string[];
  hoverBackgroundColor: string | string[];
  showAllDataLabels: boolean;
  //labelArray: | string;
}