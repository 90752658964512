import { Component, Input, OnInit } from '@angular/core';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { maxBy, orderBy } from 'lodash-es';
import { ConditionSubsequent } from '../ConditionSubsequent';

@Component({
  selector: 'ConditionSubsequentDetail',
  templateUrl: './ConditionSubsequentDetail.html',
  styleUrls: ['./ConditionSubsequentDetail.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class ConditionSubsequentDetail implements OnInit {

  //Constructor
  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private csvDataService: CsvDataService = null) {
  }

  @Input() ConditionSubsequent: ConditionSubsequent;
  @Input() RowPrimaryKey;

  //Declare the variables
  public TargetDetailData;
  public DetailDataCSV;
  public SortingKeysArray = [];
  public TextDisplayLimit;

  //Used to separate keys when combined into a single identifier
  public KeySeparator = "_";
  public AggregationType;
  public HeaderColumns = []
  public HeaderColumnCount = 0;

  ngOnInit() {

    this.Page_Init();
  }

  //Custom constructor
  public Page_Init() {

    //Process for the valid RowPrimaryKey
    if (!this.globalFunctions.isEmpty(this.RowPrimaryKey) && this.RowPrimaryKey.includes(this.KeySeparator) === true) {
      this.AggregationType = this.RowPrimaryKey.split('_')[1];

      //Initialise the header columns depending on the Aggregation Type
      if (this.AggregationType === this.ConditionSubsequent.AggregationTypeUpcoming) {

        //Clone header columns so that the each lender aggregation type details have their own sorting data
        this.HeaderColumns = JSON.parse(JSON.stringify(this.ConditionSubsequent.HeaderColumnsUpcoming));
        this.TextDisplayLimit = this.ConditionSubsequent.TextDisplayLimitUpcoming;
      }
      else if (this.AggregationType === this.ConditionSubsequent.AggregationTypeFollowup) {

        this.HeaderColumns = JSON.parse(JSON.stringify(this.ConditionSubsequent.HeaderColumnsFollowup));
        this.TextDisplayLimit = this.ConditionSubsequent.TextDisplayLimitFollowup;
      }
      else if (this.AggregationType === this.ConditionSubsequent.AggregationTypeComplete) {

        this.HeaderColumns = JSON.parse(JSON.stringify(this.ConditionSubsequent.HeaderColumnsComplete));
        this.TextDisplayLimit = this.ConditionSubsequent.TextDisplayLimitComplete;
      }
      else if (this.AggregationType === this.ConditionSubsequent.AggregationTypeOverdue) {

        this.HeaderColumns = JSON.parse(JSON.stringify(this.ConditionSubsequent.HeaderColumnsOverdue));
        this.TextDisplayLimit = this.ConditionSubsequent.TextDisplayLimitOverdue;
      }

      //Need for dynamically setting css class for number of columns
      this.HeaderColumnCount = this.HeaderColumns.length;
    }
  }

  //Align the columns for the aggregate table based on how much data is coming in.
  public CssClass_Get(): string {

    //Set the class based on the AggregateDataColumnCount done on the Parent
    let classText = 'glb_customFlexRow col-12 ';

    //Set row cols based on the number of columns
    classText += 'row-cols-' + this.HeaderColumnCount;

    return classText;
  }
  //Sync the detail data as per the row primary key
  public DetailData_Sync() {

    //Initialise the target data
    this.TargetDetailData = [];

    //Look up the target key
    const detailData = this.ConditionSubsequent.DetailData[this.RowPrimaryKey];

    if (!this.globalFunctions.isEmpty(detailData)) {

      //Set the target detail data
      this.TargetDetailData = detailData.Entity;
    }
  }

  //Limit text size on the column
  public TextSize_Limit(input, length: number): number {
    return this.globalFunctions.LimitTextSize(input, length);
  }

  //Launch the task details
  public TaskView_Launch(item): void {

    //It's possible to create a 'Headless' LoanIndex that we can reuse
    const loanIndex = new LoanIndex(this.apiService, this.globalFunctions, this.notifyService, this.dialog, this.clientDataStore);

    //Try to get its basic stuff initialized. send the first selected task from the array. we can split the key into its 2 parts for this request (as we need LoanID and TaskGUID both)
    const loanID = item.LoanID
    const taskGUID = item.TaskGUID

    //The entity in this case is always LoanTasks
    const entityName = "LoanTasks";

    //Set the fullscreen loading spinner
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Now call the loanIndex initializer, called 'HeadlessMode'. we can pass the loanID and taskGUID to specify that it should load this single records from the server, and instantly show the Edit modal for us. it will remove the fullscreen loading spinner when that is done. as a script so that callbacks. i want some way to detect when save is clicked, and then refresh myself. how to do it? pass myself as a script to the Headless Mode
    //Instead of going straight to the LoanEntityModify template, let's try to construct an entity and navigate to that instead. let's try getting loanindex to do it for us.
    loanIndex.HeadlessModeInit(loanID, entityName, taskGUID, this);

    //TODO some destruction or removal of these constructed headless LoanIndex and its related classes may be in order (after the modal is closed, ie. loanIndex.CloseSingleEntityModal)
  }

  //For re-sorting the list of detail data, when a header is clicked
  public Click_Sort(itemKey) {

    //Find the target header
    const headerColumn = this.HeaderColumns.filter(x => x.Name === itemKey);

    //If exists
    if (!this.globalFunctions.isEmpty(headerColumn)) {

      //Is this header already a sorting column
      const sortKey = headerColumn[0].SortKey;

      if (sortKey === 0) {

        //Not a sorting column yet, so it is a first click. Get the current max sorting key
        const maxSortKey = maxBy(this.HeaderColumns, 'SortKey');

        //Set the sorting key to the max number + 1
        headerColumn[0].SortKey = maxSortKey.SortKey + 1;

        //Flip the sorting direction
        headerColumn[0].SortDirection = headerColumn[0].SortDirection * -1;
      }

      //Already a sorting column, and if it's a third click, remove it from the sorting columns
      else if (headerColumn[0].SortDirection === 1) {

        //Reset the sorting data
        headerColumn[0].SortKey = 0;
        headerColumn[0].SortDirection = 1;
      }
      else {
        //Otherwise, just flip the direction, it is a second click
        headerColumn[0].SortDirection = headerColumn[0].SortDirection * -1;
      }
    }

    //Deep clone the header columns for sorting so that it doesn't affect the column display order
    let headerColumnsSort = JSON.parse(JSON.stringify(this.HeaderColumns));

    //Sort the header columns by the sort key using lodash
    headerColumnsSort = orderBy(headerColumnsSort, ["SortKey"]);

    //Arrays to store the sorting columns and their corresponding sorting directions
    const sortingColumns = [];
    const sortingDirections = [];

    //Loop through each header columns
    headerColumnsSort.forEach(element => {

      //See if it is a sorting key
      if (element.SortKey > 0) {

        //Default the sorting to ascending
        let sortingDirection = 'asc';

        //Store the sorting column
        sortingColumns.push(element.Name);

        //Flip the sorting direction as per the header data
        if (element.SortDirection === -1) {
          sortingDirection = 'desc';
        }

        //Store sorting direction
        sortingDirections.push(sortingDirection);
      }
    });

    //Sort the detail data using lodash
    this.TargetDetailData = orderBy(this.TargetDetailData, sortingColumns, sortingDirections);
  }

  //Calls the parent to refresh all Task based components
  public ParentTaskData_Refresh(): void {

    this.ConditionSubsequent.Dashboard.RefreshTaskData();
  }
}