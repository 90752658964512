<div id="PG_Error" class="glb_customFlexRow glb_Font text-white">

	<div class="col" *ngIf="data && data.message">
		<br>
		<br>

		<div *ngIf="showRedirect">
			<p class="glb_Title">Please click <a [href]="NewURL_Go()">here</a> to continue to XCHANGE.
			</p>
		</div>

		<div *ngIf="!showRedirect">
			<p class="glb_Title">{{ data.friendlyErrorMessage }}</p>
			<br>
			<br>
			<p *ngIf="allowBugReporting">Please consider logging a bug report below.</p>
			<p *ngIf="!allowBugReporting">If you keep facing this error, please contact AMAL XChangeV8 support via email at <a
					href="mailto:xchangev8support@amal.com.au">xchangev8support&#64;amal.com.au</a>, including a description of
				the action or result you were trying to achieve.</p>

			<!--Button to show the error message -->
			<div class="glb_paddingBottomSM">
				<p></p>
				<p></p>
				<div id="ErrorDetails">
					<div>
						Error Details: {{data.message}}
						<br>
						Stack Trace:
						<br>
						{{stackTraceString}}
					</div>
				</div>
				<p></p>
			</div>

			<br>

			<div *ngIf="!isBugReportSubmitted && allowBugReporting">
				<p class="glb_TitleMD glb_TitleMDMobile">Help us help you! Please report a bug.</p>
				<p class="glb_TitleSM">Enter as much information as you can about what you were doing in the moment leading up to this
					error. Details include (not limited to and if relevant):
				</p>
				<ul class="col glb_TitleSM glb_paddingBottomLG">
					<li>An Account Identifier (AccountID or Reference Number)</li>
					<li>Client Names</li>
					<li>What operation/task you were doing</li>
					<li>What you were trying to achieve</li>
					<li>Any other steps that may help us reproduce the error</li>
				</ul>

				<div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_paddingBottomLG">
					<div class="glb_paddingBottomLG">
						<textarea [(ngModel)]="reproSteps" rows="5" #focusField type="text" cdkFocusInitial tabindex="14"
							placeholder="Enter details here" name="details"
							class="glb_loginInputBox glb_skinLightOutline text-white glb_Font"></textarea>
					</div>
					<div *ngIf="!sendingBugReport" class="glb_paddingBottomSM">
						<button tabindex="15" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
							(click)="BugReport_Create()">
							<span class="glb_Font glb_smallerText">Submit Bug Report</span></button>
					</div>
					<div *ngIf="sendingBugReport" class="glb_paddingBottomSM">
						<button tabindex="15" class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
							<span class="glb_Font glb_hiddenText glb_smallerText">Submit Bug Report</span>
							<div class="spinner-border glb_floatingSpinner"></div>
						</button>
					</div>
				</div>
			</div>
			<div *ngIf="isBugReportSubmitted && isBugReportCompleted && allowBugReporting" class="glb_paddingBottomLG">
				<p class="glb_TitleMD glb_TitleMDMobile">Thanks for submitting a bug report! You are an awesome person. Pat yourself on the back.</p>
				<p class="glb_TitleSM">If we have any more questions, an AMAL support team member will be in touch with you via your registered email
					address.</p>
			</div>

			<div class="glb_paddingBottomSM">
				<button tabindex="16" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="Home_Go()">
					<span class="glb_Font glb_smallerText">Go Home</span></button>
			</div>

		</div>

	</div>
</div>