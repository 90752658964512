import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NotifyService } from '../../Services/NotifyService';
import { ErrorService } from './ErrorService';
import { GlobalFunctions } from '../GlobalFunctions';
import { ClientDataStore } from '../ClientDataStore';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
	constructor(
		private injector: Injector) { }

	handleError(error: Error | HttpErrorResponse) {

		//Console log here for local dev to see it.
		console.log('Error captured: ', error);

		//Declare services we need to handle errors
		const globalFunctions = this.injector.get(GlobalFunctions);
		const clientDataStore = this.injector.get(ClientDataStore);
		const errorsService = this.injector.get(ErrorService);
		const notifyService = this.injector.get(NotifyService);
		const ngZone = this.injector.get(NgZone);
		const router = this.injector.get(Router);

		//Hide all mat dialogs as well 
		globalFunctions.CloseAllMatDialogs();

		//Close the global loading spinner
		clientDataStore.SetShowFullscreenLoading(false);

		let errorMessage = "";
		let errorTitle = "";

		//HTTP errors are treated a little differently
		if (error instanceof HttpErrorResponse) {
			let toastTitle = "Network or HTTP Error";
			let toastMessage = "";

			//Some http error happened
			if (!navigator.onLine) {
				//No internet connection
				toastMessage = 'No Internet Connection'

				//We can't log bugs if there is no internet connection. turn bug reporting off by removing any access token that might exist.
				clientDataStore.loginDataDirect.AccessToken = "";
			}
			else {
				toastMessage = "An unexpected error has occurred."
			}

			switch (error.status) {
				case 0:
					{
						toastTitle = "No Response";
						toastMessage = "Server did not respond! Please check your connection, or try again later";

						//We can't log bugs if the server is not responding. turn bug reporting off.
						clientDataStore.loginDataDirect.AccessToken = "";
						break;
					}
				case 403: {
					toastTitle = "Not Authorised";
					toastMessage = "Unauthorised";
					break;
				}
				case 500: {
					toastMessage = "Unexpected Server error";
					break;
				}
			}

			errorsService
				.logHttpResponseError(error, toastMessage)
				.subscribe(x => {
					notifyService.Error_Show(toastMessage, toastTitle);
					ngZone.run(() => router.navigate(['/Error']));
				});

		} else if (error.message.includes('Uncaught (in promise): Error: Network Error')) {
			errorMessage = "Network error has occurred. Make sure you are connected to the internet."

			//We can't log bugs if there is no internet. Turn bug reporting off.
			clientDataStore.loginDataDirect.AccessToken = "";

			//Use the error service to log the error and send us to the custom error page
			errorsService.LogCustomError(error, errorMessage, "Network Error");
		} else {
			errorTitle = "Error!"

			//Use a friendlier message for users.
			const errorMessageCustom = "Oops! There was an application error."

			//Use the error service to log the error and send us to the custom error page
			errorsService.LogCustomError(error, errorMessageCustom, errorTitle);
		}
	}
}