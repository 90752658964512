<div id="PG_ConditionSubsequentDetail"
  class="col-12 glb_customFlexRow glb_formBGColor glb_boxedPaddingNarrow glb_Font text-white glb_Font glb_paddingZeroOffset">
  <div class="glb_Font header-padding col-12 glb_customFlexRow">
    <div class="glb_boxedPaddingNarrow col-12">
      <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">

        <!-- Headers -->
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM">
          <div class="glb_thinBottomWhiteBorder glb_paddingZeroOffset" [ngClass]="CssClass_Get()">
            <div *ngFor="let headerItem of HeaderColumns" class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div style="padding-right: 5px;">{{headerItem.DisplayName}}</div>
              <fa-icon *ngIf="headerItem.SortKey > 0 && headerItem.SortDirection === 1" class="fa-fw glb_hoverHand"
                [icon]="['fal', 'arrow-down-short-wide']" matTooltip="Click to Sort" matTooltipPosition="right" (click)="Click_Sort(headerItem.Name)">
              </fa-icon>
              <fa-icon *ngIf="headerItem.SortKey > 0 && headerItem.SortDirection === -1" class="fa-fw glb_hoverHand"
                [icon]="['fal', 'arrow-down-wide-short']" matTooltip="Click to Sort" matTooltipPosition="right" (click)="Click_Sort(headerItem.Name)">
              </fa-icon>
              <fa-icon *ngIf="headerItem.SortKey === 0" class="fa-fw glb_hoverHand" [icon]="['fal', 'sort']" matTooltip="Click to Sort"
                matTooltipPosition="right" (click)="Click_Sort(headerItem.Name)">
              </fa-icon>
              <span *ngIf="headerItem.SortKey > 0">{{headerItem.SortKey}}</span>
            </div>
          </div>
        </div>

        <!-- Details -->
        <div id="LBL_DetailRows" *ngFor="let item of TargetDetailData" class="col-12 glb_customFlexRow glb_paddingZeroOffset">
          <div class="glb_paddingZeroOffset glb_paddingBottomXS" [ngClass]="CssClass_Get()">

            <!-- Loop through headers to match order of columns -->
            <div *ngFor="let headerItem of HeaderColumns" class="glb_customFlexRow align-items-center glb_rightPaddingNarrow"
              matTooltip="{{item[headerItem.DisplayProperty]}}" matTooltipPosition="right"
              [matTooltipDisabled]="!item[headerItem.Name + '_EnableTooltip']">

              <!-- Regular data (not a hyperlink) -->
              <div *ngIf="headerItem.Name !== 'LoanID'">{{TextSize_Limit(item[headerItem.DisplayProperty],TextDisplayLimit)}}</div>

              <!-- Link type display (only the LoanID column for now) -->
              <a *ngIf="headerItem.Name === 'LoanID'" id="LNK_LaunchTask" class="hyperlinkID glb_hoverHand"
                (click)="TaskView_Launch(item)">{{TextSize_Limit(item[headerItem.DisplayProperty],TextDisplayLimit)}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>