<br>
<div class="text-white glb_Font col-lg-8">
  <p class="glb_TitleLG glb_TitleLGMobile">{{AppName}} Two Factor Setup</p>
  <p class="">Please enter your Login ID (received via email) and password below</p>

  <div class="col-lg-12" style="padding-bottom: 2.2em;">
    <p style="padding-bottom: 0.2em;">Please note that your 2FA set up code:</p>
    <li>Will only be delivered on this web page after you submit the below details successfully</li>
    <li>Must be saved on your mobile device now</li>
    <li>Will only be presented to you once</li>
    <li>If you are unable to save this code after you submit, please contact your administrator to send a new 2FA setup link</li>
  </div>

  <div class="text-white glb_customFlexRow row-cols-1 d-flex col-lg-12 justify-content-left" style="padding-bottom: 1.2em;">
    <div style="padding-bottom: 8px;">
      <input #focusField1 cdkFocusInitial tabindex="21" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font"
        placeholder="Enter your Login ID" name="userNumber" [(ngModel)]="User2FADataRequest.UserNumber">
    </div>

    <div style="padding-bottom: 8px;">
      <input #focusField2 tabindex="22" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font" type="password"
        placeholder="Enter a password" name="password" [(ngModel)]="User2FADataRequest.Password">
    </div>
  </div>

  <br>

  <!-- buttons -->
  <div class="glb_customFlexRow row-cols-1 justify-content-left">
    <div *ngIf="!IsGenerating2FACode" class="col-sm-8 col-lg-12 d-grid" style="padding-bottom: 8px;">
      <button tabindex="24" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
        (keydown.enter)="TwoFactor_Setup(TwoFactorSetupTemplate)" (click)="TwoFactor_Setup(TwoFactorSetupTemplate)">
        <span class="glb_Font glb_smallerText">Submit</span></button>
    </div>
    <div *ngIf="IsGenerating2FACode" class="col-sm-8 col-lg-12 d-grid" style="padding-bottom: 8px;">
      <button class="glb_standardButton btn glb_BGColorSkin text-white" style="position: relative;">
        <span class="glb_Font glb_hiddenText glb_smallerText">Submit</span>
        <div class="spinner-border glb_floatingSpinner"></div>
      </button>
    </div>
  </div>
</div>

<ng-template #TwoFactorSetupTemplate>
  <div class="text-white glb_Font glb_boxedPadding glb_formBGColor">
    <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button [disabled]="IsGenerating2FACode" tabindex="29"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="LoginModal_Launch()">
          <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
        </button>
      </div>
    </div>

    <div class="glb_customFlexRow" style="padding-bottom: 1.2em;">
      <p class="glb_Title glb_TitleMDMobile">Two Factor Setup</p>
    </div>
    <div class="glb_customFlexRow" style="padding-bottom: 1.2em;">
      <div cdkFocusInitial [innerHTML]="ResponseMessageHTML | sanitizeHtml"></div>
    </div>
    <!-- buttons -->
    <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
      <div *ngIf="!IsGenerating2FACode" style="padding-bottom: 8px;" class="d-grid">
        <button tabindex="28" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (keydown.enter)="LoginModal_Launch()"
          (click)="LoginModal_Launch()">
          <span class="glb_Font glb_smallerText">Login</span></button>
      </div>
      <div *ngIf="IsGenerating2FACode" style="padding-bottom: 8px;" class="d-grid">
        <button class="glb_standardButton btn glb_BGColorSkin text-white" style="position: relative;">
          <span class="glb_Font glb_hiddenText glb_smallerText">Login</span>
          <div class="spinner-border glb_floatingSpinner"></div>
        </button>
      </div>
    </div>
  </div>
</ng-template>