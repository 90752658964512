import { Component, OnInit, TemplateRef } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { StaticDataControllerMethods } from '@app/Global/EnumManager';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';

@Component({
  selector: 'AccountSummaryDetail',
  templateUrl: './AccountSummaryDetail.html',
  styleUrls: ['./AccountSummaryDetail.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class AccountSummaryDetail implements OnInit {

  //Unique Modal Identifer for the Account Summary Detail
  public ModalIdentifier;

  //Unique Modal Identifer for the sumary group
  public GroupModalIdentifier;

  //Unique Modal Identifer for the config name change modal
  public ConfigNameModalIdentifier;

  //Unique Modal Identifer for displaying mapped roles modal
  public ConfigMappedRolesModalIdentifier;

  //To launch new modal for adding a new group
  public DialogRef;

  //Account Summary Configuration to access parent attributes
  public AccountSummaryConfiguration;

  //Class properties
  public AccountSummaryConfigGUID;
  public AccountSummaryConfigName = "";
  public AccountSummaryGroups = [];
  public AccountSummaryDataUnits = [];
  public ConnectedToAccountSummaryGroups = [];
  public AccountSummaryDetailsDisplay = [];
  public DefaultDataUnitGUIDs = [];
  public UsedDataUnitGUIDs = [];
  public AccountSummaryDetailsDisplayCached = [];
  public LinkedRoles = [];

  //Used for creating/updating group details
  public GroupName: string;
  public GroupDisplayName: string;
  public GroupTemplateTitle: string;
  public ChosenGroupGUID: string;
  public IsCreatingNewGroup: boolean;

  //For updating the config name
  public ConfigurationSummaryName: string;

  //Flag to check whether the configuration is editable or not
  public EditEnabled = false;

  //Flag to check whether the configuration is editable or not
  public IsCloning = false;

  //Number of mapped roles
  public NumMappedRoles = 0;

  //Number of mapped roles text display
  public MappedRolesTextDisplay = "";

  //Current page for mapped role pagination
  public MappedRoleListCurrentPage: { currentPage: number } = { currentPage: 0 };

  //Used by paginator
  public MappedRolePaging = {
    MaxSize: 10,
    PreviousLabel: "",
    NextLabel: ""
  }

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AccountSummaryDetail>
  ) {

  }

  //Angular constructor
  ngOnInit(): void {

    //Initialise the page
    this.Page_Init();

  }

  //To initialise a page with the server data
  public Page_Init() {

    this.IsCloning = false;

    //Set the configuration name
    this.ConfigurationSummaryName = this.AccountSummaryConfigName;

    //Initialise the arrays
    this.AccountSummaryDetailsDisplay = [];
    this.DefaultDataUnitGUIDs = [];
    this.UsedDataUnitGUIDs = [];

    //Sort the data units in ascending order
    this.AccountSummaryDataUnits = this.AccountSummaryDataUnits.sort((n1, n2) => n1.DisplayOrder - n2.DisplayOrder);

    //Lets add all available DataUnits to the default data unit groups for resetting later, if required
    this.AccountSummaryDataUnits.forEach(du => {
      this.DefaultDataUnitGUIDs.push(du.GUID);
    });

    if (!this.globalFunctions.isEmpty(this.AccountSummaryGroups)) {

      //Sort the account summary groups in ascending order first
      this.AccountSummaryGroups = this.AccountSummaryGroups.sort((n1, n2) => n1.DisplayOrder - n2.DisplayOrder);

      //Group the summary data unit guids for display in the UI
      this.AccountSummaryGroups.forEach(group => {
        let groupDetails = group.ClientDUSummaryConfigGroupDetails;
        if (!this.globalFunctions.isEmpty(groupDetails)) {
          const detailDUGUIDs = [];

          //Sort the details
          groupDetails = groupDetails.sort((n1, n2) => n1.DisplayOrder - n2.DisplayOrder);

          groupDetails.forEach(detail => {

            //Grouping the DU guids for display
            detailDUGUIDs.push(detail.DataUnitGUID);

            //This is to track which data units have already been grouped
            this.UsedDataUnitGUIDs.push(detail.DataUnitGUID);
          });

          //This will be used for diplay in UI
          //The detail guids are attached as a string array for Drag and Drop feature
          this.AccountSummaryDetailsDisplay.push(
            {
              GroupGUID: group.GUID
              , Name: group.Name
              , DisplayName: group.DisplayName
              , Type: "Section"
              , DetailGUIDs: detailDUGUIDs
            }
          );
        }
      });
    }

    //Now, lets get the remaining DU available for Drag and Drop
    const RemainingDUGUIDs = [];
    this.DefaultDataUnitGUIDs.forEach(du => {
      if (!this.UsedDataUnitGUIDs.includes(du)) {
        RemainingDUGUIDs.push(du);
      }
    });

    //This will be displayed as available DUs
    this.AccountSummaryDetailsDisplay.push(
      {
        GroupGUID: "Total"
        , Name: "Available Data Units"
        , DisplayName: "Available Data Units"
        , Type: "DataUnits"
        , DetailGUIDs: RemainingDUGUIDs
      }
    );

    //This array GUIDs are used to check whether the drop area is valid for the dragged GUID or not
    for (const detailDisplay of this.AccountSummaryDetailsDisplay) {
      this.ConnectedToAccountSummaryGroups.push(detailDisplay.GroupGUID);
    }

    //Cache the details for comparison later
    this.AccountSummaryDetailsDisplayCached = JSON.parse(JSON.stringify(this.AccountSummaryDetailsDisplay));

    //Reset the num linked roles
    this.NumMappedRoles = 0;

    //Let's check if this config is mapped to any roles
    if (!this.globalFunctions.isEmpty(this.LinkedRoles)) {
      this.NumMappedRoles = this.LinkedRoles.length;

      //Construct a mapped role display text
      let roleText = "roles";

      if (this.NumMappedRoles == 1) {
        roleText = "role"
      }

      this.MappedRolesTextDisplay = "This configuration is mapped to " + this.NumMappedRoles + " " + roleText + " (Click here to view all mapped roles)."
    }
  }

  //Pagination - Page change
  public Page_OnChange(page: number, currentPage, doScrollToBottom = true) {
    currentPage.currentPage = page;

    //Dont scroll to top, bad UI experience. scroll to bottom instead
    if (doScrollToBottom) {
      this.globalFunctions.delay(50).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
  }

  //Discard configuration detail changes
  public ConfigurationDetailsChanges_Discard() {

    //Disable editing
    this.EditEnabled = false;

    //Initialise page with raw data
    this.Page_Init();
  }

  //Delete a config
  public Configuration_Delete() {

    //Display a confirmer message
    const confirmDialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);
    confirmDialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to delete this configuration: " + this.AccountSummaryConfigName + "?";

    confirmDialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Turn on full screen loading
        this.clientDataStore.SetShowFullscreenLoading(true);

        //Construct API request
        const apiRequest = { ConfigurationGUIDToDelete: this.AccountSummaryConfigGUID };

        //Invoke API to get the summary details for the chosen config
        this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.DeleteAccountSummaryConfiguration], apiRequest)
          .subscribe(apiResponse => {

            if (this.globalFunctions.isEmpty(apiResponse)) {
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {

              //Lets remove the deleted config from the summary list
              //Find the index to splice from the array
              const matchingConfigIndex = this.AccountSummaryConfiguration.AccountSummaryConfigs.findIndex(x => x.GUID === this.AccountSummaryConfigGUID);
              this.AccountSummaryConfiguration.AccountSummaryConfigs.splice(matchingConfigIndex, 1);

              //A notification would be nice
              this.notifyService.Success_Show("Account Summary Configuration has been deleted successfully", "Success");

              //Close the modal
              this.Dialog_Close(this.ModalIdentifier);

              //Turn off full screen loading
              this.clientDataStore.SetShowFullscreenLoading(false);
            }
          });
      }
    });
  }

  //Save config changes
  public ConfigurationDetailsChanges_Save() {

    //Construct the summary groups for making API request
    const summaryGroupsRequest = [];

    //Get the account summary groups/details if there are any
    const accountSummaryGroups = this.AccountSummaryGroups_Get();

    //Check if there any summary groups
    if (!this.globalFunctions.isEmpty(accountSummaryGroups)) {

      //For the group display order
      let groupDisplayOrder = 1;

      //Loop through the summary groups and add it to the api request array: summaryGroupsRequest 
      accountSummaryGroups.forEach(itemGroup => {

        //Create a summary group object with empty details. Initialise details with empty array to be updated later if there are any details
        const summaryGroup = { GUID: itemGroup.GroupGUID, Name: itemGroup.Name, DisplayName: itemGroup.Name, DisplayOrder: groupDisplayOrder, ClientDUSummaryConfigGroupDetails: [] }

        //Check if the matching group has any details/data units
        if (!this.globalFunctions.isEmpty(itemGroup.DetailGUIDs)) {

          //Detail display order
          let detailDisplayOrder = 1;

          //Loop through each detail data unit and construct detail data units for api request
          itemGroup.DetailGUIDs.forEach(itemDetail => {

            //Create group detail object
            const groupDetail = { DataUnitGUID: itemDetail, DisplayOrder: detailDisplayOrder }

            //Add it to the group
            summaryGroup.ClientDUSummaryConfigGroupDetails.push(groupDetail);

            //Increase the display order
            detailDisplayOrder = detailDisplayOrder + 1;
          });
        }

        //Add the summary to the api request array
        summaryGroupsRequest.push(summaryGroup);

        //Increase the group display order
        groupDisplayOrder = groupDisplayOrder + 1;
      });
    }

    //Check if the configuration was modified
    if (!this.IsCloning && JSON.stringify(this.AccountSummaryDetailsDisplayCached) === JSON.stringify(this.AccountSummaryDetailsDisplay)) {
      this.notifyService.Warning_Show("The configuration matches the existing values. No changes detected.", "Warning!");
      return;
    }

    //Construct API request
    const apiRequest = { AccountSummaryConfigurationGUID: this.AccountSummaryConfigGUID, ConfigurationName: this.ConfigurationSummaryName, AccountSummaryConfigurationGroups: summaryGroupsRequest };

    //Invoke API to get the summary details for the chosen config
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.UpdateAccountSummaryConfigurationDetails], apiRequest)
      .subscribe(apiResponse => {

        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Set the response values
          this.AccountSummaryGroups = response.AccountSummaryConfigurationGroups;
          this.AccountSummaryDataUnits = response.AccountSummaryDataUnits;

          //Initialise the page
          this.Page_Init();

          //Disable the editing
          this.EditEnabled = false;

          //A notification would be nice
          this.notifyService.Success_Show("Account Summary Configuration Details Updated", "Success");

          //Turn off full screen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });
  }

  //Clone from a configuration
  public Configuration_Clone() {

    //Display a confirmer message
    const confirmDialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);
    confirmDialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to clone from this configuration: " + this.AccountSummaryConfigName + "?";

    confirmDialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Turn on full screen loading
        this.clientDataStore.SetShowFullscreenLoading(true);

        //Construct API request
        const apiRequest = { CloneFromConfigurationGUID: this.AccountSummaryConfigGUID };

        //Invoke API to get the summary details for the chosen config
        this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.CloneAccountSummaryConfiguration], apiRequest)
          .subscribe(apiResponse => {

            if (this.globalFunctions.isEmpty(apiResponse)) {
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {

              //Deserialize it into an class that we can understand
              const response = JSON.parse(JSON.stringify(apiResponse));

              //Set the response values
              this.AccountSummaryGroups = response.AccountSummaryConfigurationGroups;
              this.AccountSummaryDataUnits = response.AccountSummaryDataUnits;
              this.AccountSummaryConfigGUID = response.AccountSummaryConfigurationGUID;
              this.AccountSummaryConfigName = response.ConfigurationName;

              //Reset the linked roles array
              this.LinkedRoles.length = 0;

              //Get the first entry in the array (we only expect one claim to return)
              const newAccountSummaryConfiguration = { GUID: this.AccountSummaryConfigGUID, ConfigurationName: this.AccountSummaryConfigName, Name: this.AccountSummaryConfigName };

              //Unescape
              newAccountSummaryConfiguration.Name = this.globalFunctions.HTMLUnescape(newAccountSummaryConfiguration.ConfigurationName);

              //Unshift this into the client side array (at the start)
              this.AccountSummaryConfiguration.AccountSummaryConfigs.unshift(newAccountSummaryConfiguration);

              //Initialise the page
              this.Page_Init();

              //A notification would be nice
              this.notifyService.Success_Show("Account Summary Configuration Details cloned successfully", "Success");

              //Turn off full screen loading
              this.clientDataStore.SetShowFullscreenLoading(false);
            }
          });

      }
    });
  }

  //Method to move the item in arrays when it is dragged and dropped
  DraggedItem_Dropped(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    //Sync the data units after an item has been dropped
    this.DataUnits_Sync();
  }

  //Get account summary group data
  public AccountSummaryGroups_Get() {
    //return this.AccountSummaryDetailsDisplay.filter(x => x.GroupGUID !== "Total");
    return this.AccountSummaryDetailsDisplay.filter(x => x.Type === "Section");
  }

  //Get available data units
  public DataUnitGroups_Get() {
    return this.AccountSummaryDetailsDisplay.filter(x => x.Type === "DataUnits");
  }

  //Remove a group
  public RemoveSection_Click(groupGUID) {
    const matchingGroup = this.AccountSummaryDetailsDisplay.filter(x => x.GroupGUID === groupGUID)[0];
    if (!this.globalFunctions.isEmpty(matchingGroup)) {

      //Display a confirmer message
      const confirmDialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);
      confirmDialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to remove this group: " + matchingGroup.Name + "?";

      confirmDialogRef.DialogRef.afterClosed().subscribe(result => {
        if (result === true) {

          //Lets sync the removed DUs
          const groupDUDetails = matchingGroup.DetailGUIDs;
          if (!this.globalFunctions.isEmpty(groupDUDetails)) {
            const availableDUDetails = this.AccountSummaryDetailsDisplay.filter(x => x.Type === "DataUnits")[0];
            if (!this.globalFunctions.isEmpty(availableDUDetails)) {
              groupDUDetails.forEach(deletedDU => {
                availableDUDetails.DetailGUIDs.push(deletedDU);
              });
            }
          }

          //Find the index to splice from the array
          const matchingGroupIndex = this.AccountSummaryDetailsDisplay.findIndex(x => x.GroupGUID === matchingGroup.GroupGUID);
          this.AccountSummaryDetailsDisplay.splice(matchingGroupIndex, 1);

          const matchingConnectedGroupIndex = this.ConnectedToAccountSummaryGroups.findIndex(x => x === matchingGroup.GroupGUID);
          if (!this.globalFunctions.isEmpty(matchingConnectedGroupIndex)) {
            this.ConnectedToAccountSummaryGroups.splice(matchingConnectedGroupIndex, 1);
          }

          //Sync the data units
          this.DataUnits_Sync();
        }
      }
      );
    }
  }

  //Adding new group
  public AddNewSection_Click() {
    const newGroupGUID = this.globalFunctions.GenerateFastGUID();

    //Lets just sync the display name for now. If we need, we can have separate input for display name
    this.GroupName = this.GroupDisplayName;

    this.AccountSummaryDetailsDisplay.push(
      {
        GroupGUID: newGroupGUID
        , Name: this.GroupName
        , DisplayName: this.GroupDisplayName
        , Type: "Section"
        , DetailGUIDs: []
      }

    );

    //Add it to the array to make it a valid drop area
    this.ConnectedToAccountSummaryGroups.push(newGroupGUID);

    //Close the dialog
    this.Dialog_Close(this.GroupModalIdentifier);
  }

  //Get data unit name
  public DataUnitName_Get(dataUnitGUID: string) {
    const targetDU = this.AccountSummaryDataUnits.filter(x => x.GUID === dataUnitGUID)[0];
    if (!this.globalFunctions.isEmpty(targetDU)) {
      return targetDU.Name;
    }

    return "Not found";
  }

  //Get data unit display name
  public DataUnitDisplayName_Get(dataUnitGUID: string) {
    const targetDU = this.AccountSummaryDataUnits.filter(x => x.GUID === dataUnitGUID)[0];
    if (!this.globalFunctions.isEmpty(targetDU)) {
      if (!this.globalFunctions.isEmpty(targetDU.DisplayName)) {
        return targetDU.DisplayName;
      }

      //Else return data unit name
      return targetDU.Name;

    }

    return "Not found";
  }

  //Get the sample example for the data unit
  public DataUnitSampleValue_Get(dataUnitGUID: string) {
    const targetDU = this.AccountSummaryDataUnits.filter(x => x.GUID === dataUnitGUID)[0];
    if (!this.globalFunctions.isEmpty(targetDU)) {
      return targetDU.Example;

    }

    return "Not found";
  }

  //Get account summary group name
  public AccountSummaryGroupName_Get(groupGUID: string) {
    const targetSummaryGroup = this.AccountSummaryGroups.filter(x => x.GUID === groupGUID)[0];

    if (!this.globalFunctions.isEmpty(targetSummaryGroup)) {
      return targetSummaryGroup.Name;
    }

    return "Not found";
  }

  //Syncing the data units
  public DataUnits_Sync() {
    const availableDUDetails = this.AccountSummaryDetailsDisplay.filter(x => x.Type === "DataUnits")[0];

    if (!this.globalFunctions.isEmpty(availableDUDetails)) {
      const newAvailableDUDetails = [];
      if (this.globalFunctions.isEmpty(availableDUDetails.DetailGUIDs)) {
        availableDUDetails.DetailGUIDs = [];
      }
      this.DefaultDataUnitGUIDs.forEach(element => {
        if (availableDUDetails.DetailGUIDs.includes(element) === true) {
          newAvailableDUDetails.push(element);
        }
      });

      availableDUDetails.DetailGUIDs = newAvailableDUDetails;
    }
  }

  //Pop up create group dialog box
  public Group_CreateModal(template: TemplateRef<any>, groupGUID = "") {

    //Initialise the group properties
    this.GroupDisplayName = "";
    this.GroupName = "";
    this.ChosenGroupGUID = "";
    this.GroupTemplateTitle = "Add New Group";
    this.IsCreatingNewGroup = true;

    //Check if it is for creating new group or updating existing one
    if (!this.globalFunctions.isEmpty(groupGUID)) {

      //This is for updating existing group
      const summaryGroup = this.AccountSummaryDetailsDisplay.filter(x => x.GroupGUID === groupGUID)[0];
      if (!this.globalFunctions.isEmpty(summaryGroup)) {
        this.ChosenGroupGUID = summaryGroup.GroupGUID;
        this.GroupName = summaryGroup.Name;
        this.GroupDisplayName = summaryGroup.DisplayName;
        this.GroupTemplateTitle = "Update Group Name";
        this.IsCreatingNewGroup = false;
      }
    }

    //Generate a unique identifier
    this.GroupModalIdentifier = this.globalFunctions.GenerateFastGUID();

    //Launch a dialog to create/update group
    this.globalFunctions.FeatureModal_Launch(template, this.globalFunctions.GetFeatureModalConfig('20%'), this.dialog, "Create Group", 0, true, false, this.GroupModalIdentifier);
  }

  //Pop up create config name change modal
  public ConfigNameEdit_CreateModal(template: TemplateRef<any>) {

    //Generate a unique identifier
    this.ConfigNameModalIdentifier = this.globalFunctions.GenerateFastGUID();

    //Launch a dialog to create/update group
    this.globalFunctions.FeatureModal_Launch(template, this.globalFunctions.GetFeatureModalConfig('20%'), this.dialog, "Update Config Name", 0, true, false, this.ConfigNameModalIdentifier);
  }

  //Dialog to display list of mapped roles
  public ConfigMappedRoles_Launch(template: TemplateRef<any>) {

    //Always start at the first page
    this.MappedRoleListCurrentPage.currentPage = 0;

    //Generate a unique identifier
    this.ConfigMappedRolesModalIdentifier = this.globalFunctions.GenerateFastGUID();

    //Launch a dialog
    this.globalFunctions.FeatureModal_Launch(template, this.globalFunctions.GetFeatureModalConfig('40%'), this.dialog, "List of mapped roles", 0, true, false, this.ConfigMappedRolesModalIdentifier);
  }

  //For updating the group details
  public GroupUpdate_Click() {

    //Check if we have the target group GUID
    if (!this.globalFunctions.isEmpty(this.ChosenGroupGUID)) {
      const targetSummaryGroupDisplay = this.AccountSummaryDetailsDisplay.filter(x => x.GroupGUID === this.ChosenGroupGUID)[0];
      if (!this.globalFunctions.isEmpty(targetSummaryGroupDisplay)) {

        //Lets just sync the display name for now. If we need, we can have separate input for display name
        this.GroupName = this.GroupDisplayName;

        //Set the group details to update
        targetSummaryGroupDisplay.Name = this.GroupName;
        targetSummaryGroupDisplay.DisplayName = this.GroupDisplayName;

        //Now update the Account summary group (raw data)
        const targetSummaryGroupD = this.AccountSummaryGroups.filter(x => x.GUID === this.ChosenGroupGUID)[0];
        if (!this.globalFunctions.isEmpty(targetSummaryGroupD)) {
          targetSummaryGroupD.Name = this.GroupName;
          targetSummaryGroupD.DisplayName = this.GroupDisplayName;
        }
      }
    }

    //Close the dialog
    this.Dialog_Close(this.GroupModalIdentifier);
  }

  //Close the group data dialog
  public Dialog_Close(modalIdentifier) {

    //Close the modal
    this.globalFunctions.FeatureModal_Close(modalIdentifier, false);
  }

  //Enable editing account summary configs
  public AccountSummaryConfig_EnableEditing() {
    this.EditEnabled = true;
  }

  //Check if editing is enabled or not
  public EditingDisabled_Check() {
    return !this.EditEnabled;
  }

  //Update the config name
  public ConfigNameUpdate_Click(configNameModalIdentifier) {

    //Construct API request
    const apiRequest = { AccountSummaryConfigurationGUID: this.AccountSummaryConfigGUID, ConfigurationName: this.ConfigurationSummaryName };

    //Turn on full screen loading
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Invoke API to get the summary details for the chosen config
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.UpdateAccountSummaryConfigurationName], apiRequest)
      .subscribe(apiResponse => {

        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Update successful
          //Set the account summary config name
          this.AccountSummaryConfigName = this.ConfigurationSummaryName;

          //Also update the name in the parent list
          const matchingConfig = this.AccountSummaryConfiguration.AccountSummaryConfigs.filter(x => x.GUID === this.AccountSummaryConfigGUID)[0];
          if (!this.globalFunctions.isEmpty(matchingConfig)) {
            matchingConfig.Name = this.ConfigurationSummaryName;
          }

          //A notification would be nice
          this.notifyService.Success_Show("Account Summary Configuration name updated", "Success");

          //Close the modal
          this.Dialog_Close(configNameModalIdentifier);

          //Turn off full screen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });
  }
}