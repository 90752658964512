import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'ForgotPassword',
  templateUrl: './ForgotPassword.html',
  styleUrls: ['./ForgotPassword.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})
export class ForgotPassword {
  @Input() loginModal: any;

  constructor(private router: Router,) {
  }

  ngOnInit() {
  }

  //this is the field that gets autofocused
  @ViewChild('focusField') focusField;
  @ViewChild('focusField2') focusField2;
}
