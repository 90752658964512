<div id="PG_ConfirmModal" class="glb_formBGColor glb_boxedPadding">
    <div class="text-white">
        <p class="glb_Font">{{message}}</p>
        <div class="glb_Font" [innerHtml]="htmlContent | sanitizeHtml"></div>
    </div>
    <br>
    <div *ngIf="ShowYesButton" class="glb_paddingTopSM glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
            <button id="BTN_ConfirmPageYes" tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                [mat-dialog-close]="true">
                <span class="glb_Font glb_smallerText">{{YesButtonText}}</span></button>
        </div>
        <div *ngIf="ShowNoButton" class="glb_paddingBottomSM d-grid">
            <button id="BTN_ConfirmPageNo" tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="onNoClick()" cdkFocusInitial>
                <span class="glb_Font glb_smallerText">No</span></button>
        </div>
    </div>
</div>