<div id="PG_RichTextEditor" class="glb_formBGColor glb_boxedPadding">

    <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom" class="closeModal">
            <button id="BTN_RichTextEditorClose" tabindex="15"
                class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                (click)="Modal_Close()" [disabled]="EditEnabled">
                <fa-icon [icon]="['fal', 'times']" class="fa-md modalIcon"></fa-icon>
            </button>
        </div>
    </div>
    <div class="glb_Font header-padding glb_customFlexRow">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <div class="col-8 glb_paddingZeroOffset">
                <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
                    {{PageHeader}}
                </div>
            </div>
        </div>
    </div>

    <!-- Primeng (Quill) Rich Text Editor -->
    <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
        <div class="">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow"></div>
            <div id="toolbar" class="glb_quillInputBackgroundColor">
                <span class="ql-formats">
                    <select class="ql-header"></select>
                    <select class="ql-size"></select>
                    <select class="ql-font"></select>
                </span>
                <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                    <select class="ql-color ql-picker ql-color-picker"></select>
                    <select class="ql-background ql-picker ql-color-picker"></select>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align">
                        <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                        <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                        <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                        <button class="ql-align ql-align-justify q1-box" title="justify" value="justify"></button>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-link" aria-label="Link" title="Link"></button>
                    <button class="ql-image" aria-label="Insert Image" title="Insert Image"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" title="Remove Styles"></button>
                </span>
                <span class="ql-formats">
                    <button class="glb_quillVariablesTextColor" aria-label="Variables" title="Add variables" (click)="ShowVariables_Toggle()"
                        [disabled]="!EditEnabled">Variables</button>
                </span>
                <div *ngIf="ShowVariables" class="text-black glb_customFlexRow col-12">
                    <div *ngFor="let variable of Variables" class="glb_boxedPaddingNarrow"
                        (click)="Variable_Click(QuillRichTextEditor,variable.Name)">
                        <div class="glb_hoverHand glb_allBorders glb_clickableIconButtonStyleGrey glb_boxedPaddingNarrow glb_quillVariablesBG">
                            <div class="glb_boxedPaddingNarrow glb_quillVariablesTextColor" matTooltip="{{variable.DisplayName}}
                                                                        {{variable.Description}}
                                                                        Example: {{variable.Example}}">
                                {{variable.Name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #Quill_RichTextEditor class="glb_customFlexRow glb_quillInputBackgroundColor glb_inputFieldLabel glb_quillContent"
                id="quill-editor_RichTextEditor">
            </div>
        </div>
    </div>

    <div class="col-12 glb_customFlexRow justify-content-end glb_paddingTopSM">
        <div *ngIf="EditEnabled" class="glb_customFlexRow">
            <!--Reset button-->
            <div class="glb_customFlexRow glb_boxedPaddingNarrow">
                <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" matTooltip="Revert your changes"
                    (click)="Content_Reset()">
                    <span class="glb_Font glb_smallerText">Revert</span>
                </button>
            </div>

            <!--Cancel button-->
            <div class="glb_customFlexRow glb_boxedPaddingNarrow">
                <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" matTooltip="Discard your changes and close"
                    (click)="Content_Cancel()">
                    <span class="glb_Font glb_smallerText">Cancel</span>
                </button>
            </div>

            <!-- Finish button-->
            <div class="glb_customFlexRow glb_boxedPaddingNarrow">
                <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" matTooltip="Confirm and close"
                    (click)="Content_Save()">
                    <span class="glb_Font glb_smallerText">Confirm</span>
                </button>
            </div>
        </div>
    </div>


</div>