import { Component, Input, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';


@Component({
  selector: 'TwoFactorAuthentication',
  templateUrl: './TwoFactorAuthentication.html',
  styleUrls: ['./TwoFactorAuthentication.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})
export class TwoFactorAuthentication {
  @Input() loginModal: any;
  @ViewChild('focusField') focusField;
  public appName = this.globalfunctions.GetApplicationName();

  constructor(private router: Router, private globalfunctions: GlobalFunctions) {
  }


  ngOnInit() {
  }
}
