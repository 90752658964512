<div>
  <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom">
      <button tabindex="24" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
        (click)="loginModal.ForgotPassword_Cancel()">
        <fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
      </button>
    </div>
  </div>

  <div class="glb_customFlexRow glb_Font glb_TitleMD glb_TitleMDMobile text-white">
    <div>
      <div>Please enter the details below to reset your password.</div>
    </div>
  </div>
  <br>

  <!-- input fields -->
  <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
    <div class="glb_paddingBottomSM">
      <input #focusField autocomplete="userNumber" type="text" cdkFocusInitial tabindex="21" placeholder="Enter your Login ID" name="userNumber"
        [(ngModel)]="loginModal.ResetUserNumber" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font">
    </div>

    <div class="glb_paddingBottomSM">
      <input #focusField2 tabindex="22" type="emailAddress" autocomplete="emailaddress" placeholder="Enter your Email Address" name="emailAddress"
        [(ngModel)]="loginModal.ResetEmailAddress" class="glb_loginInputBox glb_skinLightOutline text-white glb_Font">
    </div>
  </div>

  <!-- buttons -->
  <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_paddingBottomMD">
    <div *ngIf="!loginModal.IsPasswordResetLink" class="glb_paddingBottomSM d-grid">
      <button tabindex="23" class="glb_standardButton btn glb_BGColorSkin glb_skinLightOutline text-white"
        (click)="loginModal.PasswordResetLink_Get()">
        <span class="glb_Font glb_smallerText">Reset Password</span>
      </button>
    </div>
    <div *ngIf="loginModal.IsPasswordResetLink" class="glb_paddingBottomSM d-grid">
      <button class="glb_standardButton btn glb_BGColorSkin glb_skinLightOutline text-white glb_positionRelative">
        <span class="glb_Font glb_hiddenText glb_smallerText">Reset Password</span>
        <div class="spinner-border glb_floatingSpinner"></div>
      </button>
    </div>
  </div>
</div>