<div id="PG_ConditionSubsequent" class="glb_boxedPadding glb_formBGColor text-white">
  <div class="glb_forceMarginAuto glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
    <div class="glb_sectionTitle1">Condition Subsequent</div>
    <div class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">

      <!-- Show My Tasks Checkbox -->
      <div *ngIf="HasCSEditClaimFlag" class="glb_rightPaddingNarrow">
        <div class="glb_customFlexRow justify-content-left align-items-center" style="padding-bottom: 6px;">
          <mat-checkbox id="CB_ShowMyTasks" class="glb_Font mat-checkbox-dark-custom" [(ngModel)]="ShowMyTasksFlag" (change)="MyTasks_Show()"
            [disabled]="RefreshingAggregatedSpinner">
            Show My Tasks</mat-checkbox>
        </div>
      </div>

      <!-- Expand All Tasks Checkbox -->
      <div class="glb_rightPaddingNarrow">
        <div class="glb_customFlexRow justify-content-left align-items-center" style="padding-bottom: 6px;">
          <mat-checkbox id="CB_ExpandAllTasks" class="glb_Font mat-checkbox-dark-custom" [(ngModel)]="ExpandAllFlag" (change)="AllDetailData_Expand()"
            [disabled]="RefreshingAggregatedSpinner">
            Expand All</mat-checkbox>
        </div>
      </div>

      <!-- Refresh button -->
      <div *ngIf="!RefreshingAggregatedSpinner" class="glb_rightAlignedContentPadding">
        <button id="BTN_Refresh" class="glb_clickableIconButtonPadding2 glb_clickableIconButtonStyle glb_clickableIconButtonMD align-self-right"
          (click)="Aggregate_Refresh()">
          <fa-icon [icon]="['fal', 'sync-alt']" class="fa-fw glb_fixedSizeIcons glb_clickableIconButtonColorDark" matTooltip="Click to Refresh">
          </fa-icon>
        </button>
      </div>

      <!-- Refresh button spinner -->
      <div *ngIf="RefreshingAggregatedSpinner" class="glb_rightAlignedContentPadding">
        <button class="glb_clickableIconButtonPadding2 glb_clickableIconButtonStyle glb_clickableIconButtonMD btn-secondary align-self-right">
          <fa-icon [icon]="['fal', 'compact-disc']" class="fa-fw glb_fastSpin glb_fixedSizeIcons glb_clickableIconButtonColorDark"
            matTooltip="Loading...Please wait"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 glb_customFlexRow glb_paddingTopSM glb_paddingBottomSM">
    <div class="glb_inputFieldLabel glb_customFlexRow col-12 text-white" style="padding-left: 5px;">Choose From and To Dates:</div>
    <div class="col-lg-10 col-md-12 col-sm-12 col-12 glb_customFlexRow">
      <div class="glb_customFlexRow col-4 justify-content-center align-items-center" style="padding: 5px;">
        <div class="col-10 glb_floatParent" style="padding-left:0px; padding-right:0px; padding-bottom:0px;">
          <!-- <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: 1.0em;">From Date</div> -->
          <p-calendar id="INP_FromDate" #calendarFromDate [(ngModel)]="DTPFromDate.JSDate" class="DTPInputGrid" [showTime]="true"
            [showSeconds]="false" [maxDate]="DTPMaxDate" [minDate]="DTPMinDate" [showIcon]="false" [showButtonPanel]="false" [showButtonBar]="true"
            [inline]="false" [showOtherMonths]="false" [hideOnDateTimeSelect]="false" [touchUI]="false" [dateFormat]="DTPDateFormat" [hourFormat]="12"
            [appendTo]="'body'" baseZIndex="15000" inline="false" inputStyleClass="DTPInputStyle glb_Font" placeholder="From Date">
            <ng-template pTemplate="footer">
              <div class="glb_customFlexRow justify-content-center">
                <button id="BTN_CloseCalendar"
                  class="glb_standardButton glb_skinLightOutline glb_clickableIconButtonStyle glb_BGColorSkin text-white glb_Font"
                  (click)="DTP_Close(calendarFromDate, DTPFromDate)">OK</button>
              </div>
            </ng-template>
          </p-calendar>
          <div class="floatTopRightCalendarCS">
            <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button" (click)="calendarFromDate.toggle()">
              <fa-icon [icon]="['fal', 'calendar-clock']" class="fa-md" style="color:black"></fa-icon>
            </span>
          </div>
        </div>

        <!-- button to clear content inside the input box, spans a div of its own -->
        <div class="col-2 glb_PaddingHorizontalSM" style="padding-bottom:0px; padding-top:0px;">
        </div>
      </div>
      <div class="glb_customFlexRow col-4 justify-content-center align-items-center" style="padding: 5px;">
        <div class="col-10 glb_floatParent" style="padding-left:0px; padding-right:0px; padding-bottom:0px;">
          <p-calendar id="INP_ToDate" #calendarToDate [(ngModel)]="DTPToDate.JSDate" class="DTPInputGrid" [showTime]="true" [showSeconds]="false"
            [maxDate]="DTPMaxDate" [minDate]="DTPMinDate" [showIcon]="false" [showButtonPanel]="false" [showButtonBar]="true" [inline]="false"
            [showOtherMonths]="false" [hideOnDateTimeSelect]="false" [touchUI]="false" [dateFormat]="DTPDateFormat" [hourFormat]="12"
            [appendTo]="'body'" baseZIndex="15000" inline="false" inputStyleClass="DTPInputStyle glb_Font" placeholder="To Date">
            <ng-template pTemplate="footer">
              <div class="glb_customFlexRow justify-content-center">
                <button id="BTN_CloseCalendar"
                  class="glb_standardButton glb_skinLightOutline glb_clickableIconButtonStyle glb_BGColorSkin text-white glb_Font"
                  (click)="DTP_Close(calendarToDate, DTPToDate)">OK</button>
              </div>
            </ng-template>
          </p-calendar>
          <div class="floatTopRightCalendarCS">
            <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button" (click)="calendarToDate.toggle()">
              <fa-icon [icon]="['fal', 'calendar-clock']" class="fa-md" style="color:black"></fa-icon>
            </span>
          </div>
        </div>

        <!-- button to clear content inside the input box, spans a div of its own -->
        <div class="col-2 glb_PaddingHorizontalSM" style="padding-bottom:0px; padding-top:0px;">
        </div>
      </div>
      <div class="glb_customFlexRow col-4 justify-content-start align-items-center" style="padding: 5px;">
        <!-- Refresh button -->
        <div class="glb_rightAlignedContentPadding col-12">
          <button id="BTN_Generate"
            class="glb_clickableIconButtonPadding2 glb_clickableIconButtonStyle glb_clickableIconButtonMD align-self-right text-white col-lg-6 col-12 glb_Font"
            (click)="Aggregate_Refresh()">
            Generate
          </button>
        </div>
      </div>
    </div>

  </div>

  <!-- Spinner -->
  <div *ngIf="ShowSpinner" class="glb_forceMarginAuto glb_customFlexRow col-12 justify-content-center">
    <div class="glb_customFlexRow justify-content-center">
      <div class="glb_customFlexRow spinner-border" style="width: 3rem; height: 3rem;"></div>
    </div>
  </div>

  <!-- No Data to display -->
  <div *ngIf="!ShowSpinner && NoAggregateDataFlag"
    class="glb_forceMarginAuto glb_allBordersSkin glb_PaddingHorizontalMD glb_customFlexRow col-12 justify-content-center">
    <div class="glb_Font glb_customFlexRow justify-content-center">
      <div class="glb_customFlexRow glb_boxedPadding">
        No Data
      </div>
    </div>
  </div>

  <div *ngIf="!ShowSpinner && !NoAggregateDataFlag">

    <!-- Row per lender -->
    <div *ngFor="let lender of GroupedLenderData; let i = index" class="glb_allBordersSkin glb_PaddingHorizontalMD glb_Font">
      <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">

        <!-- Lender Display Name-->
        <div class="col-4 glb_customFlexRow align-self-center glb_bold">{{AggregateCount_Display(lender)}}
        </div>

        <!-- Download Button per lender-->
        <div class="col-8 justify-content-end align-items-center glb_customFlexRow" style="padding-right: 10px; padding-top: 5px;">
          <span>
            <button id="BTN_Download" class="glb_clickableIconButtonStyle glb_clickableIconButtonColorDark glb_clickableIconButtonMD align-self-right"
              (click)="DownloadBTN_Click(lender)">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
              </fa-icon>
            </button>
          </span>
        </div>

        <!-- Detail data in separate row-->
        <div class="col-12" style="padding-left: 10px;">

          <!-- Aggregate Data -->
          <div id="LBL_AggregateRows" *ngFor="let item of AggregateData | customArrayValueFilter:'Lender':lender.Lender; let i = index">
            <div class="customCardBody">
              <div>
                <div class="glb_customFlexRow glb_breakLongWords">
                  <div class="col-4 glb_customFlexRow glb_breakLongWords">{{item.AggregationTypePretty}}
                  </div>

                  <!-- Chevron -->
                  <div class="col-6 glb_customFlexRow justify-content-start rightPadding">
                    <a class="glb_hoverHand">
                      <fa-icon id="CHV_Toggle" [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS(item.RowPrimaryKey,true)"
                        class="glb_chevronStyleLight" matTooltip="Expand" matTooltipPosition="left" (click)="Chevron_Expand(item.RowPrimaryKey)">
                      </fa-icon>
                      <fa-icon id="CHV_Toggle" [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS(item.RowPrimaryKey,false)"
                        class="glb_chevronStyleLight" matTooltip="Hide" matTooltipPosition="left" (click)="Chevron_Collapse(item.RowPrimaryKey)">
                      </fa-icon>
                    </a>
                  </div>
                </div>

                <!-- Sliding content linked to the Chevron State -->
                <div [ngClass]="TemplateID_GetCSS(item.RowPrimaryKey)" class="glb_customFlexRow">

                  <!-- Detail spinner -->
                  <div *ngIf="DetailSpinner_State(item.RowPrimaryKey)" class="glb_customFlexRow col-12 justify-content-center">
                    <div class="glb_customFlexRow justify-content-center">
                      <div class="spinner-border" style="width: 1rem; height: 1rem;"></div>
                    </div>
                  </div>

                  <!-- Child components -->
                  <div class="col-12 glb_customFlexRow" [ngClass]="DetailCSS_Get(item.RowPrimaryKey)">
                    <ConditionSubsequentDetail class="col-12 glb_customFlexRow" #DetailComponents [ConditionSubsequent]="this"
                      [RowPrimaryKey]="item.RowPrimaryKey">
                    </ConditionSubsequentDetail>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>